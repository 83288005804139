import React, { useState, useEffect, useContext } from "react";
import clsx from "classnames";
import { Grid, Avatar, Typography, TextField } from "../../Common/ComponentExport";
import { Header } from "../Header";
import { useTranslation } from "react-i18next";
import { EditIcon } from "../../Common/EditIcon";
import { ListAvatar } from "../ListAvatar";
import { Users } from "../../../modelsv2/users";
import { UserCtx } from "../../../context/user/state";
import { AlertCtx } from "../../../context/alert/state";
import { useStyles } from "./EditProfile.style";

interface IPropsEditProfile {
  onBack: Function;
  avatar: string;
  getUserProfile: Function;
  name: string;
}

export const EditProfile = (props: IPropsEditProfile) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onBack, avatar, getUserProfile, name } = props;
  const { userData, updateLocalStateUser } = useContext(UserCtx);
  const { setNotificationSuccess, setNotificationError } = useContext(AlertCtx);
  const [isEditAvatar, setIsEditAvatar] = useState(false);
  const [avatarSelected, setAvatarSelected] = useState(avatar);
  const [nameSelected, setNameSelected] = useState(name);
  const [isLoading, setIsLoading] = useState(false);

  const onEditAvatar = () => {
    setIsEditAvatar(true);
  };

  const onSelectAvatar = (avatar: string) => {
    setAvatarSelected(avatar);
  };

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameSelected(event.target.value);
  };

  const onUpdate = async () => {
    if (!avatarSelected || !nameSelected) return;
    setIsLoading(true);
    try {
      await Users.updateUser({ avatar: avatarSelected, username: nameSelected });
      await getUserProfile({ fetchPolicy: "network-only" });
      updateLocalStateUser({ ...userData, avatar: avatarSelected, username: nameSelected });
      onBack();
      setNotificationSuccess(t("editProfile_Update user profile successfully!"));
    } catch (e: any) {
      setNotificationError(e?.message);
      console.log(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setAvatarSelected(avatar);
  }, [avatar]);

  useEffect(() => {
    setNameSelected(name);
  }, [name]);

  if (isEditAvatar) {
    return <ListAvatar onBack={setIsEditAvatar} onSelectAvatar={onSelectAvatar} avatarSelected={avatarSelected} />;
  }

  return (
    <Grid container className={classes.wrapper}>
      <Header
        className={clsx(classes.header, "stickyHeader")}
        actionLeft={onBack}
        textCenter={t("editProfile_Edit Profile")}
        textRight={t("common_Update")}
        isUnderlineTextRight
        actionRight={onUpdate}
        isLoadingRight={isLoading}
        isDisableTextRight={avatar === avatarSelected && name === nameSelected}
      />
      <Grid item xs={12} md={11} lg={10} className={classes.content}>
        <div className={classes.mainAvatar}>
          <div>
            <Avatar src={avatarSelected} />
            <EditIcon onClick={onEditAvatar} />
          </div>
        </div>
        <div className={classes.fieldName}>
          <Typography variant="h4">{t("common_User Name")}</Typography>
          <TextField
            fullWidth
            value={nameSelected}
            className={classes.inputName}
            onChange={onChangeName}
            disabled={isLoading}
          />
        </div>
      </Grid>
    </Grid>
  );
};
