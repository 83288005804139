import { AppLand } from "../modelsv2/applands";
import { Storage } from "@aws-amplify/storage";
import { Promos } from "../modelsv2/promos";
import { convertToScore } from "./utils";

const APP_URL = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ""
}`;

export const playPromoBattle = async (history: any, promosId?: any, gameType?: string) => {
  try {
    const startAt = Date.now();
    const deviceInfo = gameType === "STREAM" ? await AppLand.getDeviceInfo() : "{}";
    const promoSession = await Promos.startPromoBeat({
      promoId: promosId,
      device: { info: deviceInfo },
    });
    localStorage.removeItem("promoBattleId");
    history.push(
      {
        pathname: `/app/game-session/${promoSession.gameSessionId}`,
        search: `?edgeNodeId=${promoSession.edgeNodeId}&startAt=${startAt}&promoBeat=true`,
      },
      promoSession
    );
  } catch (e: any) {
    console.error("Fail to play promo battle", e);
  }
};

export const drawPromoImage = (beatResult: any, outputFormat: any) => {
  var canvas: any = document.createElement("CANVAS");
  var ctx = canvas.getContext("2d");
  canvas.height = 500;
  canvas.width = 500;

  var bg = new Image();
  bg.src = `${process.env.REACT_APP_ASSETS_ENDPOINT}assets/promoBattles/${beatResult?.promoId}/share.png`;
  bg.crossOrigin = "anonymous";

  bg.onload = function () {
    ctx.drawImage(bg, 0, 0, bg.width, bg.height, 0, 0, 500, 500);

    ctx.font = "bold 23px Arial";
    ctx.fillText(beatResult?.myName, 22, 93);
    ctx.font = "23px Arial";
    ctx.fillText(`Score - ${convertToScore(beatResult?.playerScore, beatResult?.momentType)}`, 22, 121);

    ctx.font = "bold 23px Arial";
    ctx.fillText(beatResult?.username, 22, 325);
    ctx.font = "23px Arial";
    ctx.fillText(`Score - ${convertToScore(beatResult?.opponentScore, beatResult?.momentType)}`, 22, 353);

    const dataURL = canvas.toDataURL(outputFormat);
    saveImageToS3(dataURL);
    shareImage(dataURL, beatResult?.promoId);
  };
};

const saveImageToS3 = (img: any) => {
  var binary = atob(img.split(",")[1]);
  var array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  const imgBlob = new Blob([new Uint8Array(array)], { type: "image/png" });
  const videoS3Url = `share/${Date.now()}.png`;

  try {
    Storage.put(videoS3Url, imgBlob, {
      contentType: "image/png",
    });
    console.log(
      "saved game session image to S3: ",
      `https://onmo-admin-upload-dev.s3.us-east-1.amazonaws.com/public/${videoS3Url}`
    );
    return `https://onmo-admin-upload-dev.s3.us-east-1.amazonaws.com/public/${videoS3Url}`;
  } catch (e) {
    console.log(`Fail to save image of game `, e);
  }
};

const shareImage = async (url: any, battleId?: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const filesArray = [
    new File([blob], "BeatResult.png", {
      type: "image/png",
      lastModified: new Date().getTime(),
    }),
  ];
  const shareData: any = {
    title: "Onmo battle",
    url: `${APP_URL}?promoBattleId=${battleId}`,
  };

  let navigator: any = window.navigator;
  if (navigator.share) {
    if (navigator?.canShare && navigator.canShare({ files: filesArray })) {
      shareData.files = filesArray;
    }

    navigator
      .share(shareData)
      .then(() => {
        console.info("Successfully shared");
      })
      .catch((error: any) => {
        console.error("Unsupported share feature", error);
      });
  } else {
    alert("Unsupported share feature");
  }
};
