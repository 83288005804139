export class Analytics {
  static trackEvent = (e: any): void => {
    try {
      const dataLayer = {
        event: "userEvent",
        category: e.category ? e.category : "",
        action: e.action ? e.action : "",
        label: e.label ? e.label : "",
        value: e.value ? e.value : "",
        moment_type: e.moment_type ? e.moment_type : "",
        game_type: e.game_type ? e.game_type : "",
        currency: e.currency ? e.currency : "",
        opponent: e.opponent ? e.opponent : "",
        user_uid: e.user_uid ? e.user_uid : "",
      };
      //do not delete this log
      console.log("dataLayer: ", dataLayer);

      // Google Analytics
      // @ts-ignore
      window.dataLayer?.push(dataLayer);
    } catch (e) {
      console.warn(e);
    }
  };
}
