export const INITIAL_STATE = {
  friends: null,
  friendRequests: null,
  sentRequests: null,
  openBanner: true,
  recentSearch: null,
  conditionSort: "updatedAt_default",

  sendFriendRequest: () => {
    return new Promise<void>(function (resolve, reject) {
      reject();
    });
  },
  cancelFriendRequest: () => {
    return new Promise<void>(function (resolve, reject) {
      reject();
    });
  },
  acceptFriendRequest: () => {
    return new Promise<void>(function (resolve, reject) {
      reject();
    });
  },
  rejectFriendRequest: () => {
    return new Promise<void>(function (resolve, reject) {
      reject();
    });
  },
  getListFriendRequest: () => {
    return new Promise<void>(function (resolve, reject) {
      reject();
    });
  },
  getListSentRequest: () => {
    return new Promise<void>(function (resolve, reject) {
      reject();
    });
  },
  getUserFriends: () => {
    return new Promise<void>(function (resolve, reject) {
      reject();
    });
  },
  closeBanner: () => {
    return new Promise<void>(function (resolve, reject) {
      reject();
    });
  },
  updateRecentSearch: () => {
    return new Promise<void>(function (resolve, reject) {
      reject();
    });
  },
  setConditionSort: () => {
    return new Promise<void>(function (resolve, reject) {
      reject();
    });
  },
  updateFriendList: () => {},
  updateFriendRequestList: () => {},
  updateFriendRequestSentList: () => {},
};
