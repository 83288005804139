import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  likeIcon: {
    width: 15,
    height: 15,
    minHeight: 15,
    maxHeight: 15,
    maxWidth: 15,
    minWidth: 15,
  },
  heartCount: {
    marginLeft: 4,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: -0.2,
    color: theme.palette.text.primary,
  },
  heartCount2: {
    marginLeft: 4,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "12px",
    letterSpacing: -0.2,
    color: "#FFFFFF",
  },
}));
