import React, { useContext, useReducer, useEffect } from "react";
import { INITIAL_STATE } from "./constants";
import {
  sendFriendRequest,
  getListFriendRequest,
  getListSentRequest,
  acceptFriendRequest,
  getUserFriends,
  closeBanner,
  updateRecentSearch,
  setConditionSort,
  cancelFriendRequest,
  rejectFriendRequest,
  updateFriendList,
  updateFriendRequestList,
  updateFriendRequestSentList,
} from "./dispatchers";
import Reducer from "./reducer";
import { UserCtx } from "../user/state";
import IFriendCtx from "./types";
import { IBaseFriend, IBaseFriendRequest, IUserBaseData } from "../../types/baseInterfaces";
import { MaintenanceCtx } from "../maintenance/state";
import { FriendSubscriptions } from "../../components/Subscriptions";
// import { Friends } from "../../modelsv2/friends";

export const FriendsCtx = React.createContext<IFriendCtx>(INITIAL_STATE);

export default function FriendsProvider({ children }: any) {
  const [state, dispatch] = useReducer(Reducer, INITIAL_STATE);
  const { user, userData } = useContext(UserCtx);
  const { setIsMaintenance } = useContext(MaintenanceCtx);

  const setters = {
    sendFriendRequest: (user: IUserBaseData) =>
      sendFriendRequest(userData.id, user, state.sentRequests, dispatch, setIsMaintenance),
    cancelFriendRequest: (request: IBaseFriendRequest) => cancelFriendRequest(request, state.sentRequests, dispatch),

    acceptFriendRequest: (request: IBaseFriendRequest) =>
      acceptFriendRequest(request, state.friendRequests, dispatch, setIsMaintenance),
    rejectFriendRequest: (request: IBaseFriendRequest) => rejectFriendRequest(request, state.friendRequests, dispatch),

    getListFriendRequest: () => getListFriendRequest(dispatch, setIsMaintenance),
    getListSentRequest: () => getListSentRequest(dispatch, setIsMaintenance),
    getUserFriends: (userId: string, isfetchPolicy = false) =>
      getUserFriends(dispatch, userId, setIsMaintenance, isfetchPolicy),
    closeBanner: () => closeBanner(dispatch),
    updateRecentSearch: (query: string) => updateRecentSearch(dispatch, query),
    setConditionSort: (conditionSort: string) => setConditionSort(dispatch, conditionSort),
    updateFriendList: (friends: IBaseFriend[]) => updateFriendList(dispatch, friends),
    updateFriendRequestList: (friendRequests: IBaseFriendRequest[]) =>
      updateFriendRequestList(dispatch, friendRequests),
    updateFriendRequestSentList: (sentRequests: IBaseFriendRequest[]) =>
      updateFriendRequestSentList(dispatch, sentRequests),
  };

  // const onSenderFriendRequestDeleteCallbacks = async () => {
  //   await Promise.all([
  //     getListFriendRequest(dispatch, setIsMaintenance),
  //     getUserFriends(dispatch, userData.id, setIsMaintenance),
  //   ]);
  // };

  // const onReceiverFriendRequestDeleteCallbacks = async () => {
  //   await Promise.all([
  //     getListSentRequest(dispatch, setIsMaintenance),
  //     getUserFriends(dispatch, userData.id, setIsMaintenance),
  //   ]);
  // };

  let subscriptionOnReceiveFriendDelete: any;
  let subscriptionOnSenderDeleteRequest: any;
  let subscriptionOnReceiveNewNotification: any;

  // const setupSubscriptions = async (userId: any) => {
  //   [subscriptionOnReceiveFriendDelete, subscriptionOnSenderDeleteRequest] = await Promise.all([
  //     Friends.subscribeOnReceiverFriendRequestDelete(userId, onReceiverFriendRequestDeleteCallbacks),
  //     Friends.subscribeOnSenderFriendRequestDelete(userId, onSenderFriendRequestDeleteCallbacks),
  //   ]);
  // };

  useEffect(() => {
    if (user?.attributes?.sub) {
      // setupSubscriptions(user.attributes?.sub);
    }
    return () => {
      subscriptionOnReceiveFriendDelete && subscriptionOnReceiveFriendDelete.unsubscribe();
      subscriptionOnSenderDeleteRequest && subscriptionOnSenderDeleteRequest.unsubscribe();
      subscriptionOnReceiveNewNotification && subscriptionOnReceiveNewNotification.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <FriendsCtx.Provider value={{ ...state, ...setters }}>
      <FriendSubscriptions />
      {children}
    </FriendsCtx.Provider>
  );
}
