import React from "react";
import { ComingSoon } from "../../components/ComingSoon";

export const INITIAL_STATE: any = { isMaintenance: false };
export const MaintenanceCtx = React.createContext<any>({ ...INITIAL_STATE });

export default function MaintenanceProvider({ children }: any) {
  const [isMaintenance, setIsMaintenance] = React.useState(false);

  return (
    <MaintenanceCtx.Provider value={{ setIsMaintenance, isMaintenance }}>
      {isMaintenance ? <ComingSoon /> : children}
    </MaintenanceCtx.Provider>
  );
}
