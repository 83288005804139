import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    color: "#222222",
    fontSize: 16,
    fontWeight: 400,
    minHeight: "78px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  img: {
    marginRight: "14px",
  },
  highlight: {
    fontWeight: "500",
  },
  close: {
    marginRight: "24px;",
  },
}));
