import gql from "graphql-tag";

export const onCreateGameSession = gql`
  subscription OnCreateGameSession {
    onCreateGameSession {
      id
      userId
      appId
      momentId
      edgeNodeId
      matchId
      controller
      incognito
      isStreamReady
      sessionType
      sessionResults
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGameSession = gql`
  subscription OnUpdateGameSession {
    onUpdateGameSession {
      id
      userId
      appId
      momentId
      edgeNodeId
      matchId
      controller
      incognito
      isStreamReady
      sessionType
      sessionResults
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGameSession = gql`
  subscription OnDeleteGameSession {
    onDeleteGameSession {
      id
      userId
      appId
      momentId
      edgeNodeId
      matchId
      controller
      incognito
      isStreamReady
      sessionType
      sessionResults
      createdAt
      updatedAt
    }
  }
`;
export const onGameSessionUpdate = gql`
  subscription OnGameSessionUpdate($id: ID) {
    onGameSessionUpdate(id: $id) {
      id
      userId
      appId
      momentId
      edgeNodeId
      matchId
      controller
      incognito
      isStreamReady
      sessionType
      sessionResults
      createdAt
      updatedAt
    }
  }
`;

export const END_SCREEN = gql`
  subscription EndScreen {
    endScreen {
      gameSessionId
    }
  }
`;
