import React, { useReducer, useContext, useEffect } from "react";
import { IBaseGame, IBaseTransaction } from "../../types/baseInterfaces";
import { MaintenanceCtx } from "../maintenance/state";
import { INITIAL_STATE } from "./constants";
import {
  confirmLogin,
  getUserData,
  login,
  logout,
  updateLocalStateUser,
  updateUserNotification,
  updatePushRequestModal,
  updateLikedGameStatus,
  setListLikedGameStatus,
  connectSimulationWebsocket,
  updateUserTransactions,
  updateUserHasNewNotification,
  updateIsWsClientConnected,
  updateUserWallets,
} from "./dispatchers";
import Reducer from "./reducer";
import IUserCtx from "./types";
import apollo from "../../clients/apollo";
import { UserWallet } from "../../types/others";

export const UserCtx = React.createContext<IUserCtx>(INITIAL_STATE);

export default function UserProvider({ children }: any) {
  const [state, dispatch] = useReducer(Reducer, INITIAL_STATE);
  const { setIsMaintenance } = useContext(MaintenanceCtx);
  const { likedGameStatus } = state;

  useEffect(() => {
    if (!state.user) return;
    let isWsClientConnected = apollo.isWsClientConnected();
    let nbReTry = 0;

    const checkWsConnection = () => {
      if (nbReTry > 100) return;
      nbReTry++;
      isWsClientConnected = apollo.isWsClientConnected();
      if (isWsClientConnected) {
        updateIsWsClientConnected(dispatch, isWsClientConnected);
        return;
      }

      setTimeout(() => {
        checkWsConnection();
      }, 100);
    };

    checkWsConnection();
  }, [state.user]);

  const setters = {
    login: (username: string, password: string) => login(dispatch, username, password),
    logout: () => logout(dispatch),
    confirmLogin: () => confirmLogin(dispatch),
    getUserData: () => getUserData(dispatch),
    updateLocalStateUser: (userData: any) => updateLocalStateUser(dispatch, userData),
    updateUserNotification: (pushSubscription: string) =>
      updateUserNotification(dispatch, pushSubscription, setIsMaintenance),
    updatePushRequestModal: (type: string, action: Boolean) => updatePushRequestModal(dispatch, type, action),
    setListLikedGameStatus: (listGames: IBaseGame[]) => setListLikedGameStatus(dispatch, listGames, likedGameStatus),
    updateLikedGameStatus: (gameId: string, status: boolean, likedCount: number) =>
      updateLikedGameStatus(dispatch, likedGameStatus, gameId, status, likedCount),
    connectSimulationWebsocket: () => connectSimulationWebsocket(dispatch),
    updateUserTransactions: (userTransactions: IBaseTransaction[]) =>
      updateUserTransactions(dispatch, userTransactions),
    updateUserHasNewNotification: (hasNewNotification: boolean) =>
      updateUserHasNewNotification(dispatch, hasNewNotification),
    updateUserWallets: (userWallets: UserWallet[]) => updateUserWallets(dispatch, userWallets),
  };

  return <UserCtx.Provider value={{ ...state, ...setters }}>{children}</UserCtx.Provider>;
}
