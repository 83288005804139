import React from "react";
import { Button, Dialog, Typography } from "../../../../components/Common/ComponentExport";
import { useStyles } from "./DialogAccountDisabled.style";
import AccountDisabled from "../../../../assets/empty_state_imgs/AccountDisabled.svg";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IDialogAccountDisabled {
  isOpen: boolean;
  setIsOpen: Function;
}

export const DialogAccountDisabled = (props: IDialogAccountDisabled) => {
  const { isOpen, setIsOpen } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const history: any = useHistory();

  const backToLogin = () => {
    setIsOpen(false);
    history.push("authenticate/login/email-signin");
  };

  return (
    <Dialog fullScreen open={isOpen} className={classes.containerDialog}>
      <div className={classes.containerAcc}>
        <img alt="" src={AccountDisabled} />
        <Typography className={classes.account}>Account Blocked</Typography>
        <Typography className={classes.yourAccount}>{t("menu_Your account has been blocked")}</Typography>
        <Button
          onClick={() => window.location.assign("https://support.onmostealth.com")}
          className={classes.btnSupport}>
          {t("Contact Support")}
        </Button>
        <Typography onClick={() => backToLogin()} className={classes.btnLogin}>
          {t("menu_Back to Login")}
        </Typography>
      </div>
    </Dialog>
  );
};

export default DialogAccountDisabled;
