import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  root: {
    paddingLeft: 15,
  },
  button: {
    color: theme.palette.primary.contrastText,
    fontSize: 14,
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    minWidth: "100% !important",
    height: 50,
    borderRadius: 50,
  },
  btnPhone: {
    background: "linear-gradient(324.29deg, #489F85 10.69%, #63CBAC 95.4%)",
    "& > svg": {
      position: "absolute",
      left: 20,
    },
    "& > svg > path": {
      stroke: theme.palette.background.default,
    },
  },
  btnGoogle: {
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    "& > svg": {
      position: "absolute",
      left: 20,
    },
  },
  btnApple: {
    background: "#222222",
    "& > svg": {
      position: "absolute",
      left: 24,
    },
  },
  btnFacebook: {
    background: "#1877F2 !important",
    "& > svg": {
      position: "absolute",
      left: 28,
    },
  },
}));
