import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles(() => ({
  mainContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  resetPwdDiv: {
    display: "flex",
    alignItems: "baseline",
  },
  emailConfirmationContainer: {
    maxWidth: 560,
    paddingTop: 40,
    paddingLeft: 20,
    paddingRight: 20,
    "@media(max-height: 568px)": {
      paddingTop: 28,
    },
    "@media(min-height: 569px) and (max-height: 667px)": {
      paddingTop: 32,
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      paddingTop: 36,
    },
  },
  headerTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 500,
    "@media (max-width: 360px)": {
      marginBottom: 20,
    },
    "&.email button": {
      left: 30,
    },
    "& button": {
      position: "absolute",
      left: 4,
    },
  },
  emailTitle: {
    fontWeight: 700,
    fontSize: 31,
    lineHeight: "40px",
    letterSpacing: -0.2,
    color: "#222222",
    "@media(max-height: 568px)": {
      fontSize: 22,
      lineHeight: "28px",
    },
    "@media(min-height: 569px) and (max-height: 667px)": {
      fontSize: 25,
      lineHeight: "32px",
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      fontSize: 28,
      lineHeight: "36px",
    },
  },
  titleGrid2: {
    width: "100%",
    justifyContent: "flex-start",
    paddingTop: "50px !important",
    paddingBottom: "16px !important",
    "@media(max-height: 568px)": {
      paddingTop: "35px !important",
      paddingBottom: "11.9px !important",
    },
    "@media(min-height: 569px) and (max-height: 667px)": {
      paddingTop: "40px !important",
      paddingBottom: "12px !important",
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      paddingTop: "45px !important",
      paddingBottom: "14px !important",
    },
  },
  buttonGrid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "25px !important",
    "@media(max-height: 568px)": {
      paddingTop: "17.5px !important",
    },
    "@media(min-height: 569px) and (max-height: 667px)": {
      paddingTop: "20px !important",
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      paddingTop: "22px !important",
    },
  },
  mutedText: {
    "@media(max-height: 568px)": {
      fontSize: 10.5,
    },
    "@media(min-height: 569px) and (max-height: 667px)": {
      fontSize: 12,
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      fontSize: 13,
    },
  },
  noPadding: {
    padding: "0px !important",
  },
  snackTop: {
    top: 70,
    "@media(max-height: 568px)": {
      fontSize: 50,
    },
    "@media(min-height: 569px) and (max-height: 667px)": {
      top: 56,
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      top: 63,
    },
  },
  title: {
    textAlign: "center",
  },
}));
