import { createUseStyles } from "react-jss";
import { ITheme } from "../../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  gameItem: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "&.isDiscovery": {
      height: 185,
      overflow: "hidden",
      position: "relative",
      minWidth: 140,
      marginRight: 12,
      borderRadius: 20,
    },
  },
  gameAvatar: {
    borderRadius: 20,
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: "#f1f0f0",
    paddingTop: "130%",
    position: "relative",
    "& > img": {
      width: "100%",
      height: "80%",
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      position: "absolute",
      top: 0,
    },
    "& > span": {
      height: "100%",
      position: "absolute",
      top: 0,
    },
  },
  likeGameButton: {
    position: "absolute",
    width: 29,
    height: 29,
    right: 8,
    top: 15,
    background: "#DCDCDD",
    opacity: 0.8,
    borderRadius: "50%",
    boxShadow: "1px 1px 10px rgb(0 0 0 / 25%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
  },
  footer: {
    width: "100%",
    position: "absolute",
    bottom: 0,
    background: "#fff",
    border: "1px solid #C8C8C8",
    borderBottomRightRadius: 19,
    borderBottomLeftRadius: 19,
  },
  gameName: {
    padding: "7px 9px 0px 9px",
    height: 25,
    fontWeight: 500,
    fontSize: 16,
    color: "#000",
    lineHeight: "18px",
    letterSpacing: -0.2,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "@media(max-height: 667px)": {
      fontSize: 11.2,
      marginTop: 1.6,
      lineHeight: "16px",
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      fontSize: 12.6,
      marginTop: 1.8,
      lineHeight: "18px",
    },
  },
  bottom: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 10px 10px 10px",
  },
  gameTypeAndLikeCount: {
    display: "flex",
    flexDirection: "column",
    "&.isDiscovery": {
      maxWidth: "60%",
    },
  },
  gameType: {
    fontWeight: 400,
    fontSize: 10,
    color: "#8A8A8E",
    lineHeight: "14.72px",
    width: 120,
    height: 15,
    textTransform: "uppercase",
  },
  likeCount: {
    display: "flex",
    alignItems: "center",
    "& > img": {
      width: 10,
      height: 9,
    },
    "& > p": {
      marginLeft: 3,
      fontWeight: 400,
      fontSize: 10,
      color: "#FFFFFF",
      lineHeight: "18px",
      letterSpacing: -0.2,
    },
  },
  playBtn: {
    fontSize: 12,
    fontWeight: 500,
    color: "rgba(0, 0, 0, 0.75)",
    lineHeight: "18px",
    height: 22,
    width: 51,
    background: theme.palette.background.default,
    borderRadius: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
