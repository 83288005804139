import React from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "../../Common/ComponentExport";
import { IBaseGame } from "../../../types/baseInterfaces";
import { Typography } from "../../Common/ComponentExport";
import { useTranslation } from "react-i18next";
import { useStyles } from "./ListTopGames.style";
import { GameItemCard } from "../../Common/GameCard/GameItemCard";

interface IPropsListTopGames {
  topGames?: IBaseGame[] | null;
}

export const ListTopGames = (props: IPropsListTopGames) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { topGames } = props;

  const onViewAll = () => {
    history.push("/app/games");
  };

  if (!topGames) {
    return null;
  }

  return (
    <div>
      <Grid container>
        <div className={classes.sectionTitle}>
          <Typography variant="h2">{t("common_Top Games")}</Typography>
          <Typography variant="h4" onClick={onViewAll} data-testid="click-view-all">
            {t("common_View All")}
          </Typography>
        </div>
        <div className={classes.contentTopGames}>
          <div>
            {topGames?.map((game: any, idx: number) => {
              return <GameItemCard game={game} key={idx} isChingari isDiscovery />;
            })}
          </div>
        </div>
      </Grid>
    </div>
  );
};
