import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import clsx from "classnames";
import { signInPhoneSchema } from "../../helpers/yupPhone";
import { Formik, Form } from "formik";
import { Auth } from "@aws-amplify/auth";
import { OnmoLoading } from "../../../../components/OnmoLoading";
import { useTranslation } from "react-i18next";
import { onboardingStyles } from "../../SignIn.style";
import { useStyles } from "./PhoneSignIn.style";
import { phone } from "phone";
import { THEME_PHONE_PREFIX, THEME_NAME } from "../../../../utils/theme";
import { Analytics } from "../../../../modelsv2/analytics";
import { CATEGORY } from "../../../../constants/analyticsEvent";
import { AlertCtx } from "../../../../context/alert/state";
import { Button, Typography, Grid, Container } from "../../../../components/Common/ComponentExport";
import { UserCtx } from "../../../../context/user/state";
import DialogAccountDisabled from "../DialogAccountDisabled/DialogAccountDisabled";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ThemeName } from "../../../../constants/theme";
import apollo from "../../../../clients/apollo";

interface IPropsPhoneSignIn {
  setCognitoPhone: Function;
}

export default function PhoneSignIn(props: IPropsPhoneSignIn) {
  const { t } = useTranslation();
  const { setCognitoPhone } = props;
  const history: any = useHistory();
  const { setNotificationError } = useContext(AlertCtx);
  const { userData } = useContext(UserCtx);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  // eslint-disable-next-line no-unused-vars
  const onboardingClasses = onboardingStyles();
  const classes = useStyles();
  const [label, setLabel] = useState<boolean>(true);

  const onBack = () => {
    history.push("/");
  };

  // Fix placeholder position according to the selected country's regional code
  useEffect(() => {
    if (label) {
      const focus: HTMLInputElement | null = document.getElementById("focus") as HTMLInputElement;
      const phoneNumberPlaceholder: HTMLElement | null = document.getElementById("phoneNumberPlaceholder");
      if (phoneNumberPlaceholder != null && focus != null) {
        // FIXME: rough approximation of the regional code's width
        phoneNumberPlaceholder.style.left = `${92 + focus?.value.length * 10}px`;
      }
    }
  });

  return (
    <div>
      <Container className={onboardingClasses.container}>
        {formLoading ? <OnmoLoading /> : null}
        <Grid container className={onboardingClasses.mainContent}>
          <Grid item>
            <Grid container className={onboardingClasses.resetPwdDiv}>
              <Grid item xs={3}>
                <Button data-testid="button-cancel" onClick={onBack} className={onboardingClasses.backButton}>
                  {t("common_Cancel")}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Typography className={clsx(onboardingClasses.fontWeight500, classes.title)} variant="h3">
                  {t("common_Sign Up")}
                </Typography>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
            <hr className={onboardingClasses.headerHr} />
            <Grid item>
              <Formik
                initialValues={{
                  phoneNumber: `${THEME_PHONE_PREFIX || ""}`,
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  const stripedNumber = phone(`+${values?.phoneNumber}`).phoneNumber;
                  if (!formLoading && stripedNumber) {
                    setSubmitting(true);
                    setFormLoading(true);
                    try {
                      const cognitoUserRes = await Auth.signIn(stripedNumber);
                      apollo.closeSubscriptionClient(); // trigger wsClient reconnect with authToken
                      setCognitoPhone(cognitoUserRes);
                      history.push("/authenticate/login/phone-confirmation-code");
                      setFormLoading(false);
                    } catch (e: any) {
                      Analytics.trackEvent({
                        category: CATEGORY.LOGIN,
                        action: "fail",
                        label: "phone",
                        user_uid: userData?.id,
                      });
                      if (e.message === "User does not exist.") {
                        try {
                          await Auth.signUp({
                            username: stripedNumber,
                            password: Date.now().toString() + "dD",
                            attributes: {
                              preferred_username: Date.now().toString() + "dD",
                              picture: "",
                              email: "",
                            },
                          });
                          const cognitoUserRes = await Auth.signIn(stripedNumber);
                          localStorage.setItem(`sign_up_phone`, stripedNumber);
                          setCognitoPhone(cognitoUserRes);
                          history.push("/authenticate/login/phone-confirmation-code");
                        } catch (error) {
                          Analytics.trackEvent({
                            category: CATEGORY.LOGIN,
                            action: "fail",
                            label: "phone",
                            user_uid: userData?.id,
                          });
                          setFormLoading(false);
                          setNotificationError(t("phoneSignIn_Please, provide a valid phone number."));
                        }
                      } else if (e.message === "User is disabled.") {
                        setIsOpen(true);
                      } else {
                        console.error(`Fail to login`, e);
                        setNotificationError(t("common_There was an issue with the login, please try again later."));
                        setFormLoading(false);
                      }
                    }
                  }
                }}
                validationSchema={signInPhoneSchema}>
                {(props: any) => {
                  const { handleChange, handleSubmit, setFieldValue, values } = props;

                  let submitEnabled = phone(`+${values.phoneNumber}`).isValid;

                  return (
                    <Form>
                      <Grid item>
                        <Grid container>
                          <Grid item xs={12} className={onboardingClasses.titleGrid}>
                            <Typography variant="h1" className={onboardingClasses.title}>
                              {t("phoneSignIn_What's your phone number?")}
                            </Typography>
                            <Typography variant="h4" className={onboardingClasses.subtitle}>
                              {t("phoneSignIn_We’ll send you a 4 digits verification code.")}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={classes.phoneNumberGrid}>
                            <Typography variant="h4" className={onboardingClasses.label}>
                              {t("common_Phone Number")}
                            </Typography>
                            <PhoneInput
                              country={"in"}
                              value={values.phoneNumber}
                              onChange={(phone: string) => {
                                handleChange(phone);
                                setFieldValue("phoneNumber", phone);
                              }}
                              inputProps={{ className: clsx(classes.inputCustom, "form-control") }}
                              buttonClass={classes.btnDropDown}
                              dropdownClass={classes.content}
                            />
                            {label ? (
                              <Typography
                                variant="body1"
                                id="phoneNumberPlaceholder"
                                onClick={() => {
                                  setLabel(false);
                                  const inputElement = document.getElementsByClassName("form-control")[0] as any;
                                  if (inputElement) {
                                    inputElement?.focus();
                                  }
                                  document?.getElementById("focus")?.focus();
                                }}
                                className={classes.phoneNumberPlaceholder}>
                                {t("common_Your Number")}
                              </Typography>
                            ) : null}
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              isSubmit
                              data-testid="button-send"
                              fullWidth={true}
                              variant="contained"
                              className={clsx(
                                onboardingClasses.signin,
                                !submitEnabled && onboardingClasses.btnDisabled,
                                THEME_NAME === ThemeName.VodafoneIndia && classes.initialColorButton
                              )}
                              onClick={() => handleSubmit()}
                              disabled={!submitEnabled}>
                              {t("common_Send Code")}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <DialogAccountDisabled isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
}
