import React from "react";
import { isIOS } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { OnmoPopup } from "../OnmoPopup";
import { subscribeNotification } from "../../utils/subscribePushNotification";
import { UserCtx } from "../../context/user/state";
import { ACTION_TYPES, ModalContent, AddFriendModalContent } from "./constants";
import { Analytics } from "../../modelsv2/analytics";
import { CATEGORY } from "../../constants/analyticsEvent";
import { useTranslation } from "react-i18next";
import { Typography } from "../Common/ComponentExport";

export function PushModal() {
  const { t } = useTranslation();
  const { pushModalData, userData, updateUserNotification, updatePushRequestModal } = React.useContext(UserCtx);
  const history = useHistory();

  const registerPushNotifications = async () => {
    const pushSubscription = await subscribeNotification();
    if (pushSubscription) {
      await updateUserNotification(pushSubscription);
    }
  };

  if (!userData?.id || (userData.pushSubscription && JSON.parse(userData.pushSubscription)) || !pushModalData)
    return null;

  let title: string = ModalContent.title;
  let description: string = ModalContent.description;
  if (pushModalData.type === ACTION_TYPES.ADD_FRIEND) {
    title = AddFriendModalContent.title;
    description = AddFriendModalContent.description;
  }

  if (pushModalData.action) {
    Analytics.trackEvent({
      category: CATEGORY.NOTIF_POPUP,
      action: "view",
      label: "Notif permission view popup",
      user_uid: userData?.id,
    });
  }

  const onAgree = async () => {
    if (!isIOS) {
      await registerPushNotifications();
    }
    updatePushRequestModal(pushModalData.type, false);
    Analytics.trackEvent({
      category: CATEGORY.NOTIF_POPUP,
      action: "success",
      label: "Notif permission is successful",
      user_uid: userData?.id,
    });
    history.push({ pathname: `/app/settings/notification` });
  };

  const onDisagree = () => {
    updatePushRequestModal(pushModalData.type, false);
    Analytics.trackEvent({
      category: CATEGORY.NOTIF_POPUP,
      action: "cancel",
      label: "Notif permission cancel",
      user_uid: userData?.id,
    });
  };

  return (
    <OnmoPopup
      isOpen={pushModalData.action}
      title={t(title)}
      isPriorityConfirm={true}
      onAgree={onAgree}
      onDisagree={onDisagree}
      disableActions={false}
      readOnly={false}>
      <Typography>{t(description)}</Typography>
    </OnmoPopup>
  );
}

export default PushModal;
