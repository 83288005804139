import React, { useState, useContext } from "react";
import Auth from "@aws-amplify/auth";
import ErrorOutlineIcon from "../../../assets/ErrorOutlineIcon.svg";
import ArrowBackIosIcon from "../../../assets/ArrowBackIosIcon.svg";
import { Form, Formik } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { OnmoLoading } from "../../../components/OnmoLoading";
import { onboardingStyles } from "../../SignIn/SignIn.style";
import { useStyles as phoneSignInstyles } from "../../SignIn/partials/PhoneSignIn/PhoneSignIn.style";
import { useTranslation } from "react-i18next";
import { AlertCtx } from "../../../context/alert/state";
import { useStyles } from "../ResetPassword.style";
import { Container, Button, Typography, Grid, TextField } from "../../../components/Common/ComponentExport";
import clsx from "classnames";

const resetPasswordSchema = Yup.object({
  password: Yup.string("")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase and One Number"
    )
    .required("Enter your password"),
});

interface IPropsNewPasswordForm {
  accountEmail: string;
  accountCode: string;
  setAccountCode: Function;
  setCodeValidationError: Function;
}

export default function NewPasswordForm(props: IPropsNewPasswordForm) {
  const { t } = useTranslation();
  const { accountEmail, accountCode, setAccountCode, setCodeValidationError } = props;
  const history = useHistory();
  const classes = useStyles();
  const onboardingClasses = onboardingStyles();
  const phoneSignInClasses = phoneSignInstyles();
  const { setNotificationError } = useContext(AlertCtx);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [togglePassword, setTogglePassword] = useState<boolean>(false);
  const [actionPreventError, setActionPreventError] = useState<boolean>(false);

  return (
    <div>
      <Container className={onboardingClasses.container}>
        {formLoading ? <OnmoLoading /> : null}
        <Grid container className={classes.mainContent}>
          <Grid item>
            <Grid container className={classes.resetPwdDiv}>
              <Grid item xs={3}>
                <Button
                  data-testid="button-acc"
                  startIcon={<img alt="" src={ArrowBackIosIcon} />}
                  className={classes.backButton}
                  onClick={() => {
                    setAccountCode("");
                  }}>
                  &nbsp;
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Typography className={onboardingClasses.fontWeight500}>{t("common_Reset Password")}</Typography>
              </Grid>
            </Grid>
            <hr className={onboardingClasses.headerHr} />
            <Grid item>
              <Formik
                initialValues={{
                  password: "",
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  if (!formLoading) {
                    setSubmitting(true);
                    setFormLoading(true);
                    try {
                      await Auth.forgotPasswordSubmit(accountEmail, accountCode, values.password);
                      const data = {
                        toast: { status: "success", message: t("common_Password reset successfully!") },
                      };
                      history.push("/authenticate/login/email-signin", data);
                    } catch (error: any) {
                      setFormLoading(false);
                      if (error.message === "Invalid verification code provided, please try again.") {
                        setAccountCode("");
                        setCodeValidationError(true);
                      } else {
                        setNotificationError(error.message);
                      }
                    }
                  }
                }}
                validationSchema={resetPasswordSchema}>
                {(props: any) => {
                  const { values, touched, errors, handleChange, handleBlur, handleSubmit } = props;
                  return (
                    <Form>
                      <Grid item>
                        <Grid container>
                          <Grid item xs={12} className={onboardingClasses.titleGrid}>
                            <Typography className={onboardingClasses.title}>
                              {t("newPasswordForm_Password Creation")}
                            </Typography>
                            <Typography className={onboardingClasses.subtitle}>
                              {t("newPasswordForm_Please create your new password below.")}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} className={phoneSignInClasses.phoneNumberGrid}>
                            <Typography className={onboardingClasses.label}>{t("common_Password")}</Typography>
                            <TextField
                              fullWidth
                              type={togglePassword ? "text" : "password"}
                              name="password"
                              placeholder="**********"
                              variant="outlined"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.password && touched.password && actionPreventError
                                  ? onboardingClasses.error
                                  : onboardingClasses.textFieldRoot
                              }
                              inputStyle={onboardingClasses.textFieldRoot}
                              endAdornment={
                                <React.Fragment>
                                  {errors.password && touched.password && actionPreventError ? (
                                    <img alt="" src={ErrorOutlineIcon} className={onboardingClasses.errorIconColor} />
                                  ) : values.password ? (
                                    <span
                                      className={classes.showPassword}
                                      onClick={() => setTogglePassword(!togglePassword)}>
                                      {togglePassword ? t("common_HIDE") : t("common_SHOW")}
                                    </span>
                                  ) : (
                                    <span className={classes.showPassword}>
                                      {values.password ? (
                                        <span onClick={() => setTogglePassword(!togglePassword)}>
                                          {togglePassword ? t("common_HIDE") : t("common_SHOW")}
                                        </span>
                                      ) : (
                                        <span className="disabled">{t("common_SHOW")}</span>
                                      )}
                                    </span>
                                  )}
                                </React.Fragment>
                              }
                            />
                            <Typography
                              className={clsx(
                                onboardingClasses.passwordErrorHelperText,
                                errors.password && actionPreventError
                                  ? onboardingClasses.passwordErrorHelperText
                                  : onboardingClasses.passwordactionPreventErrorTextLeft
                              )}>
                              {t("common_Must Contain 8 Characters, One Uppercase, One Lowercase and One Number")}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              isSubmit
                              fullWidth
                              data-testid="button-save"
                              variant="contained"
                              className={onboardingClasses.signin}
                              onClick={() => {
                                setActionPreventError(true);
                                handleSubmit();
                              }}
                              disabled={!values.password}>
                              {t("newPasswordForm_Save New Password")}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
