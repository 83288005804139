import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  paper: {
    borderRadius: 15,
    width: "90%",
    background: theme.palette.background.default,
    overflow: "inherit",
  },
  dialogContent: {
    marginBottom: 15,
    padding: 15,
    overflow: "auto",
  },
  imgBattle: {
    position: "absolute",
    height: 110,
    width: 110,
    top: -55,
    left: "calc(50% - 55px)",
  },
  headerPopup: {
    display: "flex",
    justifyContent: "space-between",
  },
  content: {
    textAlign: "center",
    marginTop: 25,
    boxSizing: "border-box",
  },
  textCongrat: {
    fontWeight: 700,
  },
  contentLoading: {
    height: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textStatus: {
    fontWeight: 700,
  },
  textDesc: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    marginTop: 10,
  },
  contentLB: {
    margin: "20px 0",
    backgroundColor: "#EEE",
    borderRadius: 30,
    "&::-webkit-scrollbar": {
      display: "none",
    },
    display: "flex",
    flexDirection: "column",
    padding: 10,
  },
  btnAccept: {
    height: 50,
    borderRadius: 50,
    background: theme.button.primary.background,
    color: theme.palette.primary.contrastText,
    fontSize: 14,
    fontWeight: 700,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > h5": {
      color: theme.palette.primary.contrastText,
      fontWeight: 700,
      textDecoration: "underline",
      margin: "0px 5px",
      "@media(max-width: 330px)": {
        fontSize: 13,
      },
    },
    "@media(max-width: 330px)": {
      fontSize: 13,
    },
  },
  btnPlayAgain: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    marginTop: 20,
    textDecoration: "underline",
  },
}));
