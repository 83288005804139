import React from "react";
import { useStyles } from "./SlideUpPopup.style";
import { ReactComponent as SlideUpIcon } from "../../../assets/Game/slide-up.svg";
import { Dialog } from "../ComponentExport";

interface ISlideUpPopupProps {
  isOpen: boolean;
  onClose: Function;
}

export const SlideUpPopup = (props: ISlideUpPopupProps) => {
  const classes = useStyles();
  const { isOpen, onClose } = props;

  return (
    <Dialog isSlideClose open={isOpen} onClose={() => onClose()} className={classes.paper}>
      <SlideUpIcon onClick={() => onClose()} onTouchMove={() => onClose()} />
    </Dialog>
  );
};
