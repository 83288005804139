import React, { useState, useEffect } from "react";
import { IOnboardingGame } from "../../../types/baseInterfaces";
import { ContentOnboarding } from "../../Common/ContentOnboarding";
import { ReactComponent as PlayIcon } from "../../../assets/Game/Play.svg";
import { useStyles } from "./OnboardingCard.style";

interface IOnboardingCardProps {
  game: IOnboardingGame;
  idx: number;
  activeIndex: number;
  onToggleLike: Function;
  onPlayGame: Function;
  loadedPercentage: number;
  isStarted: boolean;
}

export const OnboardingCard = (props: IOnboardingCardProps) => {
  const classes = useStyles();
  const { game, idx, activeIndex, onToggleLike, onPlayGame, loadedPercentage, isStarted } = props;
  const [videoDuration, setVideoDuration] = useState(0);
  const [isPause, setIsPause] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const onToggleVideo = () => {
    const videoGameElement = document.getElementById(game?.id) as HTMLMediaElement;
    if (videoGameElement.currentTime === videoGameElement.duration) {
      videoGameElement?.pause();
      setIsPause(true);
      videoGameElement.currentTime = 0;
      return;
    }
    if (videoGameElement?.paused) {
      videoGameElement?.play();
      setIsPause(false);
    } else {
      videoGameElement?.pause();
      setIsPause(true);
    }
  };

  const onSetVideoTime = (timeToUpdate: number) => {
    const videoGameElement = document.getElementById(game?.id) as HTMLMediaElement;
    if (videoGameElement) {
      videoGameElement.currentTime = timeToUpdate;
    }
  };

  useEffect(() => {
    const videoGameElement = document.getElementById(game?.id) as HTMLMediaElement;
    const onChangeDuration = () => {
      if (videoGameElement.readyState) {
        setVideoDuration(parseInt(videoGameElement?.duration.toString(), 10));
      }
    };
    videoGameElement.addEventListener("durationchange", onChangeDuration);
    return () => {
      videoGameElement.removeEventListener("loadeddata", onChangeDuration);
    };
  }, [game, activeIndex, isStarted]);

  useEffect(() => {
    const checkResizeBrowser = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener("resize", checkResizeBrowser);
    return () => {
      window.removeEventListener("resize", checkResizeBrowser);
    };
  }, []);

  return (
    <>
      <video id={game?.id} className={classes.videoGame} muted playsInline={true} onClick={onToggleVideo} autoPlay>
        <source
          src={`${process.env.REACT_APP_ASSETS_ENDPOINT}assets/onboarding/games/${game?.id}/gameplay.mp4`}
          type="video/mp4"
        />
      </video>
      {isPause && <PlayIcon className={classes.playIcon} onClick={onToggleVideo} />}
      {idx === activeIndex && (
        <ContentOnboarding
          style={{ top: windowHeight - 172 }}
          className={classes.content}
          videoDuration={videoDuration}
          game={game}
          onPlayVideo={onToggleVideo}
          isPause={isPause}
          onToggleLike={onToggleLike}
          onPlayGame={onPlayGame}
          loadedPercentage={loadedPercentage}
          onSetVideoTime={onSetVideoTime}
          isStarted={isStarted}
        />
      )}
    </>
  );
};
