import gql from "graphql-tag";

export const listPromos = gql`
  query ListPromos {
    listPromos {
      id
      userId
      momentId
      gameId
      startDate
      score
      endDate
      user {
        id
        avatar
        username
      }
      game {
        id
        title
        type
      }
      moment {
        title
        snapshotId
        appId
        id
      }
    }
  }
`;
