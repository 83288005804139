import React from "react";
import { useStyles } from "../OnmoLoading.style";
import clsx from "classnames";
import LogoIcon from "../../../assets/GuestMode/OnmoBlack.svg";
import { Grid } from "../../Common/ComponentExport";

export function DynamicLoading() {
  const classes = useStyles();

  return (
    <>
      <Grid container className={clsx(classes.noBackground, classes.container)}>
        <div className={classes.content}>
          <div className={clsx(classes.spinner, classes.spinnerDynamic)} />
          <img src={LogoIcon} alt="icon-logo-black" />
        </div>
      </Grid>
    </>
  );
}

export default DynamicLoading;
