import React from "react";
import { isEmpty } from "lodash-es";
import { getSquare, onErrorGetGameCard } from "../../../utils/getGameImages";
import AddIcon from "../../../assets/Chingari/Add.svg";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography, Button, Avatar } from "../../Common/ComponentExport";
import { convertToScore } from "../../../utils/utils";
import { Analytics } from "../../../modelsv2/analytics";
import { CATEGORY } from "../../../constants/analyticsEvent";
import { useStyles } from "./BeatItCard.style";

interface IPropsBeatItCard {
  gameId: string;
  userAvatar?: string;
  username?: string;
  score: number;
  gameTitle?: string;
  momentType?: string;
  gameType?: string;
  userId?: string;
}

export const BeatItCard = (props: IPropsBeatItCard) => {
  const history = useHistory();
  const { gameId, userAvatar, username, score, gameTitle, momentType, gameType, userId } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const onClickBeatIt = () => {
    Analytics.trackEvent({
      category: CATEGORY.TOURNAMENT,
      action: "beatit_click",
      label: gameTitle,
      game_type: gameType,
      user_uid: userId,
    });
    history.push(`/app/play/${gameId}`);
  };

  return (
    <div className={classes.contentBeatItCard}>
      <div className={classes.beatItAvatars}>
        <img
          id={`beat-it-${gameId}`}
          src={getSquare(gameId)}
          alt="game-card"
          onError={() => onErrorGetGameCard(`beat-it-${gameId}`)}
        />
        <div>
          <Avatar
            src={!isEmpty(userId) ? userAvatar : AddIcon}
            alt="avatar"
            className={!isEmpty(userId) ? classes.avatar : classes.noAvatar}
          />
        </div>
      </div>
      <div className={classes.beatItMoreDetail}>
        {!username ? (
          <Typography className={classes.textNone}>{t("Be the first one in this competition")}</Typography>
        ) : (
          <>
            <Typography variant="h5" className={classes.beatItUsername}>
              {username}
            </Typography>
            <Typography variant="h6" className={classes.beatItTitle}>
              {momentType?.toLowerCase() === "score" ? "Score to beat:" : "Time to beat:"}
            </Typography>
            <Typography variant="h2" className={classes.beatItNumber}>
              {convertToScore(score, momentType)}
            </Typography>
          </>
        )}
      </div>
      <Button data-testid="button-beatIt" variant="contained" className={classes.beatItBtn} onClick={onClickBeatIt}>
        {t("Beat It")}
      </Button>
    </div>
  );
};
