import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  wrapper: {
    "& > div": {
      margin: "auto",
    },
  },
  header: {
    background: "#260E2E",
    "& > p": {
      color: theme.palette.primary.contrastText,
      fontSize: 16,
      fontWeight: 500,
    },
  },
  contentAvatar: {
    padding: "0px 7px",
    background: "#260E2E",
    margin: "0px !important",
    "& > div": {
      display: "flex",
      justifyContent: "center",
    },
    "& > div > div": {
      height: 69,
      width: 69,
      position: "relative",
    },
  },
  selectedIcon: {
    height: 22,
    width: 22,
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  activeAvatar: {
    border: `3px solid ${theme.palette.primary.main}`,
  },
}));
