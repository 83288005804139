export async function deleteOldCache(): Promise<boolean> {
  try {
    const response = await fetch("/version.txt");
    const version = await response.text();

    // If the current version is older
    const latestVersionNumber = parseInt(version, 10);
    const currentVersionNumber = parseInt(process.env.REACT_APP_BUILD || "", 10);

    if (
      !Number.isNaN(latestVersionNumber) &&
      !Number.isNaN(currentVersionNumber) &&
      latestVersionNumber !== currentVersionNumber
    ) {
      const cachesNames = await caches.keys();
      await Promise.all(cachesNames.map((name) => caches.delete(name)));
      return true;
    }
  } catch (e) {
    console.error(e);
  }

  return false;
}
