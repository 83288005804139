import React from "react";
import clsx from "classnames";
import { map } from "lodash-es";
import { Header } from "../Header";
import { useTranslation } from "react-i18next";
import { Grid, Avatar } from "../../Common/ComponentExport";
import { getListAvatar } from "../../Friend/helpers";
import SelectedIcon from "../../../assets/friend_icons/selected.svg";
import { useStyles } from "./ListAvatar.style";

interface IPropsListAvatar {
  onBack: Function;
  onSelectAvatar: Function;
  avatarSelected: string;
}

export const ListAvatar = (props: IPropsListAvatar) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { onBack, onSelectAvatar, avatarSelected } = props;

  return (
    <Grid container className={classes.wrapper}>
      <Header
        className={clsx(classes.header, "stickyHeader")}
        actionLeft={() => onBack()}
        textCenter={t("common_Choose an Avatar")}
      />
      <Grid item xs={12} md={11} lg={10}>
        <Grid container spacing={8} className={classes.contentAvatar}>
          {map(getListAvatar(), (link) => (
            <Grid item xs={3} md={2} lg={2}>
              <div>
                <Avatar
                  src={link.src}
                  onClick={() => onSelectAvatar(link.src)}
                  className={clsx(avatarSelected === link?.src && classes.activeAvatar)}
                />
                {avatarSelected === link.src && (
                  <img src={SelectedIcon} alt="selected-icon" className={classes.selectedIcon} />
                )}
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
