import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "../../Common/ComponentExport";
import { LoginHeader } from "../LoginHeader";
import { LoginMethods } from "../LoginMethods";
import { ThemeName } from "../../../constants/theme";
import { getTheme } from "../../../utils/theme";
import { useStyles } from "./Login.style";

export const Login = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const THEME = getTheme();

  const onClickTerms = () => {
    //@ts-ignore
    window.open(THEME?.termsAndConditions, "_blank");
  };

  const onClickPrivacy = () => {
    //@ts-ignore
    window.open(THEME.privacyPolicy, "_blank");
  };

  return (
    <div className={classes.root}>
      <LoginHeader />
      <div>
        <Typography variant="body1" className={classes.title}>
          Connect your account to start
        </Typography>
        <LoginMethods />
        <Typography className={classes.agreement}>
          {`${t("common_By signing up or signing in to an account, you agree to", { name: ThemeName.Onmo })}`}{" "}
          <span className={classes.link} onClick={onClickTerms}>
            {t("common_Terms of Use")}
          </span>
          {` ${t("common_and")} `}
          <span className={classes.link} onClick={onClickPrivacy}>
            {t("common_Privacy Policy")}
          </span>
        </Typography>
      </div>
    </div>
  );
};
