import React from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "../../modelsv2/auths";
import { Users } from "../../modelsv2/users";
import { ACTIONS } from "./actions";
import apollo from "../../clients/apollo";
import { IBaseGame, IBaseTransaction, LikedGameStatus } from "../../types/baseInterfaces";
import { io } from "socket.io-client";
import { UserWallet } from "../../types/others";

export const login = async (dispatch: React.Dispatch<any>, username: string, password: string) => {
  try {
    apollo.closeSubscriptionClient(); // trigger wsClient reconnect with authToken
    await Auth.signin(username, password);
    const user: CognitoUser = await Auth.currentAuthenticatedUser();
    dispatch({ type: ACTIONS.LOGIN, user });
    return user;
  } catch (e) {
    console.error("Fail to log in", e);
  }
};

export const logout = async (dispatch: React.Dispatch<any>) => {
  try {
    localStorage.removeItem("login_method");
    localStorage.removeItem("has_signed_up");
    localStorage.removeItem("expire_feedback");
    localStorage.removeItem("partner");
    await apollo.getInstance().cache.reset();
    await Auth.signOut();
    dispatch({ type: ACTIONS.LOGOUT });
  } catch (e) {
    console.error("Fail to log out", e);
  }
};

export const confirmLogin = async (dispatch: React.Dispatch<any>) => {
  try {
    const user: CognitoUser = await Auth.currentAuthenticatedUser();
    dispatch({ type: ACTIONS.CONFIRMLOGIN, user });
    return user;
  } catch (error) {
    dispatch({ type: ACTIONS.DENYLOGIN, error });
  }
};

export const getUserData = async (dispatch: React.Dispatch<any>) => {
  try {
    const userDataV2 = await Users.getUserV2({ fetchPolicy: "network-only" });
    dispatch({ type: ACTIONS.SET_USER_DATA, userData: { ...userDataV2 } });
    dispatch({ type: ACTIONS.UPDATE_WALLETS, userWallets: userDataV2.wallets });
    return userDataV2;
  } catch (e: any) {
    throw new Error(e?.message);
  }
};

export const updateUserNotification = async (
  dispatch: React.Dispatch<any>,
  pushSubscription: string,
  setIsMaintenance: Function
) => {
  try {
    const userData = await Users.updateUserSubscriptions(pushSubscription, setIsMaintenance);
    dispatch({ type: ACTIONS.SET_USER_DATA, userData });
    return userData;
  } catch (e) {
    console.error("Fail to update push notification of user", e);
  }
};

export const updateLocalStateUser = (dispatch: React.Dispatch<any>, userData: any) => {
  dispatch({ type: ACTIONS.SET_USER_DATA, userData });
};

export const updatePushRequestModal = async (dispatch: React.Dispatch<any>, type: string, action: Boolean) => {
  try {
    const pushModalData: any = { type: type, action: action };
    dispatch({ type: ACTIONS.SET_PUSH_MODAL_DATA, pushModalData });
    return true;
  } catch (e) {
    console.error("Fail to update push request modal", e);
  }
};

export const setListLikedGameStatus = (
  dispatch: React.Dispatch<any>,
  listGames: IBaseGame[],
  likedGameStatus: LikedGameStatus[]
) => {
  const missingGamesList =
    likedGameStatus.length !== 0
      ? listGames.filter((item: IBaseGame) => likedGameStatus.find((i: LikedGameStatus) => i.gameId !== item.id))
      : listGames;
  const addedList = missingGamesList.map((item: IBaseGame) => {
    return { gameId: item.id, status: item.isLike, likedCount: item.likeCount };
  });
  dispatch({ type: ACTIONS.UPDATE_LIKED_GAME_STATUS, likedGameStatus: [...likedGameStatus, ...addedList] });
};

export const updateLikedGameStatus = (
  dispatch: React.Dispatch<any>,
  likedGameStatus: LikedGameStatus[],
  gameId: string,
  status: boolean,
  likedCount: number
) => {
  const updatedList = likedGameStatus.map((item: LikedGameStatus) => {
    if (item.gameId === gameId) {
      item.status = status;
      item.likedCount = likedCount;
    }
    return item;
  });
  dispatch({ type: ACTIONS.UPDATE_LIKED_GAME_STATUS, likedGameStatus: updatedList });
};

export const connectSimulationWebsocket = (dispatch: React.Dispatch<any>) => {
  const onmoAPI = process.env.REACT_APP_HTML_GAME_SIMULATION_URL || "https://dev-html-simulation.onmostealth.com/";

  const simulationWS = io(onmoAPI, {
    transports: ["websocket"],
  });

  dispatch({ type: ACTIONS.UPDATE_WEBSOCKET_OF_SIMULATION, simulationWS });
};

export const updateUserTransactions = (dispatch: React.Dispatch<any>, userTransactions: IBaseTransaction[]) => {
  dispatch({ type: ACTIONS.UPDATE_USER_TRANSACTIONS, userTransactions });
};

export const updateUserHasNewNotification = (dispatch: React.Dispatch<any>, hasNewNotification: boolean) => {
  dispatch({ type: ACTIONS.UPDATE_USER_HAS_NEW_NOTIFICATION, hasNewNotification });
};

export const updateIsWsClientConnected = (dispatch: React.Dispatch<any>, isWsClientConnected: boolean) => {
  dispatch({ type: ACTIONS.UPDATE_IS_WS_CLIENT_CONNECTED, isWsClientConnected });
};

export const updateUserWallets = (dispatch: React.Dispatch<any>, userWallets: UserWallet[]) => {
  dispatch({ type: ACTIONS.UPDATE_WALLETS, userWallets });
};
