import { createUseStyles } from "react-jss";
import { ITheme } from "../../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  root: {
    display: "flex",
    "& > div": {
      marginLeft: -7,
    },
  },
  note: {
    background: "#757575",
    borderRadius: "50%",
    fontSize: 12,
    minWidth: 20,
    color: theme.palette.primary.contrastText,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
