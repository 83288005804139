import { HTMLAttributes, FC } from "react";
import { useTranslation } from "react-i18next";
interface IGameCardProps {
  momentId?: string;
  momentTitle?: boolean;
  description?: boolean;
}

export const MomentTranslation: FC<IGameCardProps & HTMLAttributes<HTMLDivElement>> = (props) => {
  const { momentId, momentTitle, description } = props;
  const { t } = useTranslation(["common", `moment_${momentId}`]);

  if (description) return t("description", { ns: `moment_${momentId}` });
  if (momentTitle) return t("title", { ns: `moment_${momentId}` });
  else return null;
};
