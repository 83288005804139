import React from "react";
import clsx from "classnames";
import { useStyles } from "./Skeleton.style";

interface IPropsSkeleton {
  className?: string;
  style?: any;
  variant?: "circle" | "text" | "rect";
  height?: string | number;
  width?: string | number;
}

export const Skeleton = (props: IPropsSkeleton) => {
  const classes = useStyles();
  const { className, style, height, width, variant } = props;

  return (
    <div
      {...props}
      className={clsx(
        classes.root,
        variant === "circle" && classes.circle,
        variant === "text" && classes.text,
        className
      )}
      style={{ ...style, height, width }}
    />
  );
};
