import { ACTIONS } from "./actions";

export default (state: any, action: any) => {
  const { friends, friendRequests, sentRequests, query, conditionSort } = action;
  switch (action.type) {
    case ACTIONS.SET_SORT_BY:
      return {
        ...state,
        conditionSort,
      };
    case ACTIONS.UPDATE_FRIENDS_LIST:
      return {
        ...state,
        friends,
      };
    case ACTIONS.UPDATE_FRIENDS_REQUEST:
      return {
        ...state,
        friendRequests,
      };
    case ACTIONS.UPDATE_SENT_REQUEST:
      return {
        ...state,
        sentRequests,
      };
    case ACTIONS.CLOSE_BANNER:
      return {
        ...state,
        openBanner: false,
      };
    case ACTIONS.UPDATE_RECENT_SEARCH:
      return {
        ...state,
        recentSearch: query,
      };
    default:
      return state;
  }
};
