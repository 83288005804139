import { isMobile } from "react-device-detect";

export const requestFullScreen = () => {
  if (isMobile && !document.fullscreenElement && process.env.REACT_APP_NO_FULLSCREEN !== "true") {
    document.documentElement &&
      document.documentElement.requestFullscreen &&
      document.documentElement.requestFullscreen().catch((err) => {
        console.error(`Fail to request full screen`, err);
      });
  }
};
