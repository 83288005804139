import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  wrapper: {
    "& > p": {
      fontSize: 17,
      fontWeight: 500,
      color: theme.palette.primary.contrastText,
      margin: "15px 15px 5px 15px",
    },
  },
  contentGame: {
    padding: 10,
  },
  itemGame: {
    padding: 5,
    display: "flex",
    alignItems: "center",
    "& > img": {
      height: 45,
      width: 45,
      minWidth: 45,
      objectFit: "cover",
      borderRadius: 8,
    },
    "& > p": {
      color: theme.palette.primary.contrastText,
      marginLeft: 10,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      minWidth: 100,
    },
  },
}));
