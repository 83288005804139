import gql from "graphql-tag";

export const DEPOSIT_BONUS_PAID = gql`
  subscription onPaidDepositBonus {
    depositBonusPaid {
      bonusType
      onmoPaymentResponse {
        bonus {
          bonusId
          date
          expiryDate
          userId
          currency
          initialAmount
          balance
          description
          bonusType
          escrowId
        }
        transactions {
          entryId
          date
          walletId
          currency
          amount
          description
          reference
          sequence
          workflow
          runningBalance
          postedOn
          escrowId
        }
        updatedWallets {
          type
          currency
          balance
        }
      }
    }
  }
`;
