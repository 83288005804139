import { getTheme } from "./utils/theme";

const THEME = getTheme();
export const openSupportChat = (userData) => {
  window.fcWidget.init({
    token: "ed08d82a-7d81-4df7-8a2c-96bd8c13cf98",
    host: "https://wchat.freshchat.com",
    open: false,
    config: {
      headerProperty: {
        hideChatButton: true,
        appName: THEME.appName,
        appLogo: THEME.appLogo,
        backgroundColor: THEME.palette.primary.main,
      },
      showFAQOnOpen: true,
    },
  });

  // To set unique user id in your system when it is available
  window.fcWidget.setExternalId(userData?.id);

  // To set user name
  window.fcWidget.user.setFirstName(userData?.username);

  // To set user email
  window.fcWidget.user.setEmail(userData?.email);

  window.fcWidget.open();
};

export const closeSupportChat = () => {
  try {
    window.fcWidget.close();
  } catch (e) {
    console.warn(e);
  }
};
