import * as Yup from "yup";

export const signUpEmailSchema = Yup.object({
  email: Yup.string("Enter your email").email("Please enter a valid email address.").required("Email is required"),
  username: Yup.string("Enter a username").required("A unique username is required"),
  password: Yup.string("")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase and One Number"
    )
    .required("Enter your password"),
});
