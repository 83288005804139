import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    marginTop: 20,
    "& > p": {
      fontSize: 48,
      lineHeight: "50px",
    },
    "& > p:last-child": {
      fontWeight: 700,
    },
  },
  desc: {
    fontSize: 16,
    marginTop: 15,
  },
}));
