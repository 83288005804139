import { createUseStyles } from "react-jss";
import { ITheme } from "../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  root: {
    height: "calc(100vh - 44px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 127,
    "& > div": {
      height: 168,
      width: "fit-content",
      paddingRight: 12.71,
      diplay: "flex",
      justifyContent: "flex-start",
    },
    "& > button": {
      marginTop: 58,
      width: 335,
      height: 44,
      background: theme.palette.primary.main,
      borderRadius: 50,
      fontWeight: 500,
      fontSize: 16,
      color: "#fff",
      "&:hover": {
        background: theme.palette.primary.main,
      },
    },
  },
  p1: {
    height: 20,
    marginTop: 38,
    fontWeight: 500,
    fontSize: 17,
    color: "#222",
  },
  p2: {
    height: 40,
    width: 335,
    marginTop: 12,
    textAlign: "center",
    fontSize: 16,
    color: "#6E757B",
  },
}));
