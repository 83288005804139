import React, { ElementType } from "react";
import clsx from "classnames";
import { useStyles } from "./Badge.style";

interface IPropsBadge {
  children: React.ReactNode;
  className?: string;
  component: ElementType;
  style?: any;
  classes: {
    dot: string;
  };
  invisible?: boolean;
}

export const Badge = (props: IPropsBadge) => {
  const classesLocal = useStyles();
  const { children, className, style, component, classes, invisible } = props;

  const renderChild = () => (
    <>
      {children}
      <span className={clsx(classesLocal.dot, invisible && classesLocal.invisible, classes.dot)} />
    </>
  );

  return React.createElement(
    component,
    {
      className: clsx(classesLocal.root, className),
      style,
    },
    renderChild()
  );
};
