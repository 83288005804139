import { MATCH_RESULT_STATUS, USEREVENT_NOTIFICATION_TYPE } from "../types/enums";
import { THEME_REGEX_PHONE_PREFIX } from "../utils/theme";

export const nf = Intl.NumberFormat();

export const formatTime = (duration: number) => {
  const minute = String(Math.floor(duration / 60)).padStart(2, "0");
  const second = String(duration % 60).padStart(2, "0");
  return `${minute}:${second}`;
};

export const convertTotalTimePlayed = (timePlayed: number) => {
  let mins: string | number = Math.floor(timePlayed / 1000 / 60);
  if (mins < 10) {
    mins = "0" + mins;
  }
  let secs: string | number = Math.floor((timePlayed / 1000) % 60);
  if (secs < 10) {
    secs = "0" + secs;
  }
  return mins + " : " + secs;
};

export const convertToScore = (score?: any, type?: string, eventType?: string) => {
  if (eventType === USEREVENT_NOTIFICATION_TYPE.BATTLE_EXPIRED) {
    if (score === -1) {
      return "Expired";
    } else return score;
  } else {
    if (eventType === MATCH_RESULT_STATUS.WAITING) {
      return "Waiting..";
    } else if (score === 0 || score === -1) {
      return 0;
    }
  }
  return type?.toUpperCase() === "TIME" ? formatTime(score) : score;
};

export const filterSpecialCharacter = (value: string) => {
  const regexValidName = /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;
  return value.replace(regexValidName, "");
};

export const isValidName = (name: string) => /^[A-Z0-9._ ]{2,16}$/i.test(name);

export const isValidEmail = (email: string) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);

export const isDataV3 = (data: string): boolean => {
  try {
    const dataObj = JSON.parse(data);
    return dataObj?.versions?.hasOwnProperty("v3.0");
  } catch (error) {
    return false;
  }
};

export const isDataV2 = (data: string): boolean => {
  try {
    const dataObj = JSON.parse(data);
    return !dataObj.versions || dataObj.versions?.hasOwnProperty("v2.0");
  } catch (error) {
    return false;
  }
};

export const isValidPhoneNumber = (phoneNb: string): boolean => {
  return phoneNb.length >= 10 && phoneNb.length <= 18 && THEME_REGEX_PHONE_PREFIX.test(phoneNb);
};

export const validateCash = (balance: number): number | string => {
  return balance % 1 === 0 ? balance : balance?.toFixed(2);
};

export const convertPlayerRating = (skill: number, gamesPlayedByUser: number) => {
  if (gamesPlayedByUser === 0) {
    return "Try Now";
  } else if (gamesPlayedByUser === 1) {
    return "OneThird";
  } else if (gamesPlayedByUser === 2) {
    return "TwoThird";
  } else {
    if (skill < 1050) {
      return "Rookie";
    } else if (skill < 1200) {
      return "Pro";
    } else if (skill < 1350) {
      return "Elite";
    } else if (skill < 1500) {
      return "Diamond";
    } else if (skill <= 1800) {
      return "Master";
    }
  }
};

export const getUserEloRatingPercent = (skill: number, gamesPlayedByUser: number) => {
  if (gamesPlayedByUser < 3) {
    if (gamesPlayedByUser === 1) {
      return "33.3";
    } else if (gamesPlayedByUser === 2) {
      return "66.67";
    } else return "0";
  } else {
    if (skill < 1050) {
      return ((skill - 900) / 150) * 100;
    } else if (skill < 1200) {
      return ((skill - 1050) / 150) * 100;
    } else if (skill < 1350) {
      return ((skill - 1200) / 150) * 100;
    } else if (skill < 1500) {
      return ((skill - 1350) / 150) * 100;
    } else if (skill <= 1800) {
      return ((skill - 1500) / 150) * 100;
    }
  }
};

export const getColorProgressBar = (skill: number, gamesPlayedByUser: number) => {
  if (gamesPlayedByUser < 3) {
    return "#56BE9F";
  } else {
    if (skill < 1050) {
      return "#C89D74";
    } else if (skill < 1200) {
      return "#A4A4A4";
    } else if (skill < 1350) {
      return "#FFDE8A";
    } else if (skill < 1500) {
      return "#266FEC";
    } else if (skill <= 1800) {
      return "#373737";
    }
  }
};
