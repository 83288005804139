import { map } from "lodash-es";

const AVATAR_ENDPOINT = process.env.REACT_APP_ASSETS_ENDPOINT + "assets/user/avatar/";
const BANNER_ENDPOINT = process.env.REACT_APP_ASSETS_ENDPOINT + "assets/user/banner/";

export const AVATAR_COUNT = 110;
export const BANNER_COUNT = 5;

export const calSlideWidth = (nbSlides: number) => {
  let width;
  if (nbSlides === 1) {
    width = "100%";
  } else if (nbSlides === 2) {
    width = "25%";
  } else if (nbSlides >= 3) {
    width = "33%";
  }
  return width;
};

export const getListAvatar = () => {
  const array = Array.from(Array(AVATAR_COUNT).keys());
  return map(array, (i) => {
    return {
      id: i + 1,
      src: `${AVATAR_ENDPOINT}${i + 1}.png`,
    };
  });
};

export const getListBanner = () => {
  const array = Array.from(Array(BANNER_COUNT).keys());
  return map(array, (i) => {
    return {
      id: i + 1,
      src: `${BANNER_ENDPOINT}${i + 1}.png`,
    };
  });
};

export const isFriend = (friends: any, userId: string) => {
  return friends?.findIndex((f: any) => f.id === userId) !== -1;
};

export const isFriendRequest = (friendRequests: any, userId: string) => {
  return friendRequests?.findIndex((f: any) => f.senderId === userId) !== -1;
};

export const hasActivity = (userAppStats: any, userMomentStats: any, userActivities: any) => {
  if (userAppStats && userMomentStats && userActivities) {
    return userAppStats.length !== 0 || userActivities.length !== 0 || userMomentStats.length !== 0;
  }
  return false;
};

export const isMe = (userId: string, myUserId: string) => {
  return userId === myUserId;
};

export const isSentRequest = (sentRequests: any, id: string) => {
  const req = sentRequests?.find((s: any) => s.id === id);
  if (req) return !req.accepted;
  return false;
};

export const isSentRequestByUserId = (sentRequests: any, userId: string) => {
  const req = sentRequests?.find((s: any) => s.receiverId === userId);
  if (req) return !req.accepted;
  return false;
};
