import gql from "graphql-tag";

export const getGameSessionV2 = gql`
  query GetGameSessionV2($getGameSessionInput: GetGameSessionInput!) {
    getGameSessionV2(getGameSessionInput: $getGameSessionInput) {
      id
      edgeNodeId
      matchId
      host {
        id
        username
      }
      controller
      incognito
      isStreamReady
      tournamentId
      tournamentPlayerId
      moment {
        id
        appId
        title
        description
        type
        momentType
        showTimer
        time
        rankOrder
        isCompleted
        data
        isCompleted
        zoneId
        status
        app {
          id
          useSimulation
          title
          rotationMode
          type
          data
          status
        }
      }
      sessionType
      sessionResults
    }
  }
`;

export const listGameSessionsV2 = gql`
  query ListGameSessionsV2 {
    ListGameSessionsV2 {
      id
      userId
      appId
      momentId
      edgeNodeId
      # game {
      #   title    // This filed is needed in UI but missing now
      # }
    }
  }
`;
