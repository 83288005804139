import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  main: {
    marginTop: 15,
    color: theme.palette.primary.contrastText,
  },
  gameImg: {
    height: 50,
    width: 50,
    border: `1px solid ${theme.palette.background.default}`,
  },
  gameIntro: {
    marginLeft: 10,
    "& > p:first-child": {
      fontSize: 20,
      fontWeight: 600,
    },
    "& > p:last-child": {
      fontSize: 10,
      fontWeight: 500,
    },
  },
  action: {
    "& > div": {
      textAlign: "center",
    },
    "& > div:first-child": {
      marginRight: 20,
    },
    "& > div > p": {
      fontSize: 10,
      fontWeight: 500,
    },
  },
  btnPlayNow: {
    fontSize: 16,
    minWidth: "100% !important",
    fontWeight: 700,
    marginTop: 15,
    borderRadius: 30,
    height: 50,
    boxSizing: "border-box",
  },
  btnLoaded: {
    background: theme.button.primary.background,
    color: theme.palette.primary.contrastText,
  },
}));
