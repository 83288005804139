import React from "react";
import { useApolloClient } from "@apollo/client";
import { FRIEND_REQUEST_BATTLE } from "../../apollo/subscriptions/battles";
import { UserCtx } from "../../context/user/state";

export const RequestBattleSubscription = () => {
  const { userData, updateLocalStateUser, updateUserHasNewNotification } = React.useContext(UserCtx);
  const [newBattleRequest, setNewBattleRequest] = React.useState<any>(null);

  const client = useApolloClient();

  React.useEffect(() => {
    const observer = client.subscribe({
      query: FRIEND_REQUEST_BATTLE,
    });

    const subscription = observer.subscribe((data: any) => {
      setNewBattleRequest(data?.data);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  React.useEffect(() => {
    if (!newBattleRequest) return;
    updateLocalStateUser({ ...userData, nbBattlesPending: (userData?.nbBattlesPending || 0) + 1 });
    updateUserHasNewNotification(true);
    setNewBattleRequest(null);
  }, [newBattleRequest, userData]);

  return <div data-testid="request-battle-subscription"></div>;
};

export default RequestBattleSubscription;
