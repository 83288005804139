import { default as React, useContext } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { UserCtx } from "../../../context/user/state";

export const AuthenticatedRoute = ({ children, path }: any) => {
  const { user } = useContext(UserCtx);
  const location = useLocation();

  return (
    <Route path={path}>{!user ? <Redirect to={`/authenticate/guest${location.search}`} /> : <>{children}</>}</Route>
  );
};

export default AuthenticatedRoute;
