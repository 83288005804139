import React, { useContext } from "react";
import LikeGame from "../../../Common/LikeGame/LikeGame";
import { useStyles } from "./GameItemCard.style";
import clsx from "classnames";
import { IBaseGame } from "../../../../types/baseInterfaces";
import { UserCtx } from "../../../../context/user/state";
import { Typography, Grid, Skeleton } from "../../../Common/ComponentExport";
import { onErrorGetGameCard, getVertical } from "../../../../utils/getGameImages";
import { useHistory } from "react-router-dom";
import { Analytics } from "../../../../modelsv2/analytics";
import { CATEGORY } from "../../../../constants/analyticsEvent";
import { GameTranslation } from "../../GameMomentTranslation/GameTranslation";

interface IPropsGameItemCard {
  game?: IBaseGame;
  isDiscovery?: boolean;
  isChingari?: boolean;
}

export const GameItemCard = (props: IPropsGameItemCard) => {
  const { game, isDiscovery, isChingari } = props;
  const classes = useStyles();
  const { userData } = useContext(UserCtx);
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const tab = urlParams.get("tab");

  const getGameCategoryString = (category: string) => {
    try {
      if (game?.category.includes("[")) {
        const categories = JSON.parse(category || "{}")
          .toString()
          .replaceAll(",", " / ");
        return categories;
      }
      return game?.category;
    } catch (error) {
      console.error(error);
    }
  };

  const onClickGame = () => {
    if (!game) return;
    if (isChingari) {
      if (isDiscovery) {
        Analytics.trackEvent({
          category: CATEGORY.DISCOVER_PAGE,
          action: "top_games",
          label: game?.title,
          game_type: game?.type,
        });
      } else {
        Analytics.trackEvent({
          category: CATEGORY.GAME_LISTING_PAGE,
          action: "game_select",
          label: game?.title,
          game_type: game?.type,
        });
      }
      history.push(`/app/play/${game?.id}`);
    } else {
      Analytics.trackEvent({
        category: CATEGORY.GAME_LISTING_PAGE,
        action: "game_select",
        label: game?.title,
        game_type: game?.type,
        user_uid: userData?.id,
      });
      history.push({
        pathname: `/app/games/${game.id}`,
        search: `status=${tab === "drafts" ? "DRAFT" : "LIVE"}`,
      });
    }
  };

  if (!game)
    return (
      <Grid item xs={6} sm={6} md={3} lg={2} className={clsx(classes.gameItem, isDiscovery && "isDiscovery")}>
        <Skeleton variant="rect" width="100%" height={232} style={{ borderRadius: 20 }} />
      </Grid>
    );

  return (
    <Grid
      gameId={game.id}
      key={game.id}
      game
      xs={6}
      sm={6}
      md={3}
      lg={2}
      className={clsx(classes.gameItem, isDiscovery && "isDiscovery")}>
      <div className={classes.gameAvatar} onClick={() => onClickGame()}>
        <div className={classes.likeGameButton}>
          <LikeGame isHideCount={true} game={game} isTransparentLike={true} />
        </div>
        <img
          src={getVertical(game.id)}
          alt=""
          id={game.id}
          data-testid="clickGameItem"
          onError={() => onErrorGetGameCard(game.id)}
        />
        <div className={classes.footer}>
          <Typography className={classes.gameName}>
            <GameTranslation gameTitle gameId={game.id} />
          </Typography>
          <div className={classes.bottom}>
            <div className={clsx(classes.gameTypeAndLikeCount, isDiscovery && "isDiscovery")}>
              <Typography variant="h6" className={classes.gameType}>
                {getGameCategoryString(game?.category)}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
};
