import React, { useState, useEffect, useContext } from "react";
import { PreloadBeatIt } from "../PreloadBeatIt";
import { find, isNull } from "lodash-es";
import { IBaseGameBattle, IBaseDailyTournament } from "../../../types/baseInterfaces";
import { TOURNAMENT_TYPE } from "../../../constants/types";
import { AlertCtx } from "../../../context/alert/state";
import { UserCtx } from "../../../context/user/state";
import { Tournament } from "../../../modelsv2/tournament";
import { CircularProgress } from "../../Common/ComponentExport";
import { PlayHtmlGame } from "../PlayHtmlGame";
import { Analytics } from "../../../modelsv2/analytics";
import { CATEGORY } from "../../../constants/analyticsEvent";
import { useStyles } from "./PlayBeatIt.style";
import { renderCurrencyText } from "../../../utils/renderCurrency";
import { useHistory } from "react-router-dom";
import { updateWallets } from "../../../utils/wallets";

interface IPropsPlayBeatIt {
  game: IBaseGameBattle | null;
}

export const PlayBeatIt = (props: IPropsPlayBeatIt) => {
  const classes = useStyles();
  const { game } = props;
  const { setNotificationError } = useContext(AlertCtx);
  const { userData, userWallets, updateUserWallets } = useContext(UserCtx);
  const [isLoaded, setIsLoadedCallback] = useState(false);
  const [loadedPercentage, setLoadedPercentage] = useState(0);
  const [started, setStarted] = useState(false);
  const [dataGame, setDataGame] = useState<any>(null);
  const [tournamentBeatIt, setTournamentBeatIt] = useState<IBaseDailyTournament | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [gameSessionId, setGameSessionId] = useState<string | null>(null);
  const history = useHistory();

  const onBack = () => {
    setStarted(false);
    setIsLoadedCallback(false);
    history.go(-1);
  };

  const onPlay = async () => {
    if (!isLoaded || !tournamentBeatIt) return;
    Analytics.trackEvent({
      category: CATEGORY.MOMENT,
      action: "start",
      label: game?.title,
      moment_type: tournamentBeatIt?.momentType,
      game_type: game?.type,
      currency: renderCurrencyText(tournamentBeatIt?.currency),
      user_uid: userData?.id,
    });
    setIsLoading(true);
    try {
      const params = {
        tournamentId: tournamentBeatIt?.id,
        device: {
          info: "{}",
        },
      };
      const enterTournament = await Tournament.enterTournament(params);
      // TODO update userWallets
      const response = await Tournament.playTournament(enterTournament?.gameSessionId);
      const updatedWallets = updateWallets(userWallets, response.wallets);
      updateUserWallets(updatedWallets);
      setGameSessionId(enterTournament?.gameSessionId);
    } catch (e: any) {
      console.log(e);
      setNotificationError(e?.message);
    }
    setStarted(true);
    setIsLoading(false);
    Analytics.trackEvent({
      category: CATEGORY.TOURNAMENT,
      action: "tournament_start",
      label: game?.title,
      user_uid: userData?.id,
    });
  };

  const onResetGame = () => {
    setStarted(false);
    setIsLoadedCallback(false);
    setDataGame(null);
    setGameSessionId(null);
  };

  useEffect(() => {
    if (game) {
      const tournamentBeatIt = find(game?.tournamentInfo, { tournamentType: TOURNAMENT_TYPE.PRACTICE_HTML });
      const moment = find(game?.moments, { id: tournamentBeatIt?.momentId });
      const dataGameTmp = {
        id: game?.id,
        moment: moment,
        title: game?.title,
        dataMoment: moment ? moment?.data : "",
      };
      setDataGame(dataGameTmp);
      setTournamentBeatIt(tournamentBeatIt as IBaseDailyTournament);
      Analytics.trackEvent({
        category: CATEGORY.TOURNAMENT,
        action: "tournament_enter",
        label: game?.title,
        user_uid: userData?.id,
      });
    }
  }, [game]);

  if (isNull(game)) {
    return (
      <div className={classes.contentLoading} data-testid="loadding">
        <CircularProgress size={40} />
      </div>
    );
  }

  return (
    <>
      {!started && (
        <PreloadBeatIt
          game={game}
          onBack={onBack}
          isLoaded={isLoaded}
          onPlay={onPlay}
          loadedPercentage={loadedPercentage}
          missionCard={{
            avatar: tournamentBeatIt?.avatar,
            name: tournamentBeatIt?.username,
            score: tournamentBeatIt?.topScore,
            momentId: dataGame?.moment?.id,
            momentType: tournamentBeatIt?.momentType,
          }}
          expireTime={tournamentBeatIt?.expiredAt}
          isLoading={isLoading}
        />
      )}
      {dataGame && (
        <PlayHtmlGame
          game={dataGame as any}
          setLoadedPercentage={setLoadedPercentage}
          loadedPercentage={loadedPercentage}
          started={started}
          setIsLoadedCallback={setIsLoadedCallback}
          onBack={onBack}
          gameSessionId={gameSessionId}
          onResetGame={onResetGame}
        />
      )}
    </>
  );
};
