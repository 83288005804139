import React, { ElementType } from "react";
import clsx from "classnames";
import { useStyles } from "./Divider.style";

interface IPropsDivider {
  className?: string;
  style?: any;
  component?: ElementType;
}

export const Divider = (props: IPropsDivider) => {
  const classes = useStyles();
  const { className, style, component } = props;

  return React.createElement(component || "hr", {
    ...props,
    className: clsx(classes.root, className),
    style,
  });
};
