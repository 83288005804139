export const ACTIONS = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  CONFIRMLOGIN: "CONFIRMLOGIN",
  DENYLOGIN: "DENYLOGIN",
  SET_USER_DATA: "SET_USER_DATA",
  SET_PUSH_MODAL_DATA: "SET_PUSH_MODAL_DATA",
  UPDATE_LIKED_GAME_STATUS: "UPDATE_LIKED_GAME_STATUS",
  UPDATE_WEBSOCKET_OF_SIMULATION: "UPDATE_WEBSOCKET_OF_SIMULATION",
  UPDATE_USER_TRANSACTIONS: "UPDATE_USER_TRANSACTIONS",
  UPDATE_USER_HAS_NEW_NOTIFICATION: "UPDATE_USER_HAS_NEW_NOTIFICATION",
  UPDATE_IS_WS_CLIENT_CONNECTED: "UPDATE_IS_WS_CLIENT_CONNECTED",
  UPDATE_WALLETS: "UPDATE_WALLETS",
};
