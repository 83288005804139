import { createUseStyles } from "react-jss";
import { ITheme } from "../../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  root: {
    top: 0,
    left: 0,
    width: "100%",
    position: "fixed",
    zIndex: 1501,
  },
  alertRoot: {
    padding: "6px 16px",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    maxHeight: 40,
    minHeight: 40,
    textAlign: "center",
    fontWeight: 500,
    lineHeight: "18px",
    borderRadius: "unset",
    letterSpacing: -0.2,
  },
  icon: {
    position: "absolute",
    left: 16,
    padding: 0,
    margin: 0,
    height: 28,
    display: "flex",
    opacity: 0.9,
    fontSize: 22,
  },
  message: {
    padding: 0,
    lineHeight: "18px",
  },
  severity: {
    "&.s-success": {
      background: theme.palette.primary.main,
    },
    "&.s-warning": {
      background: "#EF7925",
    },
    "&.s-error": {
      background: "#d32f2f",
    },
  },
}));
