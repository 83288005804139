import gql from "graphql-tag";

export const startMatch = gql`
  mutation StartMatch($startMatchInput: StartMatchInput!) {
    startMatch(startMatchInput: $startMatchInput) {
      moment {
        id
        appId
        snapshotId
        app {
          useSimulation
          type
          title
        }
      }
      players {
        id
      }
      edgeNodeId
      matchId
      gameSessionId
      # battleInfo {
      #   prizePool       // This filed is needed in UI but missing now
      # }
    }
  }
`;

export const joinMatch = gql`
  mutation JoinMatch($joinMatchInput: JoinMatchInput!) {
    joinMatch(joinMatchInput: $joinMatchInput) {
      matchId
    }
  }
`;

export const playMatchV2 = gql`
  mutation PlayMatchV2($playMatchInput: PlayMatchInput!) {
    playMatchV2(playMatchInput: $playMatchInput) {
      moment {
        id
        appId
        snapshotId
        app {
          useSimulation
          type
          title
        }
      }
      edgeNodeId
      gameSessionId
    }
  }
`;

export const playMoment = gql`
  mutation playMoment($playMomentInput: PlayMomentInput) {
    playMoment(playMomentInput: $playMomentInput) {
      gameSessionId
      edgeNodeId
      updatedWallets {
        type
        currency
        balance
      }
    }
  }
`;

export const playSoloMoment = gql`
  mutation PlaySoloMoment($playSoloMomentInput: PlaySoloMomentInput) {
    playSoloMoment(playSoloMomentInput: $playSoloMomentInput) {
      status
    }
  }
`;

export const enterSoloMoment = gql`
  mutation EnterSoloMoment($enterSoloMomentInput: EnterSoloMomentInput) {
    enterSoloMoment(enterSoloMomentInput: $enterSoloMomentInput) {
      gameSessionId
      edgeNodeId
    }
  }
`;

export const endHtmlGameSession = gql`
  mutation EndHtmlGameSession($endHtmlGameSessionInput: EndHtmlGameSessionInput!) {
    endHtmlGameSession(endHtmlGameSessionInput: $endHtmlGameSessionInput) {
      status
    }
  }
`;

export const createStream = gql`
  mutation CreateStream($streamInput: StreamInput!) {
    createStream(streamInput: $streamInput)
  }
`;

export const createGameSession = gql`
  mutation CreateGameSession($input: CreateGameSessionInput!, $condition: ModelGameSessionConditionInput) {
    createGameSession(input: $input, condition: $condition) {
      id
      edgeNodeId
    }
  }
`;

export const updateGameSession = gql`
  mutation UpdateGameSession($input: UpdateGameSessionInput!, $condition: ModelGameSessionConditionInput) {
    updateGameSession(input: $input, condition: $condition) {
      id
      controller
      incognito
      isStreamReady
    }
  }
`;

export const deleteGameSession = gql`
  mutation DeleteGameSession($input: DeleteGameSessionInput!, $condition: ModelGameSessionConditionInput) {
    deleteGameSession(input: $input, condition: $condition)
  }
`;
