import gql from "graphql-tag";

export const getUser = gql`
  query User($userProfileInput: UserProfileInput!) {
    getUserProfile(userProfileInput: $userProfileInput) {
      user {
        id
        email
        phone
        facebookId
        googleId
        username
        avatar
        banner
      }
    }
  }
`;
export const getUserProfile = gql`
  query GetUserProfile($userProfileInput: UserProfileInput!) {
    getUserProfile(userProfileInput: $userProfileInput) {
      user {
        id
        email
        phone
        facebookId
        googleId
        username
        avatar
        banner
        pushSubscription
        smsNotification
        phoneNotification
        billingName
        billingPhone
        billingEmail
        billingCurrency
      }
      nbMatchesWon
      nbTournamentsWon
      nbFriends
      winningPercent
      nbUserPlayedBeatItTournaments
      totalEarning {
        totalWinnings
        currency
        type
        external_id
        id
      }
      nbSoloChallengesPlayed
      todayEarning {
        totalWinnings
        currency
        type
        walletId
        external_id
      }
    }
  }
`;

export const getUserV2 = gql`
  query GetUserV2($isSubscription: Boolean!) {
    getUserV2 {
      user {
        id
        email
        phone
        facebookId
        googleId
        username
        avatar
        banner
        xp
        currency
        pushSubscription
        hasWelcome
        smsNotification
        phoneNotification
        billingName
        billingPhone
        billingEmail
        billingCurrency
        currencyLocked
        wallets {
          type
          currency
          balance
        }
        language
      }
      permissionGroups
      initialDepositBonus
      nbNotificationsUnread
      nbFriendRequestUnaccepted
      nbBattlesPending
      canUseCash
      whileAway {
        eventType
        momentType
        gameId
        matchId
        tournamentId
        title
        amount
        playerScore
        opponentScore
        currency
        avatar
        username
      }
      subscription @include(if: $isSubscription) {
        status
        umrid
        field
        renewalDate
      }
    }
  }
`;

export const getTop100MostRecentActivitiesByUserId = gql`
  query ListUserActivities($getUserActivitiesInput: GetUserActivitiesInput!) {
    listUserActivities(getUserActivitiesInput: $getUserActivitiesInput) {
      id
      userId
      type
      data
      createdAt
    }
  }
`;

export const listFriendsV2 = gql`
  query listFriendsV2($listFriendsInput: ListFriendsInput) {
    listFriendsV2(listFriendsInput: $listFriendsInput) {
      id
      sender {
        id
        username
        avatar
        xp
      }
      receiver {
        id
        username
        avatar
        xp
      }
      updatedAt
    }
  }
`;

export const listUserTransactions = gql`
  query ListUserTransactions($listTransactionsInput: ListTransactionsInput) {
    listUserTransactions(listTransactionsInput: $listTransactionsInput) {
      entryId
      type
      amount
      runningBalance
      currency
      walletType
      category
      transactionType
      workflow
      gameId
      gameTitle
      status
      createdAt
      reference
    }
  }
`;

export const findUser = gql`
  query Query($findUserInput: FindUserInput) {
    findUser(findUserInput: $findUserInput) {
      id
      username
      avatar
      xp
    }
  }
`;

export const getCryptoDepositAddress = gql`
  query getCryptoDepositAddress {
    getCryptoDepositAddress
  }
`;

export const estimateCryptoTransactionFee = gql`
  query estimateCryptoTransactionFee($estimateCryptoTransactionFeeInput: EstimateCryptoTransactionFeeInput) {
    estimateCryptoTransactionFee(estimateCryptoTransactionFeeInput: $estimateCryptoTransactionFeeInput)
  }
`;

export const getWithdrawal = gql`
  query getWithdrawal($getWithdrawalInput: GetWithdrawalInput) {
    getWithdrawal(getWithdrawalInput: $getWithdrawalInput) {
      transactionId
    }
  }
`;

export const getDeposit = gql`
  query getDeposit($getDepositInput: GetDepositInput) {
    getDeposit(getDepositInput: $getDepositInput) {
      transactionId
    }
  }
`;

export const getConfig = gql`
  query GetConfig {
    getConfig {
      firstDepositBonus {
        amount
      }
      minimumDepositBonus {
        minAmount
        amount
      }
      freeCoins {
        amount
        maxBalanceRequired
      }
      minimumWithdrawal {
        amount
      }
    }
  }
`;
