import React from "react";
import clsx from "classnames";
import { useStyles } from "./Switch.style";

interface IPropsSwitch {
  className?: string;
  style?: any;
  checked?: boolean;
  onChange?: Function;
  disabled?: boolean;
  name?: string;
  dataTestId?: string;
  isShowCloseIcon?: boolean;
}

export const Switch = (props: IPropsSwitch) => {
  const classes = useStyles();
  const { className, style, checked, onChange, disabled, name, dataTestId, isShowCloseIcon } = props;

  const onChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;
    onChange(event);
  };

  return (
    <div className={clsx(className)} style={style} data-testid={dataTestId}>
      <input
        data-testid="switch-change"
        className={clsx(classes.tgl)}
        id={name}
        type="checkbox"
        onChange={onChangeSwitch}
        checked={Boolean(checked)}
        disabled={disabled}
      />
      <label
        className={clsx(
          classes.genButton,
          "tgl-btn",
          isShowCloseIcon && classes.withCloseIcon,
          disabled && classes.disabled
        )}
        htmlFor={name}
      />
    </div>
  );
};
