import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  stickBottom: {
    position: "fixed",
    borderRadius: 10,
  },
  dialogHeader: {
    borderBottom: "1px solid rgba(0,0,0,0.1)",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    padding: "13px 16px",
    alignItems: "center",
  },
  headerTitle: {
    fontWeight: 500,
    color: "#333",
  },
  cancelButton: {
    color: "#2d7cf6",
  },
  bodyText: {
    width: "100%",
    display: "flex",
  },
  description: {
    width: "100%",
    margin: "0 16px",
    padding: 16,
    borderBottom: "1px solid rgba(0,0,0,0.1)",
    "& > p": {
      color: "#7b7b7a",
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "17px",
    },
  },
  steps: {
    margin: "0 16px",
    padding: 16,
  },
  step1: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "left",
    marginBottom: 16,
    "& > svg": {
      flex: "0 0 auto",
      color: "#2d7cf6",
      fill: "#2d7cf6",
      height: 30,
      width: 25,
      marginRight: 32,
    },
  },
  stepText: {
    fontSize: 13,
    fontWeight: 600,
    lineHeight: "17px",
    color: "#7b7b7a",
  },
  step2: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "left",
    marginBottom: 0,
    "& > svg": {
      flex: "0 0 auto",
      height: 30,
      width: 25,
      marginRight: 32,
    },
  },
}));
