import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  header: {
    background: "#260E2E",
    "& > p": {
      color: theme.palette.primary.contrastText,
      fontSize: 16,
      fontWeight: 500,
    },
  },
  mainListGame: {
    margin: "auto",
    "& > div": {
      padding: 8,
    },
    "& > div > div": {
      padding: 6,
    },
    background: "#260E2E",
  },
  gameItem: {
    padding: 5,
  },
  mainLoading: {
    height: "calc(100vh - 56px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
