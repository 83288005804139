import React from "react";
import clsx from "classnames";
import { useStyles } from "./Alert.style";

interface IPropsAlert {
  className?: string;
  severity: "success" | "error" | "warning";
  iconMapping?: string;
  message: string;
  dataTestid?: string;
}

export const Alert = (props: IPropsAlert) => {
  const classes = useStyles();
  const { className, severity, iconMapping, message, dataTestid } = props;

  return (
    <div className={clsx(classes.alertRoot, classes.severity, `s-${severity}`, className)}>
      {iconMapping && <img className={classes.icon} src={iconMapping} alt="" />}
      <div className={classes.message} data-testid={dataTestid}>
        {message}
      </div>
    </div>
  );
};
