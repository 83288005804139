import React, { useEffect, useState, useContext } from "react";
import DashedCircle from "../../../assets/Arena/DashedCircle.svg";
import { useTranslation } from "react-i18next";
import clsx from "classnames";
import { useStyles } from "./LeaderBoard.style";
import { convertToScore } from "../../../utils/utils";
import { USEREVENT_NOTIFICATION_TYPE } from "../../../types/enums";
import { Typography, Avatar } from "../ComponentExport";
import { isBeatItTournament } from "../../../utils/tournament";
import { UserCtx } from "../../../context/user/state";
import { isIOS } from "react-device-detect";
import { renderCurrencyIcon } from "../../../utils/renderCurrency";
import { ISessionResults } from "../../../types/others";
import { useHistory } from "react-router-dom";

interface IPropsLeaderBoard {
  isTransparent: boolean;
  myId?: string;
  tournament?: any;
  arena?: any;
  isHideWaitingPlayer?: boolean;
  isTournamentStartScreen?: boolean;
  sessionResults?: ISessionResults;
  onClosePopup?: Function;
}

export const LeaderBoard = (props: IPropsLeaderBoard) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    isTransparent,
    myId,
    tournament,
    isHideWaitingPlayer,
    arena,
    isTournamentStartScreen,
    sessionResults,
    onClosePopup,
  } = props;

  const { userData } = useContext(UserCtx);
  const history = useHistory();

  const [leaderBoards, setLeaderBoards] = useState<any>(null);
  const myRank = tournament?.leaderboards?.findIndex((p: any) => p.userId === userData?.id) + 1;

  const onClickProfile = (id: string, event: React.MouseEvent<HTMLElement>) => {
    onClosePopup && onClosePopup();
    event?.stopPropagation();
    history.push(`/app/user/${id}`);
  };

  const renderCurrentTry = () => {
    return (
      <div className={clsx(classes.listUser, classes.showMe)}>
        <div id={"myId"} className={clsx(classes.top3, isTransparent && classes.transparent)}>
          <div className={clsx(classes.champion, `myUser`)}>
            <Avatar
              src={userData?.avatar}
              className={clsx(classes.avatarCP, myRank > 3 || !myRank ? "myScore" : `r${myRank}`)}
            />
            {myRank > 0 && <div className={clsx(classes.rankId, `r${myRank}`)}>{myRank}</div>}
            {!isBeatItTournament(tournament?.tournamentType) && !myId && tournament?.calculatedPrizePool[myRank - 1] ? (
              <Avatar className={clsx(classes.rewardCoin, `r${myRank < 4 ? myRank : "w"}`, `myUser`)}>
                <Typography className={isIOS ? classes.noTransparent : ""} variant="h6">
                  {renderCurrencyIcon(
                    tournament?.currency,
                    tournament?.calculatedPrizePool[myRank - 1],
                    classes.btcText
                  )}
                </Typography>
              </Avatar>
            ) : (
              <div className={clsx(classes.rewardCoin, "rewardEmpty")} />
            )}
            <div>
              <Typography className={clsx(classes.username, isTransparent && classes.colorWhite)}>Me</Typography>
              <Typography
                className={clsx(
                  classes.score,
                  classes.colorGold,
                  `r${myRank}`,
                  isTournamentStartScreen ? "dark" : "white"
                )}>
                {convertToScore(sessionResults?.results?.score, tournament?.momentType)}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTop3 = () => {
    if (!leaderBoards) return null;
    return (
      <div className={classes.listUser}>
        {leaderBoards.map((item: any, idx: number) => {
          return (
            <div
              id={item?.user?.id === userData.id && idx > 2 ? "myId" : ""}
              key={idx}
              className={clsx(classes.top3, isTransparent && classes.transparent)}>
              <div className={clsx(classes.champion, myId === item?.user?.id && `myUser`)}>
                <Avatar
                  onClick={(event: any) => onClickProfile(item?.userId, event)}
                  src={item?.user?.avatar}
                  className={clsx(
                    classes.avatarCP,
                    idx + 1 > 3 ? (myId === item?.user?.id ? `myScore` : "") : `r${idx + 1}`
                  )}
                />
                <div className={clsx(classes.rankId, `r${idx + 1}`)}>{idx + 1}</div>
                {tournament?.calculatedPrizePool[idx] && !isBeatItTournament(tournament?.tournamentType) && idx < 5 ? (
                  <Avatar
                    className={clsx(
                      classes.rewardCoin,
                      idx > 2 ? `rw` : `r${idx + 1}`,
                      myId === item?.user?.id && `myUser`
                    )}>
                    <Typography className={isIOS ? classes.noTransparent : ""} variant="h6">
                      {renderCurrencyIcon(
                        tournament?.currency,
                        tournament?.calculatedPrizePool[idx] || 0,
                        classes.btcText
                      )}
                    </Typography>
                  </Avatar>
                ) : (
                  <div className={classes.rewardCoin} />
                )}
                <div>
                  <Typography className={clsx(classes.username, isTransparent && classes.colorWhite)}>
                    {item?.user?.id === userData.id ? "Me" : item?.user?.username || t("common_N/A")}
                  </Typography>
                  <Typography
                    className={clsx(
                      classes.score,
                      classes.colorGold,
                      `r${idx + 1}`,
                      isTournamentStartScreen ? "dark" : "white"
                    )}>
                    {convertToScore(item?.score, tournament?.momentType)}
                  </Typography>
                </div>
              </div>
            </div>
          );
        })}
        {leaderBoards.length < 3 && !isHideWaitingPlayer && (
          <div className={classes.waitingForPlayers}>
            <img alt="" src={DashedCircle} className={classes.userWaiting} />
            {!isBeatItTournament(tournament?.tournamentType) ? (
              <Avatar className={classes.rewardCoin}>
                <Typography className={isIOS ? classes.noTransparent : ""} variant="h6"></Typography>
              </Avatar>
            ) : (
              <div className={classes.rewardCoin} />
            )}
            <Typography className={classes.textWaiting}>{t("Waiting for players...")}</Typography>
          </div>
        )}
      </div>
    );
  };

  const renderTournamentCancelled = () => {
    return (
      <div className={classes.top3Empty}>
        {Array.from(new Array(3)).map((_, index: number) => {
          return (
            <div className={classes.championCancelled} key={index}>
              <Avatar classes={{ img: classes.imgNoAvatar }} />
              <div className={classes.rewardCoin}></div>
              <Typography className={clsx(classes.username, isTransparent && classes.colorWhite)}>
                {t("common_N/A")}
              </Typography>
              <Typography className={clsx(classes.score, classes.colorGold)}>{t("common_N/A")}</Typography>
            </div>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    if (tournament) {
      setLeaderBoards(tournament?.leaderboards);
    }
  }, []);

  useEffect(() => {
    document.getElementById("myId")?.scrollIntoView({ behavior: "smooth", block: "end", inline: "center" });
  }, [leaderBoards]);

  return arena?.eventType === USEREVENT_NOTIFICATION_TYPE.TOURNAMENT_CANCELLED ? (
    <>
      <Typography className={classes.yourRank}>No result</Typography>
      <div className={classes.container}>{renderTournamentCancelled()}</div>
    </>
  ) : (
    <>
      {!isTournamentStartScreen && (
        <Typography className={classes.yourRank}>
          {sessionResults ? t("common_Current try") : `${t("common_Your rank #")}${myRank}`}
        </Typography>
      )}
      <div className={clsx(classes.container, leaderBoards?.length < 3 && classes.centerContent)}>
        {sessionResults ? renderCurrentTry() : renderTop3()}
      </div>
    </>
  );
};

export default LeaderBoard;
