import React, { useState, useContext, useEffect } from "react";
import { Auth } from "@aws-amplify/auth";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { isEmpty, includes } from "lodash-es";
import clsx from "classnames";
import { useHistory } from "react-router-dom";
import { ONBOARDING_URL } from "../../constants/endpoint";
import Logo from "../../assets/GuestMode/OnmoWhite.svg";
import { THEME_NAME, getTheme } from "../../utils/theme";
import { useTranslation } from "react-i18next";
import { ReactComponent as FacebookIcon } from "../../assets/GuestMode/facebook_white.svg";
import { ReactComponent as GoogleIcon } from "../../assets/GuestMode/Google.svg";
import { ReactComponent as MobileIcon } from "../../assets/GuestMode/mobile.svg";
import { ReactComponent as AppleIcon } from "../../assets/GuestMode/Apple.svg";
import { Analytics } from "../../modelsv2/analytics";
import { CATEGORY } from "../../constants/analyticsEvent";
import { PopupDownloadApp } from "../Common/PopupDownloadApp";
import { isIOS, isMobile } from "react-device-detect";
import { useStyles } from "./Login.style";
import UtilsPWA from "../../utils/pwa";
import { isAbleToAddTheAppToHomeScreenInIOS } from "../../constants/conditions";
import { Button, Typography, Grid, CircularProgress } from "../Common/ComponentExport";
import { AlertCtx } from "../../context/alert/state";
import { UserCtx } from "../../context/user/state";
import { GuestHome } from "../Chingari/GuestHome/GuestHome";
import { ReactComponent as ZainLogo } from "../../assets/GuestMode/zain-logo.svg";
import SafaricomLogo from "../../assets/GuestMode/safaricom-logo.svg";
import DialogAccountDisabled from "../../views/SignIn/partials/DialogAccountDisabled/DialogAccountDisabled";
import { ThemeName } from "../../constants/theme";

interface ILoginProps {
  isHideHeader?: boolean;
}

export const Login = (props: ILoginProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { isHideHeader } = props;
  const { setNotificationError } = useContext(AlertCtx);
  const { userData } = useContext(UserCtx);
  const [formLoading, setFormLoading] = useState("");
  const [isOpenDowload, setIsOpenDownload] = useState(false);
  const [isInstall, setIsInstall] = useState(window.matchMedia("(display-mode: standalone)").matches);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const urlParams = new URLSearchParams(window.location.search);
  const errorDescription = urlParams.get("error_description");
  const VARIATIONS = urlParams.get("variations");
  const isPWA = window.matchMedia("(display-mode: standalone)").matches;
  const THEME = getTheme();
  const onClickTerms = () => {
    //@ts-ignore
    window.open(THEME?.termsAndConditions, "_blank");
  };

  const onClickPrivacy = () => {
    //@ts-ignore
    window.open(THEME.privacyPolicy, "_blank");
  };

  const handleSignIn = async (provider: string) => {
    try {
      if (!isEmpty(formLoading)) return;
      setFormLoading(provider);
      localStorage.setItem("login_method", provider);
      Analytics.trackEvent({
        category: CATEGORY.SIGNUP,
        action: "start",
        label: provider,
        user_uid: userData?.id,
      });
      if (provider === "Facebook" || provider === "Google") {
        await Auth.federatedSignIn({ provider: provider as CognitoHostedUIIdentityProvider });
      } else if (provider === "phone") {
        history.push("/authenticate/login/phone-signin");
      } else {
        history.push("/authenticate/login/email-signin");
      }
      localStorage.setItem("hasJustLogged", "true");
      setFormLoading("");
    } catch (e: any) {
      e.message === "User is disabled." && setIsOpen(true);
    }
  };

  const PWAInstall = () => {
    // Check if app is installed
    if (window.matchMedia("(display-mode: standalone)").matches || isInstall) {
      alert(t("common_App is installed"));
      return;
    }
    if (UtilsPWA.deferredPrompt) {
      // Show the install prompt
      UtilsPWA.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      UtilsPWA.deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === "accepted") {
          alert(t("common_App installed successfully"));
          Analytics.trackEvent({
            category: CATEGORY.INSTALL,
            action: "success",
            label: "login",
            user_uid: userData?.id,
          });
          setIsInstall(true);
        } else {
          console.info("User opted out from installing");
          Analytics.trackEvent({
            category: CATEGORY.INSTALL,
            action: "cancel",
            label: "login",
            user_uid: userData?.id,
          });
        }
      });
    } else {
      alert(t("common_App is installed"));
    }
  };

  const onDownload = () => {
    if (isIOS) {
      setIsOpenDownload(true);
    } else {
      PWAInstall();
    }
    Analytics.trackEvent({
      category: CATEGORY.INSTALL,
      action: "view",
      label: "login",
      user_uid: userData?.id,
    });
  };

  const getPowerByLogo = () => {
    switch (THEME_NAME) {
      case ThemeName.Zain:
        return <ZainLogo />;
      case ThemeName.Safaricom:
        return <img src={SafaricomLogo} alt="logo" />;
      default:
        return <img src={Logo} alt="" />;
    }
  };

  useEffect(() => {
    try {
      // @ts-ignore
      navigator.getInstalledRelatedApps().then((relatedApps) => setIsInstall(relatedApps.length > 0));
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    let timeoutRedirect: NodeJS.Timeout;
    if (errorDescription) {
      if (errorDescription?.includes("email"))
        setNotificationError(`To login to ${ThemeName.Onmo}, your Facebook account requires a valid email.`);
      else setNotificationError(errorDescription);
      timeoutRedirect = setTimeout(() => {
        const iframeTag = document.getElementsByTagName("iframe");
        if (iframeTag) {
          iframeTag[0].style.display = "none";
        }
      }, 3000);
    }
    return () => {
      clearTimeout(timeoutRedirect);
    };
  }, [errorDescription]);

  if (THEME?.authRedirectUrl) {
    return (
      <div className={classes.mainLoading}>
        <CircularProgress size={40} />
      </div>
    );
  }

  if (process.env.REACT_APP_IS_CHINGARI === "true") {
    return <GuestHome />;
  }

  return (
    <>
      {THEME_NAME !== ThemeName.Zain && THEME_NAME !== ThemeName.VodafoneIndia && (
        <video className={classes.videoMedia} muted loop autoPlay playsInline={true}>
          <source src={ONBOARDING_URL} type="video/mp4" />
        </video>
      )}
      <div
        className={clsx(
          classes.wrapper,
          THEME_NAME === ThemeName.Zain ? "themeZain" : VARIATIONS,
          THEME_NAME === ThemeName.VodafoneIndia && classes.vodafoneIndiaBackground,
          isHideHeader && classes.noTopNavigation
        )}>
        <div>
          {THEME_NAME !== ThemeName.VodafoneIndia && !isHideHeader && (
            <div className={classes.header}>
              <img src={Logo} alt="" />
              {THEME_NAME === ThemeName.Onmo && !isInstall && !!isMobile && !isPWA && (
                <Button
                  data-testid="btn-getApp"
                  variant="contained"
                  className={clsx(classes.btnGetApp, "btn-install-pwa")}
                  onClick={onDownload}>
                  {t("common_Get ONMO App")}
                </Button>
              )}
            </div>
          )}
          <div>
            <Typography className={classes.textWelcome}>
              {VARIATIONS === "focusCash" ? (
                t("login_Let's play!")
              ) : VARIATIONS === "featuring" ? (
                t("login_Ready to play?")
              ) : (
                <>
                  {t("common_welcome to")} <span>{THEME_NAME}</span>
                </>
              )}
            </Typography>
            <Typography variant="h3" className={classes.txtDesc}>
              {VARIATIONS === "focusCash"
                ? t("login_Test your skill on popular games and win real cash. No download required.")
                : VARIATIONS === "featuring"
                ? t("login_Test your skills on Subway Surfer and hundreds of other popular mobile games.")
                : t("login_Get access to the best game Moments, Battles and Daily Tournaments.")}
            </Typography>
          </div>
          {(THEME_NAME === ThemeName.Zain ||
            THEME_NAME === ThemeName.Safaricom ||
            THEME_NAME === ThemeName.VodafoneIndia) && (
            <div className={classes.zainPoweredBy}>
              <Typography variant="h5">{t("login_Powered by")}</Typography>
              {getPowerByLogo()}
            </div>
          )}
        </div>
        <div className={classes.action}>
          {VARIATIONS === "focusCash" && (
            <Grid item xs={12} md={6} lg={6} className={classes.textClaimCash}>
              Claim your ₹100 bonus
            </Grid>
          )}
          <Grid container spacing={2}>
            {process.env.REACT_APP_LOGIN_FB === "true" && !VARIATIONS && (
              <Grid item xs={12} md={6} lg={6}>
                <Button
                  variant="contained"
                  fullWidth
                  data-testid="signInFacebook"
                  startIcon={formLoading !== "Facebook" && <FacebookIcon />}
                  onClick={() => handleSignIn("Facebook")}
                  className={clsx(classes.btnFacebook, "btn-fb-login")}>
                  {formLoading === "Facebook" ? <CircularProgress size={25} /> : t("login_Connect with Facebook")}
                </Button>
              </Grid>
            )}
            {process.env.REACT_APP_LOGIN_PHONE === "true" && (
              <Grid item xs={12} md={6} lg={6}>
                <Button
                  data-testid="signInPhone"
                  variant="outlined"
                  fullWidth
                  startIcon={<MobileIcon />}
                  onClick={() => handleSignIn("phone")}
                  className={clsx(
                    classes.btnGoogle,
                    classes.btnPhone,
                    VARIATIONS || "noVariation",
                    THEME_NAME === ThemeName.VodafoneIndia && classes.initialBackground,
                    "btn-phone-login"
                  )}>
                  {t("login_Signup with phone number")}
                </Button>
              </Grid>
            )}
            {process.env.REACT_APP_LOGIN_GG === "true" && (
              <Grid item xs={12} md={6} lg={6}>
                <Button
                  data-testid="signInGoogle"
                  variant="contained"
                  fullWidth
                  startIcon={formLoading !== "Google" && <GoogleIcon />}
                  onClick={() => handleSignIn("Google")}
                  className={clsx(classes.btnGoogle, "btn-gg-login")}>
                  {formLoading === "Google" ? <CircularProgress size={25} /> : t("login_Connect with Google")}
                </Button>
              </Grid>
            )}
            {process.env.REACT_APP_LOGIN_APPLE === "true" && !VARIATIONS && isIOS && (
              <Grid item xs={12} md={6} lg={6}>
                <Button
                  data-testid="signInApple"
                  variant="outlined"
                  fullWidth
                  startIcon={formLoading !== "Apple" && <AppleIcon />}
                  onClick={() => handleSignIn("Apple")}
                  className={clsx(classes.btnGoogle, classes.btnApple)}>
                  {formLoading === "Apple" ? <CircularProgress size={25} /> : t("login_Connect with Apple")}
                </Button>
              </Grid>
            )}
            {process.env.REACT_APP_LOGIN_EMAIL === "true" && !VARIATIONS && (
              <Grid item xs={12} md={6} lg={6}>
                <Button
                  data-testid="signInEmail"
                  variant="contained"
                  fullWidth
                  startIcon={<GoogleIcon />}
                  onClick={() => handleSignIn("email")}
                  className={clsx(classes.btnGoogle, "btn-email-login")}
                  data-cy="sign-in-via-email">
                  Email
                </Button>
              </Grid>
            )}
            {THEME?.btnRedirectUrl && (
              <Grid item xs={12} md={6} lg={6}>
                <Button
                  variant="outlined"
                  fullWidth
                  startIcon={<MobileIcon />}
                  onClick={() => (window.location.href = THEME?.btnRedirectUrl)}
                  className={clsx(
                    classes.btnGoogle,
                    classes.btnPhone,
                    "noVariation",
                    THEME_NAME === ThemeName.VodafoneIndia && classes.initialBackground
                  )}>
                  {t("login_Signup with phone number")}
                </Button>
              </Grid>
            )}
          </Grid>
          <Typography className={classes.agreement}>
            {`${t("common_By signing up or signing in to an account, you agree to", { name: THEME_NAME })}`}{" "}
            <span className={classes.link} onClick={onClickTerms}>
              {t("common_Terms of Use")}
            </span>
            {` ${t("common_and")} `}
            <span className={classes.link} onClick={onClickPrivacy}>
              {t("common_Privacy Policy")}
            </span>
          </Typography>
        </div>
      </div>
      <DialogAccountDisabled isOpen={isOpen} setIsOpen={setIsOpen} />
      {THEME_NAME === ThemeName.Onmo && isAbleToAddTheAppToHomeScreenInIOS && (
        <PopupDownloadApp isOpen={isOpenDowload} onClose={() => setIsOpenDownload(false)} />
      )}
    </>
  );
};
