import React from "react";
import { THEME_NAME } from "../../../utils/theme";
import OnmoCoin from "../../../assets/store/icon-coin.svg";
import { ReactComponent as CoinDiaLog } from "../../../assets/store/CoinDialog.svg";
import { ThemeName } from "../../../constants/theme";

export const AppCoin = ({ classStyles }: any) => {
  switch (THEME_NAME) {
    case ThemeName.VodafoneIndia: {
      return <img className={classStyles} alt="" src={OnmoCoin} data-testid="appcoin" />;
    }
    case ThemeName.Safaricom: {
      return <img className={classStyles} alt="" src={OnmoCoin} data-testid="appcoin" />;
    }
    case ThemeName.Zain: {
      return <img className={classStyles} alt="" src={OnmoCoin} data-testid="appcoin" />;
    }
    case ThemeName.Dialog: {
      return <CoinDiaLog className={classStyles} data-testid="appcoin" />;
    }
    default:
      return <img className={classStyles} alt="" src={OnmoCoin} data-testid="appcoin" />;
  }
};

export default AppCoin;
