export const ACTIONS = {
  SET_STREAM_STATUS: "SET_STREAM_STATUS",
  SET_GAME_SESSION: "SET_GAME_SESSION",
  SET_VOLUME: "SET_VOLUME",
  TOGGLE_MUTED: "TOGGLE_MUTED",
  TOGGLE_ALERTS: "TOGGLE_ALERTS",
  PLAY_MOMENT: "PLAY_MOMENT",
  CHANGE_WATCHERS: "CHANGE_WATCHERS",
  ALERT_ACTION: "ALERT_ACTION",
  SET_PEER: "SET_PEER",
};
