import { filter, find } from "lodash-es";
import { IBaseFriend, IBaseFriendRequest } from "../../../types/baseInterfaces";

export const removeARequestFromSentList = (
  requestId: string,
  sentRequests: IBaseFriendRequest[]
): IBaseFriendRequest[] => {
  return filter(sentRequests, (request) => request.id !== requestId);
};

export const getFriendIdFromDeleteFriendRequest = (userId: string, deletedFriend: any): string => {
  return userId === deletedFriend.senderId ? deletedFriend.receiverId : deletedFriend.senderId;
};

export const removeAFriendFromFriendList = (friends: IBaseFriend[], friendId: string) => {
  return filter(friends, (friend) => friend.id !== friendId);
};

export const isNewFriendRequestUpdated = (
  friendRequests: IBaseFriendRequest[],
  newFriendRequestId: string
): boolean => {
  return find(friendRequests, (request) => request.id === newFriendRequestId) !== undefined;
};

export const removeAFriendRequestFromReceivedList = (
  requestId: string,
  receivedRequests: IBaseFriendRequest[]
): IBaseFriendRequest[] => {
  return filter(receivedRequests, (request) => request.id !== requestId);
};
