import { Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import ArrowBackIosIcon from "../../../../assets/ArrowBackIosIcon.svg";
import { Auth } from "@aws-amplify/auth";
import { OnmoLoading } from "../../../../components/OnmoLoading";
import { UserCtx } from "../../../../context/user/state";
import { onboardingStyles } from "../../SignIn.style";
import useDigitInput from "react-digit-input";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStyles } from "./PhoneConfirmationCode.style";
import { Analytics } from "../../../../modelsv2/analytics";
import { CATEGORY } from "../../../../constants/analyticsEvent";
import { AlertCtx } from "../../../../context/alert/state";
import { Button, Typography, Grid, Container } from "../../../../components/Common/ComponentExport";
import clsx from "classnames";
import { Users } from "../../../../modelsv2/users";

export default function PhoneConfirmationCode(props: { cognitoPhone: Object }) {
  const { t } = useTranslation();
  const { setNotificationError, setNotificationSuccess } = useContext(AlertCtx);
  const { confirmLogin, userData } = useContext(UserCtx);
  const [userRes, setUserRes] = useState<any>(props.cognitoPhone);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const onboardingClasses = onboardingStyles();
  const classes = useStyles();
  const history = useHistory();

  const [value, onChange] = useState<string>("");
  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 4,
    value,
    onChange,
  });

  if (!userRes) {
    history.push("/authenticate/login/phone-signin");
  }

  async function resendCode() {
    await Auth.signIn(userRes).then((res) => {
      setUserRes(res);
      setNotificationSuccess(t("phoneConfirmationCode_Code re-sent successfully"));
    });
  }

  return (
    <div>
      <Container className={classes.phoneConfirmationContainer}>
        {formLoading ? <OnmoLoading /> : null}
        <Grid container className={onboardingClasses.mainContent}>
          <Grid item>
            <Grid container className={onboardingClasses.resetPwdDiv}>
              <Grid item xs={3} onClick={() => history.push("/authenticate/login/phone-signin")}>
                <Button startIcon={<img alt="" src={ArrowBackIosIcon} />} className={classes.backButton}>
                  {}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Typography className={clsx(onboardingClasses.fontWeight500, classes.title)} variant="h3">
                  {t("common_Sign Up")}
                </Typography>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
            <hr className={onboardingClasses.headerHr} />
            <Formik
              initialValues={{
                confirmationCode: "",
              }}
              onSubmit={async (values, { setSubmitting }) => {
                if (!formLoading) {
                  setSubmitting(true);
                  setFormLoading(true);
                  try {
                    await Auth.sendCustomChallengeAnswer(userRes, value);
                    try {
                      const log = await confirmLogin();
                      setFormLoading(false);
                      if (!log) {
                        setNotificationError(t("phoneConfirmationCode_Please enter a valid verification code."));
                      } else {
                        Analytics.trackEvent({
                          category: CATEGORY.LOGIN,
                          action: "success",
                          label: "phone",
                          user_uid: userData?.id,
                        });
                      }
                    } catch (error: any) {
                      setFormLoading(false);
                      if (error.message === "not authenticated") {
                        Analytics.trackEvent({
                          category: CATEGORY.SIGNUP,
                          action: "fail",
                          label: "phone",
                          user_uid: userData?.id,
                        });
                        setNotificationError(t("phoneConfirmationCode_Please enter a valid verification code."));
                      } else {
                        setNotificationError(t("common_There was an issue with the login, please try again later."));
                      }
                    }
                  } catch (e) {
                    console.error(`Fail to log in`, e);
                    setFormLoading(false);
                    setNotificationError(t("common_There was an issue with the login, please try again later."));
                  }
                }
              }}>
              {(props: any) => {
                const { handleChange, handleSubmit, setFieldValue } = props;
                const submitEnabled = value.trim().length === 4;
                return (
                  <Form>
                    <Grid item>
                      <Grid container>
                        <Grid item xs={12} className={onboardingClasses.titleGrid}>
                          <Typography className={onboardingClasses.title}>
                            {t("common_Verify your phone number")}
                          </Typography>
                          <Typography variant="h4" className={onboardingClasses.subtitle}>
                            {t("common_Enter the 4 digits code we sent to you by SMS.")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.digitGrid}>
                          <Grid container spacing={3} alignItems="center" justify="center">
                            <Grid item xs={3}>
                              <div className={classes.digitContainer}>
                                <input
                                  type="tel"
                                  inputMode="numeric"
                                  autoFocus
                                  {...digits[0]}
                                  className={classes.digitInput}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={3}>
                              <div className={classes.digitContainer}>
                                <input type="tel" inputMode="numeric" {...digits[1]} className={classes.digitInput} />
                              </div>
                            </Grid>
                            <Grid item xs={3}>
                              <div className={classes.digitContainer}>
                                <input type="tel" inputMode="numeric" {...digits[2]} className={classes.digitInput} />
                              </div>
                            </Grid>
                            <Grid item xs={3}>
                              <div className={classes.digitContainer}>
                                <input
                                  type="tel"
                                  inputMode="numeric"
                                  {...digits[3]}
                                  className={classes.digitInput}
                                  name="confirmationCode"
                                  onKeyPress={(e) => {
                                    handleChange(e);
                                    setFieldValue("confirmationCode", value);
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            isSubmit
                            data-testid="button-submit"
                            fullWidth
                            variant="contained"
                            className={clsx(onboardingClasses.signin, !submitEnabled && onboardingClasses.btnDisabled)}
                            onClick={() => handleSubmit()}
                            disabled={!submitEnabled}>
                            {t("common_Sign In")}
                          </Button>
                          <Typography variant="body1" className={classes.linkTypography}>
                            <span className={onboardingClasses.muted}>{t("common_Didn’t receive SMS?")}</span>{" "}
                            <span
                              data-testid="span-resend"
                              className={onboardingClasses.linkStyle}
                              onClick={() => resendCode()}>
                              {t("common_Resend Code.")}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
