import { CATEGORY } from "../constants/analyticsEvent";
import { Analytics } from "../modelsv2/analytics";
import { Auth } from "@aws-amplify/auth";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { History } from "history";

export const signInBy = (provider: string, history: History) => {
  localStorage.setItem("login_method", provider);
  Analytics.trackEvent({
    category: CATEGORY.SIGNUP,
    action: "start",
    label: provider,
  });
  if (provider === "Facebook" || provider === "Google") {
    Auth.federatedSignIn({ provider: provider as CognitoHostedUIIdentityProvider });
  } else if (provider === "phone") {
    history.push("/authenticate/login/phone-signin");
  } else {
    history.push("/authenticate/login/email-signin");
  }
};
