import "./index.css";

import { isIOS, isTablet } from "react-device-detect";

import { Amplify } from "aws-amplify";
import App from "./App";
import PWAPrompt from "react-ios-pwa-prompt";
import React from "react";
import ReactDOM from "react-dom";
import awsExports from "./aws-exports";

import { BrowserRouter as Router } from "react-router-dom";
// global context
import UserProvider from "./context/user/state";
import AlertProvider from "./context/alert/state";
import MaintenanceProvider from "./context/maintenance/state";
import apollo from "./clients/apollo";
import { ApolloProvider } from "@apollo/client";
import { getTheme } from "./utils/theme";
import { ThemeProvider } from "react-jss";

Amplify.configure(awsExports);
const apolloClient = apollo.getInstance();

const THEME = getTheme();

ReactDOM.render(
  <React.StrictMode>
    <PWAPrompt
      copyShareButtonLabel={`1) Open in Safari and press the 'Share' button on the menu bar ${
        isIOS && isTablet ? "above" : "below"
      }`}
    />

    <ThemeProvider theme={THEME}>
      <ApolloProvider client={apolloClient}>
        <AlertProvider>
          <MaintenanceProvider>
            <UserProvider>
              <Router>
                <App />
              </Router>
            </UserProvider>
          </MaintenanceProvider>
        </AlertProvider>
      </ApolloProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
