import { ACTIONS } from "./actions";

export default (state: any, action: any) => {
  const { payload, isShowQuickNotification, classify } = action;

  switch (action.type) {
    case ACTIONS.SET_NOTIF_DEPOSIT: {
      return {
        ...state,
        isShowInAppNotification: true,
        isShowQuickNotification: false,
        body: { currency: payload.currency, value: payload.value, message: payload.message },
        classify,
      };
    }
    case ACTIONS.SET_NOTIF_COPIED: {
      return {
        ...state,
        isShowInAppNotification: true,
        isShowQuickNotification: false,
        body: { value: payload.value, message: payload.message },
        classify,
      };
    }
    case ACTIONS.SET_NOTIF: {
      return {
        ...state,
        isShowQuickNotification: true,
        isShowInAppNotification: false,
        body: { value: payload.value, message: payload.message },
        classify,
      };
    }
    case ACTIONS.SET_SHOW_NOTIF: {
      return {
        ...state,
        isShowQuickNotification,
        isShowInAppNotification: false,
      };
    }
    default:
      return state;
  }
};
