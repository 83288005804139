import gql from "graphql-tag";

export const startPromoBeat = gql`
  mutation StartPromo($startPromoInput: StartPromoInput!) {
    startPromo(startPromoInput: $startPromoInput) {
      promoSessionId
      gameSessionId
      edgeNodeId
      players {
        id
        username
        avatar
      }
      promo {
        id
        score
      }
      moment {
        id
        appId
        title
        description
        type
        rankOrder
        app {
          id
          title
          company
          description
          category
          type
        }
      }
    }
  }
`;

export const endPromoBeat = gql`
  mutation EndPromo($endPromoInput: EndPromoInput!) {
    endPromo(endPromoInput: $endPromoInput) {
      status
    }
  }
`;
