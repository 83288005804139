import { default as React, useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { NoInternet } from "../NoInternet";

export const AppRoutes = ({ children, path, ...rest }: any) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const updateOnlineStatus = () => {
    setIsOnline(navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Analytics page change
    // @ts-ignore
    window.dataLayer?.push({
      event: "pageView",
    });
  }, []);

  useEffect(() => {
    if (!path.includes("/game-session" || "/html-games")) {
      if (document.body) {
        document.body.removeAttribute("style");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Route exact path={path}>
      {!isOnline ? <NoInternet /> : React.cloneElement(children, { ...rest })}
    </Route>
  );
};

export default AppRoutes;
