import { TOURNAMENT_TYPE } from "../constants/types";

export const isBeatItTournament = (type: string) => {
  return type === TOURNAMENT_TYPE.PRACTICE_HTML || type === TOURNAMENT_TYPE.PRACTICE_STREAM;
};

export const isFeaturedTournament = (type: string) => {
  return type === TOURNAMENT_TYPE.ROOKIE || type === TOURNAMENT_TYPE.ROOKIE_PERCENT;
};

export const isProTournament = (type: string) => {
  return type === TOURNAMENT_TYPE.PRO || type === TOURNAMENT_TYPE.PRO_PERCENT;
};
