import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  wrapper: {
    margin: "0 auto",
    height: 56,
    maxHeight: 56,
    minHeight: 56,
    background: theme.palette.background.default,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    "&.stickyHeader": {
      position: "sticky",
      top: -1,
      zIndex: 49,
      padding: "0 20px",
    },
  },
  leftIcon: {
    position: "absolute",
    left: 15,
    display: "flex",
  },
  textLeft: {
    fontWeight: 500,
    fontSize: 15,
    color: "#222222",
    flex: 1,
    "@media(max-height: 667px)": {
      fontSize: 12,
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      fontSize: 13,
    },
  },
  textRight: {
    color: theme.palette.primary.contrastText,
    position: "absolute",
    right: 15,
  },
  textUnderline: {
    textDecoration: "underline",
  },
  disableTextRight: {
    color: "#6e6e6e",
  },
}));
