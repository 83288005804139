import React, { useState } from "react";
import clsx from "classnames";
import { isNull, map, filter } from "lodash-es";
import { useHistory } from "react-router-dom";
import { Header } from "../Header";
import { useTranslation } from "react-i18next";
import { Avatar, Typography, Grid, CircularProgress } from "../../Common/ComponentExport";
import { StatsUser } from "../StatsUser";
import { ListMostPlayedGame } from "../ListMostPlayedGame";
import { IUserProfile } from "../../../types/baseInterfaces";
import { GameType } from "../../../types/enums";
import EditIconRight from "../../../assets/Chingari/EditWhite.svg";
import { EditProfile } from "../EditProfile";
import { useStyles } from "./UserProfile.style";

interface IPropsUserProfile {
  userProfile: IUserProfile | null;
  getUserProfile: Function;
  mostPlayedGames: any;
}

export const UserProfile = (props: IPropsUserProfile) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { userProfile, getUserProfile, mostPlayedGames } = props;
  const [isEditProfile, setIsEditProfile] = useState(false);

  const onBack = () => {
    history.push("/app");
  };

  const games = map(mostPlayedGames, (game) => ({
    gameId: game?.id,
    title: game?.title,
    type: game?.type,
  }));

  if (isEditProfile) {
    return (
      <EditProfile
        onBack={setIsEditProfile}
        avatar={userProfile?.user?.avatar || ""}
        getUserProfile={getUserProfile}
        name={userProfile?.user?.username || ""}
      />
    );
  }

  return (
    <Grid container className={classes.wrapper}>
      <Header
        className={clsx(classes.header, "stickyHeader")}
        actionLeft={onBack}
        textCenter={t("userProfile_My Profile")}
        iconRight={EditIconRight}
        actionRight={() => setIsEditProfile(true)}
      />
      {isNull(userProfile) ? (
        <div className={classes.mainLoading}>
          <CircularProgress size={40} />
        </div>
      ) : (
        <Grid item xs={12} md={11} lg={10} className={classes.content}>
          <div className={classes.mainHeader}>
            <Avatar src={userProfile?.user?.avatar} />
            <Typography>{userProfile?.user?.username}</Typography>
          </div>
          <StatsUser
            nbOfBeatIt={userProfile?.nbUserPlayedBeatItTournaments || 0}
            nbGamesPlayed={mostPlayedGames?.length || 0}
          />
          <ListMostPlayedGame games={filter(games, { type: GameType.HTML })} />
        </Grid>
      )}
    </Grid>
  );
};
