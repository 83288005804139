import gql from "graphql-tag";

export const sendOtpCode = gql`
  mutation SendOtpCode($sendOtpCodeInput: sendOtpCodeInput!) {
    sendOtpCode(sendOtpCodeInput: $sendOtpCodeInput) {
      status
    }
  }
`;

export const updateUserV2 = gql`
  mutation UpdateUserV2($updateUserV2Input: UpdateUserV2Input!) {
    updateUserV2(updateUserV2Input: $updateUserV2Input) {
      status
    }
  }
`;

export const deleteUserV2 = gql`
  mutation DeleteUserV2 {
    deleteUserV2 {
      status
    }
  }
`;

export const createFeedback = gql`
  mutation CreateFeedback($createFeedbackInput: CreateFeedbackInput!) {
    createFeedback(createFeedbackInput: $createFeedbackInput) {
      status
    }
  }
`;

export const checkinUser = gql`
  mutation CheckinUser($input: CheckinUserInput) {
    checkinUser(input: $input) {
      userId
      lastCheckin
    }
  }
`;

export const updateLike = gql`
  mutation UpdateLike($updateLikeInput: UpdateLikeInput!) {
    updateLike(updateLikeInput: $updateLikeInput) {
      status
    }
  }
`;

export const lockCurrency = gql`
  mutation LockCurrency($lockCurrencyInput: LockCurrencyInput!) {
    lockCurrency(lockCurrencyInput: $lockCurrencyInput) {
      status
    }
  }
`;
