const APP_URL = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ""
}`;

export const autoRedirect = (matchToRedirect: string) => {
  if (matchToRedirect) {
    const redirectUrl = `${APP_URL}/app/battles/v4/create?gameId=${matchToRedirect}`;
    window.location.href = redirectUrl;
    localStorage.removeItem("matchToRedirect");
  }
};

export const saveRedirectUrl = () => {
  const redirectParams = new URLSearchParams(window.location.search);
  const redirectUrl = redirectParams.get("r");
  if (redirectUrl) localStorage.setItem("r", redirectUrl);
};

export const redirectOnVisibilityChange = () => {
  const matchToRedirect: any = localStorage.getItem("matchToRedirect");
  autoRedirect(matchToRedirect);
};
