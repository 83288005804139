import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  "@global": {
    "input[type='range']": {
      "-webkit-appearance": "none",
      background: "rgba(255, 255, 255, 0.4)",
      borderRadius: 5,
      backgroundImage: `linear-gradient(${theme.palette.background.default}, ${theme.palette.background.default})`,
      backgroundSize: "30% 100%",
      backgroundRepeat: "no-repeat",
    },
    "input[type='range']::-webkit-slider-thumb": {
      "-webkit-appearance": "none",
      height: 6,
      width: 6,
      borderRadius: "50%",
      background: theme.palette.background.default,
      transition: "background .3s ease-in-out",
    },
  },
  time: {
    fontSize: 10,
    fontWeight: 500,
  },
  slide: {
    width: "100%",
    margin: "0px 15px",
    height: 2,
  },
  timeLine: {
    color: theme.palette.primary.contrastText,
    opacity: 1,
    "&.hide": {
      opacity: 0,
      transition: "opacity .5s ease",
    },
  },
}));
