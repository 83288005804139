import React from "react";
import clsx from "classnames";
import { useStyles } from "./Paper.style";

interface IPropsPaper {
  children: React.ReactNode;
  className?: string;
  onClick?: Function;
  style?: any;
  square?: boolean;
}

export const Paper = (props: IPropsPaper) => {
  const classes = useStyles();
  const { children, className, onClick, style, square } = props;

  const onClickButton = (event: React.MouseEvent<HTMLElement>) => {
    if (!onClick) return;
    onClick(event);
  };

  return (
    <div
      {...props}
      className={clsx(classes.root, square && classes.square, className)}
      style={style}
      onClick={onClickButton}>
      {children}
    </div>
  );
};
