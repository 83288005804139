import { createUseStyles } from "react-jss";
import { ITheme } from "../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  header: {
    position: "fixed",
    top: 0,
    padding: 15,
    width: "calc(100vw - 30px)",
    zIndex: 1,
  },
  content: {
    "& > div > div": {
      height: "100vh",
      position: "relative",
      background: "#222222",
    },
  },
  loginPanel: {
    height: "100vh",
  },
}));
