import { createUseStyles } from "react-jss";
import { ITheme } from "../../types/theme";
import BackgroundLoginB2b from "../../assets/GuestMode/background-login-b2b.svg";
import FocusCash from "../../assets/GuestMode/FocusCash.png";
import Featuring from "../../assets/GuestMode/Featuring.png";

export const useStyles = createUseStyles((theme: ITheme) => ({
  videoMedia: {
    position: "fixed",
    width: "100%",
    minHeight: "100%",
    objectFit: "cover",
  },
  wrapper: {
    position: "fixed",
    background: "linear-gradient(179.75deg, rgba(0, 0, 0, 0) 25.39%, #111111 95.27vh, #222222 122.93%)",
    backgroundColor: "rgba(26, 26, 26, 0.4)",
    color: theme.palette.primary.contrastText,
    width: "100%",
    height: "100%",
    padding: "25px 20px 20px 20px",
    overflow: "scroll",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxSizing: "border-box",
    "&.themeZain": {
      background: `url(${BackgroundLoginB2b}) left top no-repeat, ${theme.palette.secondary.main} right bottom no-repeat`,
    },
    "&.focusCash": {
      background: `url(${FocusCash}) left top no-repeat, ${theme.palette.secondary.main} right bottom no-repeat`,
      backgroundSize: "100vw 100vh",
    },
    "&.featuring": {
      background: `url(${Featuring}) left top no-repeat, ${theme.palette.secondary.main} right bottom no-repeat`,
      backgroundSize: "100vw 100vh",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 30,
  },
  btnGetApp: {
    color: theme.palette.primary.contrastText,
    background: "rgba(255, 255, 255, 0.5)",
    borderRadius: 50,
    fontSize: 10,
    fontWeight: 500,
  },
  textWelcome: {
    fontSize: 48,
    lineHeight: "50px",
    "& > span": {
      fontWeight: 500,
    },
  },
  txtDesc: {
    color: theme.palette.primary.contrastText,
    whiteSpace: "inherit",
    marginTop: 15,
    lineHeight: "21px",
  },
  btnFacebook: {
    background: "#1877F2",
    borderRadius: 50,
    color: theme.palette.primary.contrastText,
    fontSize: 14,
    height: 50,
    marginBottom: 15,
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    "& > svg": {
      position: "absolute",
      left: 30,
    },
  },
  action: {
    marginTop: 20,
  },
  iconStart: {
    position: "absolute",
    left: 35,
  },
  btnGoogle: {
    background: theme.palette.background.default,
    borderRadius: 50,
    fontSize: 14,
    height: 50,
    marginBottom: 15,
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    "& > svg": {
      position: "absolute",
      left: 25,
    },
  },
  agreement: {
    fontSize: 14,
    textAlign: "center",
    color: theme.palette.primary.contrastText,
  },
  link: {
    color: theme.palette.primary.contrastText,
    cursor: "pointer",
    borderBottom: `1px solid ${theme.palette.background.default}`,
  },
  btnApple: {
    background: "#222222",
    color: theme.palette.primary.contrastText,
  },
  mainLoading: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  btnPhone: {
    background: "linear-gradient(119.49deg, #303030 35.3%, #202020 70.6%)",
    border: "1px solid",
    color: theme.palette.primary.contrastText,
    "& > svg > path": {
      stroke: theme.palette.background.default,
    },
    "&.focusCash": {
      background: "linear-gradient(324.29deg, #F0AD00 10.69%, #FFCF53 95.4%)",
      color: "#fff",
      border: "none",
    },
    "&.noVariation": {
      background: theme.button.primary.background,
      border: "none",
    },
  },
  zainPoweredBy: {
    marginTop: 15,
    "& > h5": {
      fontWeight: 700,
      color: theme.palette.primary.contrastText,
      marginBottom: 15,
    },
  },
  textClaimCash: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: "21px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: -0.2,
    width: "100%",
    padding: 20,
    justifyContent: "center",
  },
  vodafoneIndiaBackground: {
    background: theme.palette.primary.main,
  },
  initialBackground: {
    background: `${theme.palette.background.default} !important`,
    color: "initial",
    "& > svg > path": {
      stroke: theme.palette.secondary.main,
    },
  },
  noTopNavigation: {
    paddingTop: 80,
  },
}));
