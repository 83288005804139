import gql from "graphql-tag";

export const createFriendRequest = gql`
  mutation CreateFriendRequest($createFriendRequestInput: CreateFriendRequestInput!) {
    createFriendRequest(createFriendRequestInput: $createFriendRequestInput) {
      id
    }
  }
`;

export const acceptFriendRequest = gql`
  mutation AcceptFriendRequest($id: ID!) {
    acceptFriendRequest(id: $id) {
      id
    }
  }
`;

export const deleteFriend = gql`
  mutation DeleteFriend($deleteFriendInput: DeleleFriendInput) {
    deleteFriend(deleteFriendInput: $deleteFriendInput)
  }
`;
