import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  wrapper: {
    height: "100%",
    boxSizing: "border-box",
    overflow: "scroll",
  },
  header: {
    position: "fixed",
    top: 15,
    width: "100%",
    height: 56,
    zIndex: 1,
  },
  videoGame: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  main: {
    position: "relative",
    background: "#222222",
    height: "50%",
  },
  content: {
    position: "absolute",
    bottom: -1,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    height: "50%",
    background: "linear-gradient(180deg, rgba(34, 34, 34, 0) 0%, #222222 100%)",
  },
  iconBack: {
    position: "absolute",
    left: 10,
  },
  action: {
    minHeight: "50%",
    background: "#222222",
    color: theme.palette.primary.contrastText,
    padding: "30px 20px 0px 20px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
  },
  desc: {
    fontSize: 18,
  },
  cost: {
    fontSize: 35,
    fontWeight: 500,
  },
  btnPlay: {
    height: 48,
    background: theme.button.primary.background,
    color: theme.palette.primary.contrastText,
    borderRadius: 50,
    margin: "20px 0px",
    fontWeight: 600,
    fontSize: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  beatItText: {
    "& > p": {
      float: "left",
      fontSize: 12,
      display: "flex",
      alignItems: "center",
      position: "relative",
      "& > span": {
        right: -2,
        width: "calc(100% - 40px)",
        height: 1,
        position: "absolute",
        background: theme.palette.background.default,
      },
    },
  },
  tournamentName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: 11,
    margin: "5px 0px",
  },
  tournamentNameText: {
    marginLeft: 5,
    fontSize: 20,
    fontWeight: 700,
    color: theme.palette.primary.contrastText,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    "& > span": {
      fontWeight: 400,
    },
  },
  endTime: {
    marginTop: 6,
    color: theme.palette.primary.contrastText,
    textAlign: "left",
    "& > span": {
      fontWeight: 700,
    },
  },
  beatItDes: {
    marginTop: 5,
    fontSize: 16,
    textAlign: "initial",
  },
  contentMission: {
    marginTop: 15,
  },
  btnDisable: {
    background: theme.palette.background.default,
  },
}));
