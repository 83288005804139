import React, { useReducer } from "react";
import { InAppNotification } from "../../components/InAppNotification";
import QuickNotification from "../../components/QuickNotification/QuickNotification";
import { INITIAL_STATE } from "./constants";
import {
  setShowNotif,
  setNotificationError,
  setNotificationSuccess,
  setNotificationInfo,
  setNotificationDeposit,
  setNotificationCopied,
} from "./dispatchers";
import Reducer from "./reducer";
import IAlertCtx from "./types";

export const AlertCtx = React.createContext<IAlertCtx>(INITIAL_STATE);

export default function AlertProvider({ children }: any) {
  const [state, dispatch] = useReducer(Reducer, INITIAL_STATE);

  const setters = {
    setShowNotif: (isShowQuickNotification: boolean) => setShowNotif(dispatch, isShowQuickNotification),
    setNotificationError: (message: string) => setNotificationError(dispatch, message),
    setNotificationSuccess: (message: string) => setNotificationSuccess(dispatch, message),
    setNotificationInfo: (message: string) => setNotificationInfo(dispatch, message),
    setNotificationCopied: (value: string, message: string) => setNotificationCopied(dispatch, value, message),
    setNotificationDeposit: (currency: string, value: string, message: string) =>
      setNotificationDeposit(dispatch, currency, value, message),
  };

  return (
    <AlertCtx.Provider value={{ ...state, ...setters }}>
      <>
        {state.isShowQuickNotification && !state.isShowInAppNotification && <QuickNotification />}
        {children}
        {state.isShowInAppNotification && !state.isShowQuickNotification && <InAppNotification />}
      </>
    </AlertCtx.Provider>
  );
}
