export class Html {
  public hash = (s: string) => {
    for (var i = 0, h = 9; i < s.length; ) h = Math.imul(h ^ s.charCodeAt(i++), 9 ** 9);
    return h ^ (h >>> 9);
  };

  public loadGame = (gameId: string, setLoadedPercentage: Function, onLoadCallback: Function) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");

      script.id = `${process.env.REACT_APP_ASSETS_ENDPOINT}/games/${gameId}/game.js`;
      script.crossOrigin = "anonymous";
      script.addEventListener("load", () => {
        resolve(script);
      });
      script.addEventListener("error", (e: any) => {
        reject(e);
      });

      const xhr = new XMLHttpRequest();
      xhr.open("GET", `${process.env.REACT_APP_ASSETS_ENDPOINT}games/${gameId}/game.js`, true);
      xhr.onload = (e) => {
        if (xhr.readyState === 4) {
          script.text = xhr.response;
          onLoadCallback(script);
          resolve(script);
        }
      };
      xhr.onprogress = function (e) {
        setLoadedPercentage(((e.loaded / 20000000) * 100).toFixed(0));
      };

      xhr.send();
    });
  };

  public loadGamePercent = (gameId: string, setLoadedPercentage: Function) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", `${process.env.REACT_APP_ASSETS_ENDPOINT}games/${gameId}/game.js`, true);
      xhr.onprogress = function (e) {
        setLoadedPercentage(((e.loaded / 20000000) * 100).toFixed(0));
      };
      xhr.onloadend = () => {
        setLoadedPercentage(100);
      };

      xhr.send();
    });
  };

  public hideCanvas = () => {
    const canvas = document.getElementById("UT_CANVAS") || document.getElementById("game-phaser");
    canvas?.style.setProperty("display", "none");
    console.info("hide canvas");
  };

  public removeScript = (script: any) => {
    script?.parentNode?.removeChild(script);
    const canvas = document.getElementById("UT_CANVAS") || document.getElementById("game-phaser");
    canvas?.parentNode?.removeChild(canvas);
  };

  public showCanvas = () => {
    console.log("show canvas");
    const canvas = document.getElementById("UT_CANVAS") || document.getElementById("game-phaser");
    canvas?.style.setProperty("maxWidth", "100vw");
    canvas?.style.setProperty("maxHeight", "100vh");
    canvas?.style.setProperty("display", "block");
    canvas?.style.setProperty("background", "#222323");
  };

  public resetStyleBody = (oldBody: { [key: string]: string }) => {
    if (document.body) {
      ["margin", "border", "overflow", "display"].forEach((property: string) => {
        if (oldBody[property]) {
          document.body.style.setProperty(property, oldBody[property]);
        } else {
          document.body.style.removeProperty(property);
        }
      });
    }
  };
}
