import { CognitoUser } from "amazon-cognito-identity-js";
import { EMPTY_USER } from "../../modelsv2/users";
import { IBaseNotification, IUserData } from "../../types/baseInterfaces";

export const INITIAL_STATE = {
  user: null,
  isAuthenticating: true,
  userData: EMPTY_USER,
  notifications: null,
  pushModalData: null,
  virtualCoins: 0,
  likedGameStatus: [],
  simulationWS: null,
  userTransactions: null,
  hasNewNotification: false,
  isWsClientConnected: false,
  userWallets: [],
  // Mock functions that should never be called, they are just here to pass the type checks
  login: (username: string, password: string) => {
    return new Promise<CognitoUser | any>(function (resolve, reject) {
      reject({});
    });
  },
  logout: () => {
    return new Promise<void>(function (resolve, reject) {
      reject();
    });
  },
  confirmLogin: () => {
    return new Promise<CognitoUser | any | undefined>(function (resolve, reject) {
      reject({});
    });
  },
  getUserData: () => {
    return new Promise<IUserData>(function (resolve, reject) {
      reject({});
    });
  },
  updateLocalStateUser: () => {
    return new Promise<any>(function (resolve, reject) {
      reject({});
    });
  },
  fetchNotifications: () => {
    return new Promise<IBaseNotification[]>(function (resolve, reject) {
      reject({});
    });
  },
  updateUserNotification: () => {
    return new Promise<any>(function (resolve, reject) {
      reject({});
    });
  },
  updatePushRequestModal: () => Promise.reject({}),
  updateLikedGameStatus: () => Promise.reject([]),
  setListLikedGameStatus: () => Promise.reject([]),
  connectSimulationWebsocket: () => {},
  updateUserTransactions: () => {},
  updateUserHasNewNotification: () => {},
  updateIsWsClientConnected: () => {},
  updateUserWallets: () => {},
};
