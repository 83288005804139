import AuthAmplify from "@aws-amplify/auth";

export class Auth {
  /**
   * To return an object with the authentication callbacks
   */
  static signin = (username: string, password: string) => {
    return AuthAmplify.signIn(username, password);
  };

  /**
   * To get data of current authenticated user
   */
  static currentAuthenticatedUser = async () => {
    const user = await AuthAmplify.currentAuthenticatedUser();
    return user;
  };

  /**
   * To confirm a new password using a confirmation code
   */
  static forgotPassword = (username: string) => {
    return AuthAmplify.forgotPassword(username);
  };

  /**
   * To initiate a forgot password request
   */
  static changePassword = (user: any, oldPassword: string, newPassword: string) => {
    return AuthAmplify.changePassword(user, oldPassword, newPassword);
  };

  /**
   * To change a password for an authenticated user
   */
  static signOut = () => {
    return AuthAmplify.signOut();
  };
}
