const UtilsPWA: any = {
  deferredPrompt: null,
};

window.addEventListener("beforeinstallprompt", (e) => {
  // Prevent the mini-infobar from appearing on mobile
  e.preventDefault();
  // Stash the event so it can be triggered later.
  UtilsPWA.deferredPrompt = e;
});

window.addEventListener("appinstalled", () => {
  // Log install to analytics
  console.log("INSTALL: Success");
});

export default UtilsPWA;
