import apollo from "../clients/apollo";

import { QueryOptions } from "@apollo/client";
import { listPromos } from "../apollo/queries/promos";
import { startPromoBeat, endPromoBeat } from "../apollo/mutations/promosBeat";

const apolloClient = apollo.getInstance();

export class Promos {
  /**
   * To get list Promo
   */
  static listPromos = async (options: Partial<QueryOptions> = {}): Promise<any> => {
    const response = await apolloClient.query({
      query: listPromos,
      ...options,
    });

    return response.data.listPromos;
  };

  /**
   * To play a Promo beat
   */
  static startPromoBeat = async (input: any) => {
    const response = await apolloClient.mutate({
      mutation: startPromoBeat,
      variables: {
        startPromoInput: input,
      },
    });

    return response.data.startPromo;
  };

  /**
   * To end a Promo beat
   */
  static endPromoBeat = async (input: any) => {
    const response = await apolloClient.mutate({
      mutation: endPromoBeat,
      variables: {
        endPromoInput: input,
      },
    });

    return response.data.endPromo;
  };
}
