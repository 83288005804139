import apollo from "../clients/apollo";
import {
  getTournament,
  listOpenTournaments,
  getTournamentWithRank,
  listTournament,
  listBeatIt,
  getLightTournament,
} from "../apollo/queries/tournament";
import { enterTournament, playTournament } from "../apollo/mutations/tournament";
import {
  IBaseDailyTournament,
  IBaseListBeatIt,
  IParamsGetTournament,
  IParamsPlayTournament,
} from "../types/baseInterfaces";
import { pick } from "lodash-es";
import { QueryOptions } from "@apollo/client";
import { getBeatIt } from "../apollo/queries/battles";

const apolloClient = apollo.getInstance();

export class Tournament {
  /**
   * To get tournament
   */
  static getTournament = async (params: IParamsGetTournament, options: Partial<QueryOptions> = {}) => {
    const response = await apolloClient.query({
      ...options,
      query: getTournament,
      variables: {
        getTournamentGetTournamentInput: pick({ ...params }, ["id"]),
        leaderboardsGetLeaderboardInput: pick({ ...params }, "limit"),
      },
    });
    return response.data.getTournament;
  };

  static getLightTournament = async (params: IParamsGetTournament, options: Partial<QueryOptions> = {}) => {
    const response = await apolloClient.query({
      ...options,
      query: getLightTournament,
      variables: {
        getTournamentGetTournamentInput: pick({ ...params }, ["id"]),
      },
    });
    return response.data.getTournament;
  };

  /**
   * To get tournament with rank
   */
  static getTournamentWithRank = async (params: IParamsGetTournament, options: Partial<QueryOptions> = {}) => {
    const response = await apolloClient.query({
      ...options,
      query: getTournamentWithRank,
      variables: {
        getTournamentGetTournamentInput: pick({ ...params }, ["id", "tournamentPlayerId"]),
        leaderboardsGetLeaderboardInput: pick({ ...params }, "limit"),
      },
    });
    return response.data.getTournament;
  };

  /**
   * To list open tournament
   */
  static listOpenTournaments = async (currency: string, limit: number, options: Partial<QueryOptions> = {}) => {
    const response = await apolloClient.query({
      query: listOpenTournaments,
      variables: {
        listOpenTournamentsListOpenTournamentsInput: { currency },
        leaderboardsGetLeaderboardInput: { limit },
      },
      ...options,
    });

    return response;
  };

  /**
   * To play a tournament
   */
  static playTournament = async (gameSessionId: string) => {
    const response = await apolloClient.mutate({
      mutation: playTournament,
      variables: { playTournamentInput: { gameSessionId } },
    });

    return response.data.playTournament;
  };

  /**
   * To enter a tournament
   */
  static enterTournament = async (params: IParamsPlayTournament) => {
    const response = await apolloClient.mutate({
      mutation: enterTournament,
      variables: { enterTournamentInput: params },
    });

    return response.data.enterTournament;
  };

  static listTournament = async (
    options: Partial<QueryOptions> = {},
    limit?: number
  ): Promise<IBaseDailyTournament[]> => {
    const response = await apolloClient.query({
      query: listTournament,
      variables: { listTournamentInput: { limit } },
      ...options,
    });

    return response.data.listTournament;
  };

  static listBeatIt = async (options = {}): Promise<IBaseListBeatIt[]> => {
    const response = await apolloClient.query({
      ...options,
      query: listBeatIt,
      variables: {},
    });

    return response.data.listBeatIt;
  };

  /**
   * To get list Beat it practice tournaments by game id
   */
  static getBeatIt = async (gameId: string): Promise<IBaseListBeatIt[]> => {
    const res = await apolloClient.mutate({
      mutation: getBeatIt,
      variables: { getBeatItInput: { gameId: gameId } },
    });

    return res.data.getBeatIt;
  };
}
