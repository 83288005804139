/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Suspense } from "react";
import { Switch as SwitchRRD, Redirect, useLocation, useHistory } from "react-router-dom";
import { UserCtx } from "./context/user/state";
import { isEmpty } from "lodash-es";

import { DynamicLoading } from "./components/OnmoLoading/DynamicLoading";
import { AppRoutes } from "./components/AppRoutes";
import ResetPassword from "./views/ResetPassword/ResetPassword";

import { UnauthenticatedRoute } from "./components/AppRoutes/UnauthenticatedRoute";
import { AuthenticatedRoute } from "./components/AppRoutes/AuthenticatedRoute";
import { MaintenanceCtx } from "./context/maintenance/state";
import { GlobalContextProvider } from "./context/index";
import EmailSignIn from "./views/SignIn/partials/EmailSignIn/EmailSignIn";
import EmailSignUp from "./views/SignIn/partials/EmailSignUp/EmailSignUp";
import PhoneConfirmationCode from "./views/SignIn/partials/PhoneConfirmationCode/PhoneConfirmationCode";
import EmailConfirmation from "./views/SignIn/partials/EmailConfirmation/EmailConfirmation";
import PhoneSignIn from "./views/SignIn/partials/PhoneSignIn/PhoneSignIn";
import Login from "./pages/Login/Login";
import Onboarding from "./pages/Onboarding/Onboarding";
import GoogleSignIn from "./views/SignIn/partials/GoogleSignIn/GoogleSignIn";
import { Login as LoginChingari } from "./pages/Chingari";
import { getTheme } from "./utils/theme";
import { ThemeName } from "./constants/theme";
import { playPromoBattle } from "./utils/drawResultInstaBeat";
import { Promos } from "./modelsv2/promos";
import { Users } from "./modelsv2/users";

const AppLogged = React.lazy(() => import("./AppLogged"));

export default function Routes(props: { open: boolean; setOpen: Function }) {
  const location = useLocation();
  const { setIsMaintenance } = React.useContext(MaintenanceCtx);
  const [isLoading, setIsLoading] = React.useState(false);
  const [cognitoPhone, setCognitoPhone] = React.useState<string>("");
  const { user, confirmLogin, isAuthenticating, getUserData } = React.useContext(UserCtx);
  const history = useHistory();
  const THEME = getTheme();

  const urlParams = new URLSearchParams(window.location.search);
  const promoBattleId = urlParams.get("promoBattleId");
  if (promoBattleId) localStorage.setItem("promoBattleId", promoBattleId);

  const authenticateUser = async () => {
    setIsLoading(true);
    const confirmedUser = await confirmLogin();
    if (confirmedUser?.attributes?.sub) {
      try {
        await getUserData();
        await Users.checkinUser();
        if (THEME?.name !== ThemeName.Onmo) {
          // HACK 1 because we don't know when we receive the coins
          setTimeout(async () => {
            await getUserData();
          }, 5000);

          // HACK 2 because we don't know when we receive the coins
          setTimeout(async () => {
            await getUserData();
          }, 10000);
        }
        setIsMaintenance(false);
      } catch (e) {
        console.error(e);
        setIsMaintenance(true);
      }
    } else if (!isEmpty(THEME?.authRedirectUrl)) {
      // B2B automatic redirect to onmoPay login
      window.location.href = THEME?.authRedirectUrl;
    }
    setIsLoading(false);
  };

  const defaultUrl = () => {
    if (!user) {
      return `/authenticate/guest${location.search}`;
    }
    if (process.env.REACT_APP_IS_CHINGARI === "true") {
      return "/app";
    }
    return `/app/bottom-nav/top-nav/discover${location.search}`;
  };

  const playPromoBattleGame = async (promoBattleId: string) => {
    try {
      const listPromos = await Promos.listPromos({ fetchPolicy: "network-only" });
      const promoItem = listPromos?.find((item: any) => item.id === promoBattleId);
      playPromoBattle(history, promoItem?.id, promoItem?.game?.type);
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    if (!user) {
      authenticateUser();
    } else {
      const promoBattleId = localStorage.getItem("promoBattleId");
      if (promoBattleId) playPromoBattleGame(promoBattleId);
    }
  }, [user]);

  if (isAuthenticating || isLoading) {
    const chingariSignIn = document.getElementById("first-time-chingari");
    const onmoSignIn = document.getElementById("first-time");
    const onmoRoot = document.getElementById("root");
    if (window.location.pathname !== "/authenticate/guest") {
      if (chingariSignIn) {
        chingariSignIn.style.display = "none";
      }
      if (onmoSignIn) {
        onmoSignIn.style.display = "none";
      }
      if (onmoRoot) {
        onmoRoot.style.display = "block";
      }
    } else {
      if (chingariSignIn) {
        chingariSignIn.style.display = "none";
      }
      if (onmoSignIn) {
        onmoSignIn.style.display = "block";
      }
      if (onmoRoot) {
        onmoRoot.style.display = "none";
      }
    }
    return <DynamicLoading />;
  }

  return (
    <SwitchRRD>
      {/* Unauthenticated Routes */}
      <UnauthenticatedRoute path="/authenticate">
        <Suspense fallback={<DynamicLoading />}>
          <SwitchRRD>
            {process.env.REACT_APP_LOGIN_PHONE === "true" && (
              <AppRoutes path="/authenticate/login/phone-confirmation-code">
                <PhoneConfirmationCode cognitoPhone={cognitoPhone} />
              </AppRoutes>
            )}
            {process.env.REACT_APP_LOGIN_EMAIL === "true" && (
              <AppRoutes path="/authenticate/login/email-confirmation">
                <EmailConfirmation />
              </AppRoutes>
            )}
            {process.env.REACT_APP_LOGIN_EMAIL === "true" && (
              <AppRoutes path="/authenticate/login/email-signin">
                <EmailSignIn />
              </AppRoutes>
            )}
            {process.env.REACT_APP_LOGIN_EMAIL === "true" && (
              <AppRoutes path="/authenticate/login/email-signup">
                <EmailSignUp />
              </AppRoutes>
            )}
            {process.env.REACT_APP_LOGIN_PHONE === "true" && (
              <AppRoutes path="/authenticate/login/phone-signin">
                <PhoneSignIn setCognitoPhone={setCognitoPhone} />
              </AppRoutes>
            )}
            <AppRoutes path="/authenticate/guest">
              <Login />
            </AppRoutes>
            <AppRoutes path="/authenticate/reset-password/:formStep">
              <ResetPassword />
            </AppRoutes>
            <AppRoutes path="/authenticate/reset-password">
              <ResetPassword />
            </AppRoutes>
            <AppRoutes path="/authenticate/onboarding">
              <Onboarding />
            </AppRoutes>
            <AppRoutes path="/authenticate/google">
              <GoogleSignIn />
            </AppRoutes>
            <AppRoutes path="/authenticate/chingari">
              <LoginChingari />
            </AppRoutes>
            <Redirect to={`/authenticate/guest${location.search}`} />
          </SwitchRRD>
        </Suspense>
      </UnauthenticatedRoute>

      {/* Authenticated Routes */}
      <AuthenticatedRoute path="/app">
        <GlobalContextProvider>
          <AppLogged open={props.open} setOpen={props.setOpen} />
        </GlobalContextProvider>
      </AuthenticatedRoute>

      {/* Default Routes */}
      <Redirect to={defaultUrl()} />
    </SwitchRRD>
  );
}
