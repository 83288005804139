import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Auth } from "aws-amplify";
import React, { useEffect } from "react";
import apollo from "../../../../clients/apollo";

export const GoogleSignIn = () => {
  useEffect(() => {
    apollo.closeSubscriptionClient(); // trigger wsClient reconnect with authToken
    Auth.federatedSignIn({ provider: "Google" as CognitoHostedUIIdentityProvider });
  }, []);

  return <></>;
};

export default GoogleSignIn;
