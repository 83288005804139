import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import Auth from "@aws-amplify/auth";
import * as Yup from "yup";
import ErrorOutlineIcon from "../../../assets/ErrorOutlineIcon.svg";
import ArrowBackIosIcon from "../../../assets/ArrowBackIosIcon.svg";
import { OnmoLoading } from "../../../components/OnmoLoading";
import { onboardingStyles } from "../../SignIn/SignIn.style";
import { useStyles as phoneSignInstyles } from "../../SignIn/partials/PhoneSignIn/PhoneSignIn.style";
import { useTranslation } from "react-i18next";
import { isValidEmail } from "../../../utils/utils";
import { useStyles } from "../ResetPassword.style";
import { Button, Typography, Grid, TextField, Container } from "../../../components/Common/ComponentExport";
import { AlertCtx } from "../../../context/alert/state";
import clsx from "classnames";

const resetPasswordSchema = Yup.object({
  email: Yup.string("Enter your email").required("Please enter a valid email address"),
});

interface IPropsResetPasswordForm {
  setAccountEmail: Function;
}

export default function ResetPasswordForm(props: IPropsResetPasswordForm) {
  const { t } = useTranslation();
  const { setAccountEmail } = props;
  const history: any = useHistory();
  const classes = useStyles();
  const onboardingClasses = onboardingStyles();
  const phoneSignInClasses = phoneSignInstyles();
  const { setNotificationError } = useContext(AlertCtx);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [wrongEmailError, setWrongEmailError] = useState<boolean>(false);

  const onClickBack = () => {
    if (history.length > 1) {
      history.go(-1);
    } else {
      history.push({ pathname: `/` });
    }
  };

  return (
    <div>
      <Container className={onboardingClasses.container}>
        {formLoading ? <OnmoLoading /> : null}
        <Grid container className={classes.mainContent}>
          <Grid item>
            <Grid container className={classes.resetPwdDiv}>
              <Grid item xs={3}>
                <Button
                  data-testid="button-back"
                  startIcon={<img alt="" src={ArrowBackIosIcon} />}
                  className={classes.backButton}
                  onClick={() => onClickBack()}>
                  {}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Typography className={onboardingClasses.fontWeight500}>{t("common_Reset Password")}</Typography>
              </Grid>
            </Grid>
            <hr className={onboardingClasses.headerHr} />
            <Grid item>
              <Formik
                initialValues={{
                  email: "",
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  if (!formLoading) {
                    setSubmitting(true);
                    setFormLoading(true);
                    setWrongEmailError(false);
                    try {
                      await Auth.forgotPassword(values.email.toLowerCase());
                      setAccountEmail(values.email.toLowerCase());
                    } catch (error: any) {
                      setFormLoading(false);
                      if (
                        error.message === "Username/client id combination not found." ||
                        error.message ===
                          "Cannot reset password for the user as there is no registered/verified email or phone_number"
                      ) {
                        setWrongEmailError(true);
                      } else {
                        setNotificationError(error.message);
                      }
                    }
                  }
                }}
                validationSchema={resetPasswordSchema}>
                {(props: any) => {
                  const { values, touched, errors, handleChange, handleBlur, handleSubmit } = props;
                  const isEmail = isValidEmail(values.email);

                  return (
                    <Form>
                      <Grid item>
                        <Grid container>
                          <Grid item xs={12} className={onboardingClasses.titleGrid}>
                            <Typography className={onboardingClasses.title}>
                              {t("resetPasswordForm_What’s your Email Address?")}
                            </Typography>
                            <Typography className={onboardingClasses.subtitle}>
                              {t("resetPasswordForm_We’ll send you a 6-digit verification code.")}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={phoneSignInClasses.phoneNumberGrid}>
                            <Typography className={onboardingClasses.label}>{t("common_Email")}</Typography>
                            <TextField
                              fullWidth
                              type="email"
                              name="email"
                              placeholder={t("resetPasswordForm_Your Email Address")}
                              variant="outlined"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                (errors.email && touched.email) || wrongEmailError
                                  ? onboardingClasses.error
                                  : onboardingClasses.textFieldRoot
                              }
                              inputStyle={onboardingClasses.textFieldRoot}
                              endAdornment={
                                <React.Fragment>
                                  {(errors.email && touched.email) || wrongEmailError ? (
                                    <img alt="" src={ErrorOutlineIcon} className={onboardingClasses.errorIconColor} />
                                  ) : (
                                    <></>
                                  )}
                                </React.Fragment>
                              }
                            />
                            {wrongEmailError && (
                              <div className={classes.wrongEmailError}>{t("Please enter a valid email address")}</div>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              isSubmit
                              data-testid="button-send"
                              fullWidth
                              variant="contained"
                              className={clsx(onboardingClasses.signin, !isEmail && classes.btnDisabled)}
                              onClick={() => handleSubmit()}
                              disabled={!isEmail}>
                              {t("common_Send Code")}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
