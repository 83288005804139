import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  btnFacebook: {
    background: "#1877F2",
    borderRadius: 50,
    color: theme.palette.primary.contrastText,
    fontSize: 14,
    height: 50,
    marginBottom: 15,
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    "& > svg": {
      position: "absolute",
      left: 30,
    },
  },
  btnPhone: {
    background: "#EE4036 !important",
    color: theme.palette.primary.contrastText,
    "& > svg > path": {
      stroke: theme.palette.background.default,
    },
  },
  btnApple: {
    background: "#222222",
    color: theme.palette.primary.contrastText,
  },
  btnGoogle: {
    background: theme.palette.background.default,
    borderRadius: 50,
    fontSize: 14,
    height: 50,
    marginBottom: 15,
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    "& > svg": {
      position: "absolute",
      left: 25,
    },
  },
}));
