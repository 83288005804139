import React from "react";
import "react-circular-progressbar/dist/styles.css";
import { useStyles } from "./OnmoLoading.style";
import LogoIcon from "../../assets/GuestMode/OnmoWhite.svg";
import { Grid, Typography } from "../Common/ComponentExport";

interface IOnmoLoadingProps {
  textLoading?: string;
}

export function OnmoLoading(props: IOnmoLoadingProps) {
  const classes = useStyles();
  const { textLoading } = props;

  return (
    <Grid container className={classes.container}>
      <div className={classes.content}>
        <div className={classes.spinner} />
        <img src={LogoIcon} alt="icon-logo-white" />
      </div>
      {textLoading && (
        <Typography variant="body1" className={classes.textLoading}>
          {textLoading}
        </Typography>
      )}
    </Grid>
  );
}

export default OnmoLoading;
