import { ACTIONS } from "./actions";

export default (state: any, action: any) => {
  switch (action.type) {
    case ACTIONS.OPEN_DIALOG:
      return {
        ...state,
        openDialog: true,
      };
    case ACTIONS.CLOSE_DIALOG: {
      return {
        ...state,
        openDialog: false,
      };
    }
    default:
      return state;
  }
};
