import React, { useEffect, useState, useContext } from "react";
import { onmoHtmlGame } from "../../HtmlGame/utils/onmoHtmlGame";
import { loadGame } from "../../HtmlGame/utils/loadGame";
import { hideCanvas, showCanvas } from "../../HtmlGame/utils/showOrHideCanvas";
import { resetStyleBody } from "../../HtmlGame/utils/restyleBody";
import { removeScript, freeMemoryAfterPlaying } from "../../HtmlGame/utils/freeMemoryAfterPlaying";
import { HtmlGameSessionHeader } from "../../HtmlGame/HtmlGameSessionHeader";
import { IBaseMoment } from "../../../types/baseInterfaces";
import { OnmoPopup } from "../../OnmoPopup";
import { useStyles } from "./PlayHtmlGame.style";
import { Analytics } from "../../../modelsv2/analytics";
import { CATEGORY } from "../../../constants/analyticsEvent";
import { GameSessions } from "../../../modelsv2/gameSessions";
import { Tournament } from "../../../modelsv2/tournament";
import { IBaseDailyTournament } from "../../../types/baseInterfaces";
import { Button, Typography } from "../../Common/ComponentExport";
import { PopupResultScreen } from "../../Chingari/PopupResultScreen";
import { useHistory } from "react-router-dom";
import { UserCtx } from "../../../context/user/state";

interface IPropsPlayHtmlGame {
  game: {
    dataMoment: string;
    id: string;
    moment: IBaseMoment;
    avatar: string;
    title: string;
    cost: number;
  };
  setLoadedPercentage: Function;
  loadedPercentage: number;
  started: boolean;
  setIsLoadedCallback: Function;
  onBack: Function;
  gameSessionId?: string | null;
  onResetGame?: Function;
}

let script: any;
let isReceivedEndgameEvent = false;

export const PlayHtmlGame = (props: IPropsPlayHtmlGame) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    game,
    setLoadedPercentage,
    started,
    loadedPercentage,
    setIsLoadedCallback,
    onBack,
    gameSessionId,
    onResetGame,
  } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const [timeStarted, setTimeStarted] = useState<number | null>(null);
  const [isEndGame, setIsEndGame] = useState(false);
  const [score, setScore] = useState(0);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [openDisconectedPopup, setOpenDisconectedPopup] = useState(false);
  const [isPause, setIsPause] = useState(false);
  const [isShowPopupWaitOrLeave, setIsShowPopupWaitOrLeave] = useState(false);
  const [tournament, setTournament] = useState<IBaseDailyTournament | null>(null);
  const { userData } = useContext(UserCtx);

  const startGame = () => {
    if (history.location.pathname.includes("onboarding")) {
      Analytics.trackEvent({
        category: CATEGORY.ONBOARDING,
        action: "game_start",
        label: game?.title,
        moment_type: "Battle",
        game_type: "HTML",
        currency: "Cash",
        user_uid: userData?.id,
      });
    }
    showCanvas();
    onmoHtmlGame?.resume();
    setTimeStarted(Date.now());
  };

  const onmoLoadGame = async () => {
    onmoHtmlGame.seeds = new Date().getTime();
    onmoHtmlGame.momentDatas = game.dataMoment;
    try {
      const _script = await loadGame(game.id, setLoadedPercentage);
      hideCanvas();
      script = _script;
    } catch (e) {
      console.log(e);
    }
    document.body.style.display = "block";
  };

  const updateOnlineStatus = () => {
    setIsOnline(navigator.onLine);
    if (navigator.onLine) {
      setOpenDisconectedPopup(false);
      setIsPause(false);
    } else {
      setOpenDisconectedPopup(true);
      setIsPause(true);
    }
  };

  const onmoUnloadedGame = async () => {
    if (isEndGame) {
      isReceivedEndgameEvent = true;
      onmoHtmlGame?.unload();
      await fetchTournament();
      document.body.style.overflow = "hidden";
    }
    return () => {
      onmoHtmlGame?.removeListener("UNLOADED");
    };
  };

  const loadedCallback = () => {
    isReceivedEndgameEvent = false;
    hideCanvas();
    onmoHtmlGame?.pause();
    setIsLoaded(true);
    setIsLoadedCallback(true);
  };

  const loadingCallback = () => {
    hideCanvas();
  };

  const dataCallback = (data: any) => {
    if (!isReceivedEndgameEvent) {
      setScore(data.score);
    }
  };

  const endGameCallback = (data: any) => {
    isReceivedEndgameEvent = true;
    setIsEndGame(true);
  };

  const domCallback = (event: any) => {};

  const loadCallback = () => {
    onmoHtmlGame?.addListener("LOADED", loadedCallback);
    onmoHtmlGame?.addListener("LOADING", loadingCallback);
    onmoHtmlGame?.addListener("DATA", dataCallback);
    onmoHtmlGame?.addListener("END_GAME", endGameCallback);
    onmoHtmlGame?.addListener("DOM", domCallback);
  };

  const freeMemory = () => {
    const keysBeforeJSON = localStorage.getItem("htmlgame:keys");
    let keysBefore = keysBeforeJSON === null ? Object.keys(window) : JSON.parse(keysBeforeJSON);
    if (keysBeforeJSON === null) {
      localStorage.setItem("htmlgame:keys", JSON.stringify(keysBefore));
    }
    return keysBefore;
  };

  const onClickBack = () => {
    onmoHtmlGame?.soundOff();
    setIsShowPopupWaitOrLeave(true);
    onmoHtmlGame?.pause();
    setIsPause(true);
  };

  const unMountComponent = (keysBefore: any) => {
    setIsLoadedCallback(false);
    resetStyleBody();
    if (isLoaded) {
      onmoHtmlGame?.soundOff();
      onmoHtmlGame?.unload();
    }
    removeScript(script);
    hideCanvas();
    isReceivedEndgameEvent = false;
    onmoHtmlGame?.removeListener("LOADED", loadedCallback);
    onmoHtmlGame?.removeListener("LOADING", loadingCallback);
    onmoHtmlGame?.removeListener("DATA", dataCallback);
    onmoHtmlGame?.removeListener("END_GAME", endGameCallback);
    onmoHtmlGame?.removeListener("DOM", domCallback);
    freeMemoryAfterPlaying(keysBefore);
    window.removeEventListener("online", updateOnlineStatus);
    window.removeEventListener("offline", updateOnlineStatus);
  };

  const onAgreeToGoBack = () => {
    setIsEndGame(true);
    setIsShowPopupWaitOrLeave(false);
  };

  const onClosePopup = () => {
    const gameVolume = localStorage.getItem("gameVolume");
    if (gameVolume && gameVolume !== "0") {
      onmoHtmlGame?.soundOn();
    }
    setIsShowPopupWaitOrLeave(false);
    onmoHtmlGame?.resume();
    setIsPause(false);
  };

  const fetchTournament = async () => {
    if (!gameSessionId) return;
    try {
      const time = timeStarted ? Math.round((Date.now() - timeStarted) / 1000) : 0;
      let finalScore;

      if (score === -1) {
        finalScore = -1;
      } else if (score === 0) {
        finalScore = 0;
      } else if (game?.moment?.type.toLowerCase() === "time") {
        finalScore = time;
      } else {
        finalScore = score;
      }

      await GameSessions.endHtmlGameSession({
        gameSessionId: gameSessionId,
        score: finalScore,
        time,
        failureMessage: `${finalScore === 0 || finalScore === -1 ? "You lost." : ""}`,
        inputEventCount: 0,
        updateScoreCount: 0,
      });
      const gameSession = await GameSessions.getGameSession(gameSessionId);
      const tournament = await Tournament.getTournamentWithRank(
        { id: gameSession?.tournamentId || "", tournamentPlayerId: gameSession?.tournamentPlayerId, limit: 10 },
        { fetchPolicy: "network-only" }
      );
      setTournament(tournament);
    } catch (e) {
      console.log(e);
    }
  };

  // CHECK IF USER NOT ACTIVE ONMO WEB TAB IN ORDER TO PAUSE GAME
  useEffect(() => {
    const onVisibilityChange = () => {
      if (timeStarted) {
        try {
          setIsPause(true);
          onmoHtmlGame?.pause();
        } catch (e) {
          console.warn(e);
        }
      }
    };
    document.addEventListener("visibilitychange", onVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onmoHtmlGame, timeStarted]);

  useEffect(() => {
    const gameBox = document.getElementById("UT_CANVAS") || document.getElementById("game-phaser");
    if (!isOnline) {
      gameBox?.style.setProperty("pointer-events", "none");
    } else {
      gameBox?.style.setProperty("pointer-events", "all");
    }
  }, [isOnline]);

  useEffect(() => {
    started && startGame();
  }, [started]);

  useEffect(() => {
    const addEventOnline = () => {
      window.addEventListener("online", updateOnlineStatus);
      window.addEventListener("offline", updateOnlineStatus);
    };
    onmoLoadGame();
    loadCallback();
    addEventOnline();
    const keysBefore = freeMemory();
    return () => {
      unMountComponent(keysBefore);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadedPercentage >= 100 && showCanvas();
  }, [loadedPercentage]);

  useEffect(() => {
    onmoUnloadedGame();
  }, [isEndGame, timeStarted]);

  return (
    <div>
      {timeStarted && (
        <div className={isEndGame ? classes.hideHeader : ""}>
          <HtmlGameSessionHeader
            onClickBack={onClickBack}
            nbScore={score}
            setIsEndGame={setIsEndGame}
            isShowEndScreen={isEndGame}
            isPause={isPause}
            moment={game.moment}
          />
        </div>
      )}
      <div id="error_log" style={{ display: "none" }} />
      {isEndGame && (
        <PopupResultScreen isOpen={isEndGame} resetGame={onBack} tournament={tournament} onResetGame={onResetGame} />
      )}
      <OnmoPopup
        isOpen={isShowPopupWaitOrLeave}
        title="Leave game"
        agreeText="Yes"
        disagreeText="No"
        onAgree={onAgreeToGoBack}
        onDisagree={onClosePopup}
        disableActions={false}
        isPriorityConfirm={true}
        readOnly={false}>
        <Typography className={classes.desc}>Do you want to leave ?</Typography>
      </OnmoPopup>
      <OnmoPopup
        isOpen={isPause && !isShowPopupWaitOrLeave && !isEndGame}
        title="Continue game session?"
        disableActions={true}>
        <Button className={classes.resumeStream} onClick={onClosePopup} fullWidth>
          Resume
        </Button>
      </OnmoPopup>
      <OnmoPopup
        isOpen={!isOnline && openDisconectedPopup}
        title="The stream has been disconnected."
        agreeText="Leave"
        disagreeText="Wait"
        onAgree={onAgreeToGoBack}
        onDisagree={() => {
          setOpenDisconectedPopup(false);
        }}
        disableActions={false}
        readOnly={false}>
        <Typography className={classes.desc}>Please check your network.</Typography>
      </OnmoPopup>
    </div>
  );
};
