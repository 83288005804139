import React, { useState, useEffect, useRef, useContext } from "react";
import { AlertCtx } from "../../../context/alert/state";
import { Html } from "../../../modelsv2/html";
import { LeaveGamePopup } from "../../Common/LeaveGamePopup";
import HtmlGameSessionHeader from "../../HtmlGame/HtmlGameSessionHeader/HtmlGameSessionHeader";
import { IOnboardingGame, IBaseMoment } from "../../../types/baseInterfaces";
import { PopupOnboardingCongrat } from "../PopupOnboardingCongrat";
import { useStyles } from "./OnboardingPlayGame.style";

let script: any;
// @ts-ignore
const onmoHtmlGame = window.onmoHtmlGame || {
  pause: () => {},
  resume: () => {},
  unload: () => {},
  addListener: () => {},
  removeListener: () => {},
  soundOn: () => {},
  soundOff: () => {},
};

interface IOnboardingPlayGameProps {
  setLoadedPercentage: Function;
  started?: boolean;
  game: IOnboardingGame;
  onResetGame: Function;
  isLoaded: boolean;
  setIsLoaded: Function;
}

export const OnboardingPlayGame = (props: IOnboardingPlayGameProps) => {
  const { setLoadedPercentage, started, game, onResetGame, isLoaded, setIsLoaded, ...otherProps } = props;
  const classes = useStyles();
  const [timeStarted, setTimeStarted] = useState<number | null>(null);
  const [pauseDuration, setPauseDuration] = useState<number>(0);
  const [score, setScore] = useState(0);
  const [isEndGame, setIsEndGame] = useState(false);
  const [isPause, setIsPause] = useState(false);
  const [pauseStartTime, setPauseStartTime] = useState<number>(0);
  const [openLeaveGamePopup, setOpenLeaveGamePopup] = useState(false);
  const { setNotificationError } = useContext(AlertCtx);

  const htmlModel = new Html();
  const scoreRef = useRef(score);
  scoreRef.current = score;

  const onClickBack = () => {
    setOpenLeaveGamePopup(true);
    onmoHtmlGame?.pause();
    setIsPause(true);
  };

  const onClosePopup = () => {
    setOpenLeaveGamePopup(false);
    onmoHtmlGame?.resume();
    setIsPause(false);
  };

  const onConfirmCallback = () => {
    setOpenLeaveGamePopup(false);
    onClose();
  };

  const onloadScriptCallback = (script: any) => {
    document.body.appendChild(script);
  };

  const onmoLoadGame = async () => {
    onmoHtmlGame.seeds = new Date().getTime();
    onmoHtmlGame.momentDatas = game?.dataMoment;
    try {
      const _script = await htmlModel.loadGame(game.id, setLoadedPercentage, onloadScriptCallback);
      script = _script;
      setIsPause(false);
      startGame();
    } catch (e) {
      console.log(e);
    }
    document.body.style.display = "block";
  };

  const startGame = () => {
    try {
      setTimeStarted(Date.now());
    } catch (e) {
      console.log(e);
      htmlModel.hideCanvas();
    }
  };

  const onVisibilityChange = () => {
    if (timeStarted) {
      onmoHtmlGame?.soundOff();
      try {
        setIsPause(true);
        onmoHtmlGame?.pause();
        if (pauseStartTime) {
          const time = pauseDuration + Date.now() - pauseStartTime;
          setPauseDuration(time);
        }
        setPauseStartTime(Date.now());
      } catch (e) {
        setIsPause(false);
      }
    }
  };

  const onmoUnloadedGame = () => {
    if (isEndGame) {
      onmoHtmlGame?.unload();
    }
    return () => {
      onmoHtmlGame?.removeListener("UNLOADED");
    };
  };

  const onClose = async () => {
    onResetGame(false);
    setIsEndGame(false);
    setTimeStarted(0);
    onmoUnloadedGame();
    setScore(0);
    htmlModel.hideCanvas();
    setIsLoaded(false);
    htmlModel.loadGamePercent(game?.id, setLoadedPercentage);
  };

  useEffect(() => {
    onmoUnloadedGame();
  }, [isEndGame]);

  useEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, [onmoHtmlGame, timeStarted, pauseStartTime]);

  useEffect(() => {
    started && onmoLoadGame();
  }, [started]);

  useEffect(() => {
    let timeoutStart: NodeJS.Timeout;
    const loadedCallback = async () => {
      try {
        htmlModel.showCanvas();
        onmoHtmlGame?.resume();
        setIsLoaded(true);
      } catch (error) {
        setNotificationError("Fail to start game!");
        setIsLoaded(false);
      }
    };
    const loadingCallback = () => {
      htmlModel.hideCanvas();
    };
    const domCallback = () => {};
    const dataCallback = (data: any) => {
      setScore(data.score);
    };
    const endGameCallback = () => {
      setIsEndGame(true);
    };
    onmoHtmlGame?.addListener("LOADED", loadedCallback);
    onmoHtmlGame?.addListener("LOADING", loadingCallback);
    onmoHtmlGame?.addListener("END_GAME", endGameCallback);
    onmoHtmlGame?.addListener("DATA", dataCallback);
    onmoHtmlGame?.addListener("DOM", domCallback);
    window.addEventListener("popstate", onClickBack);
    return () => {
      htmlModel.removeScript(script);
      htmlModel.hideCanvas();
      clearTimeout(timeoutStart);
      onmoHtmlGame?.removeListener("LOADED", loadedCallback);
      onmoHtmlGame?.removeListener("LOADING", loadingCallback);
      onmoHtmlGame?.removeListener("DATA", dataCallback);
      onmoHtmlGame?.removeListener("END_GAME", endGameCallback);
      onmoHtmlGame?.removeListener("DOM", domCallback);
      window.removeEventListener("popstate", onClickBack);
    };
  }, []);

  useEffect(() => {
    onmoUnloadedGame();
    htmlModel.loadGamePercent(game?.id, setLoadedPercentage);
  }, [game]);

  if (!isLoaded) return null;

  return (
    <div {...otherProps}>
      <LeaveGamePopup open={openLeaveGamePopup} onCloseCallback={onClosePopup} onConfirmCallback={onConfirmCallback} />
      {timeStarted && (
        <div className={isEndGame ? classes.hideHeader : ""}>
          <HtmlGameSessionHeader
            onClickBack={onClickBack}
            nbScore={score}
            setIsEndGame={setIsEndGame}
            isShowEndScreen={isEndGame}
            isPause={isPause}
            moment={game?.moment as IBaseMoment}
          />
        </div>
      )}
      <PopupOnboardingCongrat isEndGame={isEndGame} isOpen={isEndGame} onClose={onClose} title="Congratulations" />
    </div>
  );
};
