import { createUseStyles } from "react-jss";
import { ITheme } from "../../types/theme";

export const onboardingStyles = createUseStyles((theme: ITheme) => ({
  mainContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  resetPwdDiv: {
    display: "flex",
    alignItems: "baseline",
  },
  container: {
    maxWidth: 560,
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    "@media(max-height: 568px)": {
      marginTop: 14,
    },
    "@media(min-height: 569px) and (max-height: 667px)": {
      marginTop: 16,
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      marginTop: 18,
    },
  },
  imgLink: {
    flexGrow: 1,
  },
  img: {
    height: 72,
    "@media(max-height: 568px)": {
      height: 50,
    },
    "@media(min-height: 569px) and (max-height: 667px)": {
      height: 57,
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      height: 64,
    },
  },
  backButton: {
    fontSize: 15,
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: -0.2,
    textAlign: "left",
    paddingLeft: "0px",
    justifyContent: "left",
    "@media(max-height: 568px)": {
      fontSize: 10.5,
      lineHeight: "14px",
    },
    "@media(min-height: 569px) and (max-height: 667px)": {
      fontSize: 11,
      lineHeight: "16px",
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      fontSize: 13,
      lineHeight: "18px",
    },
  },
  extraPaddingBottom: {
    paddingBottom: "50px !important",
    "@media(max-height: 568px)": {
      paddingBottom: "28px !important",
    },
    "@media(min-height: 569px) and (max-height: 667px)": {
      paddingBottom: "40px !important",
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      paddingBottom: "45px !important",
    },
  },
  error: {
    "& input": {
      padding: "15px 14px",
      "@media(max-height: 568px)": {
        padding: "10.5px 14px",
      },
      "@media(min-height: 569px) and (max-height: 667px)": {
        padding: "12px 14px",
      },
      "@media (min-height: 668px) and (max-height: 736px)": {
        padding: "13px 14px",
      },
    },
    borderColor: "#BF0E08",
    "&.root": {
      color: "#BF0E08",
    },
    "&.input": {
      borderColor: "#BF0E08",
    },
    "& fieldset": {
      borderColor: "#BF0E08 !important",
    },
    "&:hover fieldset": {
      borderColor: "#BF0E08 !important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#BF0E08 !important",
    },
    "& .Mui-focused fieldset": {
      border: "2px solid #BF0E08 !important",
    },
    "& .MuiFormHelperText-root": {
      color: "#BF0E08",
      marginLeft: 0,
      marginTop: 4,
      fontSize: 12,
      lineHeight: "15px",
      "@media(max-height: 568px)": {
        marginTop: 2.1,
        fontSize: 11.2,
        lineHeight: "14px",
      },
      "@media(min-height: 569px) and (max-height: 667px)": {
        marginTop: 3,
        fontSize: 10,
        lineHeight: "12px",
      },
      "@media (min-height: 668px) and (max-height: 736px)": {
        marginTop: 3,
        fontSize: 11,
        lineHeight: "13px",
      },
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
    "& .MuiInputAdornment-positionEnd": {
      marginRight: 14,
    },
  },
  passwordErrorHelperText: {
    color: "#BF0E08",
    marginLeft: 0,
    marginTop: 4,
    fontSize: 12,
    lineHeight: "15px",
    "@media(max-height: 568px)": {
      marginTop: 2.1,
      fontSize: 11.2,
      lineHeight: "14px",
    },
    "@media(min-height: 569px) and (max-height: 667px)": {
      marginTop: 3,
      fontSize: 10,
      lineHeight: "12px",
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      marginTop: 3,
      fontSize: 11,
      lineHeight: "13px",
    },
  },
  label: {
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: -0.2,
    textAlign: "left",
    paddingBottom: 4,
    color: "#222222",
    "@media(max-height: 568px)": {
      marginTop: 2.1,
      fontSize: 12,
      lineHeight: "14px",
    },
    "@media(min-height: 569px) and (max-height: 667px)": {
      paddingBottom: 3,
      fontSize: 13,
      lineHeight: "16px",
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      paddingBottom: 3,
      fontSize: 14,
      lineHeight: "18px",
    },
  },
  facebook: {
    height: 48,
    fontWeight: 500,
    fontSize: 16,
    background: "#1877F2",
    textTransform: "none",
    borderRadius: 50,
    color: "white",
    letterSpacing: -0.2,
    "&:hover": {
      background: "#1E88E5",
    },
    "&$startIcon": {
      position: "absolute",
      left: 18,
    },
    "@media(max-height: 568px)": {
      height: 33.6,
      fontSize: 11.2,
    },
    "@media(min-height: 569px) and (max-height: 667px)": {
      height: 38,
      fontSize: 12,
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      height: 44,
      fontSize: 14,
    },
  },
  startIcon: {
    position: "absolute",
    left: 22,
  },
  signin: {
    height: 48,
    fontWeight: 500,
    fontSize: 16,
    background: theme.button?.primary.background,
    borderRadius: 50,
    color: theme.palette.primary.contrastText,
    "&:disabled": {
      background: "#C0C0C0",
      color: "#FAFAFA",
    },
    "@media(max-height: 568px)": {
      height: 35.46,
      fontSize: 11.2,
    },
    "@media(min-height: 569px) and (max-height: 667px)": {
      height: 38,
      fontSize: 12,
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      height: 44,
      fontSize: 14,
    },
  },
  btnDisabled: {
    background: "#C0C0C0",
    color: `${theme.palette.primary.contrastText} !important`,
  },
  title: {
    fontWeight: 700,
    fontSize: 31,
    lineHeight: "40px",
    letterSpacing: -0.2,
    color: "#222222",
    "@media (min-width: 414px)": {
      height: 80,
    },
    "@media (min-width: 360px) and (max-width: 374px)": {
      fontSize: 24,
      lineHeight: "28px",
      marginBottom: -3,
    },
    "@media (min-width: 320px) and (max-width: 359px)": {
      fontSize: 20,
      lineHeight: "22px",
      marginBottom: -3,
    },
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: -0.2,
    textAlign: "left",
    marginTop: 15,
    "@media(max-height: 568px)": {
      fontSize: 11.2,
      lineHeight: "14px",
      marginTop: 8.4,
      marginBottom: 0,
    },
    "@media(min-height: 569px) and (max-height: 667px)": {
      fontSize: 12,
      lineHeight: "16px",
      marginTop: 12,
      marginBottom: 0,
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      fontSize: 14,
      lineHeight: "18px",
      marginTop: 14,
      marginBottom: 0,
    },
  },
  textFieldRoot: {
    borderRadius: 8,
    width: "100%",
    border: "unset",
    "& input": {
      padding: "15px 14px",
      "&:focus-visible": {
        border: "unset",
        outline: "none",
      },
      "@media(max-height: 568px)": {
        padding: "10.5px 14px",
      },
      "@media(min-height: 569px) and (max-height: 667px)": {
        padding: "12px 14px",
      },
      "@media (min-height: 668px) and (max-height: 736px)": {
        padding: "13px 14px",
      },
    },
    "&:hover fieldset": {
      borderColor: "#33776B !important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#33776B !important",
    },
    "& .Mui-focused fieldset": {
      border: "2px solid #33776B !important",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
    "& .MuiInputAdornment-positionEnd": {
      marginRight: 14,
    },
  },
  agreement: {
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: -0.2,
    textAlign: "center",
    color: "#8a8a8e",
    paddingTop: 16,
    "@media(max-height: 568px)": {
      fontSize: 9.8,
      lineHeight: "10.5px",
    },
    "@media(min-height: 569px) and (max-height: 667px)": {
      fontSize: 12,
      lineHeight: "15px",
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      fontSize: 13,
      lineHeight: "16px",
    },
    "@media (orientation: landscape)": {
      paddingTop: "8px",
    },
  },
  version: {
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: -0.2,
    textAlign: "center",
    color: "#8a8a8e",
    "@media(max-height: 568px)": {
      fontSize: 9.8,
      lineHeight: "10.5px",
    },
    "@media(min-height: 569px) and (max-height: 667px)": {
      fontSize: 12,
      lineHeight: "15px",
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      fontSize: 13,
      lineHeight: "16px",
    },
  },
  linkStyle: {
    cursor: "pointer",
    textDecoration: "underline",
    color: theme.palette.primary.main,
    marginLeft: 5,
  },
  titleGrid: {
    paddingTop: 48,
    paddingBottom: "20px !important",
    "@media(max-height: 568px)": {
      paddingTop: 33.6,
      paddingBottom: "14px !important",
    },
    "@media(min-height: 569px) and (max-height: 667px)": {
      paddingTop: 38,
      paddingBottom: "16px !important",
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      paddingTop: 44,
      paddingBottom: "18px !important",
    },
  },
  logoGrid: {
    marginTop: 96,
    marginBottom: 96,
    "@media(max-height: 568px)": {
      marginTop: 67.2,
      marginBottom: 67.2,
    },
    "@media(min-height: 569px) and (max-height: 667px)": {
      marginTop: 76,
      marginBottom: 76,
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      marginTop: 86,
      marginBottom: 86,
    },
  },
  passwordErrorInput: {
    "& input": {
      padding: "15px 14px 15px 0px",
      "@media(max-height: 568px)": {
        padding: "10.5px 14px 10.5px 0px",
      },
      "@media(min-height: 569px) and (max-height: 667px)": {
        padding: "12px 14px 12px 0px",
      },
      "@media (min-height: 668px) and (max-height: 736px)": {
        padding: "13px 14px 13px 0px",
      },
    },
    border: "1px solid",
    borderColor: "#BF0E08",
    borderRadius: 8,
    paddingLeft: 14,
    "&:hover": {
      borderColor: "#BF0E08",
    },
    "&:focus": {
      borderColor: "#BF0E08",
    },
    "&:before": {
      border: 0,
    },
    "&.Mui-focused": {
      border: "2px solid #BF0E08",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
    "& .MuiInputAdornment-positionEnd": {
      marginRight: 14,
    },
    "& .MuiButtonBase-root": {
      paddingRight: 0,
    },
  },
  errorIconColor: {
    color: "#BF0E08",
  },
  passwordErrorIcon: {
    marginRight: 12,
  },
  passwordInput: {
    "& input": {
      padding: "15px 14px 15px 0px",
      width: "100%",
      border: "unset",
      "@media(max-height: 568px)": {
        padding: "10.5px 14px 10.5px 0px",
      },
      "@media(min-height: 569px) and (max-height: 667px)": {
        padding: "12px 14px 12px 0px",
      },
      "@media (min-height: 668px) and (max-height: 736px)": {
        padding: "13px 14px 13px 0px",
      },
    },
    width: "100%",
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
    "&:focus": {
      borderColor: theme.palette.primary.main,
    },
    "&:before": {
      border: 0,
    },
    "&.Mui-focused": {
      border: "2px solid #33776B",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
    "& .MuiInputAdornment-positionEnd": {
      marginRight: 14,
    },
    "& .MuiButtonBase-root": {
      paddingRight: 0,
    },
  },
  passwordGrid: {
    paddingTop: 36,
    paddingBottom: 36,
    "@media(max-height: 568px)": {
      paddingTop: 25.2,
      paddingBottom: 25.2,
    },
    "@media(min-height: 569px) and (max-height: 667px)": {
      paddingTop: 28,
      paddingBottom: 28,
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      paddingTop: 32,
      paddingBottom: 32,
    },
  },
  buttons: {
    maxWidth: 560,
    margin: "auto",
  },
  fontWeight500: {
    fontWeight: 500,
    alignItems: "center",
    alignContent: "center",
  },
  muted: {
    color: "#8a8a8e",
  },
  headerHr: {
    margin: "11px -20px 0 -20px",
    border: "1px solid #FAFAFA",
    minWidth: "100%",
    "@media(max-height: 568px)": {
      margin: "7.7px -20px 0 -20px",
    },
    "@media(min-height: 569px) and (max-height: 667px)": {
      margin: "9px -20px 0 -20px",
    },
    "&.emailHR": {
      margin: "11px -40px 40px -40px",
      "@media(max-height: 568px)": {
        margin: "6.2px -40px 2.8px -40px",
      },
      "@media(min-height: 569px) and (max-height: 667px)": {
        margin: "9px -40px 32px -40px",
      },
      "@media (min-height: 668px) and (max-height: 736px)": {
        margin: "10px -40px 36px -40px",
      },
    },
  },
  t70: {
    top: 70,
    "@media(max-height: 568px)": {
      top: 50,
    },
    "@media(min-height: 569px) and (max-height: 667px)": {
      top: 56,
    },
  },
  passwordactionPreventErrorText: {
    color: "#BF0E08",
  },
  passwordactionPreventErrorTextLeft: {
    color: "#8a8a8e",
  },
}));
