import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles(
  (theme) => ({
    wrapper: {
      position: "fixed",
      backgroundColor: "#260E2E",
      zIndex: 22,
      width: "100%",
      bottom: 0,
      height: 62,
      display: "flex",
      margin: "auto",
      // boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.15)",
      "& > div": {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    childrenContent: {
      height: "fit-content",
      paddingBottom: 20,
    },
  }),
  { index: 1 }
);
