import React, { useState } from "react";
import NoInternetImage from "../../assets/empty_state_imgs/NoInternetImage.svg";
import { OnmoLoading } from "../OnmoLoading";
import { useTranslation } from "react-i18next";
import { useStyles } from "./NoInternet.style";
import { useHistory } from "react-router-dom";
import { Button, Typography } from "../Common/ComponentExport";

export const NoInternet = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);

  const onClickRefresh = () => {
    setLoading(true);
    history.push({
      pathname: window.location.pathname,
    });
  };

  return (
    <>
      {isLoading && <OnmoLoading />}
      <div className={classes.root}>
        <div>
          <img alt="" src={NoInternetImage} />
        </div>
        <Typography className={classes.p1}>{t("common_Whoops")}</Typography>
        <Typography className={classes.p2}>
          {t("noInternet_No internet connection! Make sure your wifi or cellular data is turned on.")}
        </Typography>
        <Button data-testid="text-refresh" onClick={onClickRefresh}>
          {t("noInternet_Refresh")}
        </Button>
      </div>
    </>
  );
};
export default NoInternet;
