import { ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject, split, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Auth } from "aws-amplify";
import { WebSocketLink } from "@apollo/client/link/ws";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { getMainDefinition } from "@apollo/client/utilities";

let isWsConnected = false;

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_V2 || "https://3xj7i67lte.execute-api.us-east-1.amazonaws.com/dev",
  includeExtensions: true,
});

const authLink = setContext(async (_, { headers }) => {
  const currentSession = await Auth.currentSession();
  const accessToken = currentSession.getAccessToken();
  const tokenJwt = accessToken.getJwtToken();

  return {
    headers: {
      ...headers,
      authorization: tokenJwt || "",
    },
  };
});

const wsClient = new SubscriptionClient(
  process.env.REACT_APP_API_RESPONSE_WS_URL || "wss://10etk93b59.execute-api.us-east-1.amazonaws.com/dev",

  {
    // lazy: true,
    reconnect: true,
    connectionParams: async () => {
      const cognitoSession = await Auth.currentSession();
      const authToken = cognitoSession.getAccessToken().getJwtToken();
      return {
        headers: {
          authorization: authToken,
        },
      };
    },
  },
  null,
  []
);

const originalOnmessageFunc = wsClient.client.onmessage;

const checkConnectionStatus = (data: any) => {
  try {
    const dataReceived = JSON.parse(data);
    if (dataReceived?.payload?.data?.status === "success") {
      console.log("[Subscription] - Authenticated");
      isWsConnected = true;

      // put back the orignal onmessage func
      wsClient.client.onmessage = originalOnmessageFunc;
    } else if (dataReceived?.payload?.data?.status === "error") {
      isWsConnected = false;
      closeSubscriptionClient();
    }
  } catch (e) {
    console.log(e);
  }
};

wsClient.onConnecting(() => {
  console.log("[Subscription] - Connecting...");

  // override onmessage func
  wsClient.client.onmessage = ({ data }: { data: any }) => {
    checkConnectionStatus(data);
  };
});

wsClient.onReconnecting(() => {
  console.log("[Subscription] - Reconnecting...");

  // override onmessage func
  wsClient.client.onmessage = ({ data }: { data: any }) => {
    checkConnectionStatus(data);
  };
});

wsClient.onDisconnected(() => {
  console.log("[Subscription] - Disconnected");
  isWsConnected = false;
});

const isWsClientConnected = () => {
  return isWsConnected;
};

// wsClient will reconnect since `reconnect`equals true
const closeSubscriptionClient = () => {
  wsClient.close(false);
};

const wsLink = new WebSocketLink(wsClient);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink,
  httpLink
);

let client: ApolloClient<NormalizedCacheObject> | null = null;
export const getInstance = (): ApolloClient<NormalizedCacheObject> => {
  if (client) return client;

  client = new ApolloClient({
    link: from([authLink, splitLink]),
    uri: process.env.REACT_APP_GRAPHQL_V2 || "https://3xj7i67lte.execute-api.us-east-1.amazonaws.com/dev",
    cache: new InMemoryCache({}),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
        errorPolicy: "ignore",
      },
      query: {
        fetchPolicy: "cache-first",
        errorPolicy: "all",
      },
    },
  });

  return client;
};

const apollo = {
  getInstance,
  closeSubscriptionClient,
  isWsClientConnected,
};

export default apollo;
