import React, { SyntheticEvent } from "react";
import clsx from "classnames";
import IconProfile from "../../../../assets/Arena/UnKnownAvatar.svg";
import { useStyles } from "./Avatar.style";

interface IPropsAvatar {
  children?: React.ReactNode;
  className?: string;
  onClick?: Function;
  style?: any;
  src?: string;
  alt?: string;
  variant?: "rounded" | "circle" | "square";
  classes?: {
    img?: string;
    root?: string;
  };
  id?: string;
}

export const Avatar = (props: IPropsAvatar) => {
  const classesLocal = useStyles();
  const { children, className, onClick, style, src, alt, variant, classes, id } = props;

  const onClickButton = (event: React.MouseEvent<HTMLElement>) => {
    if (!onClick) return;
    onClick(event);
  };

  const handleOnError = (event: SyntheticEvent<HTMLImageElement>) => {
    event.currentTarget.src = IconProfile;
  };

  return (
    <div
      {...props}
      className={clsx(
        classesLocal.root,
        variant === "rounded" && classesLocal.rounded,
        variant === "square" && classesLocal.square,
        className,
        classes?.root,
        "avatar-root"
      )}
      style={style}
      onClick={onClickButton}>
      {!children && (
        <img
          id={id}
          src={src ? src : IconProfile}
          alt={alt}
          className={clsx(classesLocal.img, classes?.img)}
          onError={handleOnError}
        />
      )}
      {children}
    </div>
  );
};
