import { createUseStyles } from "react-jss";
import { ITheme } from "../../../../types/theme";

// @ts-ignore
export const useStyles = createUseStyles((theme: ITheme) => ({
  logoGrid: {
    margin: "auto",
    paddingTop: window.innerHeight / 17,
    paddingBottom: window.innerHeight / 17,
    "@media(max-height: 667px)": {
      paddingTop: window.innerHeight / 19,
      paddingBottom: window.innerHeight / 19,
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      paddingTop: window.innerHeight / 18,
      paddingBottom: window.innerHeight / 18,
    },
  },
  passwordGrid: {
    padding: "1.5rem 0",
    "@media(max-height: 667px)": {
      padding: "1.2rem 0",
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      padding: "1.3rem 0",
    },
  },
  signUpGrid: {
    paddingTop: window.innerHeight / 17,
    "@media(max-height: 667px)": {
      paddingTop: window.innerHeight / 19,
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      paddingTop: window.innerHeight / 18,
    },
  },
  signInGrid: {
    marginTop: 20,
    textAlign: "center",
  },
  signInText: {
    fontSize: "15px",
    color: "#8a8a8e",
    display: "flex",
    flexDirection: "row",
    "@media(max-height: 667px)": {
      fontSize: "12px",
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      fontSize: "13px",
    },
  },
  title: {
    textAlign: "center",
  },
  rule: {
    color: `${theme.palette.secondary.main} !important`,
  },
  togglePass: {
    fontSize: 10,
    fontWeight: 500,
    color: "#9B9BA3",
    textTransform: "uppercase",
    "&.enable": {
      color: theme.palette.primary.main,
    },
  },
}));
