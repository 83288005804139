import React, { useEffect } from "react";
import { useStyles } from "./InAppNotificationCopied.style";
import { IInAppNotificationBody } from "../InAppNotification";

interface IInAppNotificationCopiedProps extends IInAppNotificationBody {
  value: string;
}

export function InAppNotificationCopied({ onCloseHandler, children, value }: IInAppNotificationCopiedProps) {
  const classes = useStyles();

  useEffect(() => {
    const onCloseTimeoutId = setTimeout(onCloseHandler, 3000);
    return () => {
      clearTimeout(onCloseTimeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root} data-testid="in-app-notification-copied-content">
      <span className={classes.highlight}>{children}</span>
      <span className={classes.ellipsis}>{value}</span>
    </div>
  );
}

export default InAppNotificationCopied;
