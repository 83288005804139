import { HTMLAttributes, FC } from "react";
import { useTranslation } from "react-i18next";
interface IGameTranslationProps {
  gameId?: string | null;
  description?: boolean;
  gameTitle?: boolean;
}

export const GameTranslation: FC<IGameTranslationProps & HTMLAttributes<HTMLDivElement>> = (props) => {
  const { gameId, description, gameTitle } = props;
  const { t } = useTranslation(["common", `game_${gameId}`]);

  if (description) return t("description", { ns: `game_${gameId}` });
  if (gameTitle) return t("title", { ns: `game_${gameId}` });
  else return null;
};
