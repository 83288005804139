import { createUseStyles } from "react-jss";
import { ITheme } from "../../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  root: {
    width: "100%",
    display: "block",
    boxSizing: "border-box",
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: 6,
    paddingRight: 6,
  },
}));
