import { Form, Formik } from "formik";
import React, { useEffect, useState, useContext } from "react";
import ArrowBackIosIcon from "../../../assets/ArrowBackIosIcon.svg";
import { Auth } from "@aws-amplify/auth";
import { OnmoLoading } from "../../../components/OnmoLoading";
import { onboardingStyles } from "../../SignIn/SignIn.style";
import useDigitInput from "react-digit-input";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AlertCtx } from "../../../context/alert/state";
import { useStyles } from "../../SignIn/partials/PhoneConfirmationCode/PhoneConfirmationCode.style";
import { Button, Grid, Container, Typography } from "../../../components/Common/ComponentExport";
import clsx from "classnames";

interface IPropsVerifyResetPasswordCode {
  accountEmail: string;
  setAccountCode: Function;
  codeValidationError: boolean;
  setAccountEmail: Function;
}

export default function VerifyResetPasswordCode(props: IPropsVerifyResetPasswordCode) {
  const { t } = useTranslation();
  const { accountEmail, setAccountCode, codeValidationError, setAccountEmail } = props;
  const onboardingClasses = onboardingStyles();
  const classes = useStyles();
  const history = useHistory();
  const { setNotificationError, setNotificationSuccess } = useContext(AlertCtx);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [value, onChange] = useState<string>("");
  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 6,
    value,
    onChange,
  });

  async function resendCode() {
    setNotificationSuccess(t("common_Code re-sent successfully."));
    await Auth.forgotPassword(accountEmail.toLowerCase());
  }

  const checkCodeValid = () => {
    if (codeValidationError) {
      setNotificationError(t("verifyResetPasswordCode_Please provide a valid verification code."));
    }
  };

  useEffect(checkCodeValid, [codeValidationError]);

  return (
    <div>
      <Container className={classes.phoneConfirmationContainer}>
        {formLoading ? <OnmoLoading /> : null}
        <Grid container className={classes.mainContent}>
          <Grid item>
            <Grid container className={classes.resetPwdDiv}>
              <Grid
                item
                xs={3}
                onClick={() => {
                  setAccountEmail("");
                }}>
                <Button startIcon={<img alt="" src={ArrowBackIosIcon} />} className={classes.backButton}>
                  {}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Typography className={onboardingClasses.fontWeight500} variant="h3">
                  {t("common_Reset Password")}
                </Typography>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
            <hr className={onboardingClasses.headerHr} />
            <Formik
              initialValues={{
                confirmationCode: "",
              }}
              onSubmit={async (values, { setSubmitting }) => {
                if (!formLoading) {
                  setSubmitting(true);
                  setFormLoading(true);
                  try {
                    setAccountCode(value);
                    history.push("/authenticate/reset-password");
                  } catch (error: any) {
                    setFormLoading(false);
                    setNotificationError(error.message);
                  }
                }
              }}>
              {(props: any) => {
                const { handleChange, handleSubmit, setFieldValue } = props;
                const submitEnabled = value.trim().length === 6;
                return (
                  <Form>
                    <Grid item>
                      <Grid container>
                        <Grid item xs={12} className={onboardingClasses.titleGrid}>
                          <Typography variant="h1" className={onboardingClasses.title}>
                            {t("verifyResetPasswordCode_Verify your Email Address")}
                          </Typography>
                          <Typography variant="h2" className={onboardingClasses.subtitle}>
                            {t("verifyResetPasswordCode_Enter the 6-digit code we sent to you by email.")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.digitGrid}>
                          <Grid container spacing={3} alignItems="center" justify="center">
                            <Grid item xs={2}>
                              <div className={classes.digitContainer}>
                                <input
                                  type="tel"
                                  inputMode="numeric"
                                  autoFocus
                                  {...digits[0]}
                                  className={classes.digitInput}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={2}>
                              <div className={classes.digitContainer}>
                                <input type="tel" inputMode="numeric" {...digits[1]} className={classes.digitInput} />
                              </div>
                            </Grid>
                            <Grid item xs={2}>
                              <div className={classes.digitContainer}>
                                <input type="tel" inputMode="numeric" {...digits[2]} className={classes.digitInput} />
                              </div>
                            </Grid>
                            <Grid item xs={2}>
                              <div className={classes.digitContainer}>
                                <input type="tel" inputMode="numeric" {...digits[3]} className={classes.digitInput} />
                              </div>
                            </Grid>
                            <Grid item xs={2}>
                              <div className={classes.digitContainer}>
                                <input type="tel" inputMode="numeric" {...digits[4]} className={classes.digitInput} />
                              </div>
                            </Grid>
                            <Grid item xs={2}>
                              <div className={classes.digitContainer}>
                                <input
                                  type="tel"
                                  inputMode="numeric"
                                  {...digits[5]}
                                  className={classes.digitInput}
                                  name="confirmationCode"
                                  onKeyPress={(e) => {
                                    handleChange(e);
                                    setFieldValue("confirmationCode", value);
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            isSubmit
                            data-testid="button-next"
                            fullWidth
                            variant="contained"
                            className={clsx(onboardingClasses.signin, !submitEnabled && onboardingClasses.btnDisabled)}
                            onClick={() => handleSubmit()}
                            disabled={!submitEnabled}>
                            {t("common_Next")}
                          </Button>
                          <Typography className={classes.linkTypography}>
                            <span className={onboardingClasses.muted}>
                              {t("verifyResetPasswordCode_Didn’t receive Email?")}
                            </span>{" "}
                            <span className={onboardingClasses.linkStyle} onClick={() => resendCode()}>
                              {t("common_Resend Code.")}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
