import React, { useState, useContext } from "react";
import { useStyles } from "./LikeGame.style";
import LikeIcon from "../../../assets/Game/not-like-yet.svg";
import LikedIcon from "../../../assets/Game/liked.svg";
import { Users } from "../../../modelsv2/users";
import { UserCtx } from "../../../context/user/state";
import LoveIcon from "../../../assets/img/love.svg";
import NewLikedIcon from "../../../assets/Game/new-liked-icon.svg";
import NewLikeIcon from "../../../assets/Game/new-like-icon.svg";
import { IBaseGame, LikedGameStatus } from "../../../types/baseInterfaces";
import { AlertCtx } from "../../../context/alert/state";
import { Typography, CircularProgress } from "../ComponentExport";

interface IPropsLikeGame {
  game: IBaseGame;
  fromGameDetail?: boolean;
  isTransparentLike?: boolean;
  isHideCount?: boolean;
}

export const LikeGame = (props: IPropsLikeGame) => {
  const { isTransparentLike, fromGameDetail, game, isHideCount } = props;
  const classes = useStyles();

  const { userData, likedGameStatus, updateLikedGameStatus } = useContext(UserCtx);
  const { setNotificationError } = useContext(AlertCtx);

  const isLiked: boolean = likedGameStatus?.filter((item: LikedGameStatus) => item.gameId === game?.id)[0]?.status;
  const likedCount: number = likedGameStatus?.filter((item: LikedGameStatus) => item.gameId === game?.id)[0]
    ?.likedCount;

  const [isUpdating, setIsUpdating] = useState(false);

  const onLikeGame = async (event: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    setIsUpdating(true);
    try {
      await Users.updateLike({ userId: userData?.id, gameId: game.id, isLike: !isLiked });
      updateLikedGameStatus(game.id, !isLiked, isLiked ? likedCount - 1 : likedCount + 1);
    } catch (e) {
      setNotificationError("Cannot update this game");
    }
    setIsUpdating(false);
  };

  const renderHeartIcon = () => {
    if (isLiked) {
      return isHideCount ? NewLikedIcon : LikedIcon;
    } else if (isTransparentLike) {
      return isHideCount ? NewLikeIcon : LikeIcon;
    } else {
      return LoveIcon;
    }
  };

  return (
    <div className={classes.wrapper}>
      {isUpdating ? (
        <CircularProgress size={14} />
      ) : (
        <img
          className={isHideCount ? "" : classes.likeIcon}
          src={renderHeartIcon()}
          alt="heart-icon"
          onClick={(e) => onLikeGame(e)}
          data-testid="click-like"
        />
      )}
      {!isHideCount && (
        <Typography data-testid="like-count" className={fromGameDetail ? classes.heartCount2 : classes.heartCount}>
          {likedCount}
        </Typography>
      )}
    </div>
  );
};

export default LikeGame;
