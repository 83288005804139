import React from "react";
import { useTranslation } from "react-i18next";
import { map } from "lodash-es";
import { Typography, Grid } from "../../Common/ComponentExport";
import { BeatItCard } from "../BeatItCard";
import { IBaseListBeatIt } from "../../../types/baseInterfaces";
import { useStyles } from "./ListBeatIt.style";

interface IPropsListBeatIt {
  beatIts?: IBaseListBeatIt[] | null;
}

export const ListBeatIt = (props: IPropsListBeatIt) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { beatIts } = props;

  if (!beatIts) {
    return null;
  }

  return (
    <div className={classes.contentBeatIt}>
      <div className={classes.title}>
        <Typography variant="h2">{t("common_Just Beat It")}</Typography>
        <Typography>{t("common_Beat the highest score and practice your skills")}</Typography>
      </div>
      <Grid container className={classes.mainBeatIt}>
        {map(beatIts, (beatIt, i) => (
          <Grid item xs={12} sm={12} md={6} lg={6} key={i}>
            <BeatItCard
              gameId={beatIt.tournamentInfo?.gameId}
              userAvatar={beatIt?.tournamentInfo?.avatar}
              username={beatIt?.tournamentInfo?.username}
              score={beatIt?.tournamentInfo?.topScore}
              gameTitle={beatIt?.tournamentInfo?.moment?.app?.title}
              momentType={beatIt?.tournamentInfo?.momentType}
              gameType={beatIt?.tournamentInfo?.moment?.app?.type}
              userId={beatIt?.tournamentInfo?.userId}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
