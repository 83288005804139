import React, { useEffect } from "react";
import { ReactComponent as IconGames } from "../../../assets/Chingari/Game.svg";
import { ReactComponent as Onmobile } from "../../../assets/nav_icons/Onmobile.svg";
import { ReactComponent as IconProfile } from "../../../assets/Chingari/Profile.svg";
import { useHistory } from "react-router-dom";
import { useStyles } from "./BottomNavigation.style";
import { THEME_NAME } from "../../../utils/theme";
import { ThemeName } from "../../../constants/theme";

export const BottomNavigation = ({ children }: any) => {
  const classes = useStyles();
  const history = useHistory();

  const navigateToTab = (pathname: string) => {
    history.push(pathname);
  };

  const scrollTop = () => {
    history.listen(() => {
      window.scrollTo(0, 0);
    });
  };

  const getHomeIcon = () => {
    switch (THEME_NAME) {
      case ThemeName.Dialog:
        return <Onmobile />;
      // return <DialogLogo className={!isHomeTabActive ? classes.deactiveHome : ""} />;
      case ThemeName.Zain:
        return <Onmobile />;
      default:
        return <Onmobile />;
    }
  };

  useEffect(scrollTop, []);

  return (
    <div>
      <div id="bottom-nav" className={classes.wrapper}>
        <div onClick={() => navigateToTab("/app/games")}>
          <IconGames />
        </div>
        <div>{getHomeIcon()}</div>
        <div onClick={() => navigateToTab("/app/me")}>
          <IconProfile />
        </div>
      </div>
      <div className={classes.childrenContent}>{children}</div>
    </div>
  );
};
