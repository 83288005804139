import React, { useContext } from "react";
import { Header } from "../Header";
import clsx from "classnames";
import { isNull } from "lodash-es";
import Logo from "../../../assets/GuestMode/OnmoWhite.svg";
import BannerIcon from "./assets/banner.jpg";
import { Grid } from "../../Common/ComponentExport";
import { IBaseGame, IBaseListBeatIt } from "../../../types/baseInterfaces";
import { CircularProgress } from "../../Common/ComponentExport";
import { ListTopGames } from "../ListTopGames";
import { ListBeatIt } from "../ListBeatIt";
import { Analytics } from "../../../modelsv2/analytics";
import { CATEGORY } from "../../../constants/analyticsEvent";
import { UserCtx } from "../../../context/user/state";
import { useStyles } from "./Home.style";

interface IPropsHome {
  topGames?: IBaseGame[] | null;
  listBeatIt?: IBaseListBeatIt[] | null;
}

export const Home = (props: IPropsHome) => {
  const classes = useStyles();
  const { topGames, listBeatIt } = props;
  const { userData } = useContext(UserCtx);

  const onBack = () => {
    window.close();
  };

  const onNavigateOnmo = () => {
    Analytics.trackEvent({
      category: CATEGORY.DISCOVER_PAGE,
      action: "header_banner",
      label: "click",
      user_uid: userData?.id,
    });
    window.open(process.env.REACT_APP_URL_ONMO || "https://play.onmo.com/authenticate/chingari", "_blank");
  };

  return (
    <Grid container>
      <Header className={clsx(classes.header, "stickyHeader")} actionLeft={onBack} iconCenter={Logo} />
      {isNull(topGames) || isNull(listBeatIt) ? (
        <div className={classes.mainLoading}>
          <CircularProgress size={40} />
        </div>
      ) : (
        <Grid item xs={12} md={11} lg={10} className={classes.content}>
          <img src={BannerIcon} alt="banner" className={classes.banner} onClick={onNavigateOnmo} />
          <div className={classes.main}>
            <ListTopGames topGames={topGames} />
            <ListBeatIt beatIts={listBeatIt} />
          </div>
        </Grid>
      )}
    </Grid>
  );
};
