import { createUseStyles } from "react-jss";
import { ITheme } from "../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  container: {
    textAlign: "center",
    marginTop: "40%",
  },
  title: {
    fontWeight: 500,
    fontSize: "17px",
    lineHeight: "20px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    marginBottom: "12px",
    marginTop: "27px",
  },
  description: {
    width: "335px",
    height: "40px",
    display: "inline-table",
    fontSize: "16px",
    lineHeight: "20px",
    color: theme.palette.text.primary,
  },
  button: {
    width: 335,
    height: 44,
    background: `${theme.button.primary.background} !important`,
    borderRadius: `50px !important`,
    color: `${theme.palette.primary.contrastText} !important`,
    margin: "auto",
    marginTop: 35,
    fontSize: 16,
    fontWeight: 500,
  },
}));
