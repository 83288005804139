const urlB64ToUint8Array = (base64String: any) => {
  try {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    // eslint-disable-next-line no-useless-escape
    const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  } catch (e) {
    return [];
  }
};

export const subscribeNotification = async () => {
  try {
    // Prompts notifications permission dialog if first time
    const permission = await window.Notification.requestPermission();

    // User has granted permission
    if (permission === "granted") {
      const registration: any = await navigator.serviceWorker.getRegistration();
      let sub = await registration?.pushManager.getSubscription();

      // User is not yet subscribed, so subscribe
      if (!sub) {
        const applicationServerKey = urlB64ToUint8Array(process.env.REACT_APP_PUSH_PUBLIC_KEY);
        sub = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: applicationServerKey,
        });
      }

      return JSON.stringify(sub);
    }

    // User has disabled permissions, nothing we can do anymore :(
    else {
      throw new Error("Please enable push notification before");
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const unsubscribeNotification = async () => {
  try {
    // Unregister browser from push notifications
    const registration: any = await navigator.serviceWorker.getRegistration();
    const sub = await registration.pushManager.getSubscription();
    if (sub) {
      await sub.unsubscribe();
    }
  } catch (error) {
    console.warn(error);
  }
  // Cannot set empty field in db, so using dummy value
  return "null";
};
