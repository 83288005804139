import React, { HTMLAttributes, FC } from "react";
import clsx from "classnames";
import { Button, Grid } from "../ComponentExport";
import { ReactComponent as PhoneIcon } from "../../../assets/GuestMode/mobile.svg";
import { ReactComponent as GoogleIcon } from "../../../assets/GuestMode/Google.svg";
import { ReactComponent as AppleIcon } from "../../../assets/GuestMode/Apple.svg";
import { ReactComponent as FacebookIcon } from "../../../assets/GuestMode/facebook_white.svg";
import { useStyles } from "./LoginMethod.style";

interface ILoginMethodProps {
  onLogin: Function;
}

export const LoginMethod: FC<ILoginMethodProps & HTMLAttributes<HTMLDivElement>> = (props) => {
  const classes = useStyles();
  const { onLogin, ...otherProps } = props;

  return (
    <Grid spacing={8} container {...otherProps} className={clsx(classes.root, props.className)}>
      {process.env.REACT_APP_LOGIN_PHONE === "true" && (
        <Grid item xs={6} sm={12} md={12}>
          <Button
            variant="contained"
            className={clsx(classes.button, classes.btnPhone)}
            startIcon={<PhoneIcon />}
            onClick={() => onLogin("phone")}>
            Sign Up with Phone Number
          </Button>
        </Grid>
      )}
      {process.env.REACT_APP_LOGIN_GG === "true" && (
        <Grid item xs={6} sm={12} md={12}>
          <Button
            variant="contained"
            className={clsx(classes.button, classes.btnGoogle)}
            startIcon={<GoogleIcon />}
            onClick={() => onLogin("Google")}>
            Connect with Google
          </Button>
        </Grid>
      )}
      {process.env.REACT_APP_LOGIN_APPLE === "true" && (
        <Grid item xs={6} sm={12} md={12}>
          <Button
            variant="contained"
            className={clsx(classes.button, classes.btnApple)}
            startIcon={<AppleIcon />}
            onClick={() => onLogin("Apple")}>
            Connect with Apple
          </Button>
        </Grid>
      )}
      {process.env.REACT_APP_LOGIN_FB === "true" && (
        <Grid item xs={6} sm={12} md={12}>
          <Button
            variant="contained"
            className={clsx(classes.button, classes.btnFacebook)}
            startIcon={<FacebookIcon />}
            onClick={() => onLogin("Facebook")}>
            Connect with Facebook
          </Button>
        </Grid>
      )}
      {process.env.REACT_APP_LOGIN_EMAIL === "true" && (
        <Grid item xs={6} sm={12} md={12}>
          <Button
            variant="contained"
            className={clsx(classes.button, classes.btnGoogle)}
            startIcon={<GoogleIcon />}
            onClick={() => onLogin("email")}>
            Email
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
