import { createUseStyles } from "react-jss";
import { ITheme } from "../../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  root: {},
  listStar: {
    display: "flex",
    flexDirection: "row",
  },
  star: {
    marginRight: 10,
    "& > svg > path": {
      fill: theme.palette.primary.main,
    },
  },
}));
