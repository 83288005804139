import React, { useState } from "react";
import NewPasswordForm from "./partials/NewPasswordForm";
import ResetPasswordForm from "./partials/ResetPasswordForm";
import VerifyResetPasswordCode from "./partials/VerifyResetPasswordCode";

export default function ResetPassword() {
  const [accountEmail, setAccountEmail] = useState<string>("");
  const [accountCode, setAccountCode] = useState<string>("");
  const [codeValidationError, setCodeValidationError] = useState<boolean>(false);

  if (accountEmail && accountCode) {
    return (
      <NewPasswordForm
        accountEmail={accountEmail}
        accountCode={accountCode}
        setAccountCode={setAccountCode}
        setCodeValidationError={setCodeValidationError}
      />
    );
  }

  if (accountEmail) {
    return (
      <VerifyResetPasswordCode
        accountEmail={accountEmail}
        setAccountEmail={setAccountEmail}
        setAccountCode={setAccountCode}
        codeValidationError={codeValidationError}
      />
    );
  }

  return <ResetPasswordForm setAccountEmail={setAccountEmail} />;
}
