import React, { useState, useRef, useEffect } from "react";
import { Typography, Button, CircularProgress } from "../../Common/ComponentExport";
import BackIcon from "../../../assets/Chingari/BackCircle.svg";
import clsx from "classnames";
import PauseIcon from "../../../assets/Game/Pause.svg";
import PlayIcon from "../../../assets/Game/Play.svg";
import BeatItIcon from "../../../assets/Game/beat-it-icon.svg";
import { getUrlGameVideo } from "../../../utils/gameVideo";
import { IBaseGameBattle } from "../../../types/baseInterfaces";
import { countDown } from "../../../utils/countDown";
import { MissionCard } from "../MissionCard";
import { IPropsMissionCard } from "../MissionCard/MissionCard";
import { useStyles } from "./PreloadBeatIt.style";
import { useTranslation } from "react-i18next";
import { GameTranslation } from "../../Common/GameMomentTranslation/GameTranslation";

interface IPropsPreloadBeatIt {
  onBack: Function;
  isLoaded: boolean;
  onPlay: Function;
  loadedPercentage: number;
  game: IBaseGameBattle | null;
  missionCard: IPropsMissionCard;
  expireTime?: string;
  isLoading: boolean;
}

export const PreloadBeatIt = (props: IPropsPreloadBeatIt) => {
  const classes = useStyles();
  const { onBack, isLoaded, onPlay, loadedPercentage, game, missionCard, expireTime, isLoading } = props;
  const { t } = useTranslation();

  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPause, setIsPause] = useState(true);
  const [endTime, setEndTime] = useState<string | null>(null);

  const onSwitchVideo = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (videoRef?.current?.paused) {
      videoRef?.current?.play();
      setIsPause(true);
    } else {
      videoRef?.current?.pause();
      setIsPause(false);
    }
  };

  useEffect(() => {
    let countdownInterval: NodeJS.Timeout;
    let endTime = null;
    if (expireTime) {
      countdownInterval = setInterval(() => {
        endTime = countDown(new Date(expireTime).getTime());
        setEndTime(endTime);
      }, 1000);
    }
    return () => {
      clearInterval(countdownInterval);
    };
  }, [expireTime]);

  if (!game) return null;

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        {isLoaded && (
          <img
            src={BackIcon}
            alt="back-icon"
            data-testid="back-icon"
            className={classes.iconBack}
            onClick={() => onBack()}
          />
        )}
      </div>
      <div className={classes.main}>
        <video ref={videoRef} className={classes.videoGame} muted loop autoPlay playsInline={true}>
          <source src={getUrlGameVideo(game?.id)} type="video/mp4" />
        </video>
        <div className={classes.content}>
          <img src={isPause ? PauseIcon : PlayIcon} alt="play-icon" onClick={onSwitchVideo} />
        </div>
      </div>
      <div className={classes.action}>
        <div className={classes.tournamentName}>
          <img src={BeatItIcon} alt="beat-it" />
          <Typography className={classes.tournamentNameText}>
            {t("common_Beat It")} /{" "}
            <span>
              <GameTranslation gameTitle gameId={game?.id} />
            </span>
          </Typography>
        </div>
        <Typography variant="h4" className={classes.endTime}>
          {t("common_End in")} <span>{endTime}</span>
        </Typography>
        <Typography className={classes.beatItDes}>
          {t("common_Beat the highest score and practice your skills")}
        </Typography>
        <div className={classes.contentMission}>
          <MissionCard {...missionCard} />
        </div>
        <Button
          fullWidth
          data-testid="btn-play-now"
          variant="contained"
          className={clsx(classes.btnPlay, isLoading && classes.btnDisable)}
          data-cy="btn-play-now"
          onClick={() => onPlay()}
          disabled={isLoading}>
          {!isLoaded && !isLoading && `Loading...${loadedPercentage}%`}
          {isLoaded && !isLoading && "Start Now"}
          {isLoading && <CircularProgress size={25} />}
        </Button>
      </div>
    </div>
  );
};
