/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

import { IUserDevice } from "../types/baseInterfaces";

export type EndGameSessionInput = {
  edgeNodeId: string,
  score: number,
  time: number,
  failureMessage?: string | null,
};

export type EndHtmlGameSessionInput = {
  gameSessionId: string,
  score: number,
  time: number,
  failureMessage?: string | null,
  inputEventCount: number,
  updateScoreCount: number,
};


export type UserDevice = {
  info?: string | null;
  screen_size?: string | null;
};

export type PlayMomentInput = {
  gameSessionId?: string | null;
  device: UserDevice;
};

export type CreateFriendRequestInput = {
  senderId: string,
  receiverId: string,
};

export type Friend = {
  __typename: "Friend",
  id?: string,
  senderId?: string,
  receiverId?: string,
  accepted?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string,
  sender?: User,
  receiver?: User,
};

export type User = {
  __typename: "User",
  id?: string,
  email?: string | null,
  phone?: string | null,
  facebookId?: string | null,
  googleId?: string | null,
  username?: string,
  avatar?: string | null,
  banner?: string | null,
  xp?: number,
  currency?: number,
  pushSubscription?: string | null,
  online?: boolean | null,
  createdAt?: string,
  updatedAt?: string,
};

export type CreateScoreV2Input = {
  gameId: string,
  momentId?: string | null,
  userId?: string | null,
  playSessionId: string,
  score: number,
  scoreAttributes?: Array<ScoreAttributeInput | null> | null,
  lastPlayedTime?: number | null,
};

export type ScoreAttributeInput = {
  type?: string | null,
  score?: string | null,
};

export type CreatedScore = {
  __typename: "CreatedScore",
  status?: string | null,
  bestScore?: number | null,
  currentScorePercentile?: number | null,
  error?: string | null,
};

export type CreateLeaderboardInput = {
  gameId: string,
  momentId?: string | null,
  scoreAttributes?: Array<string | null> | null,
  leaderboards: Array<LeaderboardInput | null>,
};

export type LeaderboardInput = {
  chronos?: LifeTimeLeaderboardInput | null,
  ordering?: string | null,
};

export type LifeTimeLeaderboardInput = {
  daily?: number | null,
  weekly?: number | null,
  monthly?: number | null,
};

export type CreateTransactionV2Input = {
  key: string,
};

export type PreparePurchaseCoinInput = {
  store_item_id: string,
  return_url: string,
}

export type CreateBattleInput = {
  gameId: string,
  momentId: string,
  startTime?: number | null,
  endTime?: number | null,
  noOfPlayers: number,
  chargeType?: ChargeType | null,
  entryFee?: number | null,
  expiryMins: number,
  reminderMinsBeforeExpiry: number,
  prizePool: number,
  prizeBreakup: Array<PrizeBreakupInput | null>,
};

export enum ChargeType {
  cash = "cash",
  coin = "coin",
  free = "free",
}


export type PrizeBreakupInput = {
  startRank: number,
  endRank: number,
  totalAmount: number,
  perUserAmount?: number | null,
};

export type Battle = {
  __typename: "Battle",
  battleId?: string | null,
  gameId?: string | null,
  momentId?: string | null,
  startTime?: number | null,
  endTime?: number | null,
  noOfPlayers?: number | null,
  chargeType?: ChargeType | null,
  entryFee?: number | null,
  createdTime?: number | null,
  expiryMins?: number | null,
  prizePool?: number | null,
  prizeBreakup?: Array<PrizeBreakup | null> | null,
  error?: string | null,
};

export type PrizeBreakup = {
  __typename: "PrizeBreakup",
  startRank?: number | null,
  endRank?: number | null,
  totalAmount?: number | null,
  perUserAmount?: number | null,
};

export type StartMatchInput = {
  gameId: string,
  opponents?: Array<string | null> | null,
  inviteCode?: string | null,
  device: IUserDevice
};

export type PlayMatch = {
  __typename: "PlayMatch",
  moment?: Moment,
  players?: Array<LightUser | null> | null,
  edgeNodeId?: string | null,
  matchId?: string | null,
};

export type Moment = {
  __typename: "Moment",
  id?: string,
  appId?: string,
  snapshotId?: string,
  title?: string,
  description?: string | null,
  type?: string | null,
  time?: number | null,
  playCost?: number | null,
  unlockXp?: number | null,
  unlockCost?: number | null,
  data?: string | null,
  timesPlayed?: number | null,
  showTimer?: boolean | null,
  momentType?: MomentType | null,
  rankOrder?: RankOrder | null,
  status?: Status | null,
  createdAt?: string,
  updatedAt?: string,
  app?: App,
};

export enum MomentType {
  TUTORIAL = "TUTORIAL",
  CASUAL = "CASUAL",
  CHALLENGE = "CHALLENGE",
  BATTLE = "BATTLE",
  ZEN = "ZEN",
}


export enum RankOrder {
  DESC = "DESC",
  ASC = "ASC",
}


export enum Status {
  DRAFT = "DRAFT",
  UPCOMING = "UPCOMING",
  LIVE = "LIVE",
  DISABLED = "DISABLED",
}


export type App = {
  __typename: "App",
  id?: string,
  title?: string,
  company?: string,
  description?: string,
  category?: string,
  data?: string | null,
  timesPlayed?: number | null,
  rotationMode?: RotationMode | null,
  status?: Status | null,
  type?: GameType | null,
  createdAt?: string,
  updatedAt?: string,
  moment?: ModelMomentConnection,
};

export enum RotationMode {
  PORTRAIT = "PORTRAIT",
  LANDSCAPE = "LANDSCAPE",
  ANY = "ANY",
}


export enum GameType {
  STREAM = "STREAM",
  HTML = "HTML",
}


export type ModelMomentConnection = {
  __typename: "ModelMomentConnection",
  items?: Array<Moment | null> | null,
  nextToken?: string | null,
};

export type LightUser = {
  __typename: "LightUser",
  id?: string,
  username?: string,
  avatar?: string | null,
  xp?: number,
  banner?: string | null,
  online?: boolean | null,
};

export type UpdateMatchInput = {
  userId?: string | null,
  gameId?: string | null,
  momentId?: string | null,
  matchId: string,
  playSessionId?: string | null,
  score?: string | null,
  playState?: string | null,
  paymentState?: PaymentState | null,
  paymentTrxId?: string | null,
  lastPlayedTime?: number | null,
};

export enum PaymentState {
  PENDING = "PENDING",
  PAID = "PAID",
  CHARGED = "CHARGED",
  REFUNDED = "REFUNDED",
  NA = "NA",
}


export type LightMatchInput = {
  matchId: string,
  gameId?: string | null,
};

export type JoinMatchInput = {
  inviteCode: string,
};

export type CancelMatchInput = {
  matchId: string,
};

export type PlayMatchInput = {
  matchId: string,
  device: IUserDevice
};

export type ListFriendsInput = {
  userId: string
}

export type StreamInput = {
  appId: string,
  deviceInfo: string,
  gameMomentId?: string | null,
  gameSnapshotId?: string | null,
  screenSize?: string | null,
  username?: string | null,
};

export type UpdateUserV2Input = {
  username?: string | null,
  avatar?: string | null,
  banner?: string | null,
  pushSubscription?: string | null,
  phoneNotVerified?: string,
  otpCode?: number,
  smsNotification?: boolean,
  billingName?: string | null;
  billingPhone?: string | null;
  billingEmail?: string | null;
  billingCurrency?: string | null;
};


export type LockCurrencyInput = {
  billingCurrency: string;
};

export type sendOtpCodeInput = {
  phoneNotVerified: string,
};

export type Default = {
  __typename: "Default",
  status?: string,
};

export type score = {
  __typename: "score",
  id?: number,
  score?: number,
  user?: string,
  moment?: string,
  app?: number,
  created_at?: number | null,
};

export type CreateScoreInput = {
  id: number,
  score: number,
  user: string,
  moment: string,
  app: number,
  created_at?: number | null,
};

export type UpdateScoreInput = {
  id: number,
  score?: number | null,
  user?: string | null,
  moment?: string | null,
  app?: number | null,
  created_at?: number | null,
};

export type transaction = {
  __typename: "transaction",
  id?: number,
  status?: number | null,
  amount?: number | null,
  type?: number | null,
  transactionKey?: string | null,
  data?: string | null,
  userId?: number | null,
  oldUserId?: string | null,
  createdAt?: number | null,
  updatedAt?: number | null,
};

export type CreateTransactionInput = {
  id: number,
  status?: number | null,
  amount?: number | null,
  type?: number | null,
  transactionKey?: string | null,
  data?: string | null,
  userId?: number | null,
  oldUserId?: string | null,
  createdAt?: number | null,
  updatedAt?: number | null,
};

export type UpdateTransactionInput = {
  id: number,
  status?: number | null,
  amount?: number | null,
  type?: number | null,
  transactionKey?: string | null,
  data?: string | null,
  userId?: number | null,
  oldUserId?: string | null,
  createdAt?: number | null,
  updatedAt?: number | null,
};

export type CreateGameSessionInput = {
  id?: string | null,
  userId: string,
  appId: string,
  momentId: string,
  edgeNodeId: string,
  matchId?: string | null,
  controller?: string | null,
  incognito?: boolean | null,
  isStreamReady?: boolean | null,
  sessionType?: GameSessionType | null,
  sessionResults?: string | null,
};

export enum GameSessionType {
  CASUAL = "CASUAL",
  CHALLENGE = "CHALLENGE",
  BATTLE = "BATTLE",
  TOURNAMENT = "TOURNAMENT",
}


export type ModelGameSessionConditionInput = {
  appId?: ModelIDInput | null,
  momentId?: ModelIDInput | null,
  edgeNodeId?: ModelStringInput | null,
  matchId?: ModelStringInput | null,
  controller?: ModelStringInput | null,
  incognito?: ModelBooleanInput | null,
  isStreamReady?: ModelBooleanInput | null,
  sessionType?: ModelGameSessionTypeInput | null,
  sessionResults?: ModelStringInput | null,
  and?: Array<ModelGameSessionConditionInput | null> | null,
  or?: Array<ModelGameSessionConditionInput | null> | null,
  not?: ModelGameSessionConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array<string | null> | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array<number | null> | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array<string | null> | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelGameSessionTypeInput = {
  eq?: GameSessionType | null,
  ne?: GameSessionType | null,
};

export type GameSession = {
  __typename: "GameSession",
  id?: string,
  userId?: string,
  appId?: string,
  momentId?: string,
  edgeNodeId?: string,
  matchId?: string | null,
  controller?: string | null,
  incognito?: boolean | null,
  isStreamReady?: boolean | null,
  sessionType?: GameSessionType | null,
  sessionResults?: string | null,
  createdAt?: string,
  updatedAt?: string,
};

export type DeleteGameSessionInput = {
  id?: string | null,
};

export type CreateSnapshotInput = {
  id?: string | null,
  appId: string,
  title?: string | null,
  playTime?: number | null,
};

export type ModelSnapshotConditionInput = {
  appId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  playTime?: ModelIntInput | null,
  and?: Array<ModelSnapshotConditionInput | null> | null,
  or?: Array<ModelSnapshotConditionInput | null> | null,
  not?: ModelSnapshotConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array<number | null> | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Snapshot = {
  __typename: "Snapshot",
  id?: string,
  appId?: string,
  title?: string | null,
  playTime?: number | null,
  createdAt?: string,
  updatedAt?: string,
  moments?: ModelMomentConnection,
};

export type UpdateSnapshotInput = {
  id: string,
  appId?: string | null,
  title?: string | null,
  playTime?: number | null,
};

export type DeleteSnapshotInput = {
  id?: string | null,
};

export type CreateMomentInput = {
  id?: string | null,
  appId: string,
  snapshotId: string,
  title: string,
  description?: string | null,
  type?: string | null,
  time?: number | null,
  playCost?: number | null,
  unlockXp?: number | null,
  unlockCost?: number | null,
  data?: string | null,
  timesPlayed?: number | null,
  showTimer?: boolean | null,
  momentType?: MomentType | null,
  rankOrder?: RankOrder | null,
  status?: Status | null,
};

export type ModelMomentConditionInput = {
  appId?: ModelIDInput | null,
  snapshotId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelStringInput | null,
  time?: ModelIntInput | null,
  playCost?: ModelIntInput | null,
  unlockXp?: ModelIntInput | null,
  unlockCost?: ModelIntInput | null,
  data?: ModelStringInput | null,
  timesPlayed?: ModelIntInput | null,
  showTimer?: ModelBooleanInput | null,
  momentType?: ModelMomentTypeInput | null,
  rankOrder?: ModelRankOrderInput | null,
  status?: ModelStatusInput | null,
  and?: Array<ModelMomentConditionInput | null> | null,
  or?: Array<ModelMomentConditionInput | null> | null,
  not?: ModelMomentConditionInput | null,
};

export type ModelMomentTypeInput = {
  eq?: MomentType | null,
  ne?: MomentType | null,
};

export type ModelRankOrderInput = {
  eq?: RankOrder | null,
  ne?: RankOrder | null,
};

export type ModelStatusInput = {
  eq?: Status | null,
  ne?: Status | null,
};

export type DeleteMomentInput = {
  id?: string | null,
};

export type CreateAppInput = {
  id?: string | null,
  title: string,
  company: string,
  description: string,
  category: string,
  data?: string | null,
  timesPlayed?: number | null,
  rotationMode?: RotationMode | null,
  status?: Status | null,
  type?: GameType | null,
};

export type ModelAppConditionInput = {
  title?: ModelStringInput | null,
  company?: ModelStringInput | null,
  description?: ModelStringInput | null,
  category?: ModelStringInput | null,
  data?: ModelStringInput | null,
  timesPlayed?: ModelIntInput | null,
  rotationMode?: ModelRotationModeInput | null,
  status?: ModelStatusInput | null,
  type?: ModelGameTypeInput | null,
  and?: Array<ModelAppConditionInput | null> | null,
  or?: Array<ModelAppConditionInput | null> | null,
  not?: ModelAppConditionInput | null,
};

export type ModelRotationModeInput = {
  eq?: RotationMode | null,
  ne?: RotationMode | null,
};

export type ModelGameTypeInput = {
  eq?: GameType | null,
  ne?: GameType | null,
};

export type DeleteAppInput = {
  id?: string | null,
};

export type DeleteFriendInput = {
  id?: string | null,
};

export type ModelFriendConditionInput = {
  accepted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array<ModelFriendConditionInput | null> | null,
  or?: Array<ModelFriendConditionInput | null> | null,
  not?: ModelFriendConditionInput | null,
};

export type CreateFeedbackInput = {
  coinsRating?: CoinsRatingInput | null,
  streamRating?: StreamRatingInput | null,
};

export type CoinsRatingInput = {
  ease: number,
  email: string,
  fast: number,
  features: number,
  feedback: string,
  rating: number,
  recommend: number,
};

export type StreamRatingInput = {
  edgeNodeId: string,
  feedback: string,
  gameId: string,
  momentId: string,
  network: number,
  score: number,
};

export type CreateStreamRatingInput = {
  id?: string | null,
  appId?: string | null,
  momentId?: string | null,
  userId?: string | null,
  username?: string | null,
  edgeNodeId?: string | null,
  env?: string | null,
  isHost?: boolean | null,
  rating?: number | null,
  ping?: number | null,
  feedback?: string | null,
};

export type ModelStreamRatingConditionInput = {
  appId?: ModelIDInput | null,
  momentId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  username?: ModelStringInput | null,
  edgeNodeId?: ModelStringInput | null,
  env?: ModelStringInput | null,
  isHost?: ModelBooleanInput | null,
  rating?: ModelIntInput | null,
  ping?: ModelIntInput | null,
  feedback?: ModelStringInput | null,
  and?: Array<ModelStreamRatingConditionInput | null> | null,
  or?: Array<ModelStreamRatingConditionInput | null> | null,
  not?: ModelStreamRatingConditionInput | null,
};

export type StreamRating = {
  __typename: "StreamRating",
  id?: string,
  appId?: string | null,
  momentId?: string | null,
  userId?: string | null,
  username?: string | null,
  edgeNodeId?: string | null,
  env?: string | null,
  isHost?: boolean | null,
  rating?: number | null,
  ping?: number | null,
  feedback?: string | null,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateStreamRatingInput = {
  id: string,
  appId?: string | null,
  momentId?: string | null,
  userId?: string | null,
  username?: string | null,
  edgeNodeId?: string | null,
  env?: string | null,
  isHost?: boolean | null,
  rating?: number | null,
  ping?: number | null,
  feedback?: string | null,
};

export type DeleteStreamRatingInput = {
  id?: string | null,
};

export type CreateStoreItemsInput = {
  id?: string | null,
  title: string,
  description: string,
  price: string,
  type: string,
  coins: number,
};

export type ModelStoreItemsConditionInput = {
  title?: ModelIDInput | null,
  description?: ModelStringInput | null,
  price?: ModelStringInput | null,
  type?: ModelStringInput | null,
  coins?: ModelIntInput | null,
  and?: Array<ModelStoreItemsConditionInput | null> | null,
  or?: Array<ModelStoreItemsConditionInput | null> | null,
  not?: ModelStoreItemsConditionInput | null,
};

export type StoreItems = {
  __typename: "StoreItems",
  id?: string,
  title?: string,
  description?: string,
  price?: string,
  type?: string,
  coins?: number,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateStoreItemsInput = {
  id: string,
  title?: string | null,
  description?: string | null,
  price?: string | null,
  type?: string | null,
  coins?: number | null,
};

export type DeleteStoreItemsInput = {
  id?: string | null,
};

export type UpdateGameSessionInput = {
  id: string,
  userId?: string | null,
  appId?: string | null,
  momentId?: string | null,
  edgeNodeId?: string | null,
  matchId?: string | null,
  controller?: string | null,
  incognito?: boolean | null,
  isStreamReady?: boolean | null,
  sessionType?: GameSessionType | null,
  sessionResults?: string | null,
};

export type CreateNotificationInput = {
  id?: string | null,
  userId: string,
  type: string,
  data: string,
  state?: NotificationState | null,
};

export enum NotificationState {
  NEW = "NEW",
  SEEN = "SEEN",
  READ = "READ",
}


export type ModelNotificationConditionInput = {
  type?: ModelStringInput | null,
  data?: ModelStringInput | null,
  state?: ModelNotificationStateInput | null,
  and?: Array<ModelNotificationConditionInput | null> | null,
  or?: Array<ModelNotificationConditionInput | null> | null,
  not?: ModelNotificationConditionInput | null,
};

export type ModelNotificationStateInput = {
  eq?: NotificationState | null,
  ne?: NotificationState | null,
};

export type Notification = {
  __typename: "Notification",
  id?: string,
  userId?: string,
  type?: string,
  data?: string,
  state?: NotificationState | null,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateNotificationInput = {
  id: string,
  userId?: string | null,
  type?: string | null,
  data?: string | null,
  state?: NotificationState | null,
};

export type DeleteNotificationInput = {
  id?: string | null,
};

export type UpdateMomentInput = {
  id: string,
  appId?: string | null,
  snapshotId?: string | null,
  title?: string | null,
  description?: string | null,
  type?: string | null,
  time?: number | null,
  playCost?: number | null,
  unlockXp?: number | null,
  unlockCost?: number | null,
  data?: string | null,
  timesPlayed?: number | null,
  showTimer?: boolean | null,
  momentType?: MomentType | null,
  rankOrder?: RankOrder | null,
  status?: Status | null,
};

export type UpdateAppInput = {
  id: string,
  title?: string | null,
  company?: string | null,
  description?: string | null,
  category?: string | null,
  data?: string | null,
  timesPlayed?: number | null,
  rotationMode?: RotationMode | null,
  status?: Status | null,
  type?: GameType | null,
};

export type CreateFriendInput = {
  id?: string | null,
  senderId: string,
  receiverId: string,
  accepted?: boolean | null,
  createdAt?: string | null,
};

export type UpdateFriendInput = {
  id: string,
  senderId?: string | null,
  receiverId?: string | null,
  accepted?: boolean | null,
  createdAt?: string | null,
};

export type CreateUserInput = {
  id?: string | null,
  email?: string | null,
  phone?: string | null,
  facebookId?: string | null,
  googleId?: string | null,
  username: string,
  avatar?: string | null,
  banner?: string | null,
  xp: number,
  currency: number,
  pushSubscription?: string | null,
  online?: boolean | null,
};

export type ModelUserConditionInput = {
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  facebookId?: ModelStringInput | null,
  googleId?: ModelStringInput | null,
  username?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  banner?: ModelStringInput | null,
  xp?: ModelIntInput | null,
  currency?: ModelIntInput | null,
  pushSubscription?: ModelStringInput | null,
  online?: ModelBooleanInput | null,
  and?: Array<ModelUserConditionInput | null> | null,
  or?: Array<ModelUserConditionInput | null> | null,
  not?: ModelUserConditionInput | null,
};

export type UpdateUserInput = {
  id: string,
  email?: string | null,
  phone?: string | null,
  facebookId?: string | null,
  googleId?: string | null,
  username?: string | null,
  avatar?: string | null,
  banner?: string | null,
  xp?: number | null,
  currency?: number | null,
  pushSubscription?: string | null,
  online?: boolean | null,
};

export type DeleteUserInput = {
  id?: string | null,
};

export type CreateUserAppStatsInput = {
  id?: string | null,
  userId: string,
  appId: string,
  totalTimePlayed: number,
  numberOfTimesPlayed: number,
  xp: number,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type ModelUserAppStatsConditionInput = {
  userId?: ModelIDInput | null,
  appId?: ModelIDInput | null,
  totalTimePlayed?: ModelIntInput | null,
  numberOfTimesPlayed?: ModelIntInput | null,
  xp?: ModelIntInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array<ModelUserAppStatsConditionInput | null> | null,
  or?: Array<ModelUserAppStatsConditionInput | null> | null,
  not?: ModelUserAppStatsConditionInput | null,
};

export type UserAppStats = {
  __typename: "UserAppStats",
  id?: string,
  userId?: string,
  appId?: string,
  totalTimePlayed?: number,
  numberOfTimesPlayed?: number,
  xp?: number,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type UpdateUserAppStatsInput = {
  id: string,
  userId?: string | null,
  appId?: string | null,
  totalTimePlayed?: number | null,
  numberOfTimesPlayed?: number | null,
  xp?: number | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type DeleteUserAppStatsInput = {
  id?: string | null,
};

export type CreateUserMomentStatsInput = {
  id?: string | null,
  userId: string,
  appId: string,
  momentId: string,
  totalTimePlayed: number,
  numberOfTimesPlayed: number,
  xp: number,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type ModelUserMomentStatsConditionInput = {
  userId?: ModelIDInput | null,
  appId?: ModelIDInput | null,
  momentId?: ModelIDInput | null,
  totalTimePlayed?: ModelIntInput | null,
  numberOfTimesPlayed?: ModelIntInput | null,
  xp?: ModelIntInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array<ModelUserMomentStatsConditionInput | null> | null,
  or?: Array<ModelUserMomentStatsConditionInput | null> | null,
  not?: ModelUserMomentStatsConditionInput | null,
};

export type UserMomentStats = {
  __typename: "UserMomentStats",
  id?: string,
  userId?: string,
  appId?: string,
  momentId?: string,
  totalTimePlayed?: number,
  numberOfTimesPlayed?: number,
  xp?: number,
  updatedAt?: string | null,
  createdAt?: string | null,
  moment?: Moment,
};

export type UpdateUserMomentStatsInput = {
  id: string,
  userId?: string | null,
  appId?: string | null,
  momentId?: string | null,
  totalTimePlayed?: number | null,
  numberOfTimesPlayed?: number | null,
  xp?: number | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type DeleteUserMomentStatsInput = {
  id?: string | null,
};

export type CreateUserActivityInput = {
  id?: string | null,
  userId: string,
  type: string,
  text: string,
  data?: string | null,
  createdAt?: string | null,
};

export type ModelUserActivityConditionInput = {
  userId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  text?: ModelStringInput | null,
  data?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array<ModelUserActivityConditionInput | null> | null,
  or?: Array<ModelUserActivityConditionInput | null> | null,
  not?: ModelUserActivityConditionInput | null,
};

export type UserActivity = {
  __typename: "UserActivity",
  id?: string,
  userId?: string,
  type?: string,
  text?: string,
  data?: string | null,
  createdAt?: string | null,
  updatedAt?: string,
};

export type UpdateUserActivityInput = {
  id: string,
  userId?: string | null,
  type?: string | null,
  text?: string | null,
  data?: string | null,
  createdAt?: string | null,
};

export type DeleteUserActivityInput = {
  id?: string | null,
};

export type UserProfileInput = {
  userId: string,
};

export type GetGameSessionInput = {
  gameSessionId: string,
}

export type UserProfile = {
  __typename: "UserProfile",
  user?: User,
  nbMomentsPlayed?: number | null,
  nbMatchesWon?: number | null,
  nbFriends?: number | null,
  winningPercent?: number | null,
  userAppStats?: Array<UserAppStats | null> | null,
  userMomentStats?: Array<UserMomentStats | null> | null,
  userActivity?: Array<UserActivity | null> | null,
};

export type GetUserActivitiesInput = {
  userId: string,
}

export type GameSessionV2 = {
  id: string,
  edgeNodeId: string,
  matchId: String,
  host: User,
  moment: Moment,
  sessionType: GameSessionType,
  sessionResults: string,
  createdAt: ModelStringInput
  updatedAt: ModelStringInput
};

export type GetScoreInput = {
  gameId: string,
  momentId: string,
  userId: string,
  chronoType: ChronoType,
  leaderboardDate?: string | null,
  chronoScroll?: number | null,
};

export enum ChronoType {
  daily = "daily",
  weekly = "weekly",
  overall = "overall",
}


export type LeaderboardUserRank = {
  __typename: "LeaderboardUserRank",
  userId?: string | null,
  score?: string | null,
  rank?: number | null,
  error?: string | null,
};

export type ScoreInput = {
  gameId?: string | null,
  momentId?: string | null,
  userId: string,
  pageNo?: number | null,
  pageSize?: number | null,
  sort?: ScoreSort | null,
};

export enum ScoreSort {
  SCORE = "SCORE",
  TIME = "TIME",
}


export type ScoreV2 = {
  __typename: "ScoreV2",
  gameId?: string | null,
  momentId?: string | null,
  playSessionId?: string | null,
  lastPlayedTime?: number | null,
  score?: string | null,
  scoreAttributes?: ScoreAttribute,
  error?: string | null,
};

export type ScoreAttribute = {
  __typename: "ScoreAttribute",
  coins?: string | null,
};

export type GetLeaderboardInput = {
  gameId: string,
  momentId: string,
  pageNo?: number | null,
  pageSize?: number | null,
  chronoType: ChronoType,
  leaderboardDate?: string | null,
  chronoScroll?: number | null,
};

export type Leaderboard = {
  __typename: "Leaderboard",
  leaderboardDate?: string | null,
  leaders?: Array<LeaderboardUserRank | null> | null,
  error?: string | null,
};

export type Challenge = {
  __typename: "Challenge",
  id?: number,
  score?: number,
  user?: User,
  moment?: Moment,
  created_at?: number | null,
};

export type GetBattlesInput = {
  battleId: string,
};

export type GetRandomBattlesInput = {
  gameId: string,
};

export type ListBattlesInput = {
  gameId: string,
  momentId?: string | null,
};

export type Match = {
  __typename: "Match",
  gameId?: string | null,
  momentId?: string | null,
  matchId?: string | null,
  hostUserId?: string | null,
  expiryTime?: number | null,
  joinedPlayers?: number | null,
  matchState?: string | null,
  noOfPlayers?: number | null,
  inviteCode?: string | null,
  battleInfo?: Battle,
  moment?: Moment,
  players?: Array<MatchPlayer | null> | null,
  winners?: Array<MatchWinner | null> | null,
  error?: string | null,
};

export type MatchPlayer = {
  __typename: "MatchPlayer",
  userId?: string | null,
  user?: User,
  playState?: PlayState | null,
  paymentState?: PaymentState | null,
  lastPlayedTime?: number | null,
  score?: number | null,
  self?: boolean | null,
};

export enum PlayState {
  OPEN = "OPEN",
  PLAYING = "PLAYING",
  PLAYED = "PLAYED",
}


export type MatchWinner = {
  __typename: "MatchWinner",
  amount?: string | null,
  rank?: number | null,
  userId?: string | null,
};

export type ListMatchesInput = {
  gameId?: string | null,
  pageNo?: number | null,
  pageSize?: number | null,
  playState?: PlayState | null,
  matchState?: String | null,
};

export enum MatchState {
  CREATED = "CREATED",
  PLAYING = "PLAYING",
  PLAYED = "PLAYED",
  EXPIRED = "EXPIRED",
}


export type serializeTransaction = {
  __typename: "serializeTransaction",
  type?: string,
  amount?: string,
  action?: string,
  text?: string,
  image?: string | null,
  createdAt?: number,
};

export type ModelNotificationFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  data?: ModelStringInput | null,
  state?: ModelNotificationStateInput | null,
  and?: Array<ModelNotificationFilterInput | null> | null,
  or?: Array<ModelNotificationFilterInput | null> | null,
  not?: ModelNotificationFilterInput | null,
};

export type ModelNotificationConnection = {
  __typename: "ModelNotificationConnection",
  items?: Array<Notification | null> | null,
  nextToken?: string | null,
};

export type ModelFriendFilterInput = {
  id?: ModelIDInput | null,
  senderId?: ModelIDInput | null,
  receiverId?: ModelIDInput | null,
  accepted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array<ModelFriendFilterInput | null> | null,
  or?: Array<ModelFriendFilterInput | null> | null,
  not?: ModelFriendFilterInput | null,
};

export type ModelFriendConnection = {
  __typename: "ModelFriendConnection",
  items?: Array<Friend | null> | null,
  nextToken?: string | null,
};

export type ModelStoreItemsFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelIDInput | null,
  description?: ModelStringInput | null,
  price?: ModelStringInput | null,
  type?: ModelStringInput | null,
  coins?: ModelIntInput | null,
  and?: Array<ModelStoreItemsFilterInput | null> | null,
  or?: Array<ModelStoreItemsFilterInput | null> | null,
  not?: ModelStoreItemsFilterInput | null,
};

export type ModelStoreItemsConnection = {
  __typename: "ModelStoreItemsConnection",
  items?: Array<StoreItems | null> | null,
  nextToken?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array<string | null> | null,
  beginsWith?: string | null,
};

export type ModelGameSessionFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelStringInput | null,
  appId?: ModelIDInput | null,
  momentId?: ModelIDInput | null,
  edgeNodeId?: ModelStringInput | null,
  matchId?: ModelStringInput | null,
  controller?: ModelStringInput | null,
  incognito?: ModelBooleanInput | null,
  isStreamReady?: ModelBooleanInput | null,
  sessionType?: ModelGameSessionTypeInput | null,
  sessionResults?: ModelStringInput | null,
  and?: Array<ModelGameSessionFilterInput | null> | null,
  or?: Array<ModelGameSessionFilterInput | null> | null,
  not?: ModelGameSessionFilterInput | null,
};

export type ModelGameSessionConnection = {
  __typename: "ModelGameSessionConnection",
  items?: Array<GameSession | null> | null,
  nextToken?: string | null,
};

export type ModelSnapshotFilterInput = {
  id?: ModelIDInput | null,
  appId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  playTime?: ModelIntInput | null,
  and?: Array<ModelSnapshotFilterInput | null> | null,
  or?: Array<ModelSnapshotFilterInput | null> | null,
  not?: ModelSnapshotFilterInput | null,
};

export type ModelSnapshotConnection = {
  __typename: "ModelSnapshotConnection",
  items?: Array<Snapshot | null> | null,
  nextToken?: string | null,
};

export type ModelMomentFilterInput = {
  id?: ModelIDInput | null,
  appId?: ModelIDInput | null,
  snapshotId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelStringInput | null,
  time?: ModelIntInput | null,
  playCost?: ModelIntInput | null,
  unlockXp?: ModelIntInput | null,
  unlockCost?: ModelIntInput | null,
  data?: ModelStringInput | null,
  timesPlayed?: ModelIntInput | null,
  showTimer?: ModelBooleanInput | null,
  momentType?: ModelMomentTypeInput | null,
  rankOrder?: ModelRankOrderInput | null,
  status?: ModelStatusInput | null,
  and?: Array<ModelMomentFilterInput | null> | null,
  or?: Array<ModelMomentFilterInput | null> | null,
  not?: ModelMomentFilterInput | null,
};

export type ModelAppFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  company?: ModelStringInput | null,
  description?: ModelStringInput | null,
  category?: ModelStringInput | null,
  data?: ModelStringInput | null,
  timesPlayed?: ModelIntInput | null,
  rotationMode?: ModelRotationModeInput | null,
  status?: ModelStatusInput | null,
  type?: ModelGameTypeInput | null,
  and?: Array<ModelAppFilterInput | null> | null,
  or?: Array<ModelAppFilterInput | null> | null,
  not?: ModelAppFilterInput | null,
};

export type ModelAppConnection = {
  __typename: "ModelAppConnection",
  items?: Array<App | null> | null,
  nextToken?: string | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  facebookId?: ModelStringInput | null,
  googleId?: ModelStringInput | null,
  username?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  banner?: ModelStringInput | null,
  xp?: ModelIntInput | null,
  currency?: ModelIntInput | null,
  pushSubscription?: ModelStringInput | null,
  online?: ModelBooleanInput | null,
  and?: Array<ModelUserFilterInput | null> | null,
  or?: Array<ModelUserFilterInput | null> | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items?: Array<User | null> | null,
  nextToken?: string | null,
};

export type SearchableUserFilterInput = {
  id?: SearchableIDFilterInput | null,
  email?: SearchableStringFilterInput | null,
  phone?: SearchableStringFilterInput | null,
  facebookId?: SearchableStringFilterInput | null,
  googleId?: SearchableStringFilterInput | null,
  username?: SearchableStringFilterInput | null,
  avatar?: SearchableStringFilterInput | null,
  banner?: SearchableStringFilterInput | null,
  xp?: SearchableIntFilterInput | null,
  currency?: SearchableIntFilterInput | null,
  pushSubscription?: SearchableStringFilterInput | null,
  online?: SearchableBooleanFilterInput | null,
  and?: Array<SearchableUserFilterInput | null> | null,
  or?: Array<SearchableUserFilterInput | null> | null,
  not?: SearchableUserFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array<string | null> | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array<string | null> | null,
};

export type SearchableIntFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array<number | null> | null,
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type SearchableUserSortInput = {
  field?: SearchableUserSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableUserSortableFields {
  id = "id",
  email = "email",
  phone = "phone",
  facebookId = "facebookId",
  googleId = "googleId",
  username = "username",
  avatar = "avatar",
  banner = "banner",
  xp = "xp",
  currency = "currency",
  pushSubscription = "pushSubscription",
  online = "online",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchableUserConnection = {
  __typename: "SearchableUserConnection",
  items?: Array<User | null> | null,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelUserAppStatsFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  appId?: ModelIDInput | null,
  totalTimePlayed?: ModelIntInput | null,
  numberOfTimesPlayed?: ModelIntInput | null,
  xp?: ModelIntInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array<ModelUserAppStatsFilterInput | null> | null,
  or?: Array<ModelUserAppStatsFilterInput | null> | null,
  not?: ModelUserAppStatsFilterInput | null,
};

export type ModelUserAppStatsConnection = {
  __typename: "ModelUserAppStatsConnection",
  items?: Array<UserAppStats | null> | null,
  nextToken?: string | null,
};

export type ModelUserMomentStatsFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  appId?: ModelIDInput | null,
  momentId?: ModelIDInput | null,
  totalTimePlayed?: ModelIntInput | null,
  numberOfTimesPlayed?: ModelIntInput | null,
  xp?: ModelIntInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array<ModelUserMomentStatsFilterInput | null> | null,
  or?: Array<ModelUserMomentStatsFilterInput | null> | null,
  not?: ModelUserMomentStatsFilterInput | null,
};

export type ModelUserMomentStatsConnection = {
  __typename: "ModelUserMomentStatsConnection",
  items?: Array<UserMomentStats | null> | null,
  nextToken?: string | null,
};

export type ModelUserActivityFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  text?: ModelStringInput | null,
  data?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array<ModelUserActivityFilterInput | null> | null,
  or?: Array<ModelUserActivityFilterInput | null> | null,
  not?: ModelUserActivityFilterInput | null,
};

export type ModelUserActivityConnection = {
  __typename: "ModelUserActivityConnection",
  items?: Array<UserActivity | null> | null,
  nextToken?: string | null,
};

export type ModelStreamRatingFilterInput = {
  id?: ModelIDInput | null,
  appId?: ModelIDInput | null,
  momentId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  username?: ModelStringInput | null,
  edgeNodeId?: ModelStringInput | null,
  env?: ModelStringInput | null,
  isHost?: ModelBooleanInput | null,
  rating?: ModelIntInput | null,
  ping?: ModelIntInput | null,
  feedback?: ModelStringInput | null,
  and?: Array<ModelStreamRatingFilterInput | null> | null,
  or?: Array<ModelStreamRatingFilterInput | null> | null,
  not?: ModelStreamRatingFilterInput | null,
};

export type ModelStreamRatingConnection = {
  __typename: "ModelStreamRatingConnection",
  items?: Array<StreamRating | null> | null,
  nextToken?: string | null,
};

export type EndGameSessionMutationVariables = {
  endGameSessionInput?: EndGameSessionInput,
};

export type EndGameSessionMutation = {
  endGameSession?: string | null,
};

export type EndHtmlGameSessionMutationVariables = {
  endHtmlGameSessionInput?: EndHtmlGameSessionInput,
};

export type EndHtmlGameSessionMutation = {
  endHtmlGameSession?: string | null,
};

export type CreateFriendRequestMutationVariables = {
  createFriendRequestInput?: CreateFriendRequestInput,
};

export type CreateFriendRequestMutation = {
  createFriendRequest?: {
    __typename: "Friend",
    id: string,
    senderId: string,
    receiverId: string,
    accepted?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    sender: {
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    receiver: {
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type AcceptFriendRequestMutationVariables = {
  id?: string,
};

export type AcceptFriendRequestMutation = {
  acceptFriendRequest?: {
    __typename: "Friend",
    id: string,
    senderId: string,
    receiverId: string,
    accepted?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    sender: {
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    receiver: {
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type CreateScoreV2MutationVariables = {
  createScoreInput?: CreateScoreV2Input,
};

export type CreateScoreV2Mutation = {
  createScoreV2?: {
    __typename: "CreatedScore",
    status?: string | null,
    bestScore?: number | null,
    currentScorePercentile?: number | null,
    error?: string | null,
  } | null,
};

export type CreateLeaderboardMutationVariables = {
  createLeaderboardInput?: CreateLeaderboardInput,
};

export type CreateLeaderboardMutation = {
  createLeaderboard?: string | null,
};

export type PlayMomentMutationVariables = {
  momentId?: string,
};

export type PlayMomentMutation = {
  playMoment?: string | null,
};

export type CreateBattleMutationVariables = {
  createBattleInput?: CreateBattleInput,
};

export type CreateBattleMutation = {
  createBattle?: {
    __typename: "Battle",
    battleId?: string | null,
    gameId?: string | null,
    momentId?: string | null,
    startTime?: number | null,
    endTime?: number | null,
    noOfPlayers?: number | null,
    chargeType?: ChargeType | null,
    entryFee?: number | null,
    createdTime?: number | null,
    expiryMins?: number | null,
    prizePool?: number | null,
    prizeBreakup?: Array<{
      __typename: "PrizeBreakup",
      startRank?: number | null,
      endRank?: number | null,
      totalAmount?: number | null,
      perUserAmount?: number | null,
    } | null> | null,
    error?: string | null,
  } | null,
};

export type CreateMatchInvitationMutation = {
  createMatchInvitation?: string | null,
};

export type StartMatchMutationVariables = {
  startMatchInput?: StartMatchInput,
};

export type StartMatchMutation = {
  startMatch?: {
    __typename: "PlayMatch",
    moment?: {
      __typename: "Moment",
      id: string,
      appId: string,
      snapshotId: string,
      title: string,
      description?: string | null,
      type?: string | null,
      time?: number | null,
      playCost?: number | null,
      unlockXp?: number | null,
      unlockCost?: number | null,
      data?: string | null,
      timesPlayed?: number | null,
      showTimer?: boolean | null,
      momentType?: MomentType | null,
      rankOrder?: RankOrder | null,
      status?: Status | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    players?: Array<{
      __typename: "LightUser",
      id: string,
      username: string,
      avatar?: string | null,
      xp: number,
      banner?: string | null,
      online?: boolean | null,
    } | null> | null,
    edgeNodeId?: string | null,
    matchId?: string | null,
  } | null,
};

export type UpdateMatchMutationVariables = {
  updateMatchInput?: UpdateMatchInput,
};

export type UpdateMatchMutation = {
  updateMatch?: string | null,
};

export type ValidateMatchMutationVariables = {
  validateMatchInput?: LightMatchInput,
};

export type ValidateMatchMutation = {
  validateMatch?: string | null,
};

export type JoinMatchMutationVariables = {
  joinMatchInput?: JoinMatchInput,
};

export type JoinMatchMutation = {
  joinMatch?: string | null,
};

export type PlayMatchV2MutationVariables = {
  playMatchInput?: PlayMatchInput,
};

export type PlayMatchV2Mutation = {
  playMatchV2?: {
    __typename: "PlayMatch",
    moment?: {
      __typename: "Moment",
      id: string,
      appId: string,
      snapshotId: string,
      title: string,
      description?: string | null,
      type?: string | null,
      time?: number | null,
      playCost?: number | null,
      unlockXp?: number | null,
      unlockCost?: number | null,
      data?: string | null,
      timesPlayed?: number | null,
      showTimer?: boolean | null,
      momentType?: MomentType | null,
      rankOrder?: RankOrder | null,
      status?: Status | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    players?: Array<{
      __typename: "LightUser",
      id: string,
      username: string,
      avatar?: string | null,
      xp: number,
      banner?: string | null,
      online?: boolean | null,
    } | null> | null,
    edgeNodeId?: string | null,
    matchId?: string | null,
  } | null,
};

export type CreateStreamMutationVariables = {
  streamInput?: StreamInput,
};

export type CreateStreamMutation = {
  createStream?: string | null,
};

export type UpdateUserV2MutationVariables = {
  updateUserV2Input?: UpdateUserV2Input,
};

export type LockCurrencyMutationVariables = {
  lockCurrency?: LockCurrencyInput,
};

export type UpdateUserV2Mutation = {
  updateUserV2?: {
    __typename: "Default",
    status: string,
  } | null,
};

export type DeleteUserV2Mutation = {
  deleteUserV2?: {
    __typename: "Default",
    status: string,
  } | null,
};

export type ClearAllNotificationMutation = {
  clearAllNotification?: string | null,
};

export type DeleteScoreMutationVariables = {
  id?: number,
};

export type DeleteScoreMutation = {
  deleteScore?: {
    __typename: "score",
    id: number,
    score: number,
    user: string,
    moment: string,
    app: number,
    created_at?: number | null,
  } | null,
};

export type CreateScoreMutationVariables = {
  createScoreInput?: CreateScoreInput,
};

export type CreateScoreMutation = {
  createScore?: {
    __typename: "score",
    id: number,
    score: number,
    user: string,
    moment: string,
    app: number,
    created_at?: number | null,
  } | null,
};

export type UpdateScoreMutationVariables = {
  updateScoreInput?: UpdateScoreInput,
};

export type UpdateScoreMutation = {
  updateScore?: {
    __typename: "score",
    id: number,
    score: number,
    user: string,
    moment: string,
    app: number,
    created_at?: number | null,
  } | null,
};

export type DeleteTransactionMutationVariables = {
  id?: number,
};

export type DeleteTransactionMutation = {
  deleteTransaction?: {
    __typename: "transaction",
    id: number,
    status?: number | null,
    amount?: number | null,
    type?: number | null,
    transactionKey?: string | null,
    data?: string | null,
    userId?: number | null,
    oldUserId?: string | null,
    createdAt?: number | null,
    updatedAt?: number | null,
  } | null,
};

export type CreateTransactionMutationVariables = {
  createTransactionInput?: CreateTransactionInput,
};

export type CreateTransactionMutation = {
  createTransaction?: {
    __typename: "transaction",
    id: number,
    status?: number | null,
    amount?: number | null,
    type?: number | null,
    transactionKey?: string | null,
    data?: string | null,
    userId?: number | null,
    oldUserId?: string | null,
    createdAt?: number | null,
    updatedAt?: number | null,
  } | null,
};

export type UpdateTransactionMutationVariables = {
  updateTransactionInput?: UpdateTransactionInput,
};

export type UpdateTransactionMutation = {
  updateTransaction?: {
    __typename: "transaction",
    id: number,
    status?: number | null,
    amount?: number | null,
    type?: number | null,
    transactionKey?: string | null,
    data?: string | null,
    userId?: number | null,
    oldUserId?: string | null,
    createdAt?: number | null,
    updatedAt?: number | null,
  } | null,
};

export type CreateGameSessionMutationVariables = {
  input?: CreateGameSessionInput,
  condition?: ModelGameSessionConditionInput | null,
};

export type CreateGameSessionMutation = {
  createGameSession?: {
    __typename: "GameSession",
    id: string,
    userId: string,
    appId: string,
    momentId: string,
    edgeNodeId: string,
    matchId?: string | null,
    controller?: string | null,
    incognito?: boolean | null,
    isStreamReady?: boolean | null,
    sessionType?: GameSessionType | null,
    sessionResults?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteGameSessionMutationVariables = {
  input?: DeleteGameSessionInput,
  condition?: ModelGameSessionConditionInput | null,
};

export type DeleteGameSessionMutation = {
  deleteGameSession?: {
    __typename: "GameSession",
    id: string,
    userId: string,
    appId: string,
    momentId: string,
    edgeNodeId: string,
    matchId?: string | null,
    controller?: string | null,
    incognito?: boolean | null,
    isStreamReady?: boolean | null,
    sessionType?: GameSessionType | null,
    sessionResults?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSnapshotMutationVariables = {
  input?: CreateSnapshotInput,
  condition?: ModelSnapshotConditionInput | null,
};

export type CreateSnapshotMutation = {
  createSnapshot?: {
    __typename: "Snapshot",
    id: string,
    appId: string,
    title?: string | null,
    playTime?: number | null,
    createdAt: string,
    updatedAt: string,
    moments?: {
      __typename: "ModelMomentConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateSnapshotMutationVariables = {
  input?: UpdateSnapshotInput,
  condition?: ModelSnapshotConditionInput | null,
};

export type UpdateSnapshotMutation = {
  updateSnapshot?: {
    __typename: "Snapshot",
    id: string,
    appId: string,
    title?: string | null,
    playTime?: number | null,
    createdAt: string,
    updatedAt: string,
    moments?: {
      __typename: "ModelMomentConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteSnapshotMutationVariables = {
  input?: DeleteSnapshotInput,
  condition?: ModelSnapshotConditionInput | null,
};

export type DeleteSnapshotMutation = {
  deleteSnapshot?: {
    __typename: "Snapshot",
    id: string,
    appId: string,
    title?: string | null,
    playTime?: number | null,
    createdAt: string,
    updatedAt: string,
    moments?: {
      __typename: "ModelMomentConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateMomentMutationVariables = {
  input?: CreateMomentInput,
  condition?: ModelMomentConditionInput | null,
};

export type CreateMomentMutation = {
  createMoment?: {
    __typename: "Moment",
    id: string,
    appId: string,
    snapshotId: string,
    title: string,
    description?: string | null,
    type?: string | null,
    time?: number | null,
    playCost?: number | null,
    unlockXp?: number | null,
    unlockCost?: number | null,
    data?: string | null,
    timesPlayed?: number | null,
    showTimer?: boolean | null,
    momentType?: MomentType | null,
    rankOrder?: RankOrder | null,
    status?: Status | null,
    createdAt: string,
    updatedAt: string,
    app?: {
      __typename: "App",
      id: string,
      title: string,
      company: string,
      description: string,
      category: string,
      data?: string | null,
      timesPlayed?: number | null,
      rotationMode?: RotationMode | null,
      status?: Status | null,
      type?: GameType | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeleteMomentMutationVariables = {
  input?: DeleteMomentInput,
  condition?: ModelMomentConditionInput | null,
};

export type DeleteMomentMutation = {
  deleteMoment?: {
    __typename: "Moment",
    id: string,
    appId: string,
    snapshotId: string,
    title: string,
    description?: string | null,
    type?: string | null,
    time?: number | null,
    playCost?: number | null,
    unlockXp?: number | null,
    unlockCost?: number | null,
    data?: string | null,
    timesPlayed?: number | null,
    showTimer?: boolean | null,
    momentType?: MomentType | null,
    rankOrder?: RankOrder | null,
    status?: Status | null,
    createdAt: string,
    updatedAt: string,
    app?: {
      __typename: "App",
      id: string,
      title: string,
      company: string,
      description: string,
      category: string,
      data?: string | null,
      timesPlayed?: number | null,
      rotationMode?: RotationMode | null,
      status?: Status | null,
      type?: GameType | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateAppMutationVariables = {
  input?: CreateAppInput,
  condition?: ModelAppConditionInput | null,
};

export type CreateAppMutation = {
  createApp?: {
    __typename: "App",
    id: string,
    title: string,
    company: string,
    description: string,
    category: string,
    data?: string | null,
    timesPlayed?: number | null,
    rotationMode?: RotationMode | null,
    status?: Status | null,
    type?: GameType | null,
    createdAt: string,
    updatedAt: string,
    moment?: {
      __typename: "ModelMomentConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteAppMutationVariables = {
  input?: DeleteAppInput,
  condition?: ModelAppConditionInput | null,
};

export type DeleteAppMutation = {
  deleteApp?: {
    __typename: "App",
    id: string,
    title: string,
    company: string,
    description: string,
    category: string,
    data?: string | null,
    timesPlayed?: number | null,
    rotationMode?: RotationMode | null,
    status?: Status | null,
    type?: GameType | null,
    createdAt: string,
    updatedAt: string,
    moment?: {
      __typename: "ModelMomentConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteFriendMutationVariables = {
  input?: DeleteFriendInput,
  condition?: ModelFriendConditionInput | null,
};

export type DeleteFriendMutation = {
  deleteFriend?: {
    __typename: "Friend",
    id: string,
    senderId: string,
    receiverId: string,
    accepted?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    sender: {
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    receiver: {
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type CreateStreamRatingMutationVariables = {
  input?: CreateStreamRatingInput,
  condition?: ModelStreamRatingConditionInput | null,
};

export type CreateStreamRatingMutation = {
  createStreamRating?: {
    __typename: "StreamRating",
    id: string,
    appId?: string | null,
    momentId?: string | null,
    userId?: string | null,
    username?: string | null,
    edgeNodeId?: string | null,
    env?: string | null,
    isHost?: boolean | null,
    rating?: number | null,
    ping?: number | null,
    feedback?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateStreamRatingMutationVariables = {
  input?: UpdateStreamRatingInput,
  condition?: ModelStreamRatingConditionInput | null,
};

export type UpdateStreamRatingMutation = {
  updateStreamRating?: {
    __typename: "StreamRating",
    id: string,
    appId?: string | null,
    momentId?: string | null,
    userId?: string | null,
    username?: string | null,
    edgeNodeId?: string | null,
    env?: string | null,
    isHost?: boolean | null,
    rating?: number | null,
    ping?: number | null,
    feedback?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteStreamRatingMutationVariables = {
  input?: DeleteStreamRatingInput,
  condition?: ModelStreamRatingConditionInput | null,
};

export type DeleteStreamRatingMutation = {
  deleteStreamRating?: {
    __typename: "StreamRating",
    id: string,
    appId?: string | null,
    momentId?: string | null,
    userId?: string | null,
    username?: string | null,
    edgeNodeId?: string | null,
    env?: string | null,
    isHost?: boolean | null,
    rating?: number | null,
    ping?: number | null,
    feedback?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateStoreItemsMutationVariables = {
  input?: CreateStoreItemsInput,
  condition?: ModelStoreItemsConditionInput | null,
};

export type CreateStoreItemsMutation = {
  createStoreItems?: {
    __typename: "StoreItems",
    id: string,
    title: string,
    description: string,
    price: string,
    type: string,
    coins: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateStoreItemsMutationVariables = {
  input?: UpdateStoreItemsInput,
  condition?: ModelStoreItemsConditionInput | null,
};

export type UpdateStoreItemsMutation = {
  updateStoreItems?: {
    __typename: "StoreItems",
    id: string,
    title: string,
    description: string,
    price: string,
    type: string,
    coins: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteStoreItemsMutationVariables = {
  input?: DeleteStoreItemsInput,
  condition?: ModelStoreItemsConditionInput | null,
};

export type DeleteStoreItemsMutation = {
  deleteStoreItems?: {
    __typename: "StoreItems",
    id: string,
    title: string,
    description: string,
    price: string,
    type: string,
    coins: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateGameSessionMutationVariables = {
  input?: UpdateGameSessionInput,
  condition?: ModelGameSessionConditionInput | null,
};

export type UpdateGameSessionMutation = {
  updateGameSession?: {
    __typename: "GameSession",
    id: string,
    userId: string,
    appId: string,
    momentId: string,
    edgeNodeId: string,
    matchId?: string | null,
    controller?: string | null,
    incognito?: boolean | null,
    isStreamReady?: boolean | null,
    sessionType?: GameSessionType | null,
    sessionResults?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNotificationMutationVariables = {
  input?: CreateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type CreateNotificationMutation = {
  createNotification?: {
    __typename: "Notification",
    id: string,
    userId: string,
    type: string,
    data: string,
    state?: NotificationState | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNotificationMutationVariables = {
  input?: UpdateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type UpdateNotificationMutation = {
  updateNotification?: {
    __typename: "Notification",
    id: string,
    userId: string,
    type: string,
    data: string,
    state?: NotificationState | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNotificationMutationVariables = {
  input?: DeleteNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type DeleteNotificationMutation = {
  deleteNotification?: {
    __typename: "Notification",
    id: string,
    userId: string,
    type: string,
    data: string,
    state?: NotificationState | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMomentMutationVariables = {
  input?: UpdateMomentInput,
  condition?: ModelMomentConditionInput | null,
};

export type UpdateMomentMutation = {
  updateMoment?: {
    __typename: "Moment",
    id: string,
    appId: string,
    snapshotId: string,
    title: string,
    description?: string | null,
    type?: string | null,
    time?: number | null,
    playCost?: number | null,
    unlockXp?: number | null,
    unlockCost?: number | null,
    data?: string | null,
    timesPlayed?: number | null,
    showTimer?: boolean | null,
    momentType?: MomentType | null,
    rankOrder?: RankOrder | null,
    status?: Status | null,
    createdAt: string,
    updatedAt: string,
    app?: {
      __typename: "App",
      id: string,
      title: string,
      company: string,
      description: string,
      category: string,
      data?: string | null,
      timesPlayed?: number | null,
      rotationMode?: RotationMode | null,
      status?: Status | null,
      type?: GameType | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateAppMutationVariables = {
  input?: UpdateAppInput,
  condition?: ModelAppConditionInput | null,
};

export type UpdateAppMutation = {
  updateApp?: {
    __typename: "App",
    id: string,
    title: string,
    company: string,
    description: string,
    category: string,
    data?: string | null,
    timesPlayed?: number | null,
    rotationMode?: RotationMode | null,
    status?: Status | null,
    type?: GameType | null,
    createdAt: string,
    updatedAt: string,
    moment?: {
      __typename: "ModelMomentConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateFriendMutationVariables = {
  input?: CreateFriendInput,
  condition?: ModelFriendConditionInput | null,
};

export type CreateFriendMutation = {
  createFriend?: {
    __typename: "Friend",
    id: string,
    senderId: string,
    receiverId: string,
    accepted?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    sender: {
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    receiver: {
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type UpdateFriendMutationVariables = {
  input?: UpdateFriendInput,
  condition?: ModelFriendConditionInput | null,
};

export type UpdateFriendMutation = {
  updateFriend?: {
    __typename: "Friend",
    id: string,
    senderId: string,
    receiverId: string,
    accepted?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    sender: {
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    receiver: {
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type CreateUserMutationVariables = {
  input?: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?: {
    __typename: "User",
    id: string,
    email?: string | null,
    phone?: string | null,
    facebookId?: string | null,
    googleId?: string | null,
    username: string,
    avatar?: string | null,
    banner?: string | null,
    xp: number,
    currency: number,
    pushSubscription?: string | null,
    online?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input?: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?: {
    __typename: "User",
    id: string,
    email?: string | null,
    phone?: string | null,
    facebookId?: string | null,
    googleId?: string | null,
    username: string,
    avatar?: string | null,
    banner?: string | null,
    xp: number,
    currency: number,
    pushSubscription?: string | null,
    online?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserMutationVariables = {
  input?: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?: {
    __typename: "User",
    id: string,
    email?: string | null,
    phone?: string | null,
    facebookId?: string | null,
    googleId?: string | null,
    username: string,
    avatar?: string | null,
    banner?: string | null,
    xp: number,
    currency: number,
    pushSubscription?: string | null,
    online?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserAppStatsMutationVariables = {
  input?: CreateUserAppStatsInput,
  condition?: ModelUserAppStatsConditionInput | null,
};

export type CreateUserAppStatsMutation = {
  createUserAppStats?: {
    __typename: "UserAppStats",
    id: string,
    userId: string,
    appId: string,
    totalTimePlayed: number,
    numberOfTimesPlayed: number,
    xp: number,
    updatedAt?: string | null,
    createdAt?: string | null,
  } | null,
};

export type UpdateUserAppStatsMutationVariables = {
  input?: UpdateUserAppStatsInput,
  condition?: ModelUserAppStatsConditionInput | null,
};

export type UpdateUserAppStatsMutation = {
  updateUserAppStats?: {
    __typename: "UserAppStats",
    id: string,
    userId: string,
    appId: string,
    totalTimePlayed: number,
    numberOfTimesPlayed: number,
    xp: number,
    updatedAt?: string | null,
    createdAt?: string | null,
  } | null,
};

export type DeleteUserAppStatsMutationVariables = {
  input?: DeleteUserAppStatsInput,
  condition?: ModelUserAppStatsConditionInput | null,
};

export type DeleteUserAppStatsMutation = {
  deleteUserAppStats?: {
    __typename: "UserAppStats",
    id: string,
    userId: string,
    appId: string,
    totalTimePlayed: number,
    numberOfTimesPlayed: number,
    xp: number,
    updatedAt?: string | null,
    createdAt?: string | null,
  } | null,
};

export type CreateUserMomentStatsMutationVariables = {
  input?: CreateUserMomentStatsInput,
  condition?: ModelUserMomentStatsConditionInput | null,
};

export type CreateUserMomentStatsMutation = {
  createUserMomentStats?: {
    __typename: "UserMomentStats",
    id: string,
    userId: string,
    appId: string,
    momentId: string,
    totalTimePlayed: number,
    numberOfTimesPlayed: number,
    xp: number,
    updatedAt?: string | null,
    createdAt?: string | null,
    moment?: {
      __typename: "Moment",
      id: string,
      appId: string,
      snapshotId: string,
      title: string,
      description?: string | null,
      type?: string | null,
      time?: number | null,
      playCost?: number | null,
      unlockXp?: number | null,
      unlockCost?: number | null,
      data?: string | null,
      timesPlayed?: number | null,
      showTimer?: boolean | null,
      momentType?: MomentType | null,
      rankOrder?: RankOrder | null,
      status?: Status | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateUserMomentStatsMutationVariables = {
  input?: UpdateUserMomentStatsInput,
  condition?: ModelUserMomentStatsConditionInput | null,
};

export type UpdateUserMomentStatsMutation = {
  updateUserMomentStats?: {
    __typename: "UserMomentStats",
    id: string,
    userId: string,
    appId: string,
    momentId: string,
    totalTimePlayed: number,
    numberOfTimesPlayed: number,
    xp: number,
    updatedAt?: string | null,
    createdAt?: string | null,
    moment?: {
      __typename: "Moment",
      id: string,
      appId: string,
      snapshotId: string,
      title: string,
      description?: string | null,
      type?: string | null,
      time?: number | null,
      playCost?: number | null,
      unlockXp?: number | null,
      unlockCost?: number | null,
      data?: string | null,
      timesPlayed?: number | null,
      showTimer?: boolean | null,
      momentType?: MomentType | null,
      rankOrder?: RankOrder | null,
      status?: Status | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeleteUserMomentStatsMutationVariables = {
  input?: DeleteUserMomentStatsInput,
  condition?: ModelUserMomentStatsConditionInput | null,
};

export type DeleteUserMomentStatsMutation = {
  deleteUserMomentStats?: {
    __typename: "UserMomentStats",
    id: string,
    userId: string,
    appId: string,
    momentId: string,
    totalTimePlayed: number,
    numberOfTimesPlayed: number,
    xp: number,
    updatedAt?: string | null,
    createdAt?: string | null,
    moment?: {
      __typename: "Moment",
      id: string,
      appId: string,
      snapshotId: string,
      title: string,
      description?: string | null,
      type?: string | null,
      time?: number | null,
      playCost?: number | null,
      unlockXp?: number | null,
      unlockCost?: number | null,
      data?: string | null,
      timesPlayed?: number | null,
      showTimer?: boolean | null,
      momentType?: MomentType | null,
      rankOrder?: RankOrder | null,
      status?: Status | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateUserActivityMutationVariables = {
  input?: CreateUserActivityInput,
  condition?: ModelUserActivityConditionInput | null,
};

export type CreateUserActivityMutation = {
  createUserActivity?: {
    __typename: "UserActivity",
    id: string,
    userId: string,
    type: string,
    text: string,
    data?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateUserActivityMutationVariables = {
  input?: UpdateUserActivityInput,
  condition?: ModelUserActivityConditionInput | null,
};

export type UpdateUserActivityMutation = {
  updateUserActivity?: {
    __typename: "UserActivity",
    id: string,
    userId: string,
    type: string,
    text: string,
    data?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteUserActivityMutationVariables = {
  input?: DeleteUserActivityInput,
  condition?: ModelUserActivityConditionInput | null,
};

export type DeleteUserActivityMutation = {
  deleteUserActivity?: {
    __typename: "UserActivity",
    id: string,
    userId: string,
    type: string,
    text: string,
    data?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type GetUserProfileQueryVariables = {
  userProfileInput?: UserProfileInput,
};

export type CreateFeedbackQueryVariables = {
  createFeedbackInput?: CreateFeedbackInput,
};

export type GetGameSessionV2QueryVariables = {
  getGameSessionInput?: GetGameSessionInput,
};

export type GetUserProfileQuery = {
  getUserProfile?: {
    __typename: "UserProfile",
    user?: {
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    nbMomentsPlayed?: number | null,
    nbMatchesWon?: number | null,
    nbFriends?: number | null,
    winningPercent?: number | null,
    userAppStats?: Array<{
      __typename: "UserAppStats",
      id: string,
      userId: string,
      appId: string,
      totalTimePlayed: number,
      numberOfTimesPlayed: number,
      xp: number,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null> | null,
    userMomentStats?: Array<{
      __typename: "UserMomentStats",
      id: string,
      userId: string,
      appId: string,
      momentId: string,
      totalTimePlayed: number,
      numberOfTimesPlayed: number,
      xp: number,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null> | null,
    userActivity?: Array<{
      __typename: "UserActivity",
      id: string,
      userId: string,
      type: string,
      text: string,
      data?: string | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null> | null,
  } | null,
};

export type GetGameSessionV2Query = {
  id: string,
  edgeNodeId: string,
  matchId: string,
  host: {
    id: string,
    email?: string | null,
    phone?: string | null,
    facebookId?: string | null,
    googleId?: string | null,
    username: string,
    avatar?: string | null,
    banner?: string | null,
    xp: number,
    currency: number,
    pushSubscription?: string | null,
    online?: boolean | null,
    createdAt: string,
    updatedAt: string,
  },
  moment: {
    id: string,
    appId: number,
    snapshotId: string,
    title: string,
    description: string,
    type: string,
    timesPlayed: number,
    momentType: string,
    app: {
      id: number,
      title: string,
      description: string,
      category: string,
      timesPlayed: number
      status: string,
      type: string,
    }
  },
  sessionType: string,
  sessionResults: string,
  createdAt: string,
  updatedAt: string,
}

export type GetScoreV2QueryVariables = {
  getScoreInput?: GetScoreInput,
};

export type GetScoreV2Query = {
  getScoreV2?: {
    __typename: "LeaderboardUserRank",
    userId?: string | null,
    score?: string | null,
    rank?: number | null,
    error?: string | null,
  } | null,
};

export type ListScoresV2QueryVariables = {
  scoreInput?: ScoreInput,
};

export type ListScoresV2Query = {
  listScoresV2?: Array<{
    __typename: "ScoreV2",
    gameId?: string | null,
    momentId?: string | null,
    playSessionId?: string | null,
    lastPlayedTime?: number | null,
    score?: string | null,
    scoreAttributes?: {
      __typename: "ScoreAttribute",
      coins?: string | null,
    } | null,
    error?: string | null,
  } | null> | null,
};

export type GetLeaderboardQueryVariables = {
  getLeaderboardInput?: GetLeaderboardInput,
};

export type GetLeaderboardQuery = {
  getLeaderboard?: {
    __typename: "Leaderboard",
    leaderboardDate?: string | null,
    leaders?: Array<{
      __typename: "LeaderboardUserRank",
      userId?: string | null,
      score?: string | null,
      rank?: number | null,
      error?: string | null,
    } | null> | null,
    error?: string | null,
  } | null,
};

export type ListChallengesQuery = {
  listChallenges?: Array<{
    __typename: "Challenge",
    id: number,
    score: number,
    user: {
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    moment: {
      __typename: "Moment",
      id: string,
      appId: string,
      snapshotId: string,
      title: string,
      description?: string | null,
      type?: string | null,
      time?: number | null,
      playCost?: number | null,
      unlockXp?: number | null,
      unlockCost?: number | null,
      data?: string | null,
      timesPlayed?: number | null,
      showTimer?: boolean | null,
      momentType?: MomentType | null,
      rankOrder?: RankOrder | null,
      status?: Status | null,
      createdAt: string,
      updatedAt: string,
    },
    created_at?: number | null,
  } | null> | null,
};

export type GetBattleQueryVariables = {
  getBattlesInput?: GetBattlesInput,
};

export type GetBattleQuery = {
  getBattle?: {
    __typename: "Battle",
    battleId?: string | null,
    gameId?: string | null,
    momentId?: string | null,
    startTime?: number | null,
    endTime?: number | null,
    noOfPlayers?: number | null,
    chargeType?: ChargeType | null,
    entryFee?: number | null,
    createdTime?: number | null,
    expiryMins?: number | null,
    prizePool?: number | null,
    prizeBreakup?: Array<{
      __typename: "PrizeBreakup",
      startRank?: number | null,
      endRank?: number | null,
      totalAmount?: number | null,
      perUserAmount?: number | null,
    } | null> | null,
    error?: string | null,
  } | null,
};

export type GetRandomBattleQueryVariables = {
  getRandomBattlesInput?: GetRandomBattlesInput,
};

export type GetRandomBattleQuery = {
  getRandomBattle?: {
    __typename: "Battle",
    battleId?: string | null,
    gameId?: string | null,
    momentId?: string | null,
    startTime?: number | null,
    endTime?: number | null,
    noOfPlayers?: number | null,
    chargeType?: ChargeType | null,
    entryFee?: number | null,
    createdTime?: number | null,
    expiryMins?: number | null,
    prizePool?: number | null,
    prizeBreakup?: Array<{
      __typename: "PrizeBreakup",
      startRank?: number | null,
      endRank?: number | null,
      totalAmount?: number | null,
      perUserAmount?: number | null,
    } | null> | null,
    error?: string | null,
  } | null,
};

export type ListBattlesQueryVariables = {
  listBattlesInput?: ListBattlesInput,
};

export type ListBattlesQuery = {
  listBattles?: Array<{
    __typename: "Battle",
    battleId?: string | null,
    gameId?: string | null,
    momentId?: string | null,
    startTime?: number | null,
    endTime?: number | null,
    noOfPlayers?: number | null,
    chargeType?: ChargeType | null,
    entryFee?: number | null,
    createdTime?: number | null,
    expiryMins?: number | null,
    prizePool?: number | null,
    prizeBreakup?: Array<{
      __typename: "PrizeBreakup",
      startRank?: number | null,
      endRank?: number | null,
      totalAmount?: number | null,
      perUserAmount?: number | null,
    } | null> | null,
    error?: string | null,
  } | null> | null,
};

export type GetMatchQueryVariables = {
  getMatchInput?: LightMatchInput,
};

export type GetMatchQuery = {
  getMatch?: {
    __typename: "Match",
    gameId?: string | null,
    momentId?: string | null,
    matchId?: string | null,
    hostUserId?: string | null,
    expiryTime?: number | null,
    joinedPlayers?: number | null,
    matchState?: string | null,
    noOfPlayers?: number | null,
    inviteCode?: string | null,
    battleInfo?: {
      __typename: "Battle",
      battleId?: string | null,
      gameId?: string | null,
      momentId?: string | null,
      startTime?: number | null,
      endTime?: number | null,
      noOfPlayers?: number | null,
      chargeType?: ChargeType | null,
      entryFee?: number | null,
      createdTime?: number | null,
      expiryMins?: number | null,
      prizePool?: number | null,
      error?: string | null,
    } | null,
    moment?: {
      __typename: "Moment",
      id: string,
      appId: string,
      snapshotId: string,
      title: string,
      description?: string | null,
      type?: string | null,
      time?: number | null,
      playCost?: number | null,
      unlockXp?: number | null,
      unlockCost?: number | null,
      data?: string | null,
      timesPlayed?: number | null,
      showTimer?: boolean | null,
      momentType?: MomentType | null,
      rankOrder?: RankOrder | null,
      status?: Status | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    players?: Array<{
      __typename: "MatchPlayer",
      userId?: string | null,
      playState?: PlayState | null,
      paymentState?: PaymentState | null,
      lastPlayedTime?: number | null,
      score?: number | null,
      self?: boolean | null,
    } | null> | null,
    winners?: Array<{
      __typename: "MatchWinner",
      amount?: string | null,
      rank?: number | null,
      userId?: string | null,
    } | null> | null,
    error?: string | null,
  } | null,
};

export type ListMatchesQueryVariables = {
  listMatchesInput?: ListMatchesInput,
};

export type ListMatchesQuery = {
  listMatches?: Array<{
    __typename: "Match",
    gameId?: string | null,
    momentId?: string | null,
    matchId?: string | null,
    hostUserId?: string | null,
    expiryTime?: number | null,
    joinedPlayers?: number | null,
    matchState?: string | null,
    noOfPlayers?: number | null,
    inviteCode?: string | null,
    battleInfo?: {
      __typename: "Battle",
      battleId?: string | null,
      gameId?: string | null,
      momentId?: string | null,
      startTime?: number | null,
      endTime?: number | null,
      noOfPlayers?: number | null,
      chargeType?: ChargeType | null,
      entryFee?: number | null,
      createdTime?: number | null,
      expiryMins?: number | null,
      prizePool?: number | null,
      error?: string | null,
    } | null,
    moment?: {
      __typename: "Moment",
      id: string,
      appId: string,
      snapshotId: string,
      title: string,
      description?: string | null,
      type?: string | null,
      time?: number | null,
      playCost?: number | null,
      unlockXp?: number | null,
      unlockCost?: number | null,
      data?: string | null,
      timesPlayed?: number | null,
      showTimer?: boolean | null,
      momentType?: MomentType | null,
      rankOrder?: RankOrder | null,
      status?: Status | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    players?: Array<{
      __typename: "MatchPlayer",
      userId?: string | null,
      playState?: PlayState | null,
      paymentState?: PaymentState | null,
      lastPlayedTime?: number | null,
      score?: number | null,
      self?: boolean | null,
    } | null> | null,
    winners?: Array<{
      __typename: "MatchWinner",
      amount?: string | null,
      rank?: number | null,
      userId?: string | null,
    } | null> | null,
    error?: string | null,
  } | null> | null,
};

export type ListMomentsV2QueryVariables = {
  appId?: string,
  status?: Status | null,
};

export type ListMomentsV2Query = {
  listMomentsV2?: Array<{
    __typename: "Moment",
    id: string,
    appId: string,
    snapshotId: string,
    title: string,
    description?: string | null,
    type?: string | null,
    time?: number | null,
    playCost?: number | null,
    unlockXp?: number | null,
    unlockCost?: number | null,
    data?: string | null,
    timesPlayed?: number | null,
    showTimer?: boolean | null,
    momentType?: MomentType | null,
    rankOrder?: RankOrder | null,
    status?: Status | null,
    createdAt: string,
    updatedAt: string,
    app?: {
      __typename: "App",
      id: string,
      title: string,
      company: string,
      description: string,
      category: string,
      data?: string | null,
      timesPlayed?: number | null,
      rotationMode?: RotationMode | null,
      status?: Status | null,
      type?: GameType | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null> | null,
};

export type ListGameSessionsV2Query = {
  ListGameSessionsV2?: Array<{
    __typename: "GameSession",
    id: string,
    userId: string,
    appId: string,
    momentId: string,
    edgeNodeId: string,
    matchId?: string | null,
    controller?: string | null,
    incognito?: boolean | null,
    isStreamReady?: boolean | null,
    sessionType?: GameSessionType | null,
    sessionResults?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null> | null,
};

export type ListTransactionsV2Query = {
  listTransactionsV2?: Array<{
    __typename: "serializeTransaction",
    type: string,
    amount: string,
    action: string,
    text: string,
    image?: string | null,
    createdAt: number,
  } | null> | null,
};

export type GetScoreQueryVariables = {
  id?: number,
};

export type GetScoreQuery = {
  getScore?: {
    __typename: "score",
    id: number,
    score: number,
    user: string,
    moment: string,
    app: number,
    created_at?: number | null,
  } | null,
};

export type ListScoresQuery = {
  listScores?: Array<{
    __typename: "score",
    id: number,
    score: number,
    user: string,
    moment: string,
    app: number,
    created_at?: number | null,
  } | null> | null,
};

export type GetTransactionQueryVariables = {
  id?: number,
};

export type GetTransactionQuery = {
  getTransaction?: {
    __typename: "transaction",
    id: number,
    status?: number | null,
    amount?: number | null,
    type?: number | null,
    transactionKey?: string | null,
    data?: string | null,
    userId?: number | null,
    oldUserId?: string | null,
    createdAt?: number | null,
    updatedAt?: number | null,
  } | null,
};

export type ListTransactionsQuery = {
  listTransactions?: Array<{
    __typename: "transaction",
    id: number,
    status?: number | null,
    amount?: number | null,
    type?: number | null,
    transactionKey?: string | null,
    data?: string | null,
    userId?: number | null,
    oldUserId?: string | null,
    createdAt?: number | null,
    updatedAt?: number | null,
  } | null> | null,
};

export type GetNotificationQueryVariables = {
  id?: string,
};

export type GetNotificationQuery = {
  getNotification?: {
    __typename: "Notification",
    id: string,
    userId: string,
    type: string,
    data: string,
    state?: NotificationState | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsQuery = {
  listNotifications?: {
    __typename: "ModelNotificationConnection",
    items?: Array<{
      __typename: "Notification",
      id: string,
      userId: string,
      type: string,
      data: string,
      state?: NotificationState | null,
      createdAt: string,
      updatedAt: string,
    } | null> | null,
    nextToken?: string | null,
  } | null,
};

export type GetFriendQueryVariables = {
  id?: string,
};

export type GetFriendQuery = {
  getFriend?: {
    __typename: "Friend",
    id: string,
    senderId: string,
    receiverId: string,
    accepted?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    sender: {
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    receiver: {
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type ListFriendsQueryVariables = {
  filter?: ModelFriendFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFriendsQuery = {
  listFriends?: {
    __typename: "ModelFriendConnection",
    items?: Array<{
      __typename: "Friend",
      id: string,
      senderId: string,
      receiverId: string,
      accepted?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null> | null,
    nextToken?: string | null,
  } | null,
};

export type GetStoreItemsQueryVariables = {
  id?: string,
};

export type GetStoreItemsQuery = {
  getStoreItems?: {
    __typename: "StoreItems",
    id: string,
    title: string,
    description: string,
    price: string,
    type: string,
    coins: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListStoreItemssQueryVariables = {
  filter?: ModelStoreItemsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStoreItemssQuery = {
  listStoreItemss?: {
    __typename: "ModelStoreItemsConnection",
    items?: Array<{
      __typename: "StoreItems",
      id: string,
      title: string,
      description: string,
      price: string,
      type: string,
      coins: number,
      createdAt: string,
      updatedAt: string,
    } | null> | null,
    nextToken?: string | null,
  } | null,
};

export type NotificationsByUserIdQueryVariables = {
  userId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationsByUserIdQuery = {
  notificationsByUserId?: {
    __typename: "ModelNotificationConnection",
    items?: Array<{
      __typename: "Notification",
      id: string,
      userId: string,
      type: string,
      data: string,
      state?: NotificationState | null,
      createdAt: string,
      updatedAt: string,
    } | null> | null,
    nextToken?: string | null,
  } | null,
};

export type ListFriendsSentQueryVariables = {
  senderId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFriendFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFriendsSentQuery = {
  listFriendsSentV2?: {
    __typename: "ModelFriendConnection",
    items?: Array<{
      __typename: "Friend",
      id: string,
      senderId: string,
      receiverId: string,
      // accepted?: boolean | null,
      sender: User
      receiver: User
      createdAt?: string | null,
      updatedAt: string,
    } | null> | null,
    nextToken?: string | null,
  } | null,
};

export type ListFriendsReceivedQueryVariables = {
  receiverId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFriendFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFriendsReceivedQuery = {
  listFriendsReceivedV2?: {
    __typename: "ModelFriendConnection",
    items?: Array<{
      __typename: "Friend",
      id: string,
      senderId: string,
      receiverId: string,
      // accepted?: boolean | null,
      sender: User
      receiver: User
      createdAt?: string | null,
      updatedAt: string,
    } | null> | null,
    nextToken?: string | null,
  } | null,
};

export type GetGameSessionQueryVariables = {
  id?: string,
};

export type GetGameSessionQuery = {
  getGameSession?: {
    __typename: "GameSession",
    id: string,
    userId: string,
    appId: string,
    momentId: string,
    edgeNodeId: string,
    matchId?: string | null,
    controller?: string | null,
    incognito?: boolean | null,
    isStreamReady?: boolean | null,
    sessionType?: GameSessionType | null,
    sessionResults?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGameSessionsQueryVariables = {
  filter?: ModelGameSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGameSessionsQuery = {
  listGameSessions?: {
    __typename: "ModelGameSessionConnection",
    items?: Array<{
      __typename: "GameSession",
      id: string,
      userId: string,
      appId: string,
      momentId: string,
      edgeNodeId: string,
      matchId?: string | null,
      controller?: string | null,
      incognito?: boolean | null,
      isStreamReady?: boolean | null,
      sessionType?: GameSessionType | null,
      sessionResults?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null> | null,
    nextToken?: string | null,
  } | null,
};

export type GameSessionsByEdgeNodeIdQueryVariables = {
  edgeNodeId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGameSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GameSessionsByEdgeNodeIdQuery = {
  gameSessionsByEdgeNodeId?: {
    __typename: "ModelGameSessionConnection",
    items?: Array<{
      __typename: "GameSession",
      id: string,
      userId: string,
      appId: string,
      momentId: string,
      edgeNodeId: string,
      matchId?: string | null,
      controller?: string | null,
      incognito?: boolean | null,
      isStreamReady?: boolean | null,
      sessionType?: GameSessionType | null,
      sessionResults?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null> | null,
    nextToken?: string | null,
  } | null,
};

export type GameSessionsByMatchIdQueryVariables = {
  matchId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGameSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GameSessionsByMatchIdQuery = {
  gameSessionsByMatchId?: {
    __typename: "ModelGameSessionConnection",
    items?: Array<{
      __typename: "GameSession",
      id: string,
      userId: string,
      appId: string,
      momentId: string,
      edgeNodeId: string,
      matchId?: string | null,
      controller?: string | null,
      incognito?: boolean | null,
      isStreamReady?: boolean | null,
      sessionType?: GameSessionType | null,
      sessionResults?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null> | null,
    nextToken?: string | null,
  } | null,
};

export type GetSnapshotQueryVariables = {
  id?: string,
};

export type GetSnapshotQuery = {
  getSnapshot?: {
    __typename: "Snapshot",
    id: string,
    appId: string,
    title?: string | null,
    playTime?: number | null,
    createdAt: string,
    updatedAt: string,
    moments?: {
      __typename: "ModelMomentConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListSnapshotsQueryVariables = {
  filter?: ModelSnapshotFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSnapshotsQuery = {
  listSnapshots?: {
    __typename: "ModelSnapshotConnection",
    items?: Array<{
      __typename: "Snapshot",
      id: string,
      appId: string,
      title?: string | null,
      playTime?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null> | null,
    nextToken?: string | null,
  } | null,
};

export type SnapshotsByAppIdQueryVariables = {
  appId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSnapshotFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SnapshotsByAppIdQuery = {
  snapshotsByAppId?: {
    __typename: "ModelSnapshotConnection",
    items?: Array<{
      __typename: "Snapshot",
      id: string,
      appId: string,
      title?: string | null,
      playTime?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null> | null,
    nextToken?: string | null,
  } | null,
};

export type ListMomentsQueryVariables = {
  filter?: ModelMomentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMomentsQuery = {
  listMoments?: {
    __typename: "ModelMomentConnection",
    items?: Array<{
      __typename: "Moment",
      id: string,
      appId: string,
      snapshotId: string,
      title: string,
      description?: string | null,
      type?: string | null,
      time?: number | null,
      playCost?: number | null,
      unlockXp?: number | null,
      unlockCost?: number | null,
      data?: string | null,
      timesPlayed?: number | null,
      showTimer?: boolean | null,
      momentType?: MomentType | null,
      rankOrder?: RankOrder | null,
      status?: Status | null,
      createdAt: string,
      updatedAt: string,
    } | null> | null,
    nextToken?: string | null,
  } | null,
};

export type GetMomentQueryVariables = {
  id?: string,
};

export type GetMomentQuery = {
  getMoment?: {
    __typename: "Moment",
    id: string,
    appId: string,
    snapshotId: string,
    title: string,
    description?: string | null,
    type?: string | null,
    time?: number | null,
    playCost?: number | null,
    unlockXp?: number | null,
    unlockCost?: number | null,
    data?: string | null,
    timesPlayed?: number | null,
    showTimer?: boolean | null,
    momentType?: MomentType | null,
    rankOrder?: RankOrder | null,
    status?: Status | null,
    createdAt: string,
    updatedAt: string,
    app?: {
      __typename: "App",
      id: string,
      title: string,
      company: string,
      description: string,
      category: string,
      data?: string | null,
      timesPlayed?: number | null,
      rotationMode?: RotationMode | null,
      status?: Status | null,
      type?: GameType | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type MomentsByAppIdQueryVariables = {
  appId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMomentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MomentsByAppIdQuery = {
  momentsByAppId?: {
    __typename: "ModelMomentConnection",
    items?: Array<{
      __typename: "Moment",
      id: string,
      appId: string,
      snapshotId: string,
      title: string,
      description?: string | null,
      type?: string | null,
      time?: number | null,
      playCost?: number | null,
      unlockXp?: number | null,
      unlockCost?: number | null,
      data?: string | null,
      timesPlayed?: number | null,
      showTimer?: boolean | null,
      momentType?: MomentType | null,
      rankOrder?: RankOrder | null,
      status?: Status | null,
      createdAt: string,
      updatedAt: string,
    } | null> | null,
    nextToken?: string | null,
  } | null,
};

export type MomentsBySnapshotQueryVariables = {
  snapshotId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMomentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MomentsBySnapshotQuery = {
  momentsBySnapshot?: {
    __typename: "ModelMomentConnection",
    items?: Array<{
      __typename: "Moment",
      id: string,
      appId: string,
      snapshotId: string,
      title: string,
      description?: string | null,
      type?: string | null,
      time?: number | null,
      playCost?: number | null,
      unlockXp?: number | null,
      unlockCost?: number | null,
      data?: string | null,
      timesPlayed?: number | null,
      showTimer?: boolean | null,
      momentType?: MomentType | null,
      rankOrder?: RankOrder | null,
      status?: Status | null,
      createdAt: string,
      updatedAt: string,
    } | null> | null,
    nextToken?: string | null,
  } | null,
};

export type ListAppsQueryVariables = {
  filter?: ModelAppFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAppsQuery = {
  listApps?: {
    __typename: "ModelAppConnection",
    items?: Array<{
      __typename: "App",
      id: string,
      title: string,
      company: string,
      description: string,
      category: string,
      data?: string | null,
      timesPlayed?: number | null,
      rotationMode?: RotationMode | null,
      status?: Status | null,
      type?: GameType | null,
      createdAt: string,
      updatedAt: string,
    } | null> | null,
    nextToken?: string | null,
  } | null,
};

export type GetAppQueryVariables = {
  id?: string,
};

export type GetAppQuery = {
  getApp?: {
    __typename: "App",
    id: string,
    title: string,
    company: string,
    description: string,
    category: string,
    data?: string | null,
    timesPlayed?: number | null,
    rotationMode?: RotationMode | null,
    status?: Status | null,
    type?: GameType | null,
    createdAt: string,
    updatedAt: string,
    moment?: {
      __typename: "ModelMomentConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?: {
    __typename: "ModelUserConnection",
    items?: Array<{
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null> | null,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id?: string,
};

export type GetUserQuery = {
  getUser?: {
    __typename: "User",
    id: string,
    email?: string | null,
    phone?: string | null,
    facebookId?: string | null,
    googleId?: string | null,
    username: string,
    avatar?: string | null,
    banner?: string | null,
    xp: number,
    currency: number,
    pushSubscription?: string | null,
    online?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UsersByUsernameQueryVariables = {
  username?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByUsernameQuery = {
  usersByUsername?: {
    __typename: "ModelUserConnection",
    items?: Array<{
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null> | null,
    nextToken?: string | null,
  } | null,
};

export type UsersByFacebookIdQueryVariables = {
  facebookId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByFacebookIdQuery = {
  usersByFacebookId?: {
    __typename: "ModelUserConnection",
    items?: Array<{
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null> | null,
    nextToken?: string | null,
  } | null,
};

export type GetUserAppStatsQueryVariables = {
  id?: string,
};

export type GetUserAppStatsQuery = {
  getUserAppStats?: {
    __typename: "UserAppStats",
    id: string,
    userId: string,
    appId: string,
    totalTimePlayed: number,
    numberOfTimesPlayed: number,
    xp: number,
    updatedAt?: string | null,
    createdAt?: string | null,
  } | null,
};

export type ListUserAppStatssQueryVariables = {
  filter?: ModelUserAppStatsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserAppStatssQuery = {
  listUserAppStatss?: {
    __typename: "ModelUserAppStatsConnection",
    items?: Array<{
      __typename: "UserAppStats",
      id: string,
      userId: string,
      appId: string,
      totalTimePlayed: number,
      numberOfTimesPlayed: number,
      xp: number,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null> | null,
    nextToken?: string | null,
  } | null,
};

export type UserAppStatsByUserIdQueryVariables = {
  userId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserAppStatsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserAppStatsByUserIdQuery = {
  userAppStatsByUserId?: {
    __typename: "ModelUserAppStatsConnection",
    items?: Array<{
      __typename: "UserAppStats",
      id: string,
      userId: string,
      appId: string,
      totalTimePlayed: number,
      numberOfTimesPlayed: number,
      xp: number,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null> | null,
    nextToken?: string | null,
  } | null,
};

export type UserAppStatsByUserIdUpdatedAtQueryVariables = {
  userId?: string | null,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserAppStatsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserAppStatsByUserIdUpdatedAtQuery = {
  userAppStatsByUserIdUpdatedAt?: {
    __typename: "ModelUserAppStatsConnection",
    items?: Array<{
      __typename: "UserAppStats",
      id: string,
      userId: string,
      appId: string,
      totalTimePlayed: number,
      numberOfTimesPlayed: number,
      xp: number,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null> | null,
    nextToken?: string | null,
  } | null,
};

export type GetUserMomentStatsQueryVariables = {
  id?: string,
};

export type GetUserMomentStatsQuery = {
  getUserMomentStats?: {
    __typename: "UserMomentStats",
    id: string,
    userId: string,
    appId: string,
    momentId: string,
    totalTimePlayed: number,
    numberOfTimesPlayed: number,
    xp: number,
    updatedAt?: string | null,
    createdAt?: string | null,
    moment?: {
      __typename: "Moment",
      id: string,
      appId: string,
      snapshotId: string,
      title: string,
      description?: string | null,
      type?: string | null,
      time?: number | null,
      playCost?: number | null,
      unlockXp?: number | null,
      unlockCost?: number | null,
      data?: string | null,
      timesPlayed?: number | null,
      showTimer?: boolean | null,
      momentType?: MomentType | null,
      rankOrder?: RankOrder | null,
      status?: Status | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListUserMomentStatssQueryVariables = {
  filter?: ModelUserMomentStatsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserMomentStatssQuery = {
  listUserMomentStatss?: {
    __typename: "ModelUserMomentStatsConnection",
    items?: Array<{
      __typename: "UserMomentStats",
      id: string,
      userId: string,
      appId: string,
      momentId: string,
      totalTimePlayed: number,
      numberOfTimesPlayed: number,
      xp: number,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null> | null,
    nextToken?: string | null,
  } | null,
};

export type UserMomentStatsByUserIdQueryVariables = {
  userId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserMomentStatsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserMomentStatsByUserIdQuery = {
  userMomentStatsByUserId?: {
    __typename: "ModelUserMomentStatsConnection",
    items?: Array<{
      __typename: "UserMomentStats",
      id: string,
      userId: string,
      appId: string,
      momentId: string,
      totalTimePlayed: number,
      numberOfTimesPlayed: number,
      xp: number,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null> | null,
    nextToken?: string | null,
  } | null,
};

export type UserMomentStatsByUserIdUpdatedAtQueryVariables = {
  userId?: string | null,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserMomentStatsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserMomentStatsByUserIdUpdatedAtQuery = {
  userMomentStatsByUserIdUpdatedAt?: {
    __typename: "ModelUserMomentStatsConnection",
    items?: Array<{
      __typename: "UserMomentStats",
      id: string,
      userId: string,
      appId: string,
      momentId: string,
      totalTimePlayed: number,
      numberOfTimesPlayed: number,
      xp: number,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null> | null,
    nextToken?: string | null,
  } | null,
};

export type GetUserActivityQueryVariables = {
  id?: string,
};

export type GetUserActivityQuery = {
  getUserActivity?: {
    __typename: "UserActivity",
    id: string,
    userId: string,
    type: string,
    text: string,
    data?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type ListUserActivitysQueryVariables = {
  filter?: ModelUserActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserActivitysQuery = {
  listUserActivitys?: {
    __typename: "ModelUserActivityConnection",
    items?: Array<{
      __typename: "UserActivity",
      id: string,
      userId: string,
      type: string,
      text: string,
      data?: string | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null> | null,
    nextToken?: string | null,
  } | null,
};

export type GetTournamentInput = {
  id: string;
  tournamentPlayerId?: string;
}

export type GetLeaderboardsInput = {
  limit: number;
}

export type ListOpenTournamentsInput = {
  currency: string;
}

export type PlayTournamentInput = {
  tournamentId: string;
  device: {
    info: string;
    screen_size: string;
  }
}

export type UserActivityByUserIdQueryVariables = {
  userId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserActivityByUserIdQuery = {
  userActivityByUserId?: {
    __typename: "ModelUserActivityConnection",
    items?: Array<{
      __typename: "UserActivity",
      id: string,
      userId: string,
      type: string,
      text: string,
      data?: string | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null> | null,
    nextToken?: string | null,
  } | null,
};

export type GetStreamRatingQueryVariables = {
  id?: string,
};

export type GetStreamRatingQuery = {
  getStreamRating?: {
    __typename: "StreamRating",
    id: string,
    appId?: string | null,
    momentId?: string | null,
    userId?: string | null,
    username?: string | null,
    edgeNodeId?: string | null,
    env?: string | null,
    isHost?: boolean | null,
    rating?: number | null,
    ping?: number | null,
    feedback?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListStreamRatingsQueryVariables = {
  filter?: ModelStreamRatingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStreamRatingsQuery = {
  listStreamRatings?: {
    __typename: "ModelStreamRatingConnection",
    items?: Array<{
      __typename: "StreamRating",
      id: string,
      appId?: string | null,
      momentId?: string | null,
      userId?: string | null,
      username?: string | null,
      edgeNodeId?: string | null,
      env?: string | null,
      isHost?: boolean | null,
      rating?: number | null,
      ping?: number | null,
      feedback?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null> | null,
    nextToken?: string | null,
  } | null,
};

export type OnGetFriendRequestSubscriptionVariables = {
  receiverId?: string | null,
};

export type OnGetFriendRequestSubscription = {
  onGetFriendRequest?: {
    __typename: "Friend",
    id: string,
    senderId: string,
    receiverId: string,
    accepted?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    sender: {
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    receiver: {
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type OnGameSessionUpdateSubscriptionVariables = {
  id?: string | null,
};

export type OnGameSessionUpdateSubscription = {
  onGameSessionUpdate?: {
    __typename: "GameSession",
    id: string,
    userId: string,
    appId: string,
    momentId: string,
    edgeNodeId: string,
    matchId?: string | null,
    controller?: string | null,
    incognito?: boolean | null,
    isStreamReady?: boolean | null,
    sessionType?: GameSessionType | null,
    sessionResults?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnFriendRequestUpdateSubscriptionVariables = {
  senderId?: string | null,
};

export type OnFriendRequestUpdateSubscription = {
  onFriendRequestUpdate?: {
    __typename: "Friend",
    id: string,
    senderId: string,
    receiverId: string,
    accepted?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    sender: {
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    receiver: {
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type OnSenderFriendRequestDeleteSubscriptionVariables = {
  receiverId?: string | null,
};

export type OnSenderFriendRequestDeleteSubscription = {
  onSenderFriendRequestDelete?: {
    __typename: "Friend",
    id: string,
    senderId: string,
    receiverId: string,
    accepted?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    sender: {
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    receiver: {
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type OnReceiverFriendRequestDeleteSubscriptionVariables = {
  senderId?: string | null,
};

export type OnReceiverFriendRequestDeleteSubscription = {
  onReceiverFriendRequestDelete?: {
    __typename: "Friend",
    id: string,
    senderId: string,
    receiverId: string,
    accepted?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    sender: {
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    receiver: {
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type OnCreateScoreSubscription = {
  onCreateScore?: {
    __typename: "score",
    id: number,
    score: number,
    user: string,
    moment: string,
    app: number,
    created_at?: number | null,
  } | null,
};

export type OnCreateTransactionSubscription = {
  onCreateTransaction?: {
    __typename: "transaction",
    id: number,
    status?: number | null,
    amount?: number | null,
    type?: number | null,
    transactionKey?: string | null,
    data?: string | null,
    userId?: number | null,
    oldUserId?: string | null,
    createdAt?: number | null,
    updatedAt?: number | null,
  } | null,
};

export type OnCreateNotificationSubscriptionVariables = {
  userId?: string,
};

export type OnCreateNotificationSubscription = {
  onCreateNotification?: {
    __typename: "Notification",
    id: string,
    userId: string,
    type: string,
    data: string,
    state?: NotificationState | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateNotificationSubscriptionVariables = {
  userId?: string,
};

export type OnUpdateNotificationSubscription = {
  onUpdateNotification?: {
    __typename: "Notification",
    id: string,
    userId: string,
    type: string,
    data: string,
    state?: NotificationState | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteNotificationSubscriptionVariables = {
  userId?: string,
};

export type OnDeleteNotificationSubscription = {
  onDeleteNotification?: {
    __typename: "Notification",
    id: string,
    userId: string,
    type: string,
    data: string,
    state?: NotificationState | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateFriendSubscription = {
  onCreateFriend?: {
    __typename: "Friend",
    id: string,
    senderId: string,
    receiverId: string,
    accepted?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    sender: {
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    receiver: {
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type OnUpdateFriendSubscription = {
  onUpdateFriend?: {
    __typename: "Friend",
    id: string,
    senderId: string,
    receiverId: string,
    accepted?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    sender: {
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    receiver: {
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type OnDeleteFriendSubscription = {
  onDeleteFriend?: {
    __typename: "Friend",
    id: string,
    senderId: string,
    receiverId: string,
    accepted?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    sender: {
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    receiver: {
      __typename: "User",
      id: string,
      email?: string | null,
      phone?: string | null,
      facebookId?: string | null,
      googleId?: string | null,
      username: string,
      avatar?: string | null,
      banner?: string | null,
      xp: number,
      currency: number,
      pushSubscription?: string | null,
      online?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type OnCreateGameSessionSubscription = {
  onCreateGameSession?: {
    __typename: "GameSession",
    id: string,
    userId: string,
    appId: string,
    momentId: string,
    edgeNodeId: string,
    matchId?: string | null,
    controller?: string | null,
    incognito?: boolean | null,
    isStreamReady?: boolean | null,
    sessionType?: GameSessionType | null,
    sessionResults?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateGameSessionSubscription = {
  onUpdateGameSession?: {
    __typename: "GameSession",
    id: string,
    userId: string,
    appId: string,
    momentId: string,
    edgeNodeId: string,
    matchId?: string | null,
    controller?: string | null,
    incognito?: boolean | null,
    isStreamReady?: boolean | null,
    sessionType?: GameSessionType | null,
    sessionResults?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteGameSessionSubscription = {
  onDeleteGameSession?: {
    __typename: "GameSession",
    id: string,
    userId: string,
    appId: string,
    momentId: string,
    edgeNodeId: string,
    matchId?: string | null,
    controller?: string | null,
    incognito?: boolean | null,
    isStreamReady?: boolean | null,
    sessionType?: GameSessionType | null,
    sessionResults?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
