import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles(() => ({
  root: {
    width: "100%",
    display: "block",
    position: "relative",
    overflow: "hidden",
    backgroundColor: "rgba(0, 29, 46, 0.11)",
    "&:after": {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      transform: "translateX(-100%)",
      background:
        "linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255,255, 0))",
      animation: `$loading 1s infinite`,
      content: `""`,
    },
  },
  text: {
    transform: "scale(1, 0.60)",
    borderRadius: 5,
  },
  circle: {
    borderRadius: "100%",
  },
  "@keyframes loading": {
    "0%": {
      transform: "translateX(-30%)",
    },
    "100%": {
      transform: "translateX(100%)",
    },
  },
}));
