import gql from "graphql-tag";

export const FRIEND_REQUEST_BATTLE = gql`
  subscription OnFriendRequestBattle {
    friendRequestBattle {
      fullBattleInfo {
        matchId
        hostUserId
        momentId
        gameId
        inviteCode
        joinedPlayers
        matchState
        battleInfo {
          gameId
          entryFee
          prizePool
          currency
          costPerUser
          battleId
        }
        players {
          userId
          playState
          score
          user {
            id
            avatar
            username
          }
        }
        winners {
          rank
          userId
        }
        expiryTime
      }
      players {
        id
        username
        avatar
        xp
      }
    }
  }
`;

export const BATTLE_COMPLETED = gql`
  subscription OnBattleCompleted {
    battleCompleted {
      matchId
      hostUserId
      gameId
      opponentId
      notificationType
      completeBattle {
        battle {
          battleId
          userIds
          maxPrizePool
          wagerPerUser
          feePerUser
        }
        updatedWallets {
          type
          currency
          balance
        }
        transactions {
          entryId
          date
          walletId
          currency
          amount
          description
          reference
          sequence
          workflow
          runningBalance
          postedOn
          escrowId
        }
      }
    }
  }
`;
