import React from "react";
import clsx from "classnames";
import { useStyles } from "./Container.style";

interface IPropsContainer {
  className?: any;
}

export const Container = ({ children, ...props }: any) => {
  const classes = useStyles();
  const { className }: IPropsContainer = props;

  return <div className={clsx(classes.root, className)}>{children}</div>;
};
