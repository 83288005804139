import apollo from "../clients/apollo";
import {
  getUserProfile,
  getUserV2,
  getUser,
  getTop100MostRecentActivitiesByUserId,
  listFriendsV2,
  listUserTransactions,
  findUser,
  getCryptoDepositAddress,
  estimateCryptoTransactionFee,
  getWithdrawal,
  getDeposit,
  getConfig,
} from "../apollo/queries/users";
import {
  updateUserV2,
  deleteUserV2,
  createFeedback,
  sendOtpCode,
  updateLike,
  lockCurrency,
  checkinUser,
} from "../apollo/mutations/users";
import {
  IUserBaseData,
  IUserData,
  IUserProfile,
  IBaseActivity,
  IBaseFriend,
  IBaseTransaction,
} from "../types/baseInterfaces";
import { UpdateUserV2Input, CreateFeedbackInput, UpdateLikeInput } from "../types/inputTypes";
import { map, filter } from "lodash-es";
import { getUserWallets } from "../utils/wallets";
import gql from "graphql-tag";
import { QueryOptions } from "@apollo/client";
import { CURRENCY } from "../apollo/constants";
import { LockCurrencyInput } from "../graphql/API";
import { getTheme } from "../utils/theme";
import { ThemeName } from "../constants/theme";

const apolloClient = apollo.getInstance();
const THEME = getTheme();

export const EMPTY_USER: any = {
  id: "",
  username: "",
  avatar: "",
  banner: "",
  xp: 0,
  currency: 0,
  email: "",
  phone: "",
  phoneNotification: "",
  googleId: "",
  pushSubscription: "",
  online: false,
  hasWelcome: false,
  facebookId: "",
  nbBattlesPending: 0,
  nbFriendRequestUnaccepted: 0,
  nbNotificationsUnread: 0,
  smsNotification: false,
};
export class Users {
  /**
   * To get list friend of a user
   */
  static getUserFriendsV2 = async (userId: string, options = {}): Promise<IBaseFriend[]> => {
    const resListFriendsV2 = await apolloClient.query({
      ...options,
      query: listFriendsV2,
      variables: { listFriendsInput: { userId } },
    });
    const result = resListFriendsV2.data.listFriendsV2;
    return map(result, (item) => {
      const friendData = item.sender.id === userId ? item.receiver : item.sender;
      return {
        requestId: item.id,
        friendUpdateAt: item.updatedAt,
        id: friendData.id,
        username: friendData.username,
        avatar: friendData.avatar,
        xp: friendData.xp,
      };
    });
  };

  /**
   * To create a new feedback
   */
  static createFeedback = async (createFeedbackInput: CreateFeedbackInput) => {
    return apolloClient.mutate({
      mutation: createFeedback,
      variables: { createFeedbackInput },
    });
  };

  /**
   * To get profile of a user
   */
  static getUserProfile = async (userId: string, options = {}): Promise<IUserProfile> => {
    const res = await apolloClient.query({
      ...options,
      query: getUserProfile,
      variables: { userProfileInput: { userId } },
    });

    return res.data.getUserProfile;
  };

  /**
   * To get data of the user include appStats
   */
  static getUserV2 = async (options = {}): Promise<IUserData> => {
    const res = await apolloClient.query({
      ...options,
      query: getUserV2,
      variables: {
        isSubscription: THEME?.name !== ThemeName.Onmo,
      },
    });

    if (res.errors?.[0]?.message === "Onmo is currently down for maintenance") {
      throw res;
    }
    if (!res?.data?.getUserV2) {
      throw new Error("User not found!");
    }
    const userDataV2: any = { ...res.data.getUserV2 };
    const flattenUser = { ...userDataV2.user, ...userDataV2, username: userDataV2.user.username.slice(0, 17) };
    const userWallets = getUserWallets(flattenUser.billingCurrency ?? CURRENCY.INR, flattenUser.wallets);
    flattenUser.virtualCoins = userWallets.getVirtualCoins() || 0;
    flattenUser.winningsCash = userWallets.getWinningsCash() || 0;
    flattenUser.depositCash = userWallets.getDepositCash() || 0;
    flattenUser.bonusCash = userWallets.getBonusCash() || 0;
    flattenUser.totalCash = flattenUser.winningsCash + flattenUser.depositCash + flattenUser.bonusCash;

    return flattenUser;
  };

  /**
   * To get data of a user
   */
  static getUser = async (userId: string): Promise<IUserBaseData> => {
    const res = await apolloClient.query({
      query: getUser,
      variables: { userProfileInput: { userId } },
    });
    return res.data.getUserProfile.user;
  };

  /**
   * To get top 100 most recent activities of a user
   */
  static getTop100MostRecentActivitiesByUserId = async (
    userId: string,
    options: Partial<QueryOptions> = {}
  ): Promise<IBaseActivity[]> => {
    const res = await apolloClient.query({
      ...options,
      query: getTop100MostRecentActivitiesByUserId,
      variables: { getUserActivitiesInput: { userId } },
    });
    return res.data.listUserActivities;
  };

  /**
   * To update data of the user are using app
   */
  static updateUser = async (params: UpdateUserV2Input) => {
    await apolloClient.mutate({
      mutation: updateUserV2,
      variables: { updateUserV2Input: params },
    });
  };

  static updateUserSubscriptions = async (pushSubscription: string, setIsMaintenance: Function) => {
    const params = {
      pushSubscription,
    };
    await apolloClient.mutate({
      mutation: updateUserV2,
      variables: { updateUserV2Input: params },
    });
    try {
      const userDataV2 = await Users.getUserV2({ fetchPolicy: "network-only" });
      return userDataV2;
    } catch (e: any) {
      if (e.errors?.[0]?.message === "Onmo is currently down for maintenance") {
        setIsMaintenance(true);
      } else {
        console.error(`Fail to get data of user`, e);
      }
      return null;
    }
  };

  /**
   * To lock currency after user choice
   */
  static lockCurrency = async (params: LockCurrencyInput) => {
    await apolloClient.mutate({
      mutation: lockCurrency,
      variables: { lockCurrencyInput: params },
    });
  };

  /**
   * To delete the user are using app
   */
  static deleteUser = async (): Promise<string> => {
    const res = await apolloClient.mutate({
      mutation: deleteUserV2,
      variables: {},
    });
    return res.data.deleteUserV2.status;
  };

  /**
   * To send OTP code to user
   */
  static sendOtpCode = async (phoneNotVerified: string): Promise<string> => {
    const res = await apolloClient.mutate({
      mutation: sendOtpCode,
      variables: { sendOtpCodeInput: { phoneNotVerified } },
    });

    return res.data.sendOtpCode.status;
  };

  static updateLike = async (input: UpdateLikeInput) => {
    const res = await apolloClient.mutate({
      mutation: updateLike,
      variables: { updateLikeInput: input },
    });

    return res.data.updateLike.status;
  };

  static updateUserProfileCache = async (userProfile: any) => {
    const { id, user } = userProfile;

    await apolloClient.writeFragment({
      id: `UserProfile:${id}`,
      fragment: gql`
        fragment UserProfile on User {
          id
          user
        }
      `,
      data: {
        user,
      },
    });
  };
  /**
   * To get all transactions by user id and currency
   */
  static listUserTransactions = async (
    currency: string,
    options = {},
    userId?: string
  ): Promise<IBaseTransaction[]> => {
    const res = await apolloClient.query({
      ...options,
      query: listUserTransactions,
      variables: { listTransactionsInput: userId ? { currency, userId } : { currency } },
    });
    return res.data.listUserTransactions;
  };

  /**
   * To find a user by username
   */
  static findUser = async (username: String, userId: string): Promise<IUserBaseData[]> => {
    const res = await apolloClient.query({
      query: findUser,
      variables: { findUserInput: { term: username } },
    });

    return filter(res.data.findUser, (user) => user.id !== userId);
  };

  /**
   * To get the user crypto deposit address
   */
  static getCryptoDepositAddress = async () => {
    const res = await apolloClient.query({
      query: getCryptoDepositAddress,
    });
    return res.data.getCryptoDepositAddress;
  };

  /**
   * Get an estimation of a transaction fee for a certain crypto currency
   */
  static estimateCryptoTransactionFee = async (amount: number, destination: string) => {
    const res = await apolloClient.query({
      query: estimateCryptoTransactionFee,
      variables: { estimateCryptoTransactionFeeInput: { amount: amount, transactionRecipient: destination } },
    });

    return res.data.estimateCryptoTransactionFee;
  };

  /**
   * Get a user withdrawal using by its id
   */
  static getWithdrawal = async (withdrawalId: string) => {
    const res = await apolloClient.query({
      query: getWithdrawal,
      variables: { getWithdrawalInput: { withdrawalId } },
    });

    return res.data.getWithdrawal;
  };

  /**
   * Get a user deposit using by its id
   */
  static getDeposit = async (depositId: string) => {
    const res = await apolloClient.query({
      query: getDeposit,
      variables: { getDepositInput: { depositId } },
    });

    return res.data.getDeposit;
  };
  /**
   * Check in user
   */
  static checkinUser = async () => {
    const partner = localStorage.getItem("partner") || process.env.REACT_APP_ENV || "dev";
    const urlParams = new URLSearchParams(window.location.search);

    const userAgent = JSON.stringify(window.navigator.userAgent);
    const utmMedium = JSON.stringify(urlParams.get("utm_medium"));
    const utmSource = JSON.stringify(urlParams.get("utm_source"));
    const utmCampaign = JSON.stringify(urlParams.get("utm_campaign"));
    
    const res = await apolloClient.mutate({
      mutation: checkinUser,
      variables: { input: { partner, userAgent, utmSource, utmMedium, utmCampaign } },
    });

    return res.data.checkinUser;
  };

  /**
   * To get config
   */
  static getConfig = async () => {
    const res = await apolloClient.query({
      query: getConfig,
    });

    return res.data.getConfig;
  };
}
