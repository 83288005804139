import { createUseStyles } from "react-jss";
import { ITheme } from "../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  root: {
    position: "fixed",
    top: "0",
    left: 0,
    zIndex: 9999,
    width: "100%",
    "& > * + *": {
      top: 6,
    },
    "& > .MuiAlert-filledSuccess": {
      display: "block",
      maxHeight: 40,
      minHeight: 40,
      color: "#fff",
      backgroundColor: "#45A677",
      fontSize: 14,
      fontWeight: 500,
      lineHeight: "18px",
      letterSpacing: -0.2,
      textAlign: "center",
      borderRadius: "unset",
      "& > .MuiAlert-icon": {
        float: "left",
        padding: 0,
        margin: 0,
        height: 28,
      },
      "& > .MuiAlert-message": {
        padding: 0,
        lineHeight: "26px",
      },
    },
  },
  alert: {
    justifyContent: "center",
  },
  success: {
    background: "#00783E",
  },
  warning: {
    background: "#EF7925",
  },
}));
