import { EMPTY_USER } from "../../modelsv2/users";
import { ACTIONS } from "./actions";

export default (state: any, action: any) => {
  const {
    user,
    userData,
    pushModalData,
    likedGameStatus,
    simulationWS,
    userTransactions,
    hasNewNotification,
    isWsClientConnected,
    userWallets,
  } = action;

  switch (action.type) {
    case ACTIONS.LOGIN:
      return {
        ...state,
        user,
      };
    case ACTIONS.LOGOUT:
      return {
        ...state,
        user: null,
        userData: EMPTY_USER,
      };
    case ACTIONS.CONFIRMLOGIN:
      return {
        ...state,
        user,
      };
    case ACTIONS.DENYLOGIN:
      return {
        ...state,
        user: null,
        isAuthenticating: false,
      };
    case ACTIONS.SET_USER_DATA:
      return {
        ...state,
        userData,
        isAuthenticating: false,
      };
    case ACTIONS.SET_PUSH_MODAL_DATA:
      return {
        ...state,
        pushModalData,
        isAuthenticating: false,
      };
    case ACTIONS.UPDATE_LIKED_GAME_STATUS:
      return {
        ...state,
        likedGameStatus,
      };
    case ACTIONS.UPDATE_WEBSOCKET_OF_SIMULATION:
      return {
        ...state,
        simulationWS,
      };
    case ACTIONS.UPDATE_USER_TRANSACTIONS:
      return {
        ...state,
        userTransactions,
      };
    case ACTIONS.UPDATE_USER_HAS_NEW_NOTIFICATION:
      return {
        ...state,
        hasNewNotification,
      };
    case ACTIONS.UPDATE_IS_WS_CLIENT_CONNECTED:
      return {
        ...state,
        isWsClientConnected,
      };
    case ACTIONS.UPDATE_WALLETS:
      return {
        ...state,
        userWallets,
      };
    default:
      return state;
  }
};
