import React, { useContext } from "react";
import clsx from "classnames";
import { isNull, filter } from "lodash-es";
import { GameType } from "../../../types/enums";
import { useHistory } from "react-router-dom";
import { Header } from "../Header";
import { useTranslation } from "react-i18next";
import { IBaseGame } from "../../../types/baseInterfaces";
import { Grid, CircularProgress } from "../../Common/ComponentExport";
import { Analytics } from "../../../modelsv2/analytics";
import { CATEGORY } from "../../../constants/analyticsEvent";
import { useStyles } from "./ListGame.style";
import { UserCtx } from "../../../context/user/state";
import { GameItemCard } from "../../Common/GameCard/GameItemCard";

interface IPropsListGame {
  listGame?: IBaseGame[] | null;
}

export const ListGame = (props: IPropsListGame) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { userData } = useContext(UserCtx);
  const { listGame } = props;

  const onBack = () => {
    Analytics.trackEvent({
      category: CATEGORY.GAME_LISTING_PAGE,
      action: "back",
      user_uid: userData?.id,
    });
    history.push("/app");
  };

  return (
    <div>
      <Header className={clsx(classes.header, "stickyHeader")} actionLeft={onBack} textCenter={t("common_Top Games")} />
      {isNull(listGame) ? (
        <div className={classes.mainLoading}>
          <CircularProgress size={40} />
        </div>
      ) : (
        <Grid item xs={12} md={11} lg={10} className={classes.mainListGame}>
          <Grid container>
            {filter(listGame, { type: GameType.HTML })?.map((game: any, idx: number) => (
              <GameItemCard game={game} key={idx} isChingari isDiscovery={false} />
            ))}
          </Grid>
        </Grid>
      )}
    </div>
  );
};
