import React, { useContext } from "react";
import { Header } from "../Header";
import clsx from "classnames";
import Logo from "../../../assets/GuestMode/OnmoWhite.svg";
import BannerIcon from "../Home/assets/banner.jpg";
import { Grid } from "../../Common/ComponentExport";
import { ListTopGames } from "../ListTopGames";
import { ListBeatIt } from "../ListBeatIt";
import { Analytics } from "../../../modelsv2/analytics";
import { CATEGORY } from "../../../constants/analyticsEvent";
import { UserCtx } from "../../../context/user/state";
import { useStyles } from "../Home/Home.style";
import { fakeListBeatIt, fakeListGame } from "./constants";
import { BottomNavigation } from "../BottomNavigation";

export const GuestHome = () => {
  const classes = useStyles();
  const { userData } = useContext(UserCtx);

  const onBack = () => {
    window.close();
  };

  const onNavigateOnmo = () => {
    Analytics.trackEvent({
      category: CATEGORY.DISCOVER_PAGE,
      action: "header_banner",
      label: "click",
      user_uid: userData?.id,
    });
    window.open(process.env.REACT_APP_URL_ONMO || "https://play.onmo.com/", "_blank");
  };

  return (
    <BottomNavigation>
      <Grid container onClick={onNavigateOnmo}>
        <Header className={clsx(classes.header, "stickyHeader")} actionLeft={onBack} iconCenter={Logo} />
        <Grid item xs={12} md={11} lg={10} className={classes.content}>
          <img src={BannerIcon} alt="banner" className={classes.banner} onClick={onNavigateOnmo} />
          <div className={classes.main}>
            <ListTopGames topGames={fakeListGame as any} />
            <ListBeatIt beatIts={fakeListBeatIt as any} />
          </div>
        </Grid>
      </Grid>
    </BottomNavigation>
  );
};
