import "./App.css";
import "./i18n";
import * as serviceWorker from "./serviceWorker";
import React, { Suspense, useEffect, useState } from "react";
import Routes from "./Routes";
import TagManager from "react-gtm-module";
import { useHistory } from "react-router-dom";
import { closeSupportChat } from "./FreshChatHelper";
import { OnmoLoading } from "./components/OnmoLoading";
import { requestFullScreen } from "./utils/requestFullScreen";
import { saveRedirectUrl, redirectOnVisibilityChange } from "./utils/redirect";
import { PushModal } from "./components/PushNotification";
import { deleteOldCache } from "./utils/serviceWorker";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || "",
  dataLayer: {
    environment: process.env.REACT_APP_GTM_ENV,
  },
};

TagManager.initialize(tagManagerArgs);

function App() {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    serviceWorker.register({
      onUpdate: () => setOpen(true),
    });
  }, []);

  useEffect(saveRedirectUrl, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", redirectOnVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", redirectOnVisibilityChange);
    };
  }, []);

  const onListenHistory = () => {
    history.listen((location: any) => {
      closeSupportChat();
    });
  };

  useEffect(onListenHistory, []);

  useEffect(() => {
    deleteOldCache().then((isOldApp) => {
      if (isOldApp) {
        window.location.reload();
      } else {
        console.log(`Version ${process.env.REACT_APP_BUILD} is the latest`);
      }
    });
  }, []);

  return (
    <Suspense fallback={<OnmoLoading />}>
      <div className="App" onClick={requestFullScreen}>
        <Routes open={open} setOpen={setOpen} />
      </div>
      <PushModal />
    </Suspense>
  );
}

export default App;
