import { endHtmlGameSession, enterSoloMoment, playMoment, playSoloMoment } from "../apollo/mutations/gameSessions";
import { getGameSessionV2 } from "../apollo/queries/gameSessions";
import { EndHtmlGameSessionInput, UserDevice } from "../graphql/API";
import { IBaseGameSession } from "../types/baseInterfaces";
import apollo from "../clients/apollo";

interface ReplayMomentInput {
  gameSessionId: string;
  device: UserDevice;
}

interface PlayMomentInput {
  gameId: string;
  momentId: string;
  sessionType: string;
  device: UserDevice;
}

const apolloClient = apollo.getInstance();

export class GameSessions {
  /**
   * To end a html game session
   */
  static endHtmlGameSession = async (input: EndHtmlGameSessionInput) => {
    await apolloClient.mutate({
      mutation: endHtmlGameSession,
      variables: {
        endHtmlGameSessionInput: { ...input, failureMessage: input.failureMessage || "" },
      },
    });
  };

  /**
   * To get data of a game session
   */
  static getGameSession = async (gameSessionId: string, options: any = {}): Promise<IBaseGameSession> => {
    const response = await apolloClient.query({
      ...options,
      query: getGameSessionV2,
      variables: { getGameSessionInput: { gameSessionId } },
    });
    return response.data.getGameSessionV2;
  };

  // /**
  //  * To get event when list of live game sesisons has updated
  //  */
  // static subscribeOnUpdateGameSessionList = async (callback: Function) => {
  //   const onUpdate: any = await apolloClient.query({ query: onUpdateGameSession, variables: {} });
  //   return onUpdate.subscribe({
  //     next: () => {
  //       callback({ forceCall: true });
  //     },
  //   });
  // };

  // /**
  //  * To get event when a game session has updated
  //  */
  // static subscribeOnUpdateGameSession = async (callback: Function, id: string) => {
  //   const onUpdate: any = await apolloClient.query({ query: onGameSessionUpdate, variables: { id } });

  //   return onUpdate.subscribe({
  //     next: (res: any) => {
  //       const gameSessionUpdated = res.value.data.onGameSessionUpdate;
  //       callback(gameSessionUpdated);
  //     },
  //   });
  // };

  // /**
  //  * To get event when a game session has created
  //  */
  // static subscribeOnCreateGameSession = async (callback: Function) => {
  //   const onCreate: any = await apolloClient.query({ query: onCreateGameSession, variables: {} });
  //   return onCreate.subscribe({
  //     next: () => {
  //       callback({ forceCall: true });
  //     },
  //   });
  // };

  // /**
  //  * To get event when a game session has deleted
  //  */
  // static subscribeOnDeleteGameSession = async (callback: Function) => {
  //   const onDelete: any = await apolloClient.query({ query: onDeleteGameSession, variables: {} });
  //   return onDelete.subscribe({
  //     next: () => {
  //       callback({ forceCall: true });
  //     },
  //   });
  // };

  static playMoment = async (params: PlayMomentInput | ReplayMomentInput) => {
    const response: any = await apolloClient.mutate({ mutation: playMoment, variables: { playMomentInput: params } });
    return response.data.playMoment;
  };

  static playSoloMoment = async (gameSessionId: string) => {
    const response: any = await apolloClient.mutate({
      mutation: playSoloMoment,
      variables: { playSoloMomentInput: { gameSessionId: gameSessionId } },
    });
    return response.data.playSoloMoment;
  };

  static enterSoloMoment = async (
    params: PlayMomentInput | ReplayMomentInput
  ): Promise<{ gameSessionId: string; edgeNodeId: string }> => {
    const response: any = await apolloClient.mutate({
      mutation: enterSoloMoment,
      variables: { enterSoloMomentInput: params },
    });
    return response.data.enterSoloMoment;
  };
}
