import { createUseStyles } from "react-jss";
import { ITheme } from "../../../../types/theme";

// @ts-ignore
export const useStyles = createUseStyles((theme: ITheme) => ({
  container: {
    maxWidth: 560,
    padding: 20,
    "@media(max-height: 568px)": {
      marginTop: 14,
    },
    "@media(min-height: 569px) and (max-height: 667px)": {
      marginTop: 16,
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      marginTop: 18,
    },
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  resetPwdDiv: {
    display: "flex",
    alignItems: "baseline",
  },
  logoGrid: {
    margin: "auto",
    paddingTop: 51,
    paddingBottom: 51,
    "@media(max-height: 568px)": {
      paddingTop: 35.7,
      paddingBottom: 35.7,
    },
    "@media(min-height: 569px) and (max-height: 667px)": {
      paddingTop: 40.8,
      paddingBottom: 40.8,
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      paddingTop: 45.9,
      paddingBottom: 45.9,
    },
  },
  passwordGrid: {
    paddingTop: 24,
    "@media(max-height: 568px)": {
      paddingTop: 16.8,
    },
    "@media(min-height: 569px) and (max-height: 667px)": {
      paddingTop: 19.2,
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      paddingTop: 21.6,
    },
  },
  signInGrid: {
    paddingTop: 72,
    paddingBottom: 24,
    "@media(max-height: 568px)": {
      paddingTop: 50.4,
      paddingBottom: 16.8,
    },
    "@media(min-height: 569px) and (max-height: 667px)": {
      paddingTop: 57.6,
      paddingBottom: 19.2,
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      paddingTop: 64.8,
      paddingBottom: 21.6,
    },
  },
  forgotPasswordText: {
    "@media(min-height: 569px) and (max-height: 667px)": {
      fontSize: 12,
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      fontSize: 13,
    },
  },
  signUpText: {
    fontSize: 15,
    color: "#8a8a8e",
    display: "flex",
    justifyContent: "center",
    marginTop: 50,
  },
  textCenter: {
    textAlign: "center",
  },
  title: {
    textAlign: "center",
  },
  rule: {
    color: `${theme.palette.secondary.main} !important`,
  },
  togglePass: {
    fontSize: 10,
    fontWeight: 500,
    color: "#9B9BA3",
    textTransform: "uppercase",
    "&.enable": {
      color: theme.palette.primary.main,
    },
  },
}));
