import React, { useEffect } from "react";
import { useStyles } from "./InAppNotificationDeposit.style";
import btcDepositIcon from "../../../assets/notification_icons/btc_deposit_icon.svg";
import rupeeDepositIcon from "../../../assets/notification_icons/rupee_deposit_icon.svg";
import closeIcon from "../../../assets/notification_icons/close.svg";
import { IInAppNotificationBody } from "../InAppNotification";
import { useTranslation } from "react-i18next";

interface IInAppNotificationDepositProps extends IInAppNotificationBody {
  currency: string;
  value: string;
}

export function InAppNotificationDeposit({
  onCloseHandler,
  currency,
  value,
  children,
}: IInAppNotificationDepositProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    const onCloseTimeoutId = setTimeout(onCloseHandler, 5000);
    return () => {
      clearTimeout(onCloseTimeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    onCloseHandler();
  };

  const getCurrencyIconPath = (currency: string): string => {
    if (currency.toUpperCase() === "BTC") {
      return btcDepositIcon;
    }
    return rupeeDepositIcon;
  };

  return (
    <div className={classes.root} data-testid="in-app-notification-deposit">
      <img
        src={getCurrencyIconPath(currency)}
        alt="Deposit Icon"
        data-testid="in-app-notification-deposit-icon"
        className={classes.img}
      />
      <div className={classes.content} data-testid="in-app-notification-deposit-content">
        <span>
          {value} {t("wallet_Deposit")}
        </span>
        <span className={classes.highlight}>{children}</span>
      </div>
      <div className={classes.close}>
        <a href="#close" onClick={onCloseClick} data-testid="in-app-notification-deposit-close-button">
          <img src={closeIcon} alt="Close" />
        </a>
      </div>
    </div>
  );
}

export default InAppNotificationDeposit;
