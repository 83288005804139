import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  content: {
    margin: "auto",
    background: "#260E2E",
    paddingBottom: 65,
  },
  header: {
    background: "#260E2E",
  },
  banner: {
    height: 186,
    width: "100%",
    objectFit: "contain",
  },
  mainLoading: {
    height: "calc(100vh - 118px)",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  main: {
    padding: 15,
  },
}));
