import React from "react";
import { CURRENCY } from "../apollo/constants";
import AppCoin from "../components/Common/AppCoin/AppCoin";
import { ReactComponent as INRIcon } from "../assets/store/icon-cash.svg";
import { ReactComponent as BTCIcon } from "../assets/store/btcIcon.svg";
import { Transaction_Types } from "../types/enums";

export const renderCurrencyText = (currency?: string) => {
  switch (currency) {
    case CURRENCY.INR:
      return "Cash";
    case CURRENCY.BTC:
      return "Bitcoin";
    case CURRENCY.XXX:
      return "Coin";
  }
};

export const renderCurrencyOnlyIcon = (currency: string) => {
  switch (currency) {
    case CURRENCY.INR:
      return <INRIcon />;
    case CURRENCY.BTC:
      return <BTCIcon />;
    case CURRENCY.XXX:
      return <AppCoin />;
  }
};

export const convertCurrency = (cost?: number, currency?: string) => {
  if (!cost) return 0;
  const totalCost = currency === CURRENCY.BTC ? cost * 1000000 : cost;
  if (totalCost >= 1000000) {
    return `${(totalCost / 1000000).toFixed(1)}m`;
  } else if (totalCost >= 10000) {
    return `${(totalCost / 1000).toFixed(1)}k`;
  } else {
    return Math.round(totalCost);
  }
};

export const renderPrize = (currency?: string, cost?: number) => {
  const totalCost = convertCurrency(cost, currency);
  switch (currency) {
    case CURRENCY.INR:
      return `₹${totalCost}`;
    case CURRENCY.BTC:
      return `${totalCost}μ`;
    case CURRENCY.XXX:
      return (
        <>
          <AppCoin />
          {totalCost}
        </>
      );
  }
};
export const renderCurrencyIcon = (currency?: string, cost?: number, iconStyle?: string, isShowCoin = true) => {
  const totalCost = convertCurrency(cost, currency);
  switch (currency) {
    case CURRENCY.INR:
      return `₹${totalCost}`;
    case CURRENCY.BTC:
      return (
        <>
          {totalCost} <span className={iconStyle}>μBTC</span>
        </>
      );
    case CURRENCY.XXX:
      return (
        <>
          {isShowCoin && <AppCoin />}
          {totalCost}
        </>
      );
  }
};

export const renderCurrencyTransaction = (currency: string, cost?: number, iconStyle?: string, type?: string) => {
  if (cost === undefined) return;
  switch (currency) {
    case CURRENCY.INR: {
      const costRounded = parseFloat(cost.toFixed(1));
      if (type === Transaction_Types.debit) {
        return `-₹${costRounded}`;
      } else return costRounded >= 0 ? `+ ₹${costRounded}` : `- ₹${-costRounded}`;
    }
    case CURRENCY.BTC: {
      if (type === Transaction_Types.debit)
        return (
          <>
            - {convertCurrency(Math.abs(cost), currency)}
            <span className={iconStyle}>μBTC</span>
          </>
        );
      return cost >= 0 ? (
        <>
          + {convertCurrency(cost, currency)}
          <span className={iconStyle}>μBTC</span>
        </>
      ) : (
        <>
          - {convertCurrency(-cost, currency)}
          <span className={iconStyle}>μBTC</span>
        </>
      );
    }
  }
};
