import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  wrapper: {
    // background: "#260E2E",
  },
  header: {
    background: "#260E2E",
    "& > p": {
      color: theme.palette.primary.contrastText,
      fontSize: 16,
      fontWeight: 500,
    },
  },
  content: {
    margin: "auto",
    background: "#260E2E",
    marginTop: -1, // Check again
    minHeight: "calc(100vh - 56px)",
  },
  mainHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: theme.palette.primary.contrastText,
    padding: "15px 0px",
    "& > div": {
      height: 128,
      width: 124,
      border: `3px solid ${theme.palette.background.default}`,
    },
    "& > p": {
      fontSize: 20,
      fontWeight: 700,
      marginTop: 10,
    },
  },
  mainLoading: {
    height: "calc(100vh - 56px)",
    background: theme.palette.background.default,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
