import React, { useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { END_SCREEN } from "../../apollo/subscriptions/gameSession";

export const EndScreenSubscription = () => {
  const [endScreenResponse, setEndScreenResponse] = React.useState<any>(null);

  const client = useApolloClient();

  useEffect(() => {
    const observer = client.subscribe({
      query: END_SCREEN,
    });

    const subscription = observer.subscribe((data: any) => {
      setEndScreenResponse(data.data);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return <div />;
};
