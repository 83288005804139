import React, { useEffect, useContext, useState } from "react";
import { UserProfile as UserProfileComponent } from "../../components/Chingari/UserProfile";
import { UserCtx } from "../../context/user/state";
import { IUserProfile } from "../../types/baseInterfaces";
import { Users } from "../../modelsv2/users";
import { Games } from "../../modelsv2/games";
import { useHistory } from "react-router-dom";

const UserProfile = () => {
  const history: any = useHistory();
  const { userData } = useContext(UserCtx);
  const [userProfile, setUserProfile] = useState<IUserProfile | null>(null);
  const [mostPlayedGames, setMostPlayedGames] = useState<any | null>(null);
  const isFetchCacheFirst = history.action === "POP";

  const getUserProfile = async (options = {}) => {
    try {
      const userProfile = await Users.getUserProfile(userData?.id, options);
      setUserProfile(userProfile);
    } catch (e) {
      console.log(e);
    }
  };

  const getTopGame = async (fetchPolicy = {}) => {
    try {
      const topGames = await Games.getUserMostPlayedGames(userData?.id);
      setMostPlayedGames(topGames);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (userData?.id) {
      getUserProfile();
      getTopGame({ fetchPolicy: isFetchCacheFirst ? "cache-first" : "network-only" });
    }
  }, [userData]);

  return (
    <UserProfileComponent userProfile={userProfile} mostPlayedGames={mostPlayedGames} getUserProfile={getUserProfile} />
  );
};

export default UserProfile;
