import React from "react";
import clsx from "classnames";
import { useStyles } from "./Header.style";
import Back from "../../../assets/Chingari/Back.svg";
import { Typography, Grid, CircularProgress } from "../../Common/ComponentExport";

interface IPropsHeader {
  className?: string;
  textLeft?: string;
  actionLeft: Function;
  iconLeft?: any;
  textCenter?: string;
  iconCenter?: any;
  actionCenter?: Function;
  textRight?: string;
  isUnderlineTextRight?: boolean;
  isDisableTextRight?: boolean;
  actionRight?: Function;
  isLoadingRight?: boolean;
  iconRight?: any;
}

export const Header = (props: IPropsHeader) => {
  const classes = useStyles();
  const {
    className,
    textLeft,
    actionLeft,
    iconLeft,
    iconCenter,
    textCenter,
    actionCenter,
    textRight,
    isUnderlineTextRight,
    isDisableTextRight,
    actionRight,
    isLoadingRight,
    iconRight,
  } = props;

  const onActionCenter = () => {
    if (!actionCenter) return;
    actionCenter();
  };

  const onClickRight = () => {
    if (!actionRight || isDisableTextRight) return;
    actionRight();
  };

  return (
    <Grid item xs={12} md={11} lg={10} className={[className, classes.wrapper]}>
      <Grid item xs={3} onClick={() => actionLeft()} dataTestId="action-left" className={classes.leftIcon}>
        {textLeft ? (
          <Typography className={classes.textLeft}>{textLeft}</Typography>
        ) : (
          <img src={iconLeft ? iconLeft : Back} alt="back" />
        )}
      </Grid>
      {iconCenter ? (
        <img src={iconCenter} alt="icon-center" onClick={onActionCenter} />
      ) : (
        <Typography>{textCenter}</Typography>
      )}
      {textRight && (
        <>
          {!isLoadingRight ? (
            <Typography
              variant="h5"
              className={clsx(
                classes.textRight,
                isUnderlineTextRight && classes.textUnderline,
                isDisableTextRight && classes.disableTextRight
              )}
              onClick={onClickRight}>
              {textRight}
            </Typography>
          ) : (
            <CircularProgress size={20} className={classes.textRight} />
          )}
        </>
      )}
      {iconRight && (
        <>
          {!isLoadingRight ? (
            <img
              className={classes.textRight}
              src={iconRight}
              data-testid="icon-right"
              alt="icon-right"
              onClick={onClickRight}
            />
          ) : (
            <CircularProgress size={20} className={classes.textRight} />
          )}
        </>
      )}
    </Grid>
  );
};
