export {
  calSlideWidth,
  getListAvatar,
  getListBanner,
  isFriend,
  isFriendRequest,
  hasActivity,
  isMe,
  isSentRequest,
  isSentRequestByUserId,
  AVATAR_COUNT,
  BANNER_COUNT,
} from "./helper";
