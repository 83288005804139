import { sample } from "lodash-es";
import { isAndroid } from "react-device-detect";

export async function shareWithoutMarketingImage(data: any, setIsShare?: Function) {
  if (isAndroid && document?.fullscreenElement) {
    document?.exitFullscreen();
  }

  const navigator: any = window.navigator;
  if (!navigator.share) {
    alert("Unsupported share feature");
    return;
  }

  try {
    localStorage.setItem("shareTo", "nativeShare");
    setIsShare && setIsShare(true);
    data.files = [];

    await navigator.share(data);

    if (isAndroid) {
      document.documentElement.requestFullscreen();
    }
  } catch (e) {
    localStorage.removeItem("shareTo");
    localStorage.removeItem("inviteCode");
    localStorage.removeItem("matchToRedirect");
    alert("Unsupported share feature");
  }
}

export default async function share(
  data: any,
  userId: string,
  setIsShare?: Function,
  onSuccess: () => void = () => console.log("Successfully shared"),
  onError: (reason: any) => void = (error: any) => console.error("Unsupported share feature", error)
) {
  const APP_URL = `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ""
  }`;

  if (isAndroid && document?.fullscreenElement) {
    document?.exitFullscreen();
  }

  const filesArray: any = [];
  const marketingImage: any = sample([`${APP_URL}/marketing/marketing-1.jpg`, `${APP_URL}/marketing-2.jpg`]);
  let response: any = await fetch(marketingImage);
  response = await response.blob();
  const file = new File([response], "onMo.jpeg", { type: response.type });
  filesArray.push(file);

  try {
    localStorage.setItem("shareTo", "nativeShare");
    setIsShare && setIsShare(true);
    let navigator: any = window.navigator;
    data.files = filesArray;
    navigator.share(data).then(() => {
      console.log("Successfully shared");
      if (isAndroid) {
        document.documentElement.requestFullscreen();
      }
    });
  } catch (e) {
    localStorage.removeItem("shareTo");
    localStorage.removeItem("inviteCode");
    localStorage.removeItem("matchToRedirect");
    alert("Unsupported share feature");
  }
}
