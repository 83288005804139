import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash-es";
import clsx from "classnames";
import { Auth } from "@aws-amplify/auth";
import { useHistory } from "react-router-dom";
import { AlertCtx } from "../../../context/alert/state";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { Grid, Button, CircularProgress } from "../../Common/ComponentExport";
import { ReactComponent as FacebookIcon } from "../../../assets/GuestMode/facebook_white.svg";
import { ReactComponent as GoogleIcon } from "../../../assets/GuestMode/Google.svg";
import { ReactComponent as MobileIcon } from "../../../assets/GuestMode/mobile.svg";
import { ReactComponent as AppleIcon } from "../../../assets/GuestMode/Apple.svg";
import { Analytics } from "../../../modelsv2/analytics";
import { CATEGORY } from "../../../constants/analyticsEvent";
import { useStyles } from "./LoginMethods.style";

export const LoginMethods = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { setNotificationError } = useContext(AlertCtx);
  const [formLoading, setFormLoading] = useState("");

  const handleSignIn = async (provider: string) => {
    try {
      if (!isEmpty(formLoading)) return;
      setFormLoading(provider);
      Analytics.trackEvent({
        category: CATEGORY.SIGNUP,
        action: "start",
        label: provider,
        user_uid: "",
      });
      localStorage.setItem("login_method", provider);
      localStorage.setItem("partner", "chingari");
      if (provider === "Facebook" || provider === "Google") {
        await Auth.federatedSignIn({ provider: provider as CognitoHostedUIIdentityProvider });
      } else if (provider === "phone") {
        history.push("/authenticate/login/phone-signin");
      } else {
        history.push("/authenticate/login/email-signin");
      }
      setFormLoading("");
    } catch (e: any) {
      setNotificationError(e?.message);
      history.push("/authenticate/chingari");
    }
  };

  return (
    <div>
      <Grid container spacing={2}>
        {process.env.REACT_APP_LOGIN_FB === "true" && (
          <Grid item xs={12} md={6} lg={6}>
            <Button
              variant="contained"
              fullWidth
              data-testid="signInFacebook"
              startIcon={formLoading !== "Facebook" && <FacebookIcon />}
              onClick={() => handleSignIn("Facebook")}
              className={clsx(classes.btnFacebook, "btn-fb-login")}>
              {formLoading === "Facebook" ? <CircularProgress size={25} /> : t("login_Connect with Facebook")}
            </Button>
          </Grid>
        )}
        {process.env.REACT_APP_LOGIN_PHONE === "true" && (
          <Grid item xs={12} md={6} lg={6}>
            <Button
              data-testid="signInPhone"
              variant="outlined"
              fullWidth
              startIcon={<MobileIcon />}
              onClick={() => handleSignIn("phone")}
              className={clsx(classes.btnGoogle, classes.btnPhone, "btn-phone-login")}>
              {t("login_Signup with phone number")}
            </Button>
          </Grid>
        )}
        {process.env.REACT_APP_LOGIN_GG === "true" && (
          <Grid item xs={12} md={6} lg={6}>
            <Button
              data-testid="signInGoogle"
              variant="contained"
              fullWidth
              startIcon={formLoading !== "Google" && <GoogleIcon />}
              onClick={() => handleSignIn("Google")}
              className={clsx(classes.btnGoogle, "btn-gg-login")}>
              {formLoading === "Google" ? <CircularProgress size={25} /> : t("login_Connect with Google")}
            </Button>
          </Grid>
        )}
        {process.env.REACT_APP_LOGIN_APPLE === "true" && (
          <Grid item xs={12} md={6} lg={6}>
            <Button
              data-testid="signInApple"
              variant="outlined"
              fullWidth
              startIcon={formLoading !== "Apple" && <AppleIcon />}
              onClick={() => handleSignIn("Apple")}
              className={clsx(classes.btnGoogle, classes.btnApple)}>
              {formLoading === "Apple" ? <CircularProgress size={25} /> : t("login_Connect with Apple")}
            </Button>
          </Grid>
        )}
        {process.env.REACT_APP_LOGIN_EMAIL === "true" && (
          <Grid item xs={12} md={6} lg={6}>
            <Button
              data-testid="signInEmail"
              variant="contained"
              fullWidth
              startIcon={<GoogleIcon />}
              onClick={() => handleSignIn("email")}
              className={clsx(classes.btnGoogle, "btn-email-login")}
              data-cy="sign-in-via-email">
              Email
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
