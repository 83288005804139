import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  wrapper: {
    background: "#2E2E2E",
    borderRadius: 20,
    padding: 15,
    "& > p": {
      fontSize: 14,
    },
  },
  infoBeatIt: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 15,
    "& > div:first-child": {
      height: 36,
      width: 36,
      border: `1px solid ${theme.palette.background.default}`,
    },
    "& > div:nth-child(2)": {
      margin: "0px 15px",
    },
    "& > div > h5": {
      color: theme.palette.primary.contrastText,
      textAlign: "left",
    },
    "& > div > h5:first-child": {
      fontWeight: 700,
    },
    "& > p": {
      fontSize: 36,
      fontWeight: 700,
    },
  },
  failScore: {
    fontSize: "16px !important",
  },
}));
