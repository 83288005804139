import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  root: {
    minWidth: 330,
    width: 330,
    padding: 20,
    boxSizing: "border-box",
    borderRadius: 15,
    textAlign: "center",
    overflow: "inherit",
  },
  title: {
    fontWeight: 700,
    fontSize: 18,
  },
  iconCongrat: {
    position: "absolute",
    height: 100,
    top: -50,
  },
  iconClose: {
    position: "absolute",
    right: 15,
    top: 15,
  },
  content: {
    marginTop: 40,
    overflow: "auto",
  },
  titleWon: {
    fontSize: 24,
    fontWeight: 700,
    color: "#F0AD00",
    margin: "10px 0px",
  },
  desc: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  contentMethod: {
    margin: "15px 0px 0px 0px !important",
    padding: 0,
    "& > div": {
      padding: "0px 0px 15px 0px !important",
      minWidth: "100%",
    },
    "& > div:nth-child(2) > button, & > div:last-child > button": {
      border: "2px solid #DCDCDD",
      background: "#FAFAFA",
    },
  },
  agreement: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 5,
  },
  link: {
    borderBottom: "1px solid",
  },
}));
