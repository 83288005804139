import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  sectionTitle: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 15,
    "& > h2, h4": {
      color: theme.palette.primary.contrastText,
    },
    "& > h2": {
      fontWeight: 600,
    },
  },
  contentTopGames: {
    overflowX: "scroll",
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "& > div": {
      display: "flex",
    },
  },
}));
