import React from "react";
import clsx from "classnames";
import { Avatar, Typography } from "../../Common/ComponentExport";
import { convertToScore } from "../../../utils/utils";
import { useStyles } from "./MissionCard.style";
import { MomentTranslation } from "../../Common/GameMomentTranslation/MomentTranslation";

export interface IPropsMissionCard {
  avatar?: string;
  name?: string;
  score?: number;
  momentId: string;
  momentType?: string;
}

export const MissionCard = (props: IPropsMissionCard) => {
  const classes = useStyles();
  const { avatar, name, score, momentId, momentType } = props;

  return (
    <div className={classes.wrapper}>
      <div className={classes.infoBeatIt}>
        <Avatar src={avatar} />
        <div>
          <Typography variant="h5">Score to beat</Typography>
          <Typography variant="h5">{name}</Typography>
        </div>
        <Typography className={clsx((score || 0) < 1 && classes.failScore)}>
          {convertToScore(score, momentType)}
        </Typography>
      </div>
      <Typography>
        Mission: <MomentTranslation momentId={momentId} description />
      </Typography>
    </div>
  );
};
