import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Games } from "../../modelsv2/games";
import { IBaseGameBattle } from "../../types/baseInterfaces";
import { PlayBeatIt as PlayBeatItComponent } from "../../components/Chingari/PlayBeatIt";
import { UserCtx } from "../../context/user/state";
import { isAdmin } from "../../utils/checkIsAdmin";

const PlayBeatIt = () => {
  const params = useParams<any>();
  const [game, setGame] = useState<IBaseGameBattle | null>(null);
  const { userData } = useContext(UserCtx);

  const fetchGame = async () => {
    if (!params?.gameId) return;
    try {
      const game = await Games.fetchGameData(params?.gameId, isAdmin(userData));
      setGame(game);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchGame();
  }, [params]);

  return <PlayBeatItComponent game={game} />;
};

export default PlayBeatIt;
