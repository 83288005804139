import React, { HTMLAttributes, FC, useState, useEffect } from "react";
import { HeaderOnboarding } from "../Common/HeaderOnboarding";
import { map } from "lodash-es";
import { FAKE_DATA_GAMES, TIMEOUT_HIDE_SLIDE_GUIDE } from "../../constants/game";
import { OnboardingCard } from "../Common/OnboardingCard";
import SwipeableViews from "react-swipeable-views";
import { OnboardingPlayGame } from "../Common/OnboardingPlayGame";
import { GameFactory } from "../../modelsv2/gameFactory";
import { OnmoLoading } from "../OnmoLoading";
import { Login } from "../Login";
import { useStyles } from "./Onboarding.style";
import { Analytics } from "../../modelsv2/analytics";
import { CATEGORY } from "../../constants/analyticsEvent";
import { SlideUpPopup } from "../Common/SlideUpPopup";
import { PopupOnboardingCongrat } from "../Common/PopupOnboardingCongrat";

export const Onboarding: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState(0);
  const [games, setGames] = useState(FAKE_DATA_GAMES);
  const [started, setStarted] = useState(false);
  const [loadedPercentage, setLoadedPercentage] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpenSlidePopup, setIsOpenSlidePopup] = useState(true);
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const onChangeIndex = (index: number) => {
    if (activeIndex < index) {
      Analytics.trackEvent({
        category: CATEGORY.ONBOARDING,
        action: "swipe_down",
        label: games[activeIndex]?.title,
        moment_type: "Battle",
        game_type: "HTML",
        currency: "Cash",
      });
    } else {
      Analytics.trackEvent({
        category: CATEGORY.ONBOARDING,
        action: "swipe_up",
        label: games[activeIndex]?.title,
        moment_type: "Battle",
        game_type: "HTML",
        currency: "Cash",
      });
    }
    if (index === 5) {
      Analytics.trackEvent({
        category: CATEGORY.ONBOARDING,
        action: "signup_page",
        label: "view",
        moment_type: "Battle",
        game_type: "HTML",
        currency: "Cash",
      });
    }
    setActiveIndex(index);
    setLoadedPercentage(0);
  };

  const onToggleLike = (gameId: string) => {
    Analytics.trackEvent({
      category: CATEGORY.ONBOARDING,
      action: "like_game",
      label: games[activeIndex]?.title,
      moment_type: "Battle",
      game_type: "HTML",
      currency: "Cash",
    });
    const gamesResult = GameFactory.updateLikeGames(gameId, games);
    setGames(gamesResult);
  };

  const getNbPlayed = () => {
    let nbPlayed = parseInt(localStorage.getItem("nb-played") || "0", 10);
    return (++nbPlayed).toString();
  };

  const onPlayGame = (title: string) => {
    Analytics.trackEvent({
      category: CATEGORY.ONBOARDING,
      action: "game_start",
      label: title,
      moment_type: "Battle",
      game_type: "HTML",
      currency: "Cash",
    });
    if (loadedPercentage < 100) return;
    const nbPlayed = localStorage.getItem("nb-played");
    if (nbPlayed && parseInt(nbPlayed, 10) >= 5) {
      onTogglePopup();
      return;
    }
    setStarted(true);
    localStorage.setItem("nb-played", getNbPlayed());
  };

  const onResetGame = () => {
    setStarted(false);
  };

  const onCloseSlidePopup = () => {
    setIsOpenSlidePopup(false);
  };

  const onTogglePopup = () => {
    if (!isOpenPopup) {
      Analytics.trackEvent({
        category: CATEGORY.ONBOARDING,
        action: "signup_start",
        label: games[activeIndex]?.title,
        moment_type: "Battle",
        game_type: "HTML",
        currency: "Cash",
      });
    } else {
      Analytics.trackEvent({
        category: CATEGORY.ONBOARDING,
        action: "signup_close",
        label: games[activeIndex]?.title,
        moment_type: "Battle",
        game_type: "HTML",
        currency: "Cash",
      });
    }

    setIsOpenPopup(!isOpenPopup);
  };

  useEffect(() => {
    if (activeIndex === FAKE_DATA_GAMES.length) return;
    Analytics.trackEvent({
      category: CATEGORY.ONBOARDING,
      action: "view_game",
      label: games[activeIndex]?.title,
      moment_type: "Battle",
      game_type: "HTML",
      currency: "Cash",
    });
  }, [games[activeIndex]]);

  useEffect(() => {
    const timeoutHide = setTimeout(() => {
      setIsOpenSlidePopup(false);
    }, TIMEOUT_HIDE_SLIDE_GUIDE);
    return () => {
      clearTimeout(timeoutHide);
    };
  }, []);

  return (
    <div {...props}>
      {started && !isLoaded && <OnmoLoading textLoading="Loading..." />}
      <HeaderOnboarding className={classes.header} onTogglePopup={onTogglePopup} />
      <SwipeableViews
        axis="y"
        animateHeight={true}
        className={classes.content}
        animateTransitions={true}
        onChangeIndex={onChangeIndex}>
        {map(games, (game, idx) => (
          <OnboardingCard
            game={game}
            idx={idx}
            activeIndex={activeIndex}
            onToggleLike={onToggleLike}
            onPlayGame={onPlayGame}
            loadedPercentage={loadedPercentage}
            isStarted={started}
          />
        ))}
        <div className={classes.loginPanel}>
          <Login isHideHeader />
        </div>
      </SwipeableViews>
      <OnboardingPlayGame
        game={games[activeIndex]}
        setLoadedPercentage={setLoadedPercentage}
        started={started}
        onResetGame={onResetGame}
        isLoaded={isLoaded}
        setIsLoaded={setIsLoaded}
      />
      <SlideUpPopup isOpen={isOpenSlidePopup && activeIndex === 0} onClose={onCloseSlidePopup} />
      <PopupOnboardingCongrat isOpen={isOpenPopup} onClose={onTogglePopup} title="Time to Sign Up" />
    </div>
  );
};
