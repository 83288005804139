import React from "react";
import { map } from "lodash-es";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "../../Common/ComponentExport";
import { onErrorGetGameCard, getSquare } from "../../../utils/getGameImages";
import { useStyles } from "./ListMostPlayedGame.style";

interface IGameInput {
  gameId: string;
  title: string;
}

interface IPropsListMostPlayedGame {
  games: IGameInput[];
}

export const ListMostPlayedGame = (props: IPropsListMostPlayedGame) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { games } = props;

  return (
    <div className={classes.wrapper}>
      <Typography>{t("mostGamePlayed_Most Played Games")}</Typography>
      <Grid container className={classes.contentGame}>
        {map(games, (game) => (
          <Grid item xs={6} md={4} lg={3} className={classes.itemGame}>
            <img
              id={game?.gameId}
              src={getSquare(game?.gameId)}
              onError={() => onErrorGetGameCard(game?.gameId)}
              alt="game-card"
            />
            <Typography variant="body1">{game?.title}</Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
