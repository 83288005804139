export const fakeListGame = [
  {
    id: "6",
    title: "Bingo Mania",
    company: "Back to the Game",
    description: "Combine the numbers to make rows and win!",
    category: '["Puzzle"]',
    data: '{"url":"https://onmo-admin-upload-prod.s3.amazonaws.com/public/games/6/bingo-mania.js"}',
    timesPlayed: 3050,
    rotationMode: "PORTRAIT",
    status: "LIVE",
    type: "HTML",
    canUseCash: true,
    isLike: false,
    likeCount: 2,
    totalCompleted: 4,
    countMoments: 2,
  },
  {
    id: "4",
    title: "Bubble Puzzle",
    company: "Back to the Game",
    description: "Shoot at like-colored bubbles to make them pop",
    category: '["Puzzle"]',
    data: '{"url":"https://onmo-admin-upload-prod.s3.amazonaws.com/public/games/4/bubble-puzzle.js"}',
    timesPlayed: 11183,
    rotationMode: "PORTRAIT",
    status: "LIVE",
    type: "HTML",
    canUseCash: true,
    isLike: false,
    likeCount: 0,
    totalCompleted: 6,
    countMoments: 1,
  },
  {
    id: "1",
    title: "Cube Puzzle",
    company: "Back to the Game",
    description: "Combine the tiles to blow them up! ",
    category: '["Puzzle"]',
    data: '{"url":"https://onmo-admin-upload-prod.s3.amazonaws.com/public/games/1/cube-puzzle.js"}',
    timesPlayed: 31816,
    rotationMode: "PORTRAIT",
    status: "LIVE",
    type: "HTML",
    canUseCash: true,
    isLike: false,
    likeCount: 1,
    totalCompleted: 7,
    countMoments: 1,
  },
  {
    id: "100",
    title: "Galaxy Warriors",
    company: "N/A",
    description:
      "Galaxy Warriors is a spaceship aircraft simulation game in space. Watch out for enemies shooting back and strong Bosses, hordes of enemies can also be handled with various boosters at your disposal. Dodge the bullet hell and shoot them all down!",
    category: '["Arcade","Action"]',
    data: '{"versions":{"v3.0":{"states":[],"triggers":{"zoneTriggers":[]}}}}',
    timesPlayed: 1636,
    rotationMode: "PORTRAIT",
    status: "LIVE",
    type: "HTML",
    canUseCash: true,
    isLike: false,
    likeCount: 0,
    totalCompleted: 0,
    countMoments: 1,
  },
  {
    id: "15",
    title: "Gems 'N' Break",
    company: "N/A",
    description:
      "Gem's N Break is a complexe puzzle game where the player has to slide tiles in holes in order to complete rows and gain points before they reach the top of the grid.",
    category: '["Puzzle"]',
    data: '{"versions":{"v3.0":{"states":[],"triggers":{"zoneTriggers":[]}}}}',
    timesPlayed: 148,
    rotationMode: "PORTRAIT",
    status: "LIVE",
    type: "HTML",
    canUseCash: true,
    isLike: false,
    likeCount: 0,
    totalCompleted: 4,
    countMoments: 1,
    hasBattle: false,
    hasSolo: false,
    hasTournament: true,
  },
  {
    id: "101",
    title: "Knife Smash",
    company: "N/A",
    description:
      " Throw the knives into the logs to break them, aim to slash the apples. Each 5th stage is defended by a boss",
    category: '["Arcade"]',
    data: '{"versions":{"v3.0":{"states":[],"triggers":{"zoneTriggers":[]}}}}',
    timesPlayed: 6259,
    rotationMode: "PORTRAIT",
    status: "LIVE",
    type: "HTML",
    canUseCash: true,
    isLike: false,
    likeCount: 0,
    totalCompleted: 1,
    countMoments: 1,
  },
  {
    id: "5",
    title: "Lucky7",
    company: "Back to the Game",
    description: "Simple and fun card game. Create stacks of 7 to make points.",
    category: '["Puzzle"]',
    data: '{"url":"https://onmo-admin-upload-prod.s3.amazonaws.com/public/games/5/lucky7.js"}',
    timesPlayed: 1255,
    rotationMode: "PORTRAIT",
    status: "LIVE",
    type: "HTML",
    canUseCash: true,
    isLike: false,
    likeCount: 0,
    totalCompleted: 1,
    countMoments: 2,
  },
  {
    id: "102",
    title: "Mahjong Quest",
    company: "N/A",
    description: "Classic Mahjong game",
    category: '["Puzzle"]',
    data: '{"versions":{"v3.0":{"states":[],"triggers":{"zoneTriggers":[]}}}}',
    timesPlayed: 447,
    rotationMode: "LANDSCAPE",
    status: "LIVE",
    type: "HTML",
    canUseCash: true,
    isLike: false,
    likeCount: 0,
    totalCompleted: 1,
    countMoments: 1,
  },
  {
    id: "14",
    title: "Poker Blitz",
    company: "N/A",
    description:
      "Fast-paced poker game where you will need to take the right decision to gain as many points as possible",
    category: '["Card Game"]',
    data: '{"versions":{"v3.0":{"states":[],"triggers":{"zoneTriggers":[]}}}}',
    timesPlayed: 266,
    rotationMode: "PORTRAIT",
    status: "LIVE",
    type: "HTML",
    canUseCash: true,
    isLike: false,
    likeCount: 0,
    totalCompleted: 2,
    countMoments: 1,
  },
  {
    id: "2",
    title: "REACH gold",
    company: "Back to the Game",
    description:
      "Challenge your strategic skills by combining coins to score points and succeed. Reach beyond your own limits as well as those of your friends!",
    category: '["Puzzle"]',
    data: '{"url":"https://onmo-admin-upload-prod.s3.amazonaws.com/public/games/2/reach.js"}',
    timesPlayed: 3508,
    rotationMode: "PORTRAIT",
    status: "LIVE",
    type: "HTML",
    canUseCash: true,
    isLike: false,
    likeCount: 0,
    totalCompleted: 3,
    countMoments: 2,
  },
  {
    id: "8",
    title: "Same",
    company: "N/A",
    description: "Destroy as many connected tiles as possible.",
    category: '["N/A"]',
    data: '{"versions":{"v3.0":{"states":[],"triggers":{"zoneTriggers":[]}}}}',
    timesPlayed: 3372,
    rotationMode: "PORTRAIT",
    status: "LIVE",
    type: "HTML",
    canUseCash: true,
    isLike: false,
    likeCount: 0,
    totalCompleted: 3,
    countMoments: 1,
  },
  {
    id: "7",
    title: "Solitaire Royal",
    company: "N/A",
    description:
      "Solitaire Royal is a solitaire-based card game played with a standard 52-card deck. Move the cards to the foundation in the upper right by moving them in order from Ace through King.",
    category: '["N/A"]',
    data: '{"versions":{"v3.0":{"states":[],"triggers":{"zoneTriggers":[]}}}}',
    timesPlayed: 511,
    rotationMode: "PORTRAIT",
    status: "LIVE",
    type: "HTML",
    canUseCash: true,
    isLike: false,
    likeCount: 0,
    totalCompleted: 1,
    countMoments: 2,
  },
  {
    id: "3",
    title: "Sudoku",
    company: "Back to the Game",
    description:
      " The goal of Sudoku is to fill a 9×9 grid with numbers so that each row, column and 3×3 section contain all of the digits between 1 and 9.",
    category: '["Puzzle"]',
    data: '{"url":"https://onmo-admin-upload-prod.s3.amazonaws.com/public/games/3/sudoku.js"}',
    timesPlayed: 672,
    rotationMode: "PORTRAIT",
    status: "LIVE",
    type: "HTML",
    canUseCash: true,
    isLike: false,
    likeCount: 0,
    totalCompleted: 2,
    countMoments: 2,
  },
  {
    id: "12",
    title: "Sudoku Blitz",
    company: "N/A",
    description:
      "Sudoku Blitz is a fast-paced Sudoku game where the objective is complete as many grids as possible. Once all cells within the current grid is completed, a new grid is generated.",
    category: '["Puzzle","Math Puzzle"]',
    data: '{"versions":{"v3.0":{"states":[],"triggers":{"zoneTriggers":[]}}}}',
    timesPlayed: 124,
    rotationMode: "PORTRAIT",
    status: "LIVE",
    type: "HTML",
    canUseCash: true,
    isLike: false,
    likeCount: 0,
    totalCompleted: 1,
    countMoments: 1,
  },
  {
    id: "11",
    title: "Sweet Rush",
    company: "N/A",
    description:
      "Combine at least 3 connected items of the same type by swiping one of them in order to make combos and gain points.",
    category: '["Puzzle","Hyper Casual"]',
    data: '{"versions":{"v3.0":{"states":[],"triggers":{"zoneTriggers":[]}}}}',
    timesPlayed: 772,
    rotationMode: "PORTRAIT",
    status: "LIVE",
    type: "HTML",
    canUseCash: true,
    isLike: false,
    likeCount: 0,
    totalCompleted: 2,
    countMoments: 1,
  },
  {
    id: "13",
    title: "Yatzy Reroll",
    company: "N/A",
    description: "Casual and addictive Yatzy game",
    category: '["Board Game"]',
    data: '{"versions":{"v3.0":{"states":[],"triggers":{"zoneTriggers":[]}}}}',
    timesPlayed: 203,
    rotationMode: "PORTRAIT",
    status: "LIVE",
    type: "HTML",
    canUseCash: true,
    isLike: false,
    likeCount: 0,
    totalCompleted: 0,
    countMoments: 1,
  },
];
export const fakeListBeatIt = [
  {
    tournamentInfo: {
      id: "c964d320-e73f-4202-ae40-f69b5fe5baaa",
      tournamentType: "PRACTICE_HTML",
      tournamentCategories: ["BEATIT", "PRACTICE"],
      currency: "XXX",
      gameId: "101",
      momentType: "Score",
      topScore: 10,
      username: "Shreesha Bhat",
      avatar: "https://lh3.googleusercontent.com/a/AATXAJwah0-FUVGrUOlwRUPKYOR5Itc23V1WzEgKTnWX=s96-c",
      expiredAt: "2022-03-11T19:05:36.000Z",
      playerCount: 1,
      maxPlayers: 1000,
      costPerUser: 0,
      userId: "40e6fc14-0e15-4a1c-8b07-953b2a9f1de8",
      moment: {
        title: "Z-Marathon",
        app: {
          __typename: "App",
          title: "Knife Smash",
          category: '["Arcade"]',
          type: "HTML",
        },
      },
    },
  },
  {
    tournamentInfo: {
      id: "1cc4dafe-a603-45e1-b399-696a65bb08de",
      tournamentType: "PRACTICE_HTML",
      tournamentCategories: ["BEATIT", "PRACTICE"],
      currency: "XXX",
      gameId: "2",
      momentType: "Score",
      topScore: 0,
      username: "Shreesha Bhat",
      avatar: "https://assets-dev.onmostealth.com/assets/user/avatar/1.png",
      expiredAt: "2022-03-11T12:10:36.000Z",
      playerCount: 1,
      maxPlayers: 1000,
      costPerUser: 0,
      userId: "40e6fc14-0e15-4a1c-8b07-953b2a9f1de8",
      moment: {
        title: "Z - Marathon",
        app: {
          __typename: "App",
          title: "REACH gold",
          category: '["Puzzle"]',
          type: "HTML",
        },
      },
    },
  },
  {
    tournamentInfo: {
      id: "6fb29c27-31dd-4e0c-b7b9-3a7eeb3714aa",
      tournamentType: "PRACTICE_HTML",
      tournamentCategories: ["BEATIT", "PRACTICE"],
      currency: "XXX",
      gameId: "11",
      momentType: "Score",
      topScore: 0,
      username: "hai",
      avatar: "https://assets-dev.onmostealth.com/assets/user/avatar/2.png",
      expiredAt: "2022-03-12T06:00:36.000Z",
      playerCount: 1,
      maxPlayers: 1000,
      costPerUser: 0,
      userId: "40e6fc14-0e15-4a1c-8b07-953b2a9f1de8",
      moment: {
        title: "Z - Marathon ",
        app: {
          __typename: "App",
          title: "Sweet Rush",
          category: '["Puzzle","Hyper Casual"]',
          type: "HTML",
        },
      },
    },
  },
  {
    tournamentInfo: {
      id: "f39f2b81-5312-4e24-96d0-395717c1a980",
      tournamentType: "PRACTICE_HTML",
      tournamentCategories: ["BEATIT", "PRACTICE"],
      currency: "XXX",
      gameId: "4",
      momentType: "Score",
      topScore: 2324,
      username: "trung",
      avatar: "https://assets-dev.onmostealth.com/assets/user/avatar/3.png",
      expiredAt: "2022-03-11T12:05:36.000Z",
      playerCount: 1,
      maxPlayers: 1000,
      costPerUser: 0,
      userId: "40e6fc14-0e15-4a1c-8b07-953b2a9f1de8",
      moment: {
        title: "Z - Marathon",
        app: {
          __typename: "App",
          title: "Bubble Puzzle",
          category: '["Puzzle"]',
          type: "HTML",
        },
      },
    },
  },
  {
    tournamentInfo: {
      id: "9b54167c-e01c-4e67-ad37-ce46fb00ae25",
      tournamentType: "PRACTICE_HTML",
      tournamentCategories: ["BEATIT", "PRACTICE"],
      currency: "XXX",
      gameId: "7",
      momentType: "Score",
      topScore: 3214,
      username: "quan",
      avatar: "https://lh3.googleusercontent.com/a/AATXAJyfkg4CfWT5lJ5iRW-NlgPTLG66-7fP28PR3LrL=s96-c",
      expiredAt: "2022-03-12T04:10:36.000Z",
      playerCount: 1,
      maxPlayers: 1000,
      costPerUser: 0,
      userId: "1bdc924d-d06c-4106-9f50-c0685b8852bd",
      moment: {
        title: "Z - Marathon",
        app: {
          __typename: "App",
          title: "Solitaire Royal",
          category: '["N/A"]',
          type: "HTML",
        },
      },
    },
  },
];
