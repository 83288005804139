import React, { useState } from "react";
import { useStyles } from "./ComingSoon.style";
import Coming from "../../assets/notification_icons/ComingSoon.svg";
import { useTranslation } from "react-i18next";
import { NoInternet } from "../NoInternet";
import { Button, Typography } from "../Common/ComponentExport";

export const ComingSoon = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isOnline, setIsOnline] = useState(true);

  const reloadPage = () => {
    window.location.href = "/";
    setIsOnline(navigator.onLine);
  };

  return (
    <div className={classes.container}>
      {isOnline ? (
        <>
          <div>
            <img src={Coming} alt="back" />
            <Typography className={classes.title}>{t("comingSoon_title")}</Typography>
          </div>
          <div>
            <Typography className={classes.description}>{t("comingSoon_body")}</Typography>
          </div>
          <div>
            <Button
              variant="contained"
              data-testid="button-commingSoon"
              className={classes.button}
              onClick={reloadPage}>
              {t("comingSoon_button")}
            </Button>
          </div>
        </>
      ) : (
        <NoInternet />
      )}
    </div>
  );
};

export default ComingSoon;
