import { ACTIONS } from "./actions";

export const setShowNotif = (dispatch: React.Dispatch<any>, isShowQuickNotif: boolean) => {
  dispatch({ type: ACTIONS.SET_SHOW_NOTIF, isShowQuickNotif });
};

export const setNotificationError = (dispatch: React.Dispatch<any>, message: string) => {
  dispatch({ type: ACTIONS.SET_NOTIF, payload: { message }, classify: "error" });
};

export const setNotificationSuccess = (dispatch: React.Dispatch<any>, message: string) => {
  dispatch({ type: ACTIONS.SET_NOTIF, payload: { message }, classify: "success" });
};

export const setNotificationInfo = (dispatch: React.Dispatch<any>, message: string) => {
  dispatch({ type: ACTIONS.SET_NOTIF, payload: { message }, classify: "info" });
};

export const setNotificationCopied = (dispatch: React.Dispatch<any>, value: string, message: string) => {
  dispatch({ type: ACTIONS.SET_NOTIF_COPIED, payload: { value, message }, classify: "copied" });
};

export const setNotificationDeposit = (
  dispatch: React.Dispatch<any>,
  currency: string,
  value: string,
  message: string
) => {
  dispatch({ type: ACTIONS.SET_NOTIF_DEPOSIT, payload: { currency, value, message }, classify: "deposit" });
};
