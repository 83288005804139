import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  contentBeatItCard: {
    display: "flex",
    alignItems: "center",
  },
  beatItAvatars: {
    "& > img, span:nth-child(1)": {
      "&:nth-child(1)": {
        height: 52,
        width: 52,
        borderRadius: 10,
      },
    },
    "& > div, span:nth-child(2)": {
      height: 49,
      background: "#DCDCDD",
      border: `2px solid ${theme.palette.background.default}`,
      width: 49,
      borderRadius: "50%",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: -37,
      position: "relative",
      zIndex: 2,
      marginLeft: 29,
    },
  },
  beatItMoreDetail: {
    flexGrow: 1,
    marginLeft: 13,
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
  },
  beatItUsername: {
    fontWeight: 500,
    color: theme.palette.primary.contrastText,
  },
  beatItTitle: {
    color: theme.palette.primary.contrastText,
    marginTop: 5,
  },
  beatItNumber: {
    margin: "3px 0px",
    fontWeight: 700,
    color: theme.palette.primary.contrastText,
  },
  beatItBtn: {
    background: theme.button.primary.background,
    color: theme.palette.primary.contrastText,
    fontSize: 14,
    minWidth: 84,
    height: 34,
    borderRadius: 50,
    fontWeight: 600,
  },
  textNone: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.primary.contrastText,
  },
  avatar: {
    height: "100%",
    width: "100%",
  },
  noAvatar: {
    height: 14,
    width: 14,
  },
}));
