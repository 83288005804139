import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  wrapper: {
    padding: 10,
    display: "flex",
    justifyContent: "center",
  },
  itemStat: {
    padding: 5,
    "& > div": {
      background: "#4C205A",
      borderRadius: 8,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 10,
    },
    "& > div > img": {
      marginRight: 10,
    },
    "& > div > div": {
      textAlign: "center",
    },
    "& > div > div > h6": {
      color: theme.palette.primary.contrastText,
      marginTop: 5,
    },
    "& > div > div > h2": {
      color: theme.palette.primary.contrastText,
      fontWeight: 700,
    },
  },
}));
