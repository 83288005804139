import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    overflowX: "scroll",
    marginTop: 10,
    "@media (orientation: landscape)": {
      marginTop: 0,
    },
    /* Hide scrollbar for IE, Edge and Firefox */
    "-ms-overflow-style": "none" /* IE and Edge */,
    scrollbarWidth: "none" /* Firefox */,
    /* Hide scrollbar for Chrome, Safari and Opera */
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  yourRank: {
    fontSize: 18,
    fontWeight: 500,
    color: "#2B2B2B",
    marginTop: 5,
  },
  top3: {
    width: 100,
    height: 121,
    "@media (orientation: landscape)": {
      height: "auto",
    },
    position: "relative",
    zIndex: 3,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0 5px",
    "& > div": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
    },
  },
  top3Empty: {
    position: "relative",
    zIndex: 3,
    padding: 5,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > div": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
    },
  },
  rankId: {
    position: "absolute",
    top: "5%",
    right: "17%",
    zIndex: 2,
    background: "linear-gradient(324.29deg, #489F85 10.69%, #63CBAC 95.4%);",
    width: 20,
    height: 20,
    fontWeight: 500,
    color: "#fff",
    borderRadius: 10,
    fontSize: 16,
    textAlign: "center",
    "&.r1": {
      background: "linear-gradient(101.05deg, #EBB017 7.93%, rgba(250, 204, 87, 0.865833) 55.39%, #FFDE8A 90.5%);",
    },
    "&.r2": {
      background: "linear-gradient(98.91deg, #A4A4A4 8.19%, #BABABA 29.88%, #D9D9D9 85.78%);",
    },
    "&.r3": {
      background: "linear-gradient(99.28deg, #C89D74 8.14%, #DEB48B 29.44%, #E4C4A6 84.33%);",
    },
  },
  runnerUp1: {
    "& > div:nth-child(1)": {
      width: 72,
      height: 72,
      border: "3px solid #C4C4C4",
      "@media(max-height: 667px)": {
        width: 57.6,
        height: 57.6,
      },
      "@media (min-height: 668px) and (max-height: 736px)": {
        width: 64.8,
        height: 64.8,
      },
    },
    "& > div:nth-child(2)": {
      width: 25,
      height: 25,
      background: "#C4C4C4",
      fontWeight: 500,
      fontSize: 15,
      letterSpacing: "-0.36px",
      "@media(max-height: 667px)": {
        width: 20,
        height: 20,
        fontSize: 12,
      },
      "@media (min-height: 668px) and (max-height: 736px)": {
        width: 22.5,
        height: 22.5,
        fontSize: 13.5,
      },
    },
  },
  runnerUp2: {
    "& > div:nth-child(1)": {
      width: 72,
      height: 72,
      border: "3px solid #C89D74",
      "@media(max-height: 667px)": {
        width: 57.6,
        height: 57.6,
      },
      "@media (min-height: 668px) and (max-height: 736px)": {
        width: 64.8,
        height: 64.8,
      },
    },
    "& > div:nth-child(2)": {
      width: 25,
      height: 25,
      background: "#C89D74",
      fontWeight: 500,
      fontSize: 15,
      letterSpacing: "-0.36px",
      "@media(max-height: 667px)": {
        width: 20,
        height: 20,
        fontSize: 12,
      },
      "@media (min-height: 668px) and (max-height: 736px)": {
        width: 22.5,
        height: 22.5,
        fontSize: 13.5,
      },
    },
  },
  username: {
    height: 20,
    marginTop: 2,
    fontWeight: 500,
    fontSize: 15,
    textAlign: "center",
    width: 90,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: "rgba(138, 138, 142, 1)",
  },
  listUser: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    height: "100%",
    padding: 10,
    "@media (orientation: landscape)": {
      padding: 0,
    },
  },
  showMe: {
    width: "100%",
    justifyContent: "center",
  },
  score: {
    height: 20,
    fontSize: 15,
    letterSpacing: "-0.36px",
    color: "rgba(60, 60, 67, 0.6)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: 700,
    "@media(max-height: 667px)": {
      height: 16,
      fontSize: 12,
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      height: 18,
      fontSize: 13.5,
    },
    width: "100%",
    textAlign: "center",
  },
  textWaiting: {
    fontSize: 15,
    letterSpacing: "-0.36px",
    color: "rgba(138, 138, 142, 1)",
    fontWeight: 500,
    textAlign: "center",
  },
  champion: {
    "& > div:nth-child(1)": {
      position: "relative",
      width: 50,
      height: 50,
      "@media (orientation: landscape)": {
        width: 46,
        height: 46,
      },
    },
    "&.myUser": {
      "& > div:nth-child(1)": {
        position: "relative",
        width: 60,
        height: 60,
        "@media (orientation: landscape)": {
          width: 46,
          height: 46,
        },
      },
    },
  },
  waitingForPlayers: {
    padding: 5,
    width: 100,
    height: 121,
    textAlign: "center",
  },
  championCancelled: {
    padding: 3,
    "& > div:nth-child(1)": {
      position: "relative",
      width: 50,
      height: 50,
    },
  },
  avatarCP: {
    width: 96,
    height: 96,
    border: "3px solid #C5C5C7",
    "&.myScore": {
      border: "3px solid rgba(72, 159, 133, 1)",
    },
    "&.r1": {
      border: "3px solid #EBB017",
    },
    "&.r2": {
      border: "3px solid #A4A4A4",
    },
    "&.r3": {
      border: "3px solid #C89D74",
    },
    "@media(max-height: 667px)": {
      width: 76.8,
      height: 76.8,
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      width: 86.4,
      height: 86.4,
    },
  },
  userWaiting: {
    width: 50,
    height: 50,
  },
  avatar: {
    height: 48,
    width: 48,
    "@media(max-height: 667px)": {
      height: 38.4,
      width: 38.4,
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      height: 43.2,
      width: 43.2,
    },
  },
  contentRank: {
    maxHeight: 200,
    overflow: "scroll",
    "@media(max-height: 650px)": {
      maxHeight: 100,
    },
  },
  transparent: {
    background: "transparent",
  },
  colorWhite: {
    color: "#fff",
  },
  colorGrey: {
    color: "#A4A4A4",
  },
  colorBronze: {
    color: "#C89D74",
  },
  colorGold: {
    height: 24,
    fontSize: 15,
    "&.myScore": {
      color: "rgba(72, 159, 133, 1)",
    },
    "&.r1": {
      color: "#EBB017 !important",
    },
    "&.r2": {
      color: "#A4A4A4 !important",
    },
    "&.r3": {
      color: "#C89D74 !important",
    },
    "&.dark": {
      color: "#fff",
    },
    "&.white": {
      color: "#424242",
    },
  },
  rewardCoin: {
    width: 60,
    fontSize: 13,
    borderRadius: 20,
    height: 25,
    "@media (orientation: landscape)": {
      height: 18,
    },
    "&.rewardEmpty": {
      "@media (orientation: landscape)": {
        height: 12,
      },
    },
    marginTop: -10,
    zIndex: 1,
    "&.rw": {
      background: "linear-gradient(324.29deg, #489F85 10.69%, #63CBAC 95.4%)",
    },
    "&.myScore": {
      background: "rgba(72, 159, 133, 1)",
    },
    "&.r1": {
      background: "linear-gradient(101.05deg, #EBB017 7.93%, rgba(250, 204, 87, 0.865833) 55.39%, #FFDE8A 90.5%);",
    },
    "&.r2": {
      background: "linear-gradient(98.91deg, #A4A4A4 8.19%, #BABABA 29.88%, #D9D9D9 85.78%);",
    },
    "&.r3": {
      background: "linear-gradient(99.28deg, #C89D74 8.14%, #DEB48B 29.44%, #E4C4A6 84.33%);",
    },
    "&.myUser": {
      marginTop: -10,
    },
    "& > h6": {
      background: "linear-gradient(119.49deg, #2B2B2B 35.3%, #606060 70.6%)",
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent",
      fontSize: "13px !important",
      fontWeight: 500,
      zIndex: 1,
      "& > img": {
        width: 12,
        marginRight: 2,
      },
    },
  },
  noTransparent: {
    "-webkit-text-fill-color": "unset !important",
  },
  imgNoAvatar: {
    objectFit: "contain",
  },
  btcText: {
    fontSize: 8,
    marginLeft: -3,
  },
  centerContent: {
    justifyContent: "center",
  },
}));
