import {
  getLeaderboard,
  listChallenges,
  listMomentsV2,
  getGame,
  listGames,
  listUserGameSkills,
  listGamesByCategories,
  getUserMostPlayedGames,
  getUserFeed,
} from "../apollo/queries/games";
import { Status, MomentType } from "../graphql/API";
import { sortBy, map, filter } from "lodash-es";
import { IBaseGame, IBaseListGamesByCategories, IBaseListUserGameSkills } from "../types/baseInterfaces";
import apollo from "../clients/apollo";
import { GetLeaderboardInput } from "../types/inputTypes";
import { IBaseChallenge, IBaseLeaderboard, IBaseMoment, IBaseMomentsList } from "../types/baseInterfaces";
import gql from "graphql-tag";
import { QueryOptions } from "@apollo/client";

const apolloClient = apollo.getInstance();

export class Games {
  /**
   * To get list of games in app
   */
  static fetchLiveGamesListV2 = async (options = {}): Promise<IBaseGame[]> => {
    const response = await apolloClient.query({
      ...options,
      query: listGames,
      variables: { status: Status.LIVE },
    });

    return sortBy(response.data.listGames, "title");
  };

  static getUserMostPlayedGames = async (userId: string, options = {}) => {
    const response = await apolloClient.query({
      ...options,
      query: getUserMostPlayedGames,
      variables: { getUserMostPlayedGamesInput: { userId } },
    });

    return response.data.getUserMostPlayedGames;
  };

  /**
   * To get list of draft games in app
   */
  static fetchDraftGamesList = async (): Promise<IBaseGame[]> => {
    const response = await apolloClient.query({ query: listGames, variables: { status: Status.DRAFT } });
    return sortBy(response.data.listGames, "title");
  };

  /**
   * To get list of daily top scores
   */
  static getLeaderboard = async (input: GetLeaderboardInput): Promise<IBaseLeaderboard> => {
    const res = await apolloClient.query({
      query: getLeaderboard,
      variables: { getLeaderboardInput: { ...input } },
    });
    return res.data.getLeaderboard;
  };

  /**
   * To get list of daily top scores
   */
  static fetchListChallenges = async (): Promise<IBaseChallenge[]> => {
    const response = await apolloClient.query({
      query: listChallenges,
      variables: {},
    });

    return response.data.listChallenges;
  };

  /**
   * To get list moments of a game
   */
  static fetchMomentsListV2 = async (
    appId: string | number,
    status: string,
    options: any = {}
  ): Promise<IBaseMomentsList> => {
    const response = await apolloClient.query({
      ...options,
      query: listMomentsV2,
      variables: { appId, status: [status] },
    });

    const listMoments = map(response.data.listMomentsV2, (o) => {
      return { ...o };
    });
    const allMoments = sortBy(listMoments, "title") || [];
    const tutorialTypes = [MomentType.TUTORIAL, MomentType.ZEN];

    const momentsTutorial = allMoments.filter((item: any) => tutorialTypes.includes(item.momentType));
    const momentsNotTutorial = allMoments.filter((item: any) => !tutorialTypes.includes(item.momentType));
    const momentsBattle = allMoments.filter((item: any) => item.momentType === MomentType.BATTLE);

    const haveZoneIdCasualMoments: IBaseMoment[] = filter(
      allMoments,
      (o) => o.zoneId && o.momentType === MomentType.CASUAL
    );

    return {
      allMoments,
      momentsTutorial,
      momentsNotTutorial,
      momentsBattle,
      haveZoneIdCasualMoments,
    };
  };

  static listGames = async (
    status: Array<string>,
    options = {},
    lbInput = { limit: 10, offset: 0 }
  ): Promise<IBaseGame[]> => {
    const response = await apolloClient.query({
      ...options,
      query: listGames,
      variables: {
        status,
        getLeaderboardInput: lbInput,
      },
    });

    return response.data.listGames;
  };

  static updateGameDetailCache = async (input: any) => {
    const { id, isLike, likeCount } = input;

    apolloClient.writeFragment({
      id: `Game:${id}`,
      fragment: gql`
        fragment GameDetail on Game {
          id
          isLike
          likeCount
        }
      `,
      data: {
        id,
        isLike,
        likeCount,
      },
    });
  };

  static fetchGameData = async (gameId: string, isAdmin?: boolean, options = {}) => {
    const getGameInput = !isAdmin ? { id: gameId } : { id: gameId, momentStatus: [Status.LIVE, Status.DRAFT] };
    const response = await apolloClient.query({
      ...options,
      query: getGame,
      variables: {
        getGameInput: getGameInput,
      },
    });

    return response.data.getGame;
  };

  static listGamesByCategories = async (options: Partial<QueryOptions> = {}): Promise<IBaseListGamesByCategories> => {
    const res = await apolloClient.query({
      ...options,
      query: listGamesByCategories,
      variables: {},
    });

    return res.data.listGamesByCategories;
  };

  static listUserGameSkills = async (userId?: string, options = {}): Promise<IBaseListUserGameSkills> => {
    const res = await apolloClient.query({
      ...options,
      query: listUserGameSkills,
      variables: { listUserGameSkillsInput: { userId } },
    });

    return res.data.listUserGameSkills;
  };

  static getUserFeed = async (options = {}): Promise<any> => {
    const res = await apolloClient.query({
      ...options,
      query: getUserFeed,
      variables: {},
    });

    return res.data.getUserFeed;
  };
}
