import React from "react";
import { useApolloClient } from "@apollo/client";
import { BATTLE_COMPLETED } from "../../apollo/subscriptions/battles";
import { UserCtx } from "../../context/user/state";
import { NotificationType, UserWalletType } from "../../types/enums";
import { updateWallets } from "../../utils/wallets";

export const BattleCompeletedSubscription = () => {
  const { updateUserWallets, userWallets } = React.useContext(UserCtx);
  const [battleCompletedResponse, setBattleCompletedResponse] = React.useState<any>(null);

  const client = useApolloClient();

  React.useEffect(() => {
    const observer = client.subscribe({
      query: BATTLE_COMPLETED,
    });

    const subscription = observer.subscribe((data: any) => {
      console.log("BATTLE_COMPLETED", data.data);
      setBattleCompletedResponse(data.data);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  React.useEffect(() => {
    if (!battleCompletedResponse) return;
    const notificationType = battleCompletedResponse?.battleCompleted?.notificationType;
    if (notificationType === NotificationType.BATTLE_WON) {
      const responseWallets = battleCompletedResponse?.battleCompleted?.completeBattle?.updatedWallets || [];

      const virtualWallet = responseWallets?.find((wallet: any) => wallet.type === "user.virtual");
      const updatedCoinWallet = {
        ...virtualWallet,
        type: UserWalletType.VIRTUAL,
        balance: Math.abs(virtualWallet.balance),
      };
      const updatedWallets = updateWallets(userWallets, [updatedCoinWallet]);
      updateUserWallets(updatedWallets);
    }
    setBattleCompletedResponse(null);
  }, [battleCompletedResponse, userWallets]);

  return <div data-testid="battle-completed-subscription"></div>;
};

export default BattleCompeletedSubscription;
