import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  hideHeader: {
    transform: "translate(0px, -100%)",
    transition: "all ease-in-out 1s",
  },
  desc: {
    textAlign: "center",
  },
  resumeStream: {
    marginBottom: "35px !important",
    color: theme.palette.primary.contrastText,
    background: theme.button.primary.background,
    height: 48,
    maxWidth: 263,
    borderRadius: 50,
    fontWeight: 500,
    fontSize: 16,
  },
}));
