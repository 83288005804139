import { createUseStyles } from "react-jss";
import { ITheme } from "../../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  root: {
    position: "relative",
  },
  dot: {
    height: 20,
    width: 20,
    borderRadius: "100%",
    background: theme.palette.error.light,
    display: "flex",
    zIndex: 1,
    position: "absolute",
    flexWrap: "wrap",
    transition: "transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    alignItems: "center",
    fontWeight: 500,
    alignContent: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  invisible: {
    transform: "scale(0) translate(50%, -50%)",
  },
}));
