import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  contentBeatIt: {
    marginTop: 25,
  },
  title: {
    marginBottom: 20,
    "& > h2": {
      color: theme.palette.primary.contrastText,
      fontWeight: 600,
    },
    "& > p": {
      fontSize: 14,
      color: theme.palette.primary.contrastText,
      marginTop: 10,
      lineHeight: "18px",
    },
  },
  mainBeatIt: {
    "& > div": {
      padding: "15px 0px !important",
      borderBottom: "1px solid rgba(196, 196, 196, 0.2)",
    },
  },
}));
