import { ACTIONS } from "./actions";
import { Friends } from "../../modelsv2/friends";
import { Users } from "../../modelsv2/users";
import { IBaseFriend, IBaseFriendRequest, IUserBaseData } from "../../types/baseInterfaces";
import { sortBy } from "lodash-es";

export const setConditionSort = (dispatch: React.Dispatch<any>, conditionSort: string) => {
  dispatch({ type: ACTIONS.SET_SORT_BY, conditionSort });
};

export const sendFriendRequest = async (
  userId: string,
  user: IUserBaseData,
  currentSentRequests: IBaseFriendRequest[],
  dispatch: React.Dispatch<any>,
  setIsMaintenance: Function
) => {
  try {
    const res = await Friends.sendFriendRequest({ senderId: userId, receiverId: user.id });
    const newFriendRequest = {
      id: res.id,
      receiverId: user.id,
      receiver: {
        id: res.id,
        username: user.username,
        avatar: user.avatar,
        xp: user.xp,
      },
    };
    dispatch({ type: ACTIONS.UPDATE_SENT_REQUEST, sentRequests: [...currentSentRequests, newFriendRequest] });
  } catch (e: any) {
    if (e.errors?.[0]?.message === "Onmo is currently down for maintenance") {
      setIsMaintenance(true);
    } else {
      console.error(`Fail to send friend request to ${user.id}`, e);
      throw e;
    }
  }
};

export const cancelFriendRequest = async (
  request: IBaseFriendRequest,
  currentSentRequests: IBaseFriendRequest[],
  dispatch: React.Dispatch<any>
) => {
  await Friends.deleteFriendRequest(request.id);
  const updatedSentRequests = currentSentRequests.filter((item: any) => item.receiverId !== request.receiverId);
  dispatch({ type: ACTIONS.UPDATE_SENT_REQUEST, sentRequests: updatedSentRequests });
};

export const acceptFriendRequest = async (
  request: IBaseFriendRequest,
  currentFriendRequests: IBaseFriendRequest[],
  dispatch: React.Dispatch<any>,
  setIsMaintenance: Function
) => {
  try {
    await Friends.acceptFriendRequest(request.id);
    const updatedFriendRequests = currentFriendRequests.filter((item: any) => item.senderId !== request.senderId);
    dispatch({ type: ACTIONS.UPDATE_FRIENDS_REQUEST, friendRequests: updatedFriendRequests });
  } catch (error: any) {
    if (error.errors?.[0]?.message === "Onmo is currently down for maintenance") {
      setIsMaintenance(true);
    } else {
      console.error(`Fail to accept friend request ${request.id}`, error);
    }
  }
};

export const rejectFriendRequest = async (
  request: IBaseFriendRequest,
  currentFriendRequests: IBaseFriendRequest[],
  dispatch: React.Dispatch<any>
) => {
  await Friends.deleteFriendRequest(request.id);
  const updatedFriendRequests = currentFriendRequests.filter((item: any) => item.senderId !== request.senderId);
  dispatch({ type: ACTIONS.UPDATE_FRIENDS_REQUEST, friendRequests: updatedFriendRequests });
};

export const getListFriendRequest = async (dispatch: React.Dispatch<any>, setIsMaintenance: Function) => {
  try {
    const res = await Friends.getListFriendRequest();
    dispatch({ type: ACTIONS.UPDATE_FRIENDS_REQUEST, friendRequests: res });
  } catch (e: any) {
    if (e.errors?.[0]?.message === "Onmo is currently down for maintenance") {
      setIsMaintenance(true);
    } else {
      console.error(`Fail to get list received friend request`, e);
      dispatch({ type: ACTIONS.UPDATE_FRIENDS_REQUEST, friendRequests: [] });
    }
  }
};

export const getListSentRequest = async (dispatch: React.Dispatch<any>, setIsMaintenance: Function) => {
  try {
    const res = await Friends.getListFriendsSent();
    dispatch({ type: ACTIONS.UPDATE_SENT_REQUEST, sentRequests: res });
  } catch (e: any) {
    if (e.errors?.[0]?.message === "Onmo is currently down for maintenance") {
      setIsMaintenance(true);
    } else {
      console.error(`Fail to get list sent friend request`, e);
      dispatch({ type: ACTIONS.UPDATE_SENT_REQUEST, sentRequests: [] });
    }
  }
};

export const closeBanner = (dispatch: React.Dispatch<any>) => {
  dispatch({ type: ACTIONS.CLOSE_BANNER });
};

export const getUserFriends = async (
  dispatch: React.Dispatch<any>,
  userId: string,
  setIsMaintenance: Function,
  isFetchPolicy: boolean
) => {
  try {
    const friends = await Users.getUserFriendsV2(userId, {
      fetchPolicy: isFetchPolicy ? "network-only" : "cache-first",
    });

    const friendsSorted = sortBy(friends, "username");
    dispatch({ type: ACTIONS.UPDATE_FRIENDS_LIST, friends: friendsSorted });
    return friendsSorted;
  } catch (e: any) {
    if (e.errors?.[0]?.message === "Onmo is currently down for maintenance") {
      setIsMaintenance(true);
    } else {
      console.error(`Fail to get list friends of user ${userId}`, e);
      dispatch({ type: ACTIONS.UPDATE_FRIENDS_LIST, friends: [] });
    }
    return [];
  }
};

export const updateRecentSearch = async (dispatch: React.Dispatch<any>, query: string) => {
  dispatch({ type: ACTIONS.UPDATE_RECENT_SEARCH, query });
};

export const updateFriendList = (dispatch: React.Dispatch<any>, friends: IBaseFriend[]) => {
  dispatch({ type: ACTIONS.UPDATE_FRIENDS_LIST, friends });
};

export const updateFriendRequestList = (dispatch: React.Dispatch<any>, friendRequests: IBaseFriendRequest[]) => {
  dispatch({ type: ACTIONS.UPDATE_FRIENDS_REQUEST, friendRequests });
};

export const updateFriendRequestSentList = (dispatch: React.Dispatch<any>, sentRequests: IBaseFriendRequest[]) => {
  dispatch({ type: ACTIONS.UPDATE_SENT_REQUEST, sentRequests });
};
