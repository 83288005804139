import React, { HTMLAttributes, FC, useEffect } from "react";
import { Dialog, Grid, Typography } from "../ComponentExport";
import { useHistory } from "react-router-dom";
import clsx from "classnames";
import { ReactComponent as OnmoCongratIcon } from "../../../assets/GuestMode/onmo-congrat.svg";
import { ReactComponent as CloseIcon } from "../../../assets/ArenaItemRow/Close.svg";
import { LoginMethod } from "../LoginMethod";
import { getTheme, THEME_NAME } from "../../../utils/theme";
import { signInBy } from "../../../utils/loginMethod";
import { useStyles } from "./PopupOnboardingCongrat.style";
import { Analytics } from "../../../modelsv2/analytics";
import { CATEGORY } from "../../../constants/analyticsEvent";

interface IPopupOnboardingCongratProps {
  isOpen: boolean;
  onClose: Function;
  title: string;
  isEndGame?: boolean;
}

export const PopupOnboardingCongrat: FC<IPopupOnboardingCongratProps & HTMLAttributes<HTMLDivElement>> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { isOpen, onClose, title, isEndGame, ...otherProps } = props;
  const THEME = getTheme();

  const onLogin = (provide: string) => {
    signInBy(provide, history);
  };

  const onClickTerms = () => {
    window.open(THEME?.termsAndConditions, "_blank");
  };

  const onClickPrivacy = () => {
    window.open(THEME.privacyPolicy, "_blank");
  };

  useEffect(() => {
    if (isOpen && isEndGame) {
      Analytics.trackEvent({
        category: CATEGORY.ONBOARDING,
        action: "game_complete",
        label: title,
        moment_type: "Battle",
        game_type: "HTML",
        currency: "Cash",
      });
    }
  }, [isOpen]);

  return (
    <Dialog {...otherProps} className={clsx(classes.root, props.className)} open={isOpen} onClose={onClose}>
      <Grid display="flex" justifyContent="center">
        <OnmoCongratIcon className={classes.iconCongrat} />
        <CloseIcon className={classes.iconClose} onClick={() => onClose()} />
      </Grid>
      <div className={classes.content}>
        <Typography variant="body2" className={classes.title}>
          {title}
        </Typography>
        <Typography className={classes.titleWon}>You won ₹100 Bonus</Typography>
        <Typography className={classes.desc}>Simply sign up with {THEME_NAME} and collect your winning!</Typography>
        <LoginMethod onLogin={onLogin} className={classes.contentMethod} />
        <Typography className={classes.agreement}>
          By signing up or signing in to an account, you agree to {THEME_NAME}{" "}
          <span className={classes.link} onClick={onClickTerms}>
            Terms of Use
          </span>{" "}
          and{" "}
          <span className={classes.link} onClick={onClickPrivacy}>
            Privacy Policy
          </span>
        </Typography>
      </div>
    </Dialog>
  );
};
