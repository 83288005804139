import React from "react";
import { Typography } from "../../Common/ComponentExport";
import { ReactComponent as OnmoLogo } from "../../../assets/GuestMode/OnmoWhite.svg";
import { ReactComponent as ChingariLogo } from "../../../assets/Chingari/ChingariLogo.svg";
import { useStyles } from "./LoginHeader.style";

export const LoginHeader = () => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.logo}>
        <OnmoLogo />
        <ChingariLogo />
      </div>
      <div className={classes.title}>
        <Typography>Get in the</Typography>
        <Typography>Game Zone!</Typography>
      </div>
      <Typography className={classes.desc}>
        India's best short gaming app. Play free tournaments and daily challenges.
      </Typography>
    </div>
  );
};
