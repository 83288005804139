import React, { useEffect, useState } from "react";
import { map, find } from "lodash-es";
import { AppLand } from "../../modelsv2/applands";
import { IBaseGame } from "../../types/baseInterfaces";
import { ListGame as ListGameComponent } from "../../components/Chingari/ListGame";
import { Games } from "../../modelsv2/games";
import { Analytics } from "../../modelsv2/analytics";
import { CATEGORY } from "../../constants/analyticsEvent";

const ListGame = () => {
  const [topGames, setTopGames] = useState<IBaseGame[] | null>(null);

  const getDataGame = async () => {
    try {
      const liveGameList = await Games.fetchLiveGamesListV2({ fetchPolicy: "cache-first" });
      const predictedGameExperiences = await AppLand.getPredictedGameExperiences();
      const gameForYou = map(liveGameList, (game: IBaseGame) => {
        const gamePredictedExperience = find(predictedGameExperiences, (o) => o.appId === parseInt(game.id));
        return { ...game, predictedExperience: gamePredictedExperience?.score };
      });
      setTopGames(gameForYou);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getDataGame();
    Analytics.trackEvent({
      category: CATEGORY.GAME_LISTING_PAGE,
      action: "view",
    });
  }, []);

  return <ListGameComponent listGame={topGames} />;
};

export default ListGame;
