import gql from "graphql-tag";

export const getLeaderboard = gql`
  query GetLeaderboard($getLeaderboardInput: GetLeaderboardInput!) {
    getLeaderboard(getLeaderboardInput: $getLeaderboardInput) {
      leaders {
        userId
        score
        rank
        username
        avatar
      }
      moment {
        title
        type
        app {
          useSimulation
          title
        }
      }
      error
    }
  }
`;

export const getUserMostPlayedGames = gql`
  query GetUserMostPlayedGames($getUserMostPlayedGamesInput: GetUserMostPlayedGamesInput!) {
    getUserMostPlayedGames(getUserMostPlayedGamesInput: $getUserMostPlayedGamesInput) {
      battleCost {
        currency
        fee
        cost
      }
      id
      title
      hasBattle
      type
      status
      category
      description
      canUseCash
      eloStatus
      skill
      timesPlayed
      gamesPlayedByUser
    }
  }
`;

export const listChallenges = gql`
  query ListChallenges {
    listChallenges {
      id
      score
      user {
        id
        username
        avatar
      }
      moment {
        id
        snapshotId
        title
        type
        app {
          id
          title
          type
          useSimulation
        }
      }
      created_at
    }
  }
`;

export const listMomentsV2 = gql`
  query ListMomentsV2($appId: String!, $status: [Status]) {
    listMomentsV2(appId: $appId, status: $status) {
      id
      appId
      snapshotId
      title
      unlockXp
      timesPlayed
      momentType
      isCompleted
      zoneId
    }
  }
`;

export const getMoment = gql`
  query GetMoment($id: ID!) {
    getMoment(id: $id) {
      snapshotId
      title
    }
  }
`;

export const getApp = gql`
  query GetApp($id: ID!) {
    getApp(id: $id) {
      title
      description
      type
    }
  }
`;

export const listGames = gql`
  query ListGames($status: [GameStatus]!) {
    listGames(status: $status) {
      id
      title
      company
      description
      category
      data
      timesPlayed
      rotationMode
      status
      type
      canUseCash
      isLike
      likeCount
      totalCompleted
      countMoments
      hasBattle
      hasSolo
      hasTournament
      battleCost {
        currency
        fee
        cost
      }
    }
  }
`;

export const getGame = gql`
  query GetGame($getGameInput: GetGameInput) {
    getGame(getGameInput: $getGameInput) {
      id
      title
      company
      description
      category
      timesPlayed
      data
      rotationMode
      hasTournament
      hasSolo
      hasBattle
      countMoments
      totalCompleted
      likeCount
      isLike
      tournamentInfo {
        totalPrize
        playedAt
        avatar
        username
        userId
        topScore
        maxPlayers
        playerCount
        rankingOrder
        momentType
        momentId
        gameId
        prizePool
        fee
        currency
        costPerUser
        status
        expiredAt
        startedAt
        tournamentCategories
        id
        tournamentType
      }
      status
      type
      moments {
        id
        appId
        snapshotId
        title
        description
        type
        time
        playCost
        unlockXp
        unlockCost
        data
        timesPlayed
        showTimer
        momentType
        rankOrder
        status
        createdAt
        updatedAt
        zoneId
        isCompleted
      }
      battleCost {
        fee
        currency
        cost
      }
      canUseCash
    }
  }
`;

export const listGamesByCategories = gql`
  query ListGamesByCategories {
    listGamesByCategories {
      newReleases {
        id
        title
        category
        likeCount
      }
      recentlyPlayed {
        id
        title
        category
        likeCount
      }
      trending {
        id
        title
        category
        likeCount
      }
    }
  }
`;

export const listUserGameSkills = gql`
  query ListUserGameSkills($listUserGameSkillsInput: ListUserGameSkillsInput) {
    listUserGameSkills(listUserGameSkillsInput: $listUserGameSkillsInput) {
      userId
      gameId
      userEloRating
    }
  }
`;

export const getUserFeed = gql`
  query GetUserFeed {
    getUserFeed {
      game {
        id
        title
        skill
        canUseCash
        totalCompleted
        gamesPlayedByUser
        battleCost {
          fee
          currency
          cost
        }
        hasBattle
      }
      tournaments {
        id
        gameId
        expiredAt
        currency
        costPerUser
        fee
        rankingOrder
        playerCount
        totalPrize
        tournamentType
        maxPlayers
        myRank
        moment {
          app {
            title
          }
        }
      }
      beatIt {
        tournamentInfo {
          id
          tournamentType
          tournamentCategories
          currency
          gameId
          momentType
          topScore
          username
          avatar
          expiredAt
          startedAt
          playedAt
          playerCount
          maxPlayers
          costPerUser
          userId
        }
      }
      hotBattles {
        matchId
        currency
        costPerUser
        entryFee
        matchState
        host {
          id
          username
          avatar
        }
        scoreToBeat
        prize
        winType
        createdAt
      }
      houseBattles {
        matchId
        currency
        costPerUser
        entryFee
        matchState
        scoreToBeat
        host {
          id
          username
          avatar
        }
        prize
        winType
        createdAt
      }
    }
  }
`;
