import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  xs: {
    "&.xs1": {
      flexGlow: 0,
      maxWidth: "8.333333%",
      flexBasis: "8.333333%",
    },
    "&.xs2": {
      flexGlow: 0,
      maxWidth: "16.666667%",
      flexBasis: "16.666667%",
    },
    "&.xs3": {
      flexGlow: 0,
      maxWidth: "25%",
      flexBasis: "25%",
    },
    "&.xs4": {
      flexGlow: 0,
      maxWidth: "33.333333%",
      flexBasis: "33.333333%",
    },
    "&.xs5": {
      flexGlow: 0,
      maxWidth: "41.666667%",
      flexBasis: "41.666667%",
    },
    "&.xs6": {
      flexGlow: 0,
      maxWidth: "50%",
      flexBasis: "50%",
    },
    "&.xs7": {
      flexGlow: 0,
      maxWidth: "58.333333%",
      flexBasis: "58.333333%",
    },
    "&.xs8": {
      flexGlow: 0,
      maxWidth: "66.666667%",
      flexBasis: "66.666667%",
    },
    "&.xs9": {
      flexGlow: 0,
      maxWidth: "75%",
      flexBasis: "75%",
    },
    "&.xs10": {
      flexGlow: 0,
      maxWidth: "83.333333%",
      flexBasis: "83.333333%",
    },
    "&.xs11": {
      flexGlow: 0,
      maxWidth: "91.666667%",
      flexBasis: "91.666667%",
    },
    "&.xs12": {
      flexGlow: 0,
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },
  sm: {
    "@media (max-width: 600px)": {
      "&.sm1": {
        flexGlow: 0,
        maxWidth: "8.333333%",
        flexBasis: "8.333333%",
      },
      "&.sm2": {
        flexGlow: 0,
        maxWidth: "16.666667%",
        flexBasis: "16.666667%",
      },
      "&.sm3": {
        flexGlow: 0,
        maxWidth: "25%",
        flexBasis: "25%",
      },
      "&.sm4": {
        flexGlow: 0,
        maxWidth: "33.333333%",
        flexBasis: "33.333333%",
      },
      "&.sm5": {
        flexGlow: 0,
        maxWidth: "41.666667%",
        flexBasis: "41.666667%",
      },
      "&.sm6": {
        flexGlow: 0,
        maxWidth: "50%",
        flexBasis: "50%",
      },
      "&.sm7": {
        flexGlow: 0,
        maxWidth: "58.333333%",
        flexBasis: "58.333333%",
      },
      "&.sm8": {
        flexGlow: 0,
        maxWidth: "66.666667%",
        flexBasis: "66.666667%",
      },
      "&.sm9": {
        flexGlow: 0,
        maxWidth: "75%",
        flexBasis: "75%",
      },
      "&.xs10": {
        flexGlow: 0,
        maxWidth: "83.333333%",
        flexBasis: "83.333333%",
      },
      "&.sm11": {
        flexGlow: 0,
        maxWidth: "91.666667%",
        flexBasis: "91.666667%",
      },
      "&.sm12": {
        flexGlow: 0,
        maxWidth: "100%",
        flexBasis: "100%",
      },
    },
  },
  md: {
    "@media (min-width: 960px)": {
      "&.md1": {
        flexGlow: 0,
        maxWidth: "8.333333%",
        flexBasis: "8.333333%",
      },
      "&.md2": {
        flexGlow: 0,
        maxWidth: "16.666667%",
        flexBasis: "16.666667%",
      },
      "&.md3": {
        flexGlow: 0,
        maxWidth: "25%",
        flexBasis: "25%",
      },
      "&.md4": {
        flexGlow: 0,
        maxWidth: "33.333333%",
        flexBasis: "33.333333%",
      },
      "&.md5": {
        flexGlow: 0,
        maxWidth: "41.666667%",
        flexBasis: "41.666667%",
      },
      "&.md6": {
        flexGlow: 0,
        maxWidth: "50%",
        flexBasis: "50%",
      },
      "&.md7": {
        flexGlow: 0,
        maxWidth: "58.333333%",
        flexBasis: "58.333333%",
      },
      "&.md8": {
        flexGlow: 0,
        maxWidth: "66.666667%",
        flexBasis: "66.666667%",
      },
      "&.md9": {
        flexGlow: 0,
        maxWidth: "75%",
        flexBasis: "75%",
      },
      "&.md10": {
        flexGlow: 0,
        maxWidth: "83.333333%",
        flexBasis: "83.333333%",
      },
      "&.md11": {
        flexGlow: 0,
        maxWidth: "91.666667%",
        flexBasis: "91.666667%",
      },
      "&.md12": {
        flexGlow: 0,
        maxWidth: "100%",
        flexBasis: "100%",
      },
    },
  },
  lg: {
    "@media (min-width: 1280px)": {
      "&.lg1": {
        flexGlow: 0,
        maxWidth: "8.333333%",
        flexBasis: "8.333333%",
      },
      "&.lg2": {
        flexGlow: 0,
        maxWidth: "16.666667%",
        flexBasis: "16.666667%",
      },
      "&.lg3": {
        flexGlow: 0,
        maxWidth: "25%",
        flexBasis: "25%",
      },
      "&.lg4": {
        flexGlow: 0,
        maxWidth: "33.333333%",
        flexBasis: "33.333333%",
      },
      "&.lg5": {
        flexGlow: 0,
        maxWidth: "41.666667%",
        flexBasis: "41.666667%",
      },
      "&.lg6": {
        flexGlow: 0,
        maxWidth: "50%",
        flexBasis: "50%",
      },
      "&.lg7": {
        flexGlow: 0,
        maxWidth: "58.333333%",
        flexBasis: "58.333333%",
      },
      "&.lg8": {
        flexGlow: 0,
        maxWidth: "66.666667%",
        flexBasis: "66.666667%",
      },
      "&.lg9": {
        flexGlow: 0,
        maxWidth: "75%",
        flexBasis: "75%",
      },
      "&.lg10": {
        flexGlow: 0,
        maxWidth: "83.333333%",
        flexBasis: "83.333333%",
      },
      "&.lg11": {
        flexGlow: 0,
        maxWidth: "91.666667%",
        flexBasis: "91.666667%",
      },
      "&.lg12": {
        flexGlow: 0,
        maxWidth: "100%",
        flexBasis: "100%",
      },
    },
  },
  xl: {
    "@media (min-width: 1920px)": {
      "&.xl1": {
        flexGlow: 0,
        maxWidth: "8.333333%",
        flexBasis: "8.333333%",
      },
      "&.xl2": {
        flexGlow: 0,
        maxWidth: "16.666667%",
        flexBasis: "16.666667%",
      },
      "&.xl3": {
        flexGlow: 0,
        maxWidth: "25%",
        flexBasis: "25%",
      },
      "&.xl4": {
        flexGlow: 0,
        maxWidth: "33.333333%",
        flexBasis: "33.333333%",
      },
      "&.xl5": {
        flexGlow: 0,
        maxWidth: "41.666667%",
        flexBasis: "41.666667%",
      },
      "&.xl6": {
        flexGlow: 0,
        maxWidth: "50%",
        flexBasis: "50%",
      },
      "&.xl7": {
        flexGlow: 0,
        maxWidth: "58.333333%",
        flexBasis: "58.333333%",
      },
      "&.xl8": {
        flexGlow: 0,
        maxWidth: "66.666667%",
        flexBasis: "66.666667%",
      },
      "&.xl9": {
        flexGlow: 0,
        maxWidth: "75%",
        flexBasis: "75%",
      },
      "&.xl10": {
        flexGlow: 0,
        maxWidth: "83.333333%",
        flexBasis: "83.333333%",
      },
      "&.xl11": {
        flexGlow: 0,
        maxWidth: "91.666667%",
        flexBasis: "91.666667%",
      },
      "&.xl12": {
        flexGlow: 0,
        maxWidth: "100%",
        flexBasis: "100%",
      },
    },
  },
  item: {
    margin: 0,
    boxSizing: "border-box",
  },
  container: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    boxSizing: "border-box",
  },
  spacing: {
    "&.sp2": {
      width: "100%",
      margin: -2,
      "& > div": {
        padding: 2,
      },
    },
    "&.sp3": {
      width: "100%",
      margin: -3,
      "& > div": {
        padding: 3,
      },
    },
    "&.sp4": {
      width: "100%",
      margin: -4,
      "& > div": {
        padding: 4,
      },
    },
    "&.sp5": {
      width: "100%",
      margin: -5,
      "& > div": {
        padding: 5,
      },
    },
    "&.sp6": {
      width: "100%",
      margin: -6,
      "& > div": {
        padding: 6,
      },
    },
    "&.sp7": {
      width: "100%",
      margin: -7,
      "& > div": {
        padding: 7,
      },
    },
    "&.sp8": {
      width: "100%",
      margin: -8,
      "& > div": {
        padding: 8,
      },
    },
  },
});
