import { createUseStyles } from "react-jss";
import { ITheme } from "../../../../types/theme";
import iconClose from "../../../../assets/Arena/IconClose.svg";

export const useStyles = createUseStyles((theme: ITheme) => ({
  tgl: {
    display: "none",
    "&:checked + .tgl-btn:after": {
      left: "54%",
    },
    "&:checked + .tgl-btn": {
      background: theme.button.primary.background,
    },
  },
  genButton: {
    outline: 0,
    display: "block",
    width: 40,
    height: 20,
    position: "relative",
    cursor: "pointer",
    "-webkit-user-select": "none",
    "-moz-user-select": "none",
    "-ms-user-select": "none",
    "user-select": "none",
    background: "#8A8A8E",
    borderRadius: "2em",
    padding: 2,
    "-webkit-transition": "all .4s ease",
    transition: "all .4s ease",
    "&:after": {
      position: "relative",
      display: "block",
      content: `""`,
      width: "47%",
      height: "100%",
      left: 0,
      borderRadius: "50%",
      background: "#fff",
      "-webkit-transition": "all .2s ease",
      transition: "all .2s ease",
    },
    "&:before": {
      display: "none",
    },
  },
  withCloseIcon: {
    "&:after": {
      backgroundImage: `url(${iconClose})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
  },
  disabled: {
    opacity: 0.5,
  },
}));
