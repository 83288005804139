export const CATEGORY = {
  ARENA_PAGE: "arenapage",
  BATTLE: "battle",
  CASUAL: "casual",
  DISCOVER_PAGE: "discoverpage",
  FRIENDS_PAGE: "friendspage",
  GAMES_DETAIL_PAGE: "gamedetailpage",
  LOGIN: "login",
  MOMENT: "moment",
  MENU_PAGE: "menupage",
  PROFILE: "profile",
  SIGNUP: "signup",
  INSTALL: "install",
  NOTIF_POPUP: "Notifpopup",
  BATTLE1V1: "1v1battle",
  TOURNAMENT: "tournament",
  WALLET: "wallet",
  ONBOARDING: "onboarding",
  GAME_LISTING_PAGE: "gamelistingpage",
  PROMO_BATTLE: "promobattle",
  SHOP: "shop",
};
