import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  root: {
    minWidth: 330,
    width: 330,
    padding: 20,
    boxSizing: "border-box",
    borderRadius: 15,
    textAlign: "center",
    overflow: "inherit",
  },
  iconLogout: {
    position: "absolute",
    height: 100,
    top: -50,
  },
  iconClose: {
    position: "absolute",
    right: 15,
    top: 15,
  },
  title: {
    fontWeight: 700,
    marginTop: 40,
    fontSize: 18,
  },
  desc: {
    fontSize: 16,
    margin: "15px 0px 20px 0px",
    color: theme.palette.text.secondary,
  },
  notNowBtn: {
    height: 50,
    fontSize: 16,
    fontWeight: 700,
    margin: "5px 0",
    borderRadius: 50,
    background: theme.button.primary.background,
    color: theme.palette.primary.contrastText,
  },
  confirmButton: {
    color: theme.palette.secondary.main,
    textDecoration: "underline",
    fontSize: 16,
    fontWeight: 500,
  },
}));
