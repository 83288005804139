export const GENRES = [
  { title: "games_Puzzle", value: "Puzzle" },
  { title: "games_Platformer", value: "Platformer" },
  { title: "games_Simulator", value: "Simulator" },
  { title: "games_Sports", value: "Sports" },
  { title: "games_Strategy", value: "Strategy" },
  { title: "games_Arcade", value: "Arcade" },
  { title: "games_Adventure", value: "Adventure" },
  { title: "games_Fighting", value: "Fighting" },
  { title: "games_Action", value: "Action" },
  { title: "games_Trivia", value: "Trivia" },
  { title: "games_Runner", value: "Runner" },
  { title: "games_Survival", value: "Survival" },
  { title: "games_Board Game", value: "Board Game" },
  { title: "games_Hyper Casual", value: "Hyper Casual" },
  { title: "games_Horror", value: "Horror" },
  { title: "games_Racing", value: "Racing" },
];

export const CATEGORY_FORYOU = [
  { title: "games_For you", value: "For you" },
  { title: "games_All Games", value: "All Games" },
];

export const TRANSACTION_TYPES = [
  { title: "Deposit", value: "Deposit" },
  { title: "Withdraw", value: "Withdraw" },
  { title: "Fees paid", value: "Fees paid" },
  { title: "Prize won", value: "Prize won" },
  { title: "Refund", value: "Refund" },
  { title: "Bonus", value: "Bonus" },
  { title: "Adjustment", value: "Adjustment" },
];

export const TRANSACTION_TYPES_COIN = [
  { title: "Purchased", value: "Purchased" },
  { title: "Fees paid", value: "Fees paid" },
  { title: "Prize won", value: "Prize won" },
  { title: "Refund", value: "Refund" },
  { title: "Bonus", value: "Bonus" },
  { title: "Adjustment", value: "Adjustment" },
];

export const GAME_MODES = [
  { title: "common_Tournament", value: "Tournament" },
  { title: "common_Battle", value: "Battle" },
  { title: "common_Beat It", value: "Beat It" },
  { title: "common_Friends battle", value: "Friends battle" },
];

export const FAKE_DATA_GAMES = [
  {
    id: "1",
    title: "Cube Puzzle",
    dataMoment: '{"specificSeed" : true, "seed" : 12, "turnNbr" : 90}',
    moment: {
      type: "Score",
      time: 120,
    },
    category: '["Puzzle"]',
    likeCount: 105,
    isLike: false,
  },
  {
    id: "4",
    title: "Bubble Puzzle",
    dataMoment:
      '{"specificSeed" : true, "seed" : 5, "withoutAnimations" : false, "initialNumberOfLine" : 4, "turnBetweenTwoLines" : 3, "initialNumberOfProjectiles" : 100}',
    moment: {
      type: "Score",
      time: 120,
    },
    category: '["Puzzle"]',
    likeCount: 236,
    isLike: false,
  },
  {
    id: "10",
    title: "Same",
    dataMoment:
      '{"specificSeed" : true, "seed" : 5, "gaugeSpeed" : 4.25, "usePowers" : true, "nbrToGainPower" : 7, "instantGameOver" : false, "minObjectiveValue" : 101, "maxObjectiveValue" : 201, "objectiveToWin" : false, "lifeNbr" : 30, "moveNbr" : 20}',
    moment: {
      type: "Score",
      time: 120,
    },
    category: '["Arcade"]',
    likeCount: 24,
    isLike: false,
  },
  {
    id: "5",
    title: "Lucky7",
    dataMoment: '{"specificSeed" : true, "seed" : 28, "useJokers" : true, "infiniteLife" : true}',
    moment: {
      type: "Score",
      time: 120,
    },
    category: '["Puzzle"]',
    likeCount: 24,
    isLike: false,
  },
  {
    id: "11",
    title: "Sweet Rush",
    dataMoment:
      '{"specificSeed" : true, "seed" : 222, "moves" : 60, "gridConfiguration": 0, "gridConfigurationNbr" : 0, "gameMode" : 3}',
    moment: {
      type: "Score",
      time: 120,
    },
    category: '["Puzzle", "Hyper Casual"]',
    likeCount: 28,
    isLike: false,
  },
];

export const TIMELINE_STEP_DELAY_ANIMATION = 0.1;

export const TIMEOUT_HIDE_SLIDE_GUIDE = 5000;
