import { createUseStyles } from "react-jss";
import { ITheme } from "../../../../types/theme";

// @ts-ignore
export const useStyles = createUseStyles((theme: ITheme) => ({
  containerDialog: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  account: {
    fontWeight: 500,
    fontSize: 17,
    padding: "20px 0",
  },
  yourAccount: {
    fontWeight: 400,
    fontSize: 14,
    color: "rgba(138, 138, 142, 1)",
  },
  btnSupport: {
    width: "90%",
    height: 38,
    background: theme.button.primary.background,
    color: "#fff",
    borderRadius: 50,
    fontSize: 16,
    fontWeight: 700,
    margin: "15px 0",
  },
  containerAcc: {
    textAlign: "center",
    padding: 30,
  },
  btnLogin: {
    fontSize: 16,
    fontWeight: 500,
    color: "rgba(138, 138, 142, 1)",
    textDecoration: "underline",
  },
}));
