import React, { useEffect, useState, useContext } from "react";
import { Home as HomeComponent } from "../../components/Chingari/Home";
import { Games } from "../../modelsv2/games";
import { Tournament } from "../../modelsv2/tournament";
import { AppLand } from "../../modelsv2/applands";
import { map, find, filter } from "lodash-es";
import { UserCtx } from "../../context/user/state";
import { GameType } from "../../types/enums";
import { IBaseGame, IBaseListBeatIt } from "../../types/baseInterfaces";
import { Analytics } from "../../modelsv2/analytics";
import { CATEGORY } from "../../constants/analyticsEvent";

const Home = () => {
  const { setListLikedGameStatus, getUserData, userData } = useContext(UserCtx);
  const [topGames, setTopGames] = useState<IBaseGame[] | null>(null);
  const [listBeatIt, setListBeatIt] = useState<IBaseListBeatIt[] | null>(null);

  const getDataGameAndBeatIt = async () => {
    try {
      const [beatIts, listGame] = await Promise.all([
        Tournament.listBeatIt({ fetchPolicy: "cache-first" }),
        Games.fetchLiveGamesListV2({ fetchPolicy: "cache-first" }),
      ]);
      const listHtmlGame = filter(listGame, (game) => game.type === GameType.HTML);
      const listHtmlBeatIts = filter(beatIts, (beatIt) => beatIt?.tournamentInfo?.moment?.app?.type === GameType.HTML);
      const predictedGameExperiences = await AppLand.getPredictedGameExperiences();
      const gameForYou = map(listHtmlGame, (game: IBaseGame) => {
        const gamePredictedExperience = find(predictedGameExperiences, (o) => o.appId === parseInt(game.id));
        return { ...game, predictedExperience: gamePredictedExperience?.score };
      });
      setTopGames(gameForYou);
      setListBeatIt(listHtmlBeatIts);
      setListLikedGameStatus(listHtmlGame);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getDataGameAndBeatIt();
    getUserData();
    Analytics.trackEvent({
      category: CATEGORY.DISCOVER_PAGE,
      action: "view",
      user_uid: userData?.id,
    });
  }, []);

  return <HomeComponent topGames={topGames} listBeatIt={listBeatIt} />;
};

export default Home;
