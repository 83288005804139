import React, { useReducer } from "react";
import { INITIAL_STATE } from "./constants";
import { onOpenDialog, onCloseDialog } from "./dispatchers";
import Reducer from "./reducer";
import IGuestDialogCtx from "./types";

export const GuestDialogCtx = React.createContext<IGuestDialogCtx>(INITIAL_STATE);

export default function AlertProvider({ children }: any) {
  const [state, dispatch] = useReducer(Reducer, INITIAL_STATE);

  const setters = {
    onOpenDialog: () => onOpenDialog(dispatch),
    onCloseDialog: () => onCloseDialog(dispatch),
  };

  return <GuestDialogCtx.Provider value={{ ...state, ...setters }}>{children}</GuestDialogCtx.Provider>;
}
