import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    color: "#C5C5C7",
    fontSize: 14,
    fontWeight: 400,
    height: "18px",
    padding: "6px",
  },
  highlight: {
    fontWeight: "500",
    color: "#ffffff",
    flexBasis: "40%",
  },
  ellipsis: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "inline-block",
  },
}));
