import { createUseStyles } from "react-jss";

// @ts-ignore
export const useStyles = createUseStyles(() => ({
  phoneNumberGrid: {
    marginTop: "64px",
    marginBottom: "56px",
    "@media(max-height: 667px)": {
      marginTop: "51px",
      marginBottom: "44px",
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      marginTop: "57px",
      marginBottom: "50px",
    },
  },
  phoneNumberPlaceholder: {
    position: "relative",
    top: "-34px",
    left: "90px",
    backgroundColor: "white",
    width: "70%",
    color: "#898989",
    fontSize: "15px",
    "@media(max-height: 667px)": {
      top: "-29px",
      fontSize: "12px",
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      top: "-33px",
      fontSize: "13px",
    },
  },
  title: {
    textAlign: "center",
  },
  initialColorButton: {
    color: "initial",
  },
  inputCustom: {
    width: "100% !important",
    height: "50px !important",
    borderRadius: "8px !important",
  },
  btnDropDown: {
    borderRadius: "8px 0px 0px 8px !important",
    "& > .selected-flag": {
      borderRadius: "8px 0px 0px 8px !important",
    },
  },
  content: {
    width: "calc(100vw - 30px) !important",
  },
}));
