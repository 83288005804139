import { createUseStyles } from "react-jss";
import { ITheme } from "../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  root: {
    position: "fixed",
    bottom: "1%",
    left: "50%",
    zIndex: 9999,
    width: "80%",
    borderRadius: "10px",
    transform: "translateX(-50%)",
    padding: "8px",
    "&.deposit": {
      backgroundColor: "#FAFAFA",
      boxShadow: "1px 1px 15px #000000",
    },
    "&.copied": {
      backgroundColor: "rgba(0,0,0,.9)",
    },
  },
  body: {},
  content: {
    display: "flex",
    flexDirection: "column",
  },
  highligth: {
    fontWeight: "500",
  },
  close: {
    marginRight: "24px;",
  },
}));
