import { createUseStyles } from "react-jss";
import { ITheme } from "../../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  root: {
    height: "100%",
    width: "100%",
    borderRadius: "50%",
    display: "flex",
    overflow: "hidden",
    position: "relative",
    alignItems: "center",
    flexShrink: 0,
    justifyContent: "center",
  },
  img: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  rounded: {
    borderRadius: 4,
  },
  square: {
    borderRadius: "initial",
  },
}));
