import React, { useContext } from "react";
import FriendsProvider from "./friends/state";
import GuestDialogProvider from "./guestDialog/state";
import {
  BattleCompeletedSubscription,
  DepositBonusPaid,
  RequestBattleSubscription,
  EndScreenSubscription,
} from "../components/Subscriptions";
import { UserCtx } from "./user/state";

export const GlobalContextProvider = ({ children }: any) => {
  const { isWsClientConnected } = useContext(UserCtx);
  return (
    <GuestDialogProvider>
      {isWsClientConnected && (
        <>
          <RequestBattleSubscription />
          <BattleCompeletedSubscription />
          <EndScreenSubscription />
          <DepositBonusPaid />
        </>
      )}
      <FriendsProvider>{children}</FriendsProvider>
    </GuestDialogProvider>
  );
};
