import apollo from "../clients/apollo";
import { listFriendsReceivedV2, listFriendsSentV2 } from "../apollo/queries/friends";
import { IBaseFriendRequest } from "../types/baseInterfaces";
import { CreateFriendRequestInput } from "../types/inputTypes";
import { createFriendRequest, acceptFriendRequest, deleteFriend } from "../apollo/mutations/friends";

const apolloClient = apollo.getInstance();

export class Friends {
  /**
   * To send a friend request to someone
   */
  static sendFriendRequest = async (input: CreateFriendRequestInput): Promise<IBaseFriendRequest> => {
    const res = await apolloClient.mutate({
      mutation: createFriendRequest,
      variables: { createFriendRequestInput: { ...input } },
    });

    return res.data.createFriendRequest;
  };

  /**
   * To accept a friend request from someone
   */
  static acceptFriendRequest = async (requestId: string) => {
    return apolloClient.mutate({ mutation: acceptFriendRequest, variables: { id: requestId } });
  };

  /**
   * To delete a friend request
   */
  static deleteFriendRequest = async (requestId: String) => {
    return apolloClient.mutate({ mutation: deleteFriend, variables: { deleteFriendInput: { id: requestId } } });
  };

  /**
   * To get list of received friend requests of the user are using app
   */
  static getListFriendRequest = async (): Promise<IBaseFriendRequest[]> => {
    const res = await apolloClient.query({ fetchPolicy: "network-only", query: listFriendsReceivedV2, variables: {} });
    return res.data.listFriendsReceivedV2;
  };

  /**
   * To get list of sent friend requests of the user are using app
   */
  static getListFriendsSent = async (): Promise<IBaseFriendRequest[]> => {
    const res = await apolloClient.query({
      fetchPolicy: "network-only",
      query: listFriendsSentV2,
      variables: {},
    });
    return res.data.listFriendsSentV2;
  };

  // /**
  //  * To get event when the user that using app receive a new friend request
  //  */
  // static subscribeOnReceiveFriendRequest = async (userId: String, callback: Function) => {
  //   return apolloClient
  //     .subscribe({
  //       query: onGetFriendRequest,
  //       variables: { receiverId: userId },
  //     })
  //     .subscribe({
  //       next(data) {
  //         callback();
  //       },
  //     });
  // };

  // /**
  //  * To get event when a friend request for the user that using app deleted
  //  */
  // static subscribeOnReceiverFriendRequestDelete = async (userId: String, callback: Function) => {
  //   return apolloClient
  //     .subscribe({
  //       query: onReceiverFriendRequestDelete,
  //       variables: { receiverId: userId },
  //     })
  //     .subscribe({
  //       next(data) {
  //         callback();
  //       },
  //     });
  // };

  // /**
  //  * To get event when the user that using app delete a sent friend request
  //  */
  // static subscribeOnSenderFriendRequestDelete = async (userId: String, callback: Function) => {
  //   return apolloClient
  //     .subscribe({
  //       query: onSenderFriendRequestDelete,
  //       variables: { receiverId: userId },
  //     })
  //     .subscribe({
  //       next(data) {
  //         callback();
  //       },
  //     });
  // };
}
