import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";
import BackgroundImg from "../../../assets/Chingari/Background.png";

export const useStyles = createUseStyles((theme: ITheme) => ({
  root: {
    backgroundImage: `url(${BackgroundImg})`,
    height: "100%",
    backgroundSize: "cover",
    padding: 20,
    color: theme.palette.primary.contrastText,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxSizing: "border-box",
    overflow: "auto",
  },
  title: {
    fontWeight: 700,
    textTransform: "uppercase",
    textAlign: "center",
    marginBottom: 25,
  },
  agreement: {
    fontSize: 14,
    textAlign: "center",
  },
  link: {
    color: theme.palette.primary.contrastText,
    borderBottom: `1px solid ${theme.palette.background.default}`,
  },
}));
