import { map } from "lodash-es";
import { IOnboardingGame } from "../types/baseInterfaces";

export class GameFactory {
  static updateLikeGames = (gameId: string, games: IOnboardingGame[]) => {
    return map(games, (game) => {
      if (game?.id === gameId) {
        game.isLike = !game.isLike;
        if (!game?.isLike) {
          game.likeCount -= 1;
        } else {
          game.likeCount += 1;
        }
      }
      return game;
    });
  };
}
