import React, { useContext } from "react";
import { AlertCtx } from "../../context/alert/state";
import { useStyles } from "./InAppNotification.style";
import clsx from "clsx";
import InAppNotificationDeposit from "./BodyType/InAppNotificationDeposit";
import InAppNotificationCopied from "./BodyType/InAppNotificationCopied";

export interface IInAppNotificationBody {
  onCloseHandler: () => void;
  children: string;
}

export function InAppNotification() {
  const classes = useStyles();
  const { setShowNotif, body, classify } = useContext(AlertCtx);

  const closeHandler = () => {
    setShowNotif(false);
  };

  if (classify !== "deposit" && classify !== "copied") {
    return null;
  }

  return (
    <div className={clsx(classes.root, classify)} data-testid="in-app-notification">
      <div className={classes.body} data-testid="in-app-notification-body">
        {classify === "deposit" && (
          <InAppNotificationDeposit onCloseHandler={closeHandler} value={body.value} currency={body.currency}>
            {body.message}
          </InAppNotificationDeposit>
        )}
        {classify === "copied" && (
          <InAppNotificationCopied onCloseHandler={closeHandler} value={body.value}>
            {body.message}
          </InAppNotificationCopied>
        )}
      </div>
    </div>
  );
}

export default InAppNotification;
