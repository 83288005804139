import React, { HTMLAttributes, FC, useEffect, useState } from "react";
import { Grid, Typography } from "../ComponentExport";
import { formatTime } from "../../../utils/utils";
import { TIMELINE_STEP_DELAY_ANIMATION } from "../../../constants/game";
import { useStyles } from "./TimelineVideo.style";
import clsx from "classnames";

interface ITimelineVideoProps {
  timeInSeconds: number; // amount seconds
  isPause?: boolean;
  onToggleVideo: Function;
  onSetVideoTime: Function;
}

export const TimelineVideo: FC<ITimelineVideoProps & HTMLAttributes<HTMLDivElement>> = (props) => {
  const classes = useStyles();
  const { timeInSeconds, isPause, onToggleVideo, onSetVideoTime, ...otherProps } = props;
  const [countPaused, setCountPaused] = useState(0);

  const onChangeTimeline = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCountPaused(parseFloat(event.target.value));
    onSetVideoTime(parseFloat(event.target.value));
  };

  useEffect(() => {
    if (timeInSeconds === 0) return;
    const timeUp = setInterval(() => {
      if (!isPause && countPaused <= timeInSeconds) {
        setCountPaused(countPaused + TIMELINE_STEP_DELAY_ANIMATION);
      }
      if (parseInt(countPaused.toString(), 10) === parseInt(timeInSeconds.toString(), 10)) {
        clearInterval(timeUp);
        setCountPaused(0);
        onToggleVideo();
      }
    }, 100);
    return () => {
      clearInterval(timeUp);
    };
  }, [timeInSeconds, isPause, countPaused]);

  return (
    <Grid
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      {...otherProps}
      className={clsx(classes.timeLine, !isPause && countPaused > 3 && "hide")}>
      <Typography className={classes.time}>{formatTime(Math.round(countPaused))}</Typography>
      <input
        value={countPaused}
        type="range"
        className={classes.slide}
        max={timeInSeconds}
        step={TIMELINE_STEP_DELAY_ANIMATION}
        min={0}
        style={{ backgroundSize: `${(countPaused / timeInSeconds) * 100}% 100%` }}
        onChange={onChangeTimeline}
      />
      <Typography className={classes.time}>{formatTime(timeInSeconds)}</Typography>
    </Grid>
  );
};
