import { createUseStyles } from "react-jss";
import { ITheme } from "../../types/theme";

// @ts-ignore
export const useStyles = createUseStyles((theme: ITheme) => ({
  mainContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  resetPwdDiv: {
    display: "flex",
    alignItems: "baseline",
  },
  showPassword: {
    fontWeight: "bold",
    fontSize: "10px",
    lineHeight: "16px",
    color: theme.palette.primary.main,
    padding: "0 14px",
    "& .disabled": {
      color: theme.palette.text.primary,
    },
  },
  wrongEmailError: {
    color: "#BF0E08",
    marginLeft: "0px",
    marginTop: "4px",
    fontSize: "12px",
    lineHeight: "15px",
  },
  backButton: {
    fontSize: "15px",
    lineHeight: "20px",
    letterSpacing: "-0.2px",
    paddingLeft: "0px",
    margin: "0px",
    justifyContent: "flex-start",
    "@media(max-height: 667px)": {
      fontSize: "12px",
      lineHeight: "16px",
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      fontSize: "13px",
      lineHeight: "18px",
    },
    "& > svg": {
      fontSize: 15,
    },
  },
  btnDisabled: {
    background: "#C0C0C0",
    color: `${theme.palette.primary.contrastText} !important`,
  },
}));
