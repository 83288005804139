import React, { useContext, useEffect } from "react";
import { isNull } from "lodash-es";
import { useHistory } from "react-router-dom";
import nativeShare from "../../../utils/nativeSharing";
import IconClose from "../../../assets/ArenaItemRow/Close.svg";
import { useStyles } from "./PopupResultScreen.style";
import { UserCtx } from "../../../context/user/state";
import { IBaseDailyTournament } from "../../../types/baseInterfaces";
import IconWon from "../../../assets/ArenaItemRow/IconTournamentBeatIt.svg";
import IconLose from "../../../assets/ArenaItemRow/IconTournamentLost.svg";
import IconShare from "../../../assets/Game/share-v3.svg";
import { Analytics } from "../../../modelsv2/analytics";
import { Users } from "../../../modelsv2/users";
import { CATEGORY } from "../../../constants/analyticsEvent";
import { Dialog, Typography, CircularProgress, Button } from "../../Common/ComponentExport";
import { renderCurrencyText } from "../../../utils/renderCurrency";
import { LeaderBoard } from "../../Common/LeaderBoard";
import { ThemeName } from "../../../constants/theme";

interface IPropsPopupResultScreen {
  isOpen: boolean;
  resetGame: Function;
  tournament: IBaseDailyTournament | null;
  onResetGame?: Function;
}

const APP_URL = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ""
}`;

export const PopupResultScreen = (props: IPropsPopupResultScreen) => {
  const classes = useStyles();
  const history = useHistory();
  const { isOpen, resetGame, tournament, onResetGame } = props;
  const { userData } = useContext(UserCtx);

  const trackEvents = (label: string) => {
    Analytics.trackEvent({
      category: CATEGORY.TOURNAMENT,
      action: "result_screen",
      label,
      user_uid: userData?.id,
    });
  };

  const onClosePopup = () => {
    trackEvents("xclose");
    resetGame();
  };

  const onClickShare = () => {
    try {
      trackEvents("share");
      const shareData: ShareData = {
        title: `${userData.username} played ${tournament?.moment?.app?.title}`,
        text: `I just played ${tournament?.moment?.app?.title} on ${ThemeName.Onmo}. Try it out and challenge me. My user name is ${userData.username}`,
        url: `${APP_URL}/login?shareBy=${userData.id}`,
      };
      nativeShare(shareData, userData.id);
    } catch (e) {
      console.log(e);
    }
  };

  const onNavigateOnmo = () => {
    trackEvents("tryonmo");
    window.open("https://play.onmo.com/", "_blank");
  };

  const onPlayAgain = () => {
    if (!onResetGame) return;
    trackEvents("replay");
    onResetGame();
    history.replace(history.location.pathname);
  };

  const getUserProfile = async () => {
    try {
      await Users.getUserProfile(userData?.id, { fetchPolicy: "network-only" });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (isOpen && tournament) {
      Analytics.trackEvent({
        category: CATEGORY.MOMENT,
        action: "end",
        label: tournament?.moment?.app?.title,
        moment_type: tournament?.moment?.type,
        game_type: tournament?.moment?.app?.type,
        currency: renderCurrencyText(tournament?.currency),
        user_uid: userData?.id,
      });
      trackEvents("view");
      getUserProfile();
    }
  }, [isOpen, tournament]);

  const renderContent = () => (
    <div>
      <div className={classes.headerPopup}>
        <img src={IconShare} alt="icon-share" onClick={onClickShare} data-testid="click-share" />
        <img src={tournament?.myRank === 1 ? IconWon : IconLose} alt="icon-battle" className={classes.imgBattle} />
        <img src={IconClose} alt="icon-close" onClick={onClosePopup} data-testid="click-close" />
      </div>
      <div className={classes.content}>
        <Typography variant="h3" className={classes.textStatus}>
          {tournament?.myRank === 1 ? "Congrats! You beat the score!" : "Not bad!"}
        </Typography>
        <Typography className={classes.textDesc}>
          {tournament?.myRank === 1
            ? "You’ve got great skills, why not try our real cash tournament"
            : "You have better chances of winning in real cash tournaments."}
        </Typography>
        <div className={classes.contentLB}>
          <LeaderBoard isTransparent={false} myId={userData.id} tournament={tournament} />
        </div>
        <Button
          variant="contained"
          className={classes.btnAccept}
          fullWidth
          data-testid="navigate-onmo"
          onClick={onNavigateOnmo}>
          Try <Typography variant="h5">ONMO.com</Typography> for real cash gaming
        </Button>
        <Button
          variant="text"
          className={classes.btnPlayAgain}
          data-testid="btn-play-again"
          fullWidth
          onClick={onPlayAgain}>
          Play Again
        </Button>
      </div>
    </div>
  );

  return (
    <Dialog open={isOpen} onClose={onClosePopup} className={classes.paper}>
      <div className={classes.dialogContent}>
        {isNull(tournament) ? (
          <div className={classes.contentLoading}>
            <CircularProgress size={35} />
          </div>
        ) : (
          renderContent()
        )}
      </div>
    </Dialog>
  );
};
