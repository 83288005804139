import React, { useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { DEPOSIT_BONUS_PAID } from "../../apollo/subscriptions/deposit";
import { UserCtx } from "../../context/user/state";
import {
  TransactionStatus,
  TRANSACTION_ACTION_TYPE,
  TRANSACTION_CATEGORY,
  Transaction_Types,
  TRANSACTION_WORKFLOW,
  UserWalletType,
} from "../../types/enums";

interface ISubscriptionTransaction {
  amount: number;
  currency: string;
  description: string;
  entryId: string;
  escrowId: string;
  postedOn: number;
  reference: string;
  runningBalance: number;
  sequence: number;
  walletId: string;
  workflow: TRANSACTION_WORKFLOW;
}

interface ISubscriptionBonus {
  balance: number;
  bonusId: string;
  bonusType: string;
  currency: string;
  date: string;
  description: string;
  escrowId: string;
  expiryDate: string;
  initialAmount: number;
  userId: string;
}

export const DepositBonusPaid = () => {
  const { updateUserTransactions, userTransactions, userWallets, userData, updateUserWallets } = React.useContext(
    UserCtx
  );
  const [subsTransaction, setSubsTransaction] = React.useState<ISubscriptionTransaction[] | null>(null);
  const [subsBonus, setSubsBonus] = React.useState<ISubscriptionBonus | null>(null);

  const client = useApolloClient();

  useEffect(() => {
    const observer = client.subscribe({
      query: DEPOSIT_BONUS_PAID,
    });

    const subscription = observer.subscribe((data: any) => {
      const transactions = data?.data?.depositBonusPaid?.onmoPaymentResponse?.transactions || [];
      const bonus = data?.data?.depositBonusPaid?.onmoPaymentResponse?.bonus;
      if (transactions.length && bonus) {
        const bonusPayTransaction = transactions.filter(
          (transaction: ISubscriptionTransaction) => transaction.workflow === TRANSACTION_WORKFLOW.bonusPay
        );
        setSubsTransaction(bonusPayTransaction);
        setSubsBonus(bonus);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!subsTransaction?.length || !userTransactions || !subsBonus || !userData) return;
    setSubsTransaction(null);

    const lastBonusTransactions = userTransactions.filter(
      (transaction) => transaction.transactionType === TRANSACTION_ACTION_TYPE.BONUS_CASH_RECEIVED
    );
    const runningBalance = lastBonusTransactions[0]?.runningBalance + Math.abs(subsBonus.balance);
    const bonusTransaction = subsTransaction[0];

    if (bonusTransaction) {
      updateUserTransactions([
        {
          ...bonusTransaction,
          transactionType: TRANSACTION_ACTION_TYPE.BONUS_CASH_RECEIVED,
          amount: subsBonus.balance,
          category: TRANSACTION_CATEGORY.WALLET,
          gameId: null,
          gameTitle: null,
          createdAt: Date.now(),
          type: Transaction_Types.credit,
          walletType: UserWalletType.BONUS,
          status: TransactionStatus.completed,
          runningBalance,
        },
        ...userTransactions,
      ]);
      const newUserWallets = userWallets.map((w) =>
        w.type === UserWalletType.BONUS ? { ...w, balance: subsBonus.balance } : w
      );

      updateUserWallets(newUserWallets);
    }
  }, [subsTransaction, userTransactions, subsBonus, userWallets]);

  return <div data-testid="deposit-bonus-paid"></div>;
};

export default DepositBonusPaid;
