import React, { HTMLAttributes, FC } from "react";
import { Grid, Button } from "../ComponentExport";
import { ReactComponent as LogoIcon } from "../../../assets/GuestMode/OnmoWhite.svg";
import { useStyles } from "./HeaderOnboarding.style";
import { useTranslation } from "react-i18next";

interface IHeaderOnboardingProps {
  onTogglePopup: Function;
}

export const HeaderOnboarding: FC<IHeaderOnboardingProps & HTMLAttributes<HTMLDivElement>> = (
  props: IHeaderOnboardingProps
) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onTogglePopup, ...otherProps } = props;

  return (
    <Grid {...otherProps} display="flex" justifyContent="space-between" alignItems="center">
      <LogoIcon />
      <Button variant="contained" className={classes.btnSignUp} onClick={onTogglePopup}>
        {t("common_Sign Up")}
      </Button>
    </Grid>
  );
};
