export const countDown = (endtime: Date | number | string) => {
  // create Date object for endtime
  const utcFormat = new Date(endtime);
  // get timeZone offset
  const timezoneOffset = utcFormat.getTimezoneOffset();
  const timeZone = timezoneOffset / -60;
  // create Date object for current location
  const date = new Date();
  // get UTC time in msec
  const utc = date.getTime() + date.getTimezoneOffset() * 60000;
  // convert current date to endtime's time zone
  const dateConverted = new Date(utc + 3600000 * timeZone);
  //@ts-ignore
  const timeLeft = endtime - dateConverted.getTime();

  let seconds: any = Math.floor((timeLeft / 1000) % 60);
  let minutes: any = Math.floor((timeLeft / 1000 / 60) % 60);
  let hours: any = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
  let days: any = Math.floor(timeLeft / (1000 * 60 * 60 * 24));

  if (days < 0 && hours < 0) return "N/A";

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  if (days === 0) return hours + ":" + minutes + ":" + seconds;
  return days + " days " + hours + ":" + minutes + ":" + seconds;
};
