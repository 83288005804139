import React, { useContext, useEffect, useState } from "react";
import { UserCtx } from "../../context/user/state";
import { useApolloClient } from "@apollo/client";
import {
  ACCEPT_FRIEND_REQUEST_SUBSCRIPTION,
  CREATE_FRIEND_REQUEST_SUBSCRIPTION,
  DELETE_FRIEND_SUBSCRIPTION,
} from "../../apollo/subscriptions/friends";
import { FriendsCtx } from "../../context/friends/state";
import { IBaseFriendRequest } from "../../types/baseInterfaces";
import {
  getFriendIdFromDeleteFriendRequest,
  isNewFriendRequestUpdated,
  removeAFriendFromFriendList,
  removeARequestFromSentList,
  removeAFriendRequestFromReceivedList,
} from "./helpers/friends";

export const FriendSubscriptions = () => {
  const { userData, updateLocalStateUser } = useContext(UserCtx);
  const {
    updateFriendRequestList,
    friendRequests,
    sentRequests,
    friends,
    updateFriendRequestSentList,
    updateFriendList,
  }: any = useContext(FriendsCtx);
  const [newFriend, setNewFriend] = useState<IBaseFriendRequest | null>(null);
  const [newFriendRequest, setNewFriendRequest] = useState<IBaseFriendRequest | null>(null);
  const [deletedFriend, setDeletedFriend] = useState<any>(null);

  const client = useApolloClient();

  React.useEffect(() => {
    const observer = client.subscribe({
      query: CREATE_FRIEND_REQUEST_SUBSCRIPTION,
    });

    const subscription = observer.subscribe((data: any) => {
      setNewFriendRequest(data?.data?.friendRequestCreated);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // Update local friend list and friend request list when have a new friend request from ACCEPT_FRIEND_REQUEST_SUBSCRIPTION
  useEffect(() => {
    if (!newFriendRequest || isNewFriendRequestUpdated(friendRequests, newFriendRequest.id)) return;
    updateLocalStateUser({ ...userData, nbFriendRequestUnaccepted: 1 });
    updateFriendRequestList(friendRequests?.length ? [newFriendRequest, ...friendRequests] : [newFriendRequest]);
    setNewFriendRequest(null);
  }, [newFriendRequest, friendRequests, userData]);

  React.useEffect(() => {
    const observer = client.subscribe({
      query: ACCEPT_FRIEND_REQUEST_SUBSCRIPTION,
    });

    const subscription = observer.subscribe((data: any) => {
      setNewFriend(data?.data?.acceptedFriendRequest);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // Update local friend list and friend request list when friend request is accepted from ACCEPT_FRIEND_REQUEST_SUBSCRIPTION
  useEffect(() => {
    if (!newFriend) return;
    const updatedSentList = removeARequestFromSentList(newFriend?.id, sentRequests);

    updateFriendList([newFriend?.receiver, ...(friends || [])]);
    updateFriendRequestSentList(updatedSentList);
    setNewFriend(null);
  }, [newFriend, friends, sentRequests]);

  React.useEffect(() => {
    const observer = client.subscribe({
      query: DELETE_FRIEND_SUBSCRIPTION,
    });

    const subscription = observer.subscribe((data: any) => {
      setDeletedFriend(data?.data?.deletedFriend);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // Update local friends list and friend request list when unfriended or rejected from DELETE_FRIEND_SUBSCRIPTION
  useEffect(() => {
    if (!deletedFriend || !userData) return;
    const deletedFriendId = getFriendIdFromDeleteFriendRequest(userData.id, deletedFriend);
    const updatedFriendList = removeAFriendFromFriendList(friends, deletedFriendId);
    updateFriendList(updatedFriendList);

    // Check if the friend request is rejected then update friend request sent list
    const updatedSentList = removeARequestFromSentList(deletedFriend.id, sentRequests);
    if (updatedSentList?.length < sentRequests?.length) {
      updateFriendRequestSentList(updatedSentList);
    }

    // Check if the friend request is cancel then update friend requests list
    const updatedReceivedList = removeAFriendRequestFromReceivedList(deletedFriend.id, friendRequests);
    if (updatedReceivedList?.length < friendRequests?.length) {
      updateFriendRequestList(updatedReceivedList);
      updateLocalStateUser({
        ...userData,
        nbFriendRequestUnaccepted: userData?.nbFriendRequestUnaccepted ? userData?.nbFriendRequestUnaccepted - 1 : 0,
      });
    }

    setDeletedFriend(null);
  }, [deletedFriend, sentRequests, friends, friendRequests, userData]);

  return <div data-testid="friend-subscription"></div>;
};

export default FriendSubscriptions;
