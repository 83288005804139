import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  wrapper: {
    height: "100vh",
    display: "inherit",
  },
  header: {
    background: "#260E2E",
    "& > p": {
      color: theme.palette.primary.contrastText,
      fontSize: 16,
      fontWeight: 500,
    },
  },
  content: {
    margin: "auto",
    background: "#260E2E",
    marginTop: -1, // Check again
    minHeight: "calc(100vh - 56px)",
  },
  mainAvatar: {
    display: "flex",
    justifyContent: "center",
    padding: "30px 0px",
    "& > div > svg": {
      position: "absolute",
      right: 5,
      bottom: 10,
    },
    "& > div": {
      height: 128,
      width: 128,
      position: "relative",
    },
    "& > div > div": {
      border: `3px solid ${theme.palette.background.default}`,
    },
  },
  fieldName: {
    padding: "0px 15px",
    marginTop: 10,
    "& > h4": {
      color: theme.palette.primary.contrastText,
    },
  },
  inputName: {
    borderBottom: "1px solid #472C4B",
    "& > div": {
      color: theme.palette.secondary.main,
    },
  },
}));
