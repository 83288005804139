import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  btnSignUp: {
    background: "rgba(255, 255, 255, 0.5)",
    color: theme.palette.primary.contrastText,
    fontSize: 12,
    fontWeight: 500,
    width: 100,
    borderRadius: 30,
    boxSizing: "border-box",
  },
}));
