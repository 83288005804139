import gql from "graphql-tag";

export const onGetFriendRequest = gql`
  subscription OnGetFriendRequest($receiverId: ID) {
    onGetFriendRequest(receiverId: $receiverId) {
      id
      senderId
      receiverId
      accepted
      createdAt
      updatedAt
      sender {
        id
        email
        phone
        facebookId
        googleId
        username
        avatar
        banner
        xp
        currency
        pushSubscription
        createdAt
        updatedAt
      }
      receiver {
        id
        email
        phone
        facebookId
        googleId
        username
        avatar
        banner
        xp
        currency
        pushSubscription
        createdAt
        updatedAt
      }
    }
  }
`;

export const onFriendRequestUpdate = gql`
  subscription OnFriendRequestUpdate($senderId: ID) {
    onFriendRequestUpdate(senderId: $senderId) {
      id
      senderId
      receiverId
      accepted
      createdAt
      updatedAt
      sender {
        id
        email
        phone
        facebookId
        googleId
        username
        avatar
        banner
        xp
        currency
        pushSubscription
        createdAt
        updatedAt
      }
      receiver {
        id
        email
        phone
        facebookId
        googleId
        username
        avatar
        banner
        xp
        currency
        pushSubscription
        createdAt
        updatedAt
      }
    }
  }
`;
export const onSenderFriendRequestDelete = gql`
  subscription OnSenderFriendRequestDelete($receiverId: ID) {
    onSenderFriendRequestDelete(receiverId: $receiverId) {
      id
      senderId
      receiverId
      accepted
      createdAt
      updatedAt
      sender {
        id
        email
        phone
        facebookId
        googleId
        username
        avatar
        banner
        xp
        currency
        pushSubscription
        createdAt
        updatedAt
      }
      receiver {
        id
        email
        phone
        facebookId
        googleId
        username
        avatar
        banner
        xp
        currency
        pushSubscription
        createdAt
        updatedAt
      }
    }
  }
`;
export const onReceiverFriendRequestDelete = gql`
  subscription OnReceiverFriendRequestDelete($senderId: ID) {
    onReceiverFriendRequestDelete(senderId: $senderId) {
      id
      senderId
      receiverId
      accepted
      createdAt
      updatedAt
      sender {
        id
        email
        phone
        facebookId
        googleId
        username
        avatar
        banner
        xp
        currency
        pushSubscription
        createdAt
        updatedAt
      }
      receiver {
        id
        email
        phone
        facebookId
        googleId
        username
        avatar
        banner
        xp
        currency
        pushSubscription
        createdAt
        updatedAt
      }
    }
  }
`;

export const CREATE_FRIEND_REQUEST_SUBSCRIPTION = gql`
  subscription OnFiendRequestCreated {
    friendRequestCreated {
      id
      sender {
        id
        username
        avatar
        xp
      }
      receiver {
        id
        username
        avatar
        xp
      }
      senderId
      receiverId
      accepted
      createdAt
      updatedAt
    }
  }
`;

export const ACCEPT_FRIEND_REQUEST_SUBSCRIPTION = gql`
  subscription OnFiendRequestAccepted {
    acceptedFriendRequest {
      id
      sender {
        id
        username
        avatar
        xp
      }
      receiver {
        id
        username
        avatar
        xp
      }
      senderId
      receiverId
      accepted
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_FRIEND_SUBSCRIPTION = gql`
  subscription onFriendDeleted {
    deletedFriend {
      id
      receiverId
      senderId
      sender {
        id
        username
        avatar
        xp
      }
      receiver {
        id
        username
        avatar
        xp
      }
      createdAt
      updatedAt
    }
  }
`;
