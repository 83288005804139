import gql from "graphql-tag";

export const listHotBattles = gql`
  query ListHotBattles {
    listHotBattles {
      matchId
      entryFee
      matchState
      costPerUser
      currency
      scoreToBeat
      prize
      winType
      createdAt
      host {
        id
        username
        avatar
      }
      game {
        id
        title
        battleCost {
          fee
          currency
          cost
        }
      }
    }
  }
`;

export const getMatch = gql`
  query GetMatch($getMatchInput: LightMatchInput!) {
    getMatch(getMatchInput: $getMatchInput) {
      matchState
      battleInfo {
        gameId
        entryFee
        prizePool
        currency
        costPerUser
        battleId
      }
      moment {
        appId
        title
        type
        app {
          useSimulation
          title
        }
      }
      players {
        userId
        playState
        score
        user {
          id
          avatar
          username
        }
      }
      winners {
        rank
        userId
      }
      expiryTime
      winAmount
    }
  }
`;

export const getHouseMatch = gql`
  query GetHouseMatch($matchId: String!) {
    getHouseMatch(matchId: $matchId) {
      matchState
      battleInfo {
        gameId
        entryFee
        prizePool
        currency
        costPerUser
        battleId
      }
      moment {
        appId
        title
        type
        app {
          useSimulation
          title
        }
      }
      players {
        userId
        playState
        score
        user {
          id
          avatar
          username
        }
      }
      winners {
        rank
        userId
      }
      expiryTime
      winAmount
    }
  }
`;

export const listMatches = gql`
  query ListMatches($listMatchesInput: ListMatchesInput!) {
    listMatches(listMatchesInput: $listMatchesInput) {
      matchList {
        matchId
        expiryTime
        matchState
        battleInfo {
          entryFee
          prizePool
        }
        moment {
          title
          type
          app {
            useSimulation
            id
            title
          }
        }
        players {
          userId
          score
          user {
            id
            avatar
            username
          }
        }
        winners {
          rank
          userId
        }
      }
      matchCount
    }
  }
`;

export const listBattlesForYou = gql`
  query ListBattlesForYou {
    listBattlesForYou {
      matchId
      currency
      costPerUser
      entryFee
      matchState
      host {
        id
        username
        avatar
        banner
        xp
      }
      game {
        id
        title
        description
        category
        data
        timesPlayed
        rotationMode
        status
        type
        battleCost {
          fee
          cost
          currency
        }
        canUseCash
        isLike
        likeCount
        totalCompleted
        countMoments
        hasBattle
        hasSolo
        hasTournament
      }
    }
  }
`;

export const getBeatIt = gql`
  query GetBeatIt($getBeatItInput: GetBeatItInput) {
    getBeatIt(getBeatItInput: $getBeatItInput) {
      tournament {
        id
        gameId
        momentId
        momentType
        startedAt
        expiredAt
        status
        currency
        costPerUser
        fee
        rankingOrder
        playerCount
        highestScore
        myRank
        leaderboards {
          id
          userId
          tournamentId
          score
          startedAt
          endedAt
          status
          user {
            id
            username
            avatar
          }
        }
        tournamentType
        prizePool
      }
      user {
        id
        username
        avatar
        tournamentId
        score
      }
    }
  }
`;
