import gql from "graphql-tag";

export const getTournament = gql`
  query Query(
    $getTournamentGetTournamentInput: GetTournamentInput
    $leaderboardsGetLeaderboardInput: GetLeaderboardsInput
  ) {
    getTournament(getTournamentInput: $getTournamentGetTournamentInput) {
      id
      gameId
      leaderboards(getLeaderboardInput: $leaderboardsGetLeaderboardInput) {
        status
        endedAt
        startedAt
        score
        user {
          xp
          banner
          avatar
          username
          id
        }
        userId
        tournamentId
        id
      }
      calculatedPrizePool
      prizePool
      topScore
      totalPrize
      playerCount
      rankingOrder
      fee
      currency
      costPerUser
      status
      expiredAt
      startedAt
      momentType
      momentId
      maxPlayers
      tournamentType
      moment {
        id
        appId
        app {
          useSimulation
          title
          type
          category
          rotationMode
        }
        description
        snapshotId
        title
        unlockXp
        timesPlayed
        momentType
        isCompleted
        zoneId
        time
      }
      avatar
      username
      userId
    }
  }
`;

export const getLightTournament = gql`
  query Query($getTournamentGetTournamentInput: GetTournamentInput) {
    getTournament(getTournamentInput: $getTournamentGetTournamentInput) {
      id
      gameId
      rankingOrder
      fee
      currency
      costPerUser
      status
      expiredAt
      startedAt
      momentType
      momentId
      tournamentType
      prizePool
      calculatedPrizePool
      topScore
      totalPrize
      playerCount
      maxPlayers
      moment {
        id
        appId
        app {
          useSimulation
          title
          type
          category
          rotationMode
        }
        description
        snapshotId
        title
        unlockXp
        timesPlayed
        momentType
        isCompleted
        zoneId
        time
      }
      highestScore
      avatar
      username
      userId
      tournamentCategories
    }
  }
`;

export const listOpenTournaments = gql`
  query Query(
    $listOpenTournamentsListOpenTournamentsInput: ListOpenTournamentsInput
    $leaderboardsGetLeaderboardInput: GetLeaderboardsInput
  ) {
    listOpenTournaments(listOpenTournamentsInput: $listOpenTournamentsListOpenTournamentsInput) {
      id
      gameId
      momentId
      momentType
      leaderboards(getLeaderboardInput: $leaderboardsGetLeaderboardInput) {
        id
        userId
        tournamentId
        user {
          id
          username
          avatar
        }
        score
        startedAt
        endedAt
        status
      }
      startedAt
      expiredAt
      status
      currency
      costPerUser
      fee
      rankingOrder
      highestScore
      tournamentType
      moment {
        id
        appId
        app {
          useSimulation
          title
          type
          category
        }
        snapshotId
        title
        unlockXp
        timesPlayed
        momentType
        isCompleted
        zoneId
      }
    }
  }
`;

export const getTournamentWithRank = gql`
  query Query(
    $getTournamentGetTournamentInput: GetTournamentInput
    $leaderboardsGetLeaderboardInput: GetLeaderboardsInput
  ) {
    getTournament(getTournamentInput: $getTournamentGetTournamentInput) {
      id
      gameId
      myRank
      leaderboards(getLeaderboardInput: $leaderboardsGetLeaderboardInput) {
        status
        endedAt
        startedAt
        score
        user {
          xp
          banner
          avatar
          username
          id
        }
        userId
        tournamentId
        id
      }
      calculatedPrizePool
      topScore
      playerCount
      rankingOrder
      fee
      currency
      costPerUser
      status
      totalPrize
      expiredAt
      startedAt
      momentType
      momentId
      tournamentType
      moment {
        id
        appId
        app {
          useSimulation
          title
          type
          category
        }
        description
        snapshotId
        title
        unlockXp
        timesPlayed
        momentType
        isCompleted
        zoneId
        type
      }
      prizePool
    }
  }
`;

export const listTournament = gql`
  query ListTournament($listTournamentInput: ListTournamentInput) {
    listTournament(listTournamentInput: $listTournamentInput) {
      id
      gameId
      momentId
      momentType
      expiredAt
      startedAt
      status
      currency
      costPerUser
      fee
      rankingOrder
      playerCount
      highestScore
      moment {
        id
        appId
        snapshotId
        title
        description
        type
        playCost
        unlockXp
        time
        unlockCost
        showTimer
        momentType
        rankOrder
        status
        app {
          useSimulation
          id
          title
          category
        }
        isCompleted
        zoneId
        createdAt
        updatedAt
      }
      prizePool
      totalPrize
      tournamentType
      maxPlayers
      myRank
    }
  }
`;

export const listBeatIt = gql`
  query ListBeatIt {
    listBeatIt {
      tournamentInfo {
        id
        tournamentType
        tournamentCategories
        currency
        gameId
        momentType
        topScore
        username
        avatar
        expiredAt
        startedAt
        playedAt
        playerCount
        maxPlayers
        costPerUser
        userId
        moment {
          title
          app {
            useSimulation
            title
            category
            type
          }
        }
      }
    }
  }
`;
