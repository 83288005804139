import React, { FC, HTMLAttributes } from "react";
import clsx from "classnames";
import { Dialog, Typography, Button, Grid } from "../ComponentExport";
import { ReactComponent as CloseIcon } from "../../../assets/ArenaItemRow/Close.svg";
import { ReactComponent as LogoutIcon } from "../../../assets/Menu/LogoutIcon.svg";
import { useStyles } from "./LeaveGamePopup.style";

interface ILeaveGamePopupProps {
  open: boolean;
  onCloseCallback: Function;
  onConfirmCallback: Function;
}

export const LeaveGamePopup: FC<ILeaveGamePopupProps & HTMLAttributes<HTMLElement>> = (props) => {
  const { open, onCloseCallback, onConfirmCallback, ...otherProps } = props;
  const classes = useStyles();

  return (
    <Dialog {...otherProps} className={clsx(classes.root, props.className)} open={open} onClose={onCloseCallback}>
      <Grid display="flex" justifyContent="center">
        <LogoutIcon className={classes.iconLogout} />
        <CloseIcon className={classes.iconClose} onClick={() => onCloseCallback()} />
      </Grid>
      <Typography variant="body2" className={classes.title}>
        Leave game session
      </Typography>
      <Typography className={classes.desc}>Are you sure you want to leave? You will miss the bonus prize.</Typography>
      <Button variant="contained" className={classes.notNowBtn} onClick={() => onCloseCallback()}>
        Not Now
      </Button>
      <Button variant="text" className={classes.confirmButton} onClick={() => onConfirmCallback()}>
        Confirm
      </Button>
    </Dialog>
  );
};
