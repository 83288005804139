export enum ACTION_TYPES {
  ADD_FRIEND = "ADD_FRIEND",
  USER_SIGNUP = "USER_SIGNUP",
}

export enum ModalContent {
  title = "Don’t miss out on any action!",
  description = "Enable notifications to get the most from your account.",
}

export enum AddFriendModalContent {
  title = "Request sent!",
  description = "Enable notifications and we’ll let you know when your request is accepted.",
}
