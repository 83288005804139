import gql from "graphql-tag";

export const listFriendsSent = gql`
  query ListFriendsSent(
    $senderId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFriendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFriendsSent(
      senderId: $senderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        receiverId
      }
      nextToken
    }
  }
`;
export const listFriendsReceived = gql`
  query ListFriendsReceived(
    $receiverId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFriendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFriendsReceived(
      receiverId: $receiverId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        senderId
      }
      nextToken
    }
  }
`;

export const listFriendsSentV2 = gql`
  query listFriendsSentV2 {
    listFriendsSentV2 {
      id
      receiverId
      receiver {
        id
        username
        avatar
        xp
      }
      createdAt
    }
  }
`;

export const listFriendsReceivedV2 = gql`
  query listFriendsReceivedV2 {
    listFriendsReceivedV2 {
      id
      senderId
      accepted
      sender {
        id
        username
        avatar
        xp
      }
      createdAt
    }
  }
`;
