import { ASSETS_ENDPOINT_V2 } from "../constants/endpoint";
import GameCardPlaceholder from "../assets/PlaceholderImages/GameCardPlaceholder.png";
import GameInfoPlaceholder from "../assets/PlaceholderImages/GameInfoPlaceholder.png";
import GameMomentPlaceholder from "../assets/PlaceholderImages/GameMomentPlaceholder.png";
import GamePreloadPlaceholder from "../assets/PlaceholderImages/GamePreloadPlaceholder.png";

export const getHorizontal = (gameId: number | string) => ASSETS_ENDPOINT_V2 + gameId + "/horizontal.png";
export const getVertical = (gameId: number | string) => ASSETS_ENDPOINT_V2 + gameId + "/vertical.png";
export const getSquare = (gameId: number | string) => ASSETS_ENDPOINT_V2 + gameId + "/square.png";
export const getPreloading = (gameId: number | string) => ASSETS_ENDPOINT_V2 + gameId + "/preloading.png";

export const getGameCard = (gameId: any) => {
  console.log(ASSETS_ENDPOINT_V2 + gameId + "/GameCard/Image_424x560.png");
  return ASSETS_ENDPOINT_V2 + gameId + "/GameCard/Image_424x560.png";
};
export const onErrorGetGameCard = (id: any) => {
  document.getElementById(id)?.setAttribute("src", GameCardPlaceholder);
};

export const getGameInfo = (gameId: any) => {
  return ASSETS_ENDPOINT_V2 + gameId + "/GameInfo/Image_750x522.png";
};
export const onErrorGetGameInfo = (id: any) => {
  document.getElementById(id)?.setAttribute("src", GameInfoPlaceholder);
};

export const getGameMoment = (gameId: any) => {
  return ASSETS_ENDPOINT_V2 + gameId + "/GameMoment/Image_1072x500.png";
};
export const onErrorGetGameMoment = (id: any) => {
  document.getElementById(id)?.setAttribute("src", GameMomentPlaceholder);
};

export const getGamePreload = (gameId: any) => {
  return ASSETS_ENDPOINT_V2 + gameId + "/GamePreload/Image_750x732.png";
};
export const onErrorGetGamePreload = (id: any) => {
  document.getElementById(id)?.setAttribute("src", GamePreloadPlaceholder);
};

export const getDiscoveryCardTournament = (gameId: string) => ({
  oneX: ASSETS_ENDPOINT_V2 + gameId + "/DisGameTournament/Image_275x186.png",
  twoX: ASSETS_ENDPOINT_V2 + gameId + "/DisGameTournamentX2/Image_550x372.png",
  threeX: ASSETS_ENDPOINT_V2 + gameId + "/DisGameTournamentX3/Image_825x558.png",
});

export const getDiscoveryBattle = (gameId: string) => ({
  oneX: ASSETS_ENDPOINT_V2 + gameId + "/DisBattle/Image_67x67.png",
  twoX: ASSETS_ENDPOINT_V2 + gameId + "/DisBattleX2/Image_134x134.png",
  threeX: ASSETS_ENDPOINT_V2 + gameId + "/DisBattleX3/Image_201x201.png",
});

export const getDiscoveryPremium = (gameId: string) => ({
  oneX: ASSETS_ENDPOINT_V2 + gameId + "/DisGamePremium/Image_185x185.png",
  twoX: ASSETS_ENDPOINT_V2 + gameId + "/DisGamePremiumX2/Image_370x370.png",
  threeX: ASSETS_ENDPOINT_V2 + gameId + "/DisGamePremiumX3/Image_555x555.png",
});

export const getBattleGame = (gameId: string) => ({
  oneX: ASSETS_ENDPOINT_V2 + gameId + "/battleGames/Image_175x243.png",
  twoX: ASSETS_ENDPOINT_V2 + gameId + "/battleGamesX2/Image_350x486.png",
  threeX: ASSETS_ENDPOINT_V2 + gameId + "/battleGamesX3/Image_525x729.png",
});

export const getBattleTournament = (gameId: string) => ({
  oneX: ASSETS_ENDPOINT_V2 + gameId + "/battleTournament/Image_358x208.png",
  twoX: ASSETS_ENDPOINT_V2 + gameId + "/battleTournamentX2/Image_716x416.png",
  threeX: ASSETS_ENDPOINT_V2 + gameId + "/battleTournamentX3/Image_1074x624.png",
});
