import React, { HTMLAttributes, FC } from "react";
import { THEME_NAME } from "../../../utils/theme";
import { ReactComponent as SafaricomEditIcon } from "../../../assets/friend_icons/safaricom-edit.svg";
import { ReactComponent as ZainEditIcon } from "../../../assets/friend_icons/zain-edit.svg";
import { ReactComponent as OnmoEditIcon } from "../../../assets/friend_icons/onmo-edit.svg";
import { ReactComponent as DialogEditIcon } from "../../../assets/friend_icons/dialog-edit.svg";
import { ReactComponent as VodafoneIndiaEditIcon } from "../../../assets/friend_icons/vodafone-india-edit.svg";
import { ThemeName } from "../../../constants/theme";

export const EditIcon: FC<HTMLAttributes<HTMLOrSVGElement>> = (props) => {
  switch (THEME_NAME) {
    case ThemeName.VodafoneIndia: {
      return <VodafoneIndiaEditIcon {...props} />;
    }
    case ThemeName.Safaricom: {
      return <SafaricomEditIcon {...props} />;
    }
    case ThemeName.Zain: {
      return <ZainEditIcon {...props} />;
    }
    case ThemeName.Dialog: {
      return <DialogEditIcon {...props} />;
    }
    default:
      return <OnmoEditIcon {...props} />;
  }
};
