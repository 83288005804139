import React, { HTMLAttributes, FC } from "react";
import { Grid, Avatar, Typography, Button } from "../ComponentExport";
import clsx from "classnames";
import { useTranslation } from "react-i18next";
import { TimelineVideo } from "../../Common/TimelineVideo";
import { IOnboardingGame } from "../../../types/baseInterfaces";
import { ReactComponent as HeartInactiveIcon } from "../../../assets/Game/heart-inactive.svg";
import { ReactComponent as HeartActiveIcon } from "../../../assets/Game/heart-active.svg";
import { ReactComponent as ShareIcon } from "../../../assets/Game/onboarding-share.svg";
import { getHorizontal } from "../../../utils/getGameImages";
import { getCategoryString } from "../../../utils/game";
import ShareNative from "../../../utils/nativeSharing";
import { useStyles } from "./ContentOnboarding.style";
import { Analytics } from "../../../modelsv2/analytics";
import { CATEGORY } from "../../../constants/analyticsEvent";

interface IContentOnboardingProps {
  videoDuration: number;
  game: IOnboardingGame;
  onPlayVideo: Function;
  isPause?: boolean;
  onToggleLike: Function;
  onPlayGame: Function;
  loadedPercentage: number;
  onSetVideoTime: Function;
  isStarted: boolean;
}

export const ContentOnboarding: FC<IContentOnboardingProps & HTMLAttributes<HTMLDivElement>> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    videoDuration,
    game,
    onPlayVideo,
    isPause,
    onToggleLike,
    onPlayGame,
    loadedPercentage,
    onSetVideoTime,
    isStarted,
    ...otherProps
  } = props;

  const onShare = (title: string) => {
    Analytics.trackEvent({
      category: CATEGORY.ONBOARDING,
      action: "share_game",
      label: title,
      moment_type: "Battle",
      game_type: "HTML",
      currency: "Cash",
    });
    try {
      const shareData: ShareData = {
        title: t("contentOnboarding_You’re in the mood to just beat it"),
        text: t("contentOnboarding_ONMO blends current trends like social play"),
      };
      ShareNative(shareData, "");
    } catch (error) {
      console.error(`Fail to share game`, error);
    }
  };

  return (
    <div {...otherProps}>
      <TimelineVideo
        timeInSeconds={videoDuration}
        isPause={isPause}
        onToggleVideo={onPlayVideo}
        onSetVideoTime={onSetVideoTime}
      />
      <Grid display="flex" alignItems="center" justifyContent="space-between" className={classes.main}>
        <Grid display="flex" alignItems="center">
          <Avatar src={getHorizontal(game?.id)} alt="game-img" className={classes.gameImg} />
          <div className={classes.gameIntro}>
            <Typography>{game?.title}</Typography>
            <Typography>{getCategoryString(game?.category).replace(/,/g, "/")}</Typography>
          </div>
        </Grid>
        <Grid display="flex" className={classes.action}>
          <div>
            {game?.isLike ? (
              <HeartActiveIcon onClick={() => onToggleLike(game?.id)} />
            ) : (
              <HeartInactiveIcon onClick={() => onToggleLike(game?.id)} />
            )}
            <Typography>{game?.likeCount}</Typography>
          </div>
          <div onClick={() => onShare(game?.title)}>
            <ShareIcon />
            <Typography>Share</Typography>
          </div>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        className={clsx(classes.btnPlayNow, loadedPercentage === 100 && classes.btnLoaded)}
        onClick={() => onPlayGame(game?.title)}>
        {loadedPercentage === 100 && !isStarted && "Try It Now!"}
        {loadedPercentage < 100 && !isStarted && `Loading... ${loadedPercentage}%`}
      </Button>
    </div>
  );
};
