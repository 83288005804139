import gql from "graphql-tag";

export const playTournament = gql`
  mutation Mutation($playTournamentInput: PlayTournamentInput) {
    playTournament(playTournamentInput: $playTournamentInput) {
      wallets {
        type
        balance
        currency
      }
    }
  }
`;

export const enterTournament = gql`
  mutation EnterTournament($enterTournamentInput: EnterTournamentInput) {
    enterTournament(enterTournamentInput: $enterTournamentInput) {
      edgeNodeId
      gameSessionId
      tournamentPlayerId
    }
  }
`;
