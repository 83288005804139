import { createUseStyles } from "react-jss";

// @ts-ignore
export const useStyles = createUseStyles(() => ({
  mainContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  resetPwdDiv: {
    display: "flex",
    alignItems: "baseline",
  },
  phoneConfirmationContainer: {
    maxWidth: "560px",
    paddingTop: "40px",
    paddingLeft: "20px",
    paddingRight: "20px",
    "@media(max-height: 667px)": {
      paddingTop: "32px",
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      paddingTop: "36px",
    },
  },
  digitGrid: {
    height: "68px",
    margin: "auto",
    width: "100%",
    "@media (min-width: 375px)": {
      maxWidth: "340px",
      margin: "40px auto 60px auto",
    },
    "@media (min-width: 360px) and (max-width: 374px)": {
      margin: "4px 8px 28px 8px",
    },
    "@media (min-width: 320px) and (max-width: 359px)": {
      margin: "4px 8px 24px 8px",
    },
  },
  digitContainer: {
    position: "relative",
    maxWidth: "68px",
    maxHeight: "68px",
    width: "100%",
    paddingBottom: "100%",
  },
  digitInput: {
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "#FAFAFA",
    borderRadius: "30%",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "100%",
    letterSpacing: "-0.2px",
    textAlign: "center",
    border: "none",
    outline: "none",
    color: "transparent",
    textShadow: "0 0 0 #222222",
    userSelect: "none",
    WebkitUserSelect: "none",
    WebkitTouchCallout: "none",
    ".MuiGrid-grid-xs-2 &": {
      fontSize: "22px",
    },
    "&::selection": {
      color: "transparent",
    },
    "&:focus": {
      outline: "none",
      boxShadow: "0 0 0 2px #DEEFEA",
    },
    "&:focus-within": {
      outline: "none",
      boxShadow: "0 0 0 2px #DEEFEA",
    },
    "&:focus-visible": {
      outline: "none",
      boxShadow: "0 0 0 2px #DEEFEA",
    },
    "& label:focus": {
      outline: "none",
    },
    "& label:focus-within": {
      outline: "none",
    },
    "& label:focus-visible": {
      outline: "none",
    },
  },
  linkTypography: {
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "-0.2px",
    color: "#3C3C3C",
    marginTop: "16px",
    "@media(max-height: 667px)": {
      fontSize: "12px",
      lineHeight: "16px",
      marginTop: "12px",
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      fontSize: "13px",
      lineHeight: "18px",
      marginTop: "14px",
    },
  },
  backButton: {
    fontSize: "15px",
    lineHeight: "20px",
    letterSpacing: "-0.2px",
    paddingLeft: "0px",
    margin: "0px",
    justifyContent: "flex-start",
    "@media(max-height: 667px)": {
      fontSize: "12px",
      lineHeight: "16px",
    },
    "@media (min-height: 668px) and (max-height: 736px)": {
      fontSize: "13px",
      lineHeight: "18px",
    },
    "& > svg": {
      fontSize: 15,
    },
  },
  title: {
    textAlign: "center",
  },
}));
