import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  videoGame: {
    width: "100%",
    height: "100%",
  },
  content: {
    position: "absolute",
    width: "100%",
    padding: 15,
    boxSizing: "border-box",
    transition: "top 0.3s",
    zIndex: 10,
  },
  playIcon: {
    position: "absolute",
    left: "47%",
    top: "47%",
  },
}));
