import { map, sumBy } from "lodash-es";
import { UserWalletType } from "../types/enums";
import { UserWallet } from "../types/others";

export const getUserWallets = (currency: string, wallets: UserWallet[]) => {
  const getVirtualCoins = (): number | undefined => {
    const virtualWallet = wallets?.find((item: UserWallet) => item.type === UserWalletType.VIRTUAL);
    return virtualWallet?.balance;
  };
  const getWinningsCash = (): number | undefined => {
    const winningsWallet = wallets?.find(
      (item: UserWallet) => item.type === UserWalletType.WINNINGS && item.currency === currency
    );
    return winningsWallet?.balance;
  };
  const getDepositCash = (): number | undefined => {
    const depositWallet = wallets?.find(
      (item: UserWallet) => item.type === UserWalletType.DEPOSIT && item.currency === currency
    );
    return depositWallet?.balance;
  };
  const getBonusCash = (): number | undefined => {
    const bonusWallet = wallets?.find(
      (item: UserWallet) => item.type === UserWalletType.BONUS && item.currency === currency
    );
    return bonusWallet?.balance;
  };

  return {
    getVirtualCoins,
    getWinningsCash,
    getDepositCash,
    getBonusCash,
  };
};

export const getTotalCashBalance = (wallets: UserWallet[], currency?: string) => {
  const cashWallets = wallets?.filter(
    (w) => w.type !== UserWalletType.VIRTUAL && (currency ? w.currency === currency : !currency)
  );
  return sumBy(cashWallets, "balance");
};

export const getWalletBalanceByType = (wallets: UserWallet[], walletType: UserWalletType, currency?: string) => {
  return wallets?.find((w) => w.type === walletType && (currency ? w.currency === currency : !currency))?.balance || 0;
};

export const getWalletByType = (wallets: UserWallet[], walletType: UserWalletType, currency?: string) => {
  return wallets?.find((w) => w.type === walletType && (currency ? w.currency === currency : !currency));
};

const getUpdatedWalletsByType = (
  wallets: UserWallet[],
  walletType: UserWalletType,
  balance: number,
  currency?: string
) => {
  return map(wallets, (w) =>
    w.type === walletType && (currency ? w.currency === currency : !currency) ? { ...w, balance } : w
  );
};

export const updateWallets = (wallets: UserWallet[], responseWallets: UserWallet[], currency?: string) => {
  const coinWallets = getWalletByType(responseWallets, UserWalletType.VIRTUAL);
  const depositWallet = getWalletByType(responseWallets, UserWalletType.DEPOSIT, currency);
  const bonusWallet = getWalletByType(responseWallets, UserWalletType.BONUS, currency);
  const winningsWallet = getWalletByType(responseWallets, UserWalletType.WINNINGS, currency);

  let updateWallets: UserWallet[] = wallets;

  if (coinWallets) {
    updateWallets = getUpdatedWalletsByType(updateWallets, UserWalletType.VIRTUAL, coinWallets.balance);
  }
  if (depositWallet) {
    updateWallets = getUpdatedWalletsByType(updateWallets, UserWalletType.DEPOSIT, depositWallet.balance, currency);
  }
  if (bonusWallet) {
    updateWallets = getUpdatedWalletsByType(updateWallets, UserWalletType.BONUS, bonusWallet.balance, currency);
  }

  if (winningsWallet) {
    updateWallets = getUpdatedWalletsByType(updateWallets, UserWalletType.WINNINGS, winningsWallet.balance, currency);
  }

  return updateWallets;
};
