import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "../../Common/ComponentExport";
import CrownIcon from "../../../assets/Chingari/Crown.svg";
import GameIcon from "../../../assets/friend_icons/Game.svg";
import { useStyles } from "./StatsUser.styles";

interface IPropsStatsUser {
  nbOfBeatIt: number;
  nbGamesPlayed: number;
}

export const StatsUser = (props: IPropsStatsUser) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { nbOfBeatIt, nbGamesPlayed } = props;

  return (
    <Grid container className={classes.wrapper}>
      <Grid item xs={6} md={4} lg={3} className={classes.itemStat}>
        <div>
          <img src={CrownIcon} alt="crown" />
          <div>
            <Typography variant="h2">{nbOfBeatIt}</Typography>
            <Typography variant="h6">{t("statsUser_Number of Beat It")}</Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={6} md={4} lg={3} className={classes.itemStat}>
        <div>
          <img src={GameIcon} alt="game" />
          <div>
            <Typography variant="h2">{nbGamesPlayed}</Typography>
            <Typography variant="h6">{t("statsUser_Games Played")}</Typography>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
