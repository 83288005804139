import React, { useEffect, useContext } from "react";
import { AlertCtx } from "../../context/alert/state";
import SuccessNotiIcon from "../../assets/notification_icons/success_icon.svg";
import { useStyles } from "./QuickNotification.style";
import { Alert } from "../Common/ComponentExport";

export function QuickNotification() {
  const classes = useStyles();
  const { setShowNotif, body, classify } = useContext(AlertCtx);

  useEffect(() => {
    const timeoutOpen = setTimeout(() => setShowNotif(false), 3000);
    return () => {
      clearTimeout(timeoutOpen);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <Alert
        dataTestid="alert"
        severity={classify}
        iconMapping={classify === "success" ? SuccessNotiIcon : ""}
        className={classes.alert}
        message={body.message}
      />
    </div>
  );
}

export default QuickNotification;
